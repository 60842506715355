import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "images";
  const { instance, index, store, show, update, patch, destroy } = Repository(
    `${hallId}/${resource}`,
    token
  );
  return {
    index,
    store,
    show,
    update,
    patch,
    destroy,
    checkSameFile: (payload) => instance.post("check-same-file", payload),
    bulkTrim: (payload) => instance.put("bulk-trim", payload),
    replaceImage: (payload) => instance.put("replace", payload),
  };
};
