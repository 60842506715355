<template>
  <select
    class="inputSelectbox"
    v-model="selfValue"
    :class="{ hasError: !isValid }"
    :required="isRequired"
  >
    <option value v-if="defaultText">{{ defaultText }}</option>
    <option
      class="inputSelectbox_option"
      v-for="(option, val) in selfOptions"
      :value="val"
      :selected="selfValue == val"
      :disabled="option.isDisabled"
      :key="val"
    >
      {{ option.name }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    modelValue: [String, Number],
    defaultText: {
      type: String,
      default: "-- 選択 --",
    },
    options: {
      type: [Array, Object],
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tmpIsValid: null,
    };
  },
  computed: {
    selfValue: {
      get() {
        return this.modelValue !== null ? this.modelValue : "";
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    selfOptions() {
      // this.optionsが配列かオブジェクトかで分岐
      if (Array.isArray(this.options)) {
        return this.getOptionsFromArray();
      } else if (typeof this.options === "object") {
        return this.getOptionsFromObject();
      }
    },
    isValid() {
      let isValid;
      if (!this.isRequired) {
        isValid = true;
      } else {
        isValid = this.selfValue === "" || this.selfValue == null ? false : true;
      }
      if (isValid !== this.tmpIsValid) {
        this.$emit("check", isValid);
        this.tmpIsValid = isValid;
      }
      return isValid;
    },
  },
  methods: {
    getOptionsFromArray() {
      let options = {};
      this.options.forEach((item) => {
        options[item] = {
          text: item,
          isDisabled: false,
        };
      });
      return options;
    },
    getOptionsFromObject() {
      let options = {};
      Object.keys(this.options).forEach((key) => {
        let item = this.options[key];
        if (typeof item === "object" && !Array.isArray(item)) {
          if ("isDisabled" in item) {
            options[key] = item;
          } else {
            item.isDisabled = false;
            options[key] = item;
          }
        } else {
          options[key] = {
            text: item,
            isDisabled: false,
          };
        }
      });
      return options;
    },
  },
};
</script>

<style scoped lang="scss">
.inputSelectbox {
  background-color: #fff;
  border-radius: 8px;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid $COLOR_SUB_TEXT;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  padding: 8px;
  font-size: 15px;
  min-width: 2rem;
  cursor: pointer;
  &_option {
    padding: $SUB_GRID;
  }
}
</style>
