<template>
  <div class="importList" :class="classType" :style="styleType">
    <div class="importList_title">
      <p>
        <span>{{ title }}</span>
        <span class="count fontNum-b">{{ count }}</span>
      </p>
      <div class="importList_titleButton">
        <action-button
          v-if="isShowButton"
          text="すべて登録する"
          :is-import="true"
          :is-save="isSelected"
          :is-disabled="count == 0 || isDisabledButton"
          @clickHandler="click"
        />
      </div>
      <unit-menu v-if="isShowMenu">
        <template v-if="isSelected" #menu>
          <menu-btn
            text="すべてやり直し"
            :is-disabled="count == 0 || isDisabledCancel"
            @clickHandler="cancel"
          />
        </template>
        <template v-else #menu>
          <menu-btn
            text="すべて削除する"
            :is-disabled="count == 0 || isDisabledSoftDelete"
            @clickHandler="softDelete"
          />
          <menu-btn
            text="すべて復元する"
            :is-disabled="count == 0 || isDisabledRestore"
            @clickHandler="restore"
          />
        </template>
      </unit-menu>
    </div>
    <div class="importList_list">
      <slot name="list" />
    </div>
  </div>
</template>

<script>
// component
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import MenuBtn from "@/components/atoms/common/MenuButton.vue";
import UnitMenu from "@/components/molecules/common/ListUnitMenu.vue";

export default {
  emits: ["click", "cancel", "softDelete", "restore"],
  components: {
    ActionButton,
    MenuBtn,
    UnitMenu,
  },
  props: {
    title: String,
    count: Number,
    isSelected: Boolean,
    isDisabledButton: Boolean,
    isDisabledCancel: Boolean,
    isDisabledSoftDelete: Boolean,
    isDisabledRestore: Boolean,
    isShowButton: {
      type: Boolean,
      default: true,
    },
    isShowMenu: {
      type: Boolean,
      default: true,
    },
    isImage: Boolean,
    isNav: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classType() {
      return {
        isSelected: this.isSelected,
        isImage: this.isImage,
        isNav: this.isNav,
      };
    },
    styleType() {
      let unitCount = 4;
      const space = 16;
      if (this.isImage) {
        if (this.count < unitCount || this.isSelected) unitCount = 2;
        const imageWidth = 160;
        return {
          width: imageWidth * unitCount + space * (unitCount + 1) + "px",
        };
      }
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
    cancel() {
      this.$emit("cancel");
    },
    softDelete() {
      this.$emit("softDelete");
    },
    restore() {
      this.$emit("restore");
    },
  },
};
</script>

<style scoped lang="scss">
.importList {
  @include Box();
  border-radius: 2px;
  color: $COLOR_KEY;
  flex-shrink: 0;
  margin-right: $GRID;
  min-height: $GRID * 40;
  width: $GRID * 25;
  &.isSelected {
    color: #fff;
    background: $COLOR_IMPORT_SELECTED;
  }
  &_title {
    align-items: center;
    background: inherit;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    padding: $GRID;
    position: sticky;
    top: 0;
    z-index: 5;
    .isNav & {
      top: $IMPORT_NAVI_HEIGHT;
    }
    .isFair .isNav & {
      top: $IMPORT_NAVI_L_HEIGHT;
    }
    .count {
      &::before {
        content: "（";
      }
      &::after {
        content: "）";
      }
    }
    ::v-deep .listUnitMenu {
      color: $COLOR_KEY;
      right: $GRID * 0.75;
      top: $GRID * 0.75;
      &_btn:not(.isActive) {
        color: $COLOR_SUB_LIGHT_TEXT;
        background: #e5e6ef;
      }
    }
    &Button {
      align-items: flex-start;
      display: flex;
      position: relative;
      &:not(:last-child) {
        right: $GRID * 2.5;
      }
    }
  }
  &_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: $GRID;
    position: relative;
    z-index: 1; // InnorpableCover が ListUnitMenu より下に表示されるように設定
    .isImage & {
      margin-left: -$GRID;
    }
  }
}
</style>
