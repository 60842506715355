export default {
  /**
   * 基本設定
   */
  includeDailyFair: ({ hp_should_include_daily_fair_to_fair_master }) =>
    hp_should_include_daily_fair_to_fair_master,
  isLinkedHpByAPI: ({ link_type, master }) =>
    master.link_type && link_type == master.link_type.API,
  isFairMasterIncludesDaily: ({
    hp_should_include_daily_fair_to_fair_master,
  }) => hp_should_include_daily_fair_to_fair_master == 1,

  /**
   * フェア
   */
  stockUnitType: ({ hp_stock_unit_type }) => hp_stock_unit_type,
  benefitGroupType: ({ hp_benefit_group_type }) => hp_benefit_group_type,

  /**
   * プラン
   */
  planChargeType: ({ hp_plan_charge_type }) => hp_plan_charge_type,
  planBenefitGroupType: ({ hp_plan_benefit_group_type }) =>
    hp_plan_benefit_group_type,
  planContentType: ({ hp_plan_content_type }) => hp_plan_content_type,

  /**
   * トークン
   */
  token: ({ token }) => token,
  fetchingToken: ({ fetchingToken }) => fetchingToken,
  hasErrorToken: ({ hasErrorToken }) => hasErrorToken,

  /**
   * その他
   */
  fetchingSetting: ({ fetchingSetting }) => fetchingSetting,
  failedFetchSetting: ({ failedFetchSetting }) => failedFetchSetting,
};
