import isSuspended from "@/store/common/helper/is-suspended.js";

const mutations = {
  /**
   * 式場の状態別の合計
   */
  SET_HALL_COUNT(state) {
    let count = {
      all: 0,
      before: 0, // 開始前
      preparation_started_at: 0,
      account_created_at: 0,
      // import_started_at: 0,
      import_ended_at: 0,
      // associate_started_at: 0,
      associate_ended_at: 0,
      data_created_at: 0,
      // published_at: 0,
      ready: 0, // アカウント入力中 ～ 確認中
      usage_started_at: 0,
      suspended_at: 0,
      is_test: 0,
    };
    state.hall.list.forEach((d) => {
      if (d.is_test) {
        count.is_test++;
        return;
      }
      count.all++;
      // 終了
      if (isSuspended(d.suspended_at)) count.suspended_at++;
      // 利用中
      else if (d.usage_started_at && d.in_preparation_target.length === 0) count.usage_started_at++;
      // else if (d.published_at) count.published_at++;
      // 確認
      else if (d.data_created_at) count.data_created_at++;
      // メンテナンス
      else if (d.associate_ended_at) count.associate_ended_at++;
      // else if (d.associate_started_at) count.associate_started_at++;
      // 紐付け
      else if (d.import_ended_at) count.import_ended_at++;
      // else if (d.import_started_at) count.import_started_at++;
      // インポート
      else if (d.account_created_at) count.account_created_at++;
      // アカウント入力
      else if (d.preparation_started_at) count.preparation_started_at++;
    });
    state.hall.count = count;
  },
  /**
   * 式場一覧
   * @param {Object} data 式場一覧
   */
  SET_HALL_DATA(state, data) {
    state.hall.list = data;
  },
  /**
   * 式場一覧マスターデータ
   * @param {*} master マスターデータ
   */
  SET_HALL_MASTER(state, master) {
    state.hall.master = master;
  },
  /**
   * 個別式場のコメントを更新
   * @param /halls/comment 実行した response.data {
   *   hall_id, comment
   * }
   */
  UPDATE_HALL_COMMENT(state, { hall_id, comment }) {
    const hall = state.hall.list.find((hall) => hall.hall_id == hall_id);
    if (!hall) return;
    hall.comment = comment;
  },
  /**
   * エラーテキスト
   * @param {String} payload エラーテキスト
   */
  SET_ERROR_TEXT(state, payload) {
    state.errorText = payload;
  },
  /**
   * 各ステップの権限
   * @param {Object} payload { type, value }
   */
  SET_IS_AUTHORIZED(state, payload) {
    const { type, value } = payload;
    state.isAuthorized[type] = value;
  },
};

export default mutations;
