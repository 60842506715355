<template>
  <div class="filterBlock">
    <p class="filterBlockTit">
      <i class="material-icons">search</i>
      <span>{{ title }}検索</span>
    </p>
    <div class="filterBlockInr">
      <div class="filterBlockInr-l">
        <div>
          <slot name="filter-l" />
        </div>
      </div>
      <div class="filterBlockInr-r">
        <div>
          <slot name="filter-r" />
        </div>
      </div>
    </div>
    <div class="filterBlockInr-b">
      <sort-select :sort-item="sortItem" :default-sort="defaultSort" @update="updateValue" />
      <div>
        <slot name="filter-b" />
      </div>
    </div>
  </div>
</template>

<script>
import SortSelect from "@/components/atoms/common/SortSelect.vue";

export default {
  components: {
    SortSelect,
  },
  props: {
    title: String,
    sortItem: {
      type: Object,
      default: () => ({}),
    },
    defaultSort: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("update", value);
    },
  },
};
</script>

<style lang="scss">
// TODO: 暫定対応: compat対応時にスタイル崩れが発生したため、scopedを一時的に削除
.filterBlock {
  @include DentedBox();
  box-sizing: border-box;
  display: flex;
  padding: $GRID $GRID 22px;
  position: relative;
  width: 100%;
  &Tit {
    border-right: 1px solid #e2e2e2;
    color: $COLOR_KEY;
    font-size: 14px;
    font-weight: bold;
    width: $GRID * 8;
    .material-icons {
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  &Inr {
    display: flex;
    padding: 0 0 20px $GRID;
    &-l {
      width: $GRID * 24;
    }
    &-r {
      padding-left: $GRID;
    }
    &-b {
      bottom: $GRID * 0.5;
      right: $GRID * 0.5;
      position: absolute;
    }
  }
  .filterBlockItem {
    &.isSetTit {
      align-items: center;
      display: flex;
    }
    &Tit {
      font-size: 12px;
    }
  }
  .filterInput + .filterBlockItem,
  .filterBlockItem + .filterInput,
  .filterInput + .filterInput,
  .filterBlockItem + .filterBlockItem {
    margin-top: 12px;
  }
  .filterBlockItem.isCheckbox {
    & + .filterBlockItem.isCheckbox {
      margin-top: 3px;
    }
  }
}
</style>
