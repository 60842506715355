// 画面で選択されている特典種別の値を、選択されている掲載媒体にコピーする
const fillBenefitType = (params, mediaIds) => {
  const { type } = params;
  const benefitType = Object.values(type).find((value) => value !== null);
  const filledType = mediaIds.map((mediaId) => [mediaId, benefitType]);
  const types = { ...type, ...Object.fromEntries(filledType) };

  return { ...params, type: types };
};

export default fillBenefitType;
