<template>
  <div class="inputFieldBox" :class="className">
    <component
      class="inputFieldBox_textarea"
      v-model="selfText"
      :is="textComponent"
      :placeholder="placeholder"
      :max-length="maxLength"
      :cols="cols"
      :size="cols"
      :rows="rows"
      :is-disabled="isDisabled"
      :is-required="isRequired"
      :has-limit="hasLimit"
      :hasError="!isValid"
    />
    <p class="inputFieldBox_ng" v-if="hasNgWords">
      <span class="inputFieldBox_ngTit">使用不可ワード：</span>
      <span>{{ ngWordMsg }}</span>
    </p>
    <p class="inputFieldBox_count" v-else-if="hasLengthLabel">
      {{ textLengthMsg }}
    </p>
  </div>
</template>

<script>
import { countTextLength, getTextLengthNote } from "@/lib/countTextLength";
import InputText from "@/components/atoms/common/InputText.vue";
import InputTextarea from "@/components/atoms/common/InputTextarea.vue";

export default {
  name: "ValidatingTextarea",
  emits: ["check", "update:modelValue"],
  components: {
    InputText,
    InputTextarea,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    showLengthLabel: {
      type: Boolean,
      default: null,
    },
    maxLength: {
      // 最大文字数
      type: Number,
      default: 100,
    },
    cols: {
      // 1行当たりの最大文字数目安、主にtextarea用
      type: Number,
      default: null,
    },
    rows: {
      // textareaで指定の行数を使いたい場合用
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    isTextarea: {
      type: Boolean,
      default: null,
    },
    isFullSize: {
      // width:100%で使用したい場合
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    needValidate: {
      // isRequired = false でもバリデーションチェックを返すか
      type: Boolean,
      default: false,
    },
    useErrorCode: {
      // バリデーションチェックでBoolではなくエラーコードを返すようにするか
      type: Boolean,
      default: false,
    },
    needRequireAlert: {
      // 入力エラー時に色を変えるか
      // 初回入力時等、エラー時に色を変えたくない場合はfalseにすること
      type: Boolean,
      default: true,
    },
    ngWord: {
      // NGワード
      type: Array,
      default: () => [],
    },
    mediaIds: {
      // 対象媒体（文字数カウント方式の判別に使用）
      type: [Array, Number],
      default: () => [],
    },
    // 改行コードをカウントするかどうか(lfc = line feed code)
    countLfc: {
      type: Boolean,
      default: true,
    },
    // 半角文字数のカウントを強制的に1文字にするかどうか
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorCodes: {
        valid: "no_error",
        over: "too_many",
        empty: "empty",
        ng_word: "ng_word",
      },
      tmpIsValid: null,
    };
  },
  computed: {
    selfText: {
      get() {
        const text = this.modelValue;
        return text == null ? "" : text;
      },
      set(value) {
        value = value === "" ? null : value; // 空文字はnullに統一
        if (value !== this.modelValue) {
          this.$emit("update:modelValue", value);
        }
      },
    },
    textComponent() {
      if (!this.hasLimit) {
        return "InputText";
      }
      const isTextarea = this.isTextarea,
        cols = this.cols;
      if (isTextarea === null) {
        if (cols) {
          let rowCount = this.maxLength / cols;
          return rowCount > 1 ? "InputTextarea" : "InputText";
        }
      }
      return isTextarea ? "InputTextarea" : "InputText";
    },
    hasLengthLabel() {
      return this.showLengthLabel === null ? this.hasLimit : this.showLengthLabel;
    },
    hasLengthError() {
      const maxLength = this.maxLength;
      return maxLength && maxLength < this.length;
    },
    hasRequiredError() {
      if (!this.isRequired && !this.needValidate) {
        return false;
      }
      return this.selfText ? false : true;
    },
    hasNgWords() {
      const text = this.selfText;
      if (!text) return false;
      return this.ngWord.some((ng) => text.indexOf(ng) !== -1);
    },
    textLengthMsg() {
      const maxLength = this.maxLength,
        mediaIds = this.mediaIds;
      if (this.hasLengthError) {
        return `${this.length - maxLength}字オーバーしています`;
      }
      if (this.showRequiredError) {
        return "この項目は入力必須です";
      }
      const note = getTextLengthNote(mediaIds, this.fullWidth);
      return `文字数${note}: ${this.length}/${maxLength}`;
    },
    ngWordMsg() {
      let word = "";
      if (this.hasNgWords) {
        const text = this.selfText,
          ngList = this.ngWord.filter((ng) => text.indexOf(ng) !== -1);
        word = ngList.join("、");
      }
      return word;
    },
    showRequiredError() {
      return this.needRequireAlert && this.isRequired && !this.selfText;
    },
    hasLimit() {
      return this.maxLength !== 0;
    },
    isValid() {
      const hasRequiredError = this.hasRequiredError,
        hasLengthError = this.hasLengthError,
        hasNgWords = this.hasNgWords;

      let isValid = hasRequiredError || hasLengthError || hasNgWords ? false : true;
      let data;
      if (this.useErrorCode) {
        const code = hasLengthError
          ? "over"
          : hasRequiredError
          ? "empty"
          : hasNgWords
          ? "ng_word"
          : "valid";
        data = this.errorCodes[code];
      } else {
        data = isValid;
      }
      this.sendIsValid(data);

      isValid = !(this.showRequiredError || hasLengthError || hasNgWords);
      return isValid;
    },
    className() {
      return {
        isFullSize: this.isFullSize,
        hasError: !this.isValid,
        showLabel: this.showLengthLabel,
      };
    },
    length() {
      return countTextLength(this.modelValue, this.mediaIds, this.countLfc, this.fullWidth);
    },
  },
  methods: {
    sendIsValid(isValid) {
      if (isValid !== this.tmpIsValid) {
        this.$emit("check", isValid);
        this.tmpIsValid = isValid;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.inputFieldBox {
  display: inline-block;
  position: relative;

  &.hasError & {
    &_count {
      color: $COLOR_ALERT;
    }
  }
  &.isFullSize {
    width: 100%;
  }
  &.isFullSize & {
    &_textarea {
      width: 100% !important;
    }
  }

  &_count {
    font-size: $FONT_SIZE_SS;
    color: $COLOR_SUB_TEXT;
    text-align: right;
    line-height: 1;
    margin-top: $SUB_GRID;
  }
  &_ng {
    color: $COLOR_ALERT;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    &Tit {
      font-size: $FONT_SIZE_SS;
    }
  }
}
</style>
