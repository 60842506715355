/**
 * オブジェクトを値渡しにするための関数
 * 下の階層もオブジェクトの場合、参照渡しになるため
 * 一旦 JSON にして文字列にしている
 * @param {Object} object
 */
const deepCopy = object => {
  return JSON.parse(JSON.stringify(object));
};
export default deepCopy;
