<template>
  <modal-window :close-text="closeText" @close="$emit('cancel')">
    <template #text>
      <animated-icon
        :is-delete="isDelete"
        :is-private="isPrivate"
        :is-next="isNext"
        :is-note="isNote"
        :icon="icon"
        :color-type="colorType"
      />
      <p class="text confirmText" :class="textClass">{{ text }}</p>
      <p v-if="subText" class="text confirmSubText">{{ subText }}</p>
    </template>
    <template #footerBtn v-if="buttonText">
      <action-button
        :is-large="!isSave"
        :is-alert-fill="isDelete || isPrivate"
        :is-save="isSave"
        :text="buttonText"
        @clickHandler="$emit('submit')"
      />
    </template>
  </modal-window>
</template>

<script>
import { ModalColorType } from "@/assets/modal/modal-color-type";

import ModalWindow from "@/components/molecules/common/ModalWindow.vue";
import AnimatedIcon from "@/components/atoms/common/AnimatedIcon.vue";
import ActionButton from "@/components/atoms/common/ActionButton.vue";

export default {
  components: {
    ModalWindow,
    AnimatedIcon,
    ActionButton,
  },
  props: {
    closeText: {
      type: String,
      default: null,
    },
    colorType: {
      type: Number,
      default: null,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    isNext: {
      type: Boolean,
      default: false,
    },
    isSave: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "textsms",
    },
    text: {
      type: String,
      default: null,
    },
    subText: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
  },
  computed: {
    isNote() {
      return !this.isDelete && !this.isPrivate && !this.isNext;
    },
    textClass() {
      return this.colorType === ModalColorType.ALERT ? "alert" : null;
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  white-space: pre-wrap;

  &.alert {
    color: $COLOR_ALERT;
  }
}
.confirmText {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
}
.confirmSubText {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.75;
  margin-top: $GRID;
}
</style>
