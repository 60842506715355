import { getCookieAccount } from "@/lib/cookie";

import { createAxiosInstance } from "./repository";

const index = async (apiUrl, params) => {
  const resource = getResource(apiUrl);
  const { token } = getCookieAccount();
  const instance = createAxiosInstance(token);
  return await instance.get(resource, { params });
};

const getResource = (apiUrl) => {
  const { userable_type, hall_id, userable_id } = getCookieAccount();
  if (userable_type === "Hall" || hall_id !== null) {
    const hallId = hall_id || userable_id;
    return `/${hallId}${apiUrl}`;
  }
  return userable_type === "Company" ? `/companies/${userable_id}${apiUrl}` : `${apiUrl}`;
};

const indexUsers = async (payload) => index("/users", payload);
const indexAssignableRoles = async () => index("/assignable-roles");
const indexIncompletedJobs = async (payload) => index("/jobs/incomplete", payload);
const indexDoneLogs = async (payload) => index("/logs/done", payload);
const indexHistories = async (payload) => index("/histories", payload);

export { indexUsers, indexAssignableRoles, indexIncompletedJobs, indexDoneLogs, indexHistories };
