<template>
  <button
    type="button"
    class="iconBtn"
    :class="className"
    @click.stop="onClick()"
  >
    <i class="material-icons">{{ icon }}</i>
    <span class="iconBtnText">{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    clickHandler: Function,
    icon: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    text: String,
    type: {
      type: String,
      default: null,
    },
    withBg: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    className: function() {
      const c = {
        disabled: this.isDisabled,
        withBg: this.withBg,
      };
      if (this.type) {
        c[this.type] = true;
      }
      return c;
    },
  },
  methods: {
    onClick: function() {
      this.$emit("clickHandler");
    },
  },
};
</script>

<style scoped lang="scss">
.iconBtn {
  @include btn(rgba($COLOR_KEY, 0.1));
  border: 1px solid $COLOR_KEY;
  border-radius: $GRID;
  box-shadow: $DEPTH_LV_0;
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  padding: 4px $GRID 4px 12px;
  &.disabled {
    box-shadow: none;
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
  &.withBg {
    background: $COLOR_KEY;
    color: #fff;
    &::before {
      background: rgba(255, 255, 255, 0.15);
    }
  }
  &.scheduleCancel {
    @include btn();
    background-color: #fff;
    border: 1px solid $COLOR_SUB_LIGHT_TEXT;
    box-shadow: none;
    color: $COLOR_SUB_LIGHT_TEXT;
    font-size: 13px;
    height: $GRID * 2;
    transition: all 650ms;
    .material-icons {
      font-size: 18px;
    }
    &:hover {
      border: 1px solid $SCHEDULE_COLOR_UNSAVED;
      color: $SCHEDULE_COLOR_UNSAVED;
      transition: all 150ms;
    }
  }
  .material-icons,
  &Text {
    position: relative;
    vertical-align: middle;
  }
  .material-icons {
    font-size: 16px;
    margin-right: $GRID * 0.5;
  }
}
</style>
