<template>
  <ul v-if="isShow" class="titleByMedia">
    <li class="titleByMedia_item" v-for="(v, k) in titles" v-show="v" :key="k">
      <p class="media">{{mediaName[k]}}</p>
      <p class="title">{{v}}</p>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    titles: Object,
    masterMedia: Object,
  },
  computed: {
    isShow: function() {
      return Object.values(this.titles).some((v) => !!v);
    },
    mediaName: function() {
      let name = {};
      Object.values(this.masterMedia).forEach((v) => {
        name[v.id] = v.short_name;
      });
      return name;
    },
  },
};
</script>

<style scoped lang="scss">
.titleByMedia {
  &_item {
    align-items: center;
    background: $DETAIL_ROW_EVEN_COLOR;
    display: flex;
    font-size: 12px;
    height: $GRID * 1.5;
    &:nth-child(even) {
      background: #fff;
    }
    .media {
      flex-shrink: 0;
      margin-left: $GRID * 0.5;
      width: $GRID * 4;
    }
    .title {
      color: $COLOR_KEY;
      font-weight: bold;
      margin-right: $GRID * 0.5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>