<template>
  <div class="jobList_wrap">
    <job-filter @change="isShowMoreSelf = false" />
    <div class="jobList">
      <h2 class="jobList_title">待機中の処理</h2>
      <div class="jobList_area">
        <job-unit v-for="(v, i) in showData" :key="i" :job="v" />
        <div class="message" v-if="filteredData.length <= 0">待機中の処理はありません</div>
        <button v-if="isShowMore" class="more" title="もっと見る" @click="isShowMoreSelf = false">
          <i class="material-icons">more_horiz</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// component
import JobFilter from "@/components/organisms/job/JobFilter.vue";
import JobUnit from "@/components/organisms/job/JobUnit.vue";
// store
import { mapGetters } from "vuex";

export default {
  components: {
    JobFilter,
    JobUnit,
  },
  data() {
    return {
      isShowMoreSelf: true,
    };
  },
  computed: {
    ...mapGetters("job", ["isShowParent"]),
    ...mapGetters("job/filter", ["filteredData"]),
    showData() {
      return this.isShowMore ? this.filteredData.slice(0, 10) : this.filteredData;
    },
    isShowMore() {
      if (!this.isShowMoreSelf || !this.isShowParent) return false;
      if (this.filteredData.length <= 10) this.isShowMoreSelf = false;
      return this.isShowMoreSelf;
    },
  },
};
</script>

<style scoped lang="scss">
.jobList {
  & {
    background: $BG_COLOR_BOX;
    box-shadow: $DEPTH_LV_0;
    color: $COLOR_KEY;
    margin-bottom: $GRID;
    overflow: hidden;
    padding: 0 $GRID $GRID;
  }
  &_title {
    font-size: 16px;
    display: inline-block;
    height: $GRID * 2.75;
    line-height: $GRID * 2.75;
    position: relative;
    &::before {
      background: $COLOR_KEY;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      top: 0px;
      width: 100%;
    }
  }
  &_area {
    position: relative;
    .message {
      margin-bottom: $GRID * 1.75;
      text-align: center;
    }
    .more {
      background: $COLOR_KEY;
      border: none;
      border-radius: 50%;
      bottom: -10px;
      color: #fff;
      display: block;
      left: 50%;
      position: absolute;
      &:hover {
        box-shadow: $DEPTH_LV_2;
        opacity: 0.8;
      }
    }
  }
}
</style>
