<template>
  <input-item :media-name="mediaShortName(mediaId)" :is-by-media="!isCommon">
    <div>
      <selected-image
        v-if="image.id"
        :path="image.urls.thumb"
        @reset="resetImage()"
        @change="showModal"
      />
      <button v-else type="button" class="btnSelectImg" @click="showModal">選択</button>
    </div>
  </input-item>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import SelectedImage from "@/components/molecules/common/SelectedImage.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";

export default {
  components: {
    SelectedImage,
    InputItem,
  },
  props: {
    mediaId: Number,
  },
  data() {
    return {
      title: "画像",
      keyName: "image_id",
    };
  },
  computed: {
    ...mapGetters({
      mediaIds: "mediaIds",
      mediaShortName: "mediaShortName",
      imageByMediaId: "imageByMediaId",
      isRequired: "rules/isRequired",
      maxCharacters: "rules/maxCharacters",
      selectedOnlyMedia: "selectedOnlyMedia",
    }),
    image() {
      return this.imageByMediaId(this.mediaId);
    },
    isCommon() {
      return this.mediaId === API_CODE.mediaCommon;
    },
    imageType() {
      // コレモネっとだけが選択されている場合はoriginalを返却する
      const coremonetSelected = this.mediaIds.includes(API_CODE.media.homepage);
      const onlyMediaSelected = this.mediaIds.length === 1;
      if (coremonetSelected && onlyMediaSelected) {
        return ["original"];
      }

      // ウエパが選択されている場合は1:1を返却する
      if (this.mediaIds.includes(API_CODE.media.wp)) {
        return ["1:1"];
      }

      return [];
    },
  },
  methods: {
    ...mapActions({
      showModalSelectImage: "imageSelect/showModalSelectImage",
    }),
    ...mapMutations({ updateImageId: `${types.IMAGE_ID}` }),
    resetImage() {
      this.updateImageId({ mediaId: this.mediaId, imageId: null });
    },
    showModal() {
      this.showModalSelectImage({
        selectedImageId: this.image.id,
        enabledMediaIds: this.mediaIds,
        enabledTrimType: this.imageType,
      }).then((selectedId) => {
        this.updateImageId({ mediaId: this.mediaId, imageId: selectedId });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btnSelectImg {
  @include imgSelectBtn();
}
</style>
