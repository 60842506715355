import MESSAGE_CONFIG from "@/assets/common/MessageConfig.js";
import readJson from "@/lib/readJson";
import { downloadFile } from "@/lib/downloadFile";

const actions = {
  /**
   * データ取得
   ***********************************/
  /**
   * 初期データ取得
   * manualconfig.jsonはS3の***-contentsバケットにて管理する
   */
  async getInitialData({ getters, commit }) {
    if (getters["common/isVendor"]) return;
    const data = await readJson(`/manual/manualconfig.json`);
    commit("SET_INITIAL_DATA", data);
  },
  /**
   * ファイル
   ***********************************/
  /**
   * ファイルをダウンロードする
   * ファイルはS3の***-contentsバケットにて管理する
   * @param {String} path
   */
  async download({ commit, dispatch, getters }, path) {
    if (getters["common/isVendor"]) return;
    commit("modal/setUpdateModal", {
      isShow: true,
      text: "ダウンロード中です…",
    });
    const result = await downloadFile(`${path}`);
    commit("modal/setUpdateModal", { isShow: false });
    if (!result) {
      dispatch("modal/showErrorModal", { text: "ダウンロードに失敗しました" });
    }
  },
  /**
   * モーダル
   ***********************************/
  /**
   * モーダルを開く
   */
  async showModalManual({ commit, dispatch, state }) {
    if (state.data) {
      commit("SET_MODAL_SHOW", true);
    } else {
      dispatch("modal/showErrorModal", {
        text: MESSAGE_CONFIG.error,
      });
    }
  },
  /**
   * モーダルを閉じる
   */
  async closeModalManual({ commit }) {
    commit("SET_MODAL_SHOW", false);
  },
};
export default actions;
