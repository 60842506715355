<template>
  <input
    class="inputText"
    ref="input"
    v-model="selfNumber"
    type="text"
    :class="className"
    :disabled="isDisabled"
    :maxlength="maxDigits"
    :required="myRequired"
    @keyup="CheckNumeric($event.target.value)"
  />
</template>

<script>
export default {
  emits: ["check", "update:modelValue"],
  props: {
    modelValue: [String, Number],
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    maxDigits: {
      type: Number,
      default: null,
    },
    minDigits: {
      type: Number,
      default: 0,
    },
    /*
     * バリデーションチェックをするか
     * - trueにすると、requiredじゃない場合でもValidかどうかを返すようになる
     * - isRequiredの場合は設定不要
     */
    needValidate: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selfNumber: this.modelValue,
      tmpNumber: null,
      tmpRequired: null,
      isValid: null,
    };
  },
  computed: {
    myRequired() {
      const isRequired = this.isRequired;
      if (isRequired !== this.tmpRequired) {
        this.CheckValidation(this.selfNumber);
        this.tmpRequired = isRequired;
      }
      return isRequired;
    },
    className() {
      return {
        hasError: !this.isValid || this.hasError,
        isSS: this.size == "ss",
      };
    },
  },
  watch: {
    modelValue(newValue) {
      this.selfNumber = newValue;
    },
  },
  methods: {
    CheckNumeric(value) {
      value = value
        .replace(/[０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        })
        .replace(/\D/g, "");
      if (this.maxDigits !== null) {
        value = value.slice(0, this.maxDigits);
      }
      this.selfNumber = value;
      if (this.tmpNumber !== value) {
        this.tmpNumber = value;
        this.$emit("update:modelValue", value);
        this.CheckValidation(value);
      }
    },
    CheckValidation(number) {
      let isValid = true,
        numString = String(number);
      if (this.needValidate || this.isRequired) {
        if (numString && numString.length >= this.minDigits) {
          numString = numString.replace(/\D/g, "");
          isValid = numString != "" ? true : false;
        } else {
          isValid = false;
        }
      }
      this.isValid = isValid;
      this.$emit("check", isValid);
    },
  },
};
</script>

<style scoped lang="scss">
.inputText {
  @include commonInputField();
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  width: 22rem;
  &.isSS {
    width: 4rem;
  }
}
</style>
