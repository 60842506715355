/**
 * 共通設定
 * マスター情報
 */

const MASTER_CONFIG = {
  name: "コレモネ",
  ksm: "結婚スタイルマガジン",
  tel: "075-222-5777",
  applicationTel: "075-744-0805",
  time: "10:00〜17:00（土日祝を除く）",
  mail: "info-company@niwaka-ksm.com",
};
Object.freeze(MASTER_CONFIG);
export default MASTER_CONFIG;
