/**
 * プラン設定
 * 期間の種類定義
 */

export const PLAN_PERIODS = {
  publication: "掲載",
  application: "申込",
  visit: "来館",
  target: "挙式",
};

export const getPeriodCode = (dateType) => {
  const code = {
    0: "application",
    1: "target",
    2: "visit",
  };
  return code[dateType];
};
