/**
 * デフォルトメッセージ
 */
const MESSAGE_CONFIG = {
  error: "エラーが発生しました。",
  // API_CODE.response に対応したメッセージ
  response: {
    badRequest: "データの取得に失敗しました。",
    unauthorized:
      "しばらく操作していない、または認証に問題があったため、ログアウトしました。",
    accessDenied: "アクセス権限がないページにアクセスしようとしました。",
    notFound: "データが見つかりませんでした。",
    conflict:
      "同じアカウントでログイン中です。\r\n別の方が使用されている場合、編集中のデータが消える可能性があります。\r\n強制的にログインしますか？",
    sync: "媒体とデータを同期中のため、編集できません。",
  },
};
Object.freeze(MESSAGE_CONFIG);
export default MESSAGE_CONFIG;
