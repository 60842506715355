<template>
  <transition name="comein">
    <p class="un" v-if="isShow">
      <i class="material-icons un_icon">warning</i>変更がまだ保存されていません
    </p>
  </transition>
</template>

<script>
export default {
  props: {
    isShow: Boolean
  }
};
</script>

<style scoped lang="scss">
.un {
  align-items: center;
  background-color: $SCHEDULE_COLOR_UNSAVED_LIGHT;
  border-radius: $BOX_RADIUS;
  box-shadow: $DEPTH_LV_0;
  display: inline-flex;
  color: $COLOR_TEXT;
  font-size: 14px;
  margin-right: $SUB_GRID * 2;
  padding: $SUB_GRID $SUB_GRID * 4;
  position: relative;
  &_icon {
    color: $COLOR_TEXT;
    font-size: 22px;
    padding-right: $SUB_GRID;
    animation: errorShaking 3s ease-in-out infinite;
    transform-origin: bottom center;
  }
  &::after {
    border-color: transparent transparent transparent
      $SCHEDULE_COLOR_UNSAVED_LIGHT;
    border-style: solid;
    border-width: $SUB_GRID 0 $SUB_GRID $GRID * 0.5;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    right: $SUB_GRID * 1.5 * -1;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
  }
}
.comein-enter-active,
.comein-leave-active {
  transition: opacity 0.6s, transform 0.6s;
}
.comein-enter,
.comein-leave-to {
  opacity: 0;
}
.comein-enter {
  transition: opacity 0.15s, transform 0.1s;
  transform: translateX(280px) scaleX(0.2);
  transform-origin: top right;
}
.comein-leave-to {
  transition: opacity 0.3s, transform 0.4s;
  transform: scale(1.8) skew(-1deg, -2deg);
  transform-origin: center center;
}
@keyframes errorShaking {
  0% {
    transform: rotate(0deg);
  }
  2% {
    transform: rotate(15deg);
  }
  8% {
    transform: rotate(-15deg);
  }
  14% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  26% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
