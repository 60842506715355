<template>
  <div class="text-info">
    <div class="text-info__header">
      <p class="text-info__label">{{ label }}</p>
      <p v-if="!isByMedia" class="text-info__common" :class="expand">
        {{ common }}
      </p>
    </div>
    <ul v-if="isByMedia" class="text-info__by-media">
      <li
        v-for="({ value, mediaName }, index) in byMediaItems"
        class="text-info__media-item"
        :key="index"
      >
        <span class="text-info__media-name">{{ mediaName }}</span>
        <span class="text-info__media-value" :class="expand">
          {{ value }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";

export default {
  props: {
    label: {
      type: String,
      default: "備考",
    },
    mediaIds: {
      type: Array,
      default: () => [],
    },
    textInfo: {
      type: Object,
      default: () => ({}),
    },
    textExpand: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isByMedia() {
      const commonIsNull = this.textInfo[API_CODE.mediaCommon] === null;
      const mediaHasValue = Object.values(this.textInfo).some(
        (value) => value !== null
      );

      return commonIsNull && mediaHasValue;
    },
    common() {
      return this.textInfo[API_CODE.mediaCommon] || null;
    },
    byMediaItems() {
      return this.mediaIds.map((mediaId) => {
        return {
          mediaName: this.media[mediaId].short_name,
          value: this.textInfo[mediaId] || "-",
        };
      });
    },
    expand() {
      return {
        expand: this.textExpand,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.text-info {
  width: 100%;

  &__header {
    display: flex;
    font-size: $FONT_SIZE_S;
  }

  &__label {
    width: $GRID * 3.5;
  }

  &__common {
    flex: 1;
    word-break: break-all;
  }

  // 媒体個別
  &__by-media {
    margin-top: $SUB_GRID;
    margin-left: $GRID;
    list-style: none;
  }

  &__media-item {
    display: flex;
    font-size: 11px;
    color: $COLOR_SUB_LIGHT_TEXT;
  }

  &__media-name {
    width: $GRID * 5;
    font-weight: bold;
  }

  &__media-value {
    flex: 1;
    word-break: break-all;
  }

  .expand {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
