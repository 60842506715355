import { isBefore, parseISO } from "date-fns";

// 利用終了かどうかを取得する
const isSuspended = (suspended_at) => {
  if (suspended_at === null) return false;

  const suspendedAt = parseISO(suspended_at);
  const now = new Date();
  return isBefore(suspendedAt, now);
};

export default isSuspended;
