/**
 * readJson
 * 別ドメインのファイルを読み込む時はhtaccessにaccess-control-allow-originの設定を行うこと
 ***********************************/
import axios from "axios";
import API_CODE from "@/assets/common/ApiCode";

/**
 * Jsonファイルを読み込み
 * @param {String} url ファイルのパス
 */
const readJson = async (url) => {
  if (!url) return null;
  const data = await axios
    .get(url)
    .then((response) => {
      if (
        response &&
        response.status == API_CODE.response.success &&
        response.data
      ) {
        return response.data;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export default readJson;
