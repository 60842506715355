<template>
  <button type="button" class="addBtn" :class="classType" @click="onClick()">
    <i class="material-icons">library_add</i>
    <span class="addBtn_text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    clickHandler: Function,
    isLarge: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "新規登録する"
    }
  },
  computed: {
    classType: function() {
      return {
        isLarge: this.isLarge
      };
    }
  },
  methods: {
    onClick: function() {
      this.$emit("clickHandler");
    }
  }
};
</script>

<style scoped lang="scss">
.addBtn {
  @include btn(rgba($COLOR_KEY, 0.1));
  background-origin: padding-box;
  border: 2px solid $COLOR_KEY;
  border-radius: $BOX_RADIUS;
  color: $COLOR_KEY;
  font-size: 14px;
  height: $GRID * 2.5;
  width: $GRID * 14.5;
  .addBtn_text {
    display: inline-block;
    vertical-align: middle;
  }
  .material-icons {
    font-size: 26px;
    padding-right: $GRID * 0.5;
    vertical-align: middle;
  }
  &.isLarge {
    font-size: 16px;
    height: $GRID * 4;
    width: $GRID * 14;
    .material-icons {
      font-size: 32px;
    }
  }
}
</style>
