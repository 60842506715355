<template>
  <section class="input-item">
    <input-radio
      v-model="value"
      :key="keyName"
      :items="options"
      :is-required="isRequired(keyName)"
      :is-disabled="isDisabled"
      :display-block="false"
      @check="validateType({ mediaId, isValid: $event })"
    />
    <p v-if="groupType === groupTypes.wp" class="notice">
      <span>種別はハナユメに登録・表示されません</span>
    </p>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

import InputRadio from "@/components/benefit/inputs/InputRadio.vue";

export default {
  components: {
    InputRadio,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyName: "type",
      mediaId: API_CODE.mediaCommon,
      groupTypes: BENEFIT_GROUP_TYPE,
    };
  },
  computed: {
    ...mapGetters({
      groupType: "groupType",
      benefitTypes: "benefitTypes",
      benefitTypeOptions: "benefitTypeOptions",
      isRequired: "rules/isRequired",
    }),
    value: {
      get() {
        return Object.values(this.benefitTypes).find((value) => value !== null) || null;
      },
      set(value) {
        const type = parseInt(value);
        this.updateType({ type, mediaId: this.mediaId });
      },
    },
    options() {
      const groupType = this.groupType;
      return this.benefitTypeOptions(groupType);
    },
  },
  methods: {
    ...mapMutations({
      updateType: `${types.BENEFIT_TYPE}`,
      validateType: `validation/${types.BENEFIT_TYPE}`,
    }),
  },
};
</script>

<style scoped lang="scss">
.input-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notice {
  display: flex;
  font-size: $FONT_SIZE_SS;
  margin-top: $SUB_GRID;

  &:before {
    content: "※";
    margin-right: $SUB_GRID;
  }
}
</style>
