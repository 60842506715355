<template>
  <input-form :title="title" :option-level="optionLevel([keyName])">
    <template v-slot:content>
      <input-item>
        <template v-slot:input>
          <input-radio
            v-model="value"
            :items="items"
            :is-required="rules[keyName].is_required"
            @check="sendValidation"
          />
        </template>
      </input-item>
    </template>
  </input-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import InputForm from "@/components/molecules/contact/InputForm.vue";
import InputItem from "@/components/molecules/contact/InputItem.vue";
import InputRadio from "@/components/atoms/contact/InputRadio.vue";

export default {
  components: {
    InputForm,
    InputItem,
    InputRadio,
  },
  props: {
    title: String,
    keyName: String,
    items: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      data: "utility/contact/data",
      rules: "utility/contact/rules",
      optionLevel: "utility/contact/optionLevel",
    }),
    value: {
      get() {
        return this.data[this.keyName];
      },
      set(value) {
        this.setContactData({ keyName: this.keyName, value });
      },
    },
  },
  methods: {
    ...mapActions({
      setContactData: "utility/contact/setContactData",
      setValidation: "utility/contact/setValidation",
    }),
    /**
     * validationModuleに入力情報を送信
     * @param isValid 入力済みフラグ false: 未入力 or エラー
     */
    sendValidation(isValid) {
      this.setValidation({ key: this.keyName, isValid });
    },
  },
};
</script>

<style scoped lang="scss"></style>
