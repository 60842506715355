const selectValidperiod = (params, periodTypes) => {
  const { valid_from, valid_until, valid_period_text } = params;
  const selectedFrom = { ...valid_from };
  const selectedUntil = { ...valid_until };
  const selectedText = { ...valid_period_text };
  Object.entries(periodTypes).forEach(([mediaId, type]) => {
    if (type === "date") {
      selectedText[mediaId] = null;
    } else {
      selectedFrom[mediaId] = null;
      selectedUntil[mediaId] = null;
    }
  });

  return {
    ...params,
    valid_from: selectedFrom,
    valid_until: selectedUntil,
    valid_period_text: selectedText,
  };
};

export default selectValidperiod;
