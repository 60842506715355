/**
 * vue-router 関係の処理
 */

import { saveCookie, getCookieAccount } from "@/lib/cookie";

/**
 * ナビゲーションガードをセット
 * @param {*} router
 */
export const setGuard = (router) => {
  /**
   * グローバル before ガード
   * ルートの遷移前（コンポーネントガード解決前）
   */
  router.beforeEach((to, from, next) => {
    const isLoginPage =
      location.pathname == "/login" ||
      location.pathname.indexOf("/login/") === 0;
    // 現在のページが /login は無条件でスルー // 無限ループ対策
    if (isLoginPage) {
      next();
      return;
    }
    // cookie が正常に保存されていない場合
    const cookie = getCookieAccount();
    if (!cookie.token || !cookie.userable_id) {
      next(false);
      const path = Object.keys(cookie).some(
        (k) => k != "hall_id" && !!cookie[k]
      )
        ? "/login?unauthorized"
        : "/login";
      movePage(path, "/login");
      return;
    }
    next();
  });
};

/**
 * ページ移動
 * @param {String} to 移動先
 * @param {String} check 現在のページパスが check の場合、 false (無限ループ対策)
 */
export const movePage = (to, check) => {
  if (to != "/" && window.location.href.indexOf(to) !== -1) return false; // 既に移動処理済み
  let isCurrent = false;
  if (check === "/") {
    isCurrent = location.pathname === check;
  } else if (check) {
    isCurrent = location.pathname.indexOf(check) !== -1;
  }
  if (isCurrent) return false; // 現在、対象のページの場合
  // ログインページに移動の場合
  if (to.indexOf("/login") !== -1) {
    // /login/{userable_id} に移動
    const cookie = getCookieAccount();
    if (cookie.userable_id && to.indexOf(String(cookie.userable_id)) === -1) {
      to = "/login/" + cookie.userable_id + "/" + to.slice("/login".length);
    }
    if (location.pathname !== "/") saveCookie("path", location.pathname); // 現在のパスを cookie に保存
  }
  window.location.href = to;
  return true;
};
