/**
 * ゼクシィ方式で文字数をカウント（小数）
 *
 * 半角英数記号:0.5 / その他:1 として算出
 * fullWidthがtrueの場合は全て1文字としてカウントする
 * @param {String} text 対象の文字列（注意：NGワードを除外する事）
 */
const countTextLengthZexy = (text, fullWidth) => {
  if (!text || text.length === 0) return 0;
  if (fullWidth) return text.length; 
  return text.length - countHalfWidth(text) * 0.5;
};

/**
 * 半角の文字数をカウント
 *
 * ASCII 半角英数記号 が対象
 * @param {String} text 対象の文字列
 */
const countHalfWidth = (text) => {
  return (text.match(/[a-zA-Z0-9 -/:-@¥[-`{-~]/g) || []).length;
};

export default countTextLengthZexy;
