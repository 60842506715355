export const FAIR_SORT_DEFAULT = "3";
export const FAIR_SORT = {
  "1": {
    key: "updated_at",
    order: "desc",
    text: "最終更新日 新しい順",
  },
  "2": {
    key: "updated_at",
    order: "asc",
    text: "最終更新日 古い順",
  },
  "3": {
    key: "sort",
    order: "asc",
    text: "並び替え 設定順",
  },
};
