<template>
  <textarea
    class="inputTextarea"
    v-model="selfText"
    :class="className"
    :placeholder="placeholder"
    :cols="cols"
    :rows="selfRows"
    :disabled="isDisabled"
    :required="isRequired"
    :style="calcStyle"
  ></textarea>
</template>

<script>
export default {
  props: {
    modelValue: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      text: String,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    cols: {
      // 一行当たりの文字数、widthと行数の自動計算に使用
      type: Number,
      default: 20,
    },
    maxLength: {
      // 最大文字数、行数の自動計算に使用
      type: Number,
      default: null,
    },
    rows: {
      // 行数を直接指定したい場合
      type: Number,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    selfText: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        hasError: this.hasError,
      };
    },
    selfRows() {
      if (this.rows) {
        return this.rows;
      } else if (this.maxLength !== null) {
        let rows = Math.floor(this.maxLength / this.cols) + 1; // 自動計算された想定最大行数 + 1
        return rows;
      } else {
        return 5;
      }
    },
    calcStyle() {
      return {
        width: this.cols !== null ? this.cols + "rem" : "20rem", // 横幅を文字数から計算
      };
    },
  },
};
</script>

<style scoped lang="scss">
.inputTextarea {
  @include commonInputField();
}
</style>
