<template>
  <div class="inputTag">
    <vue-tags-input
      v-model="inputName"
      :add-only-from-autocomplete="isAddOnlyAutocomplete"
      :autocomplete-always-open="filteredItems.length >= 1 && isShowAutocomplete"
      :autocomplete-items="filteredItems"
      :class="classType"
      :placeholder="placeholderEdit"
      :tags="inputItems"
      :validation="rules"
      v-click-away="away"
      @before-deleting-tag="deleteItem"
      @keydown.native="scroll"
      @tags-changed="changeItem"
      @focus="focusInput"
    />
    <div v-if="isBoxType">
      <filter-checkbox
        v-for="(v, k) in autocompleteTagNames"
        :key="k"
        :label="String(k)"
        :text="v"
        :value="isSelectedTag(v)"
        :is-btn-type="true"
        @update="clickTagBox(v)"
      />
    </div>
  </div>
</template>

<script>
import { VueTagsInput, createTag, createTags } from "@sipec/vue3-tags-input";
import { directive as ClickAway } from "vue3-click-away";

// component
import FilterCheckbox from "@/components/atoms/common/FilterCheckbox.vue";

export default {
  emits: ["input", "check"],
  directives: {
    ClickAway,
  },
  components: {
    FilterCheckbox,
    VueTagsInput,
  },
  props: {
    // 自動補完(選択可能)タグ名の配列
    autocompleteTagNames: {
      type: Array,
      default: () => [],
    },
    // 固定(編集不可)タグ名の配列
    fixedTagNames: {
      type: Array,
      default: () => [],
    },
    // 自動補完(選択可能)リストからの追加のみ許可するか
    isAddOnlyAutocomplete: {
      type: Boolean,
      default: false,
    },
    isBoxType: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    // isRequired = false でもバリデーションチェックを返すか
    needValidate: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    // 選択タグ名の配列
    tagNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      autocompleteItems: [], // 自動補完(選択可能)の項目リスト
      inputItems: [], // 入力済みの項目リスト
      inputName: "", // 入力中のタグ名 'ビーチ'
      isShowAutocomplete: false, // 自動補完リストを表示するか
      rules: [], // 入力ルール
      // // ex. タグの文字数が100文字以上の場合
      // rules: [{
      //   classes: "maxLength",
      //   rule: tag => tag.text.length > 100
      // }]
    };
  },
  computed: {
    classType() {
      return {
        isDisabledInput: this.isBoxType && this.isAddOnlyAutocomplete,
      };
    },
    filteredItems() {
      if (this.isBoxType) return [];
      const inputTexts = this.inputItems.map((item) => item.text);
      // 入力中のタグ名を含むItemのみfilter
      let items = this.autocompleteItems.filter((item) => item.text.indexOf(this.inputName) !== -1);
      // 自動補完項目リストの中で 未選択 and 入力中のタグ名を含む Itemをfilter
      const noSelect = this.autocompleteItems.filter(
        (item) => inputTexts.indexOf(item.text) === -1 && item.text.indexOf(this.inputName) === -1
      );
      items = items.concat(noSelect);
      return items;
    },
    hasRequiredError() {
      if (this.isRequired || this.needValidate) {
        return this.inputItems.length <= 0;
      } else {
        return false;
      }
    },
    isValid() {
      let isValid = !this.hasRequiredError;
      return isValid;
    },
    placeholderEdit() {
      if (this.inputItems.length >= 1 || this.inputName.length >= 1) {
        return "";
      } else {
        return this.placeholder;
      }
    },
  },
  mounted() {
    // vue-tags-inputで扱うタグのオブジェクト配列に変換
    this.autocompleteItems = createTags(this.autocompleteTagNames);
    this.inputItems = createTags(this.tagNames);
    // 初期のinputItemsのidにfixedTagNamesが含まれている場合, fixedクラスを追加
    this.inputItems = this.inputItems.map((item) => {
      if (this.fixedTagNames.indexOf(item.text) !== -1) {
        item.classes = "fixed";
      }
      return item;
    });
    this.sendIsValid();
  },
  methods: {
    changeItem(items) {
      if (this.inputItems.length !== items.length) this.inputItems = items;
      const names = items.map((item) => item.text);
      this.$emit("input", names);
      this.sendIsValid();
    },
    clickTagBox(name) {
      if (this.isSelectedTag(name)) {
        this.deleteItem(createTag(name, []));
        this.inputItems = this.inputItems.filter((v) => v.text !== name);
      } else {
        this.inputItems.push(createTag(name, []));
      }
      this.changeItem(this.inputItems);
    },
    deleteItem(target) {
      if (target.tag && target.tag.classes !== "fixed") {
        target.deleteTag();
      }
    },
    scroll(e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
        let scrollElem = document.getElementsByClassName("ti-autocomplete")[0];
        let elem = document.getElementsByClassName("ti-selected-item")[0];
        if (!scrollElem || !elem) return;
        const lastOffsetY = scrollElem.scrollHeight - elem.clientHeight;
        if (elem.offsetTop === 0) {
          scrollElem.scrollTop = 0;
        } else if (elem.offsetTop === lastOffsetY) {
          scrollElem.scrollTop = lastOffsetY;
        } else if (elem.offsetTop > scrollElem.clientHeight && e.keyCode === 40) {
          scrollElem.scrollTop += elem.clientHeight;
        } else if (elem.offsetTop + elem.clientHeight < scrollElem.scrollTop && e.keyCode === 38) {
          scrollElem.scrollTop -= elem.clientHeight;
        }
      }
    },
    sendIsValid() {
      this.$emit("check", this.isValid);
    },
    away() {
      this.isShowAutocomplete = false;
    },
    focusInput() {
      this.isShowAutocomplete = true;
    },
    isSelectedTag(name) {
      const names = this.inputItems.map((item) => item.text);
      return names.includes(name);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/inputTag.scss";
.inputTag {
  ::v-deep .vue-tags-input {
    background-color: inherit;
    max-width: inherit;
    &.isDisabledInput {
      .ti-new-tag-input {
        cursor: none;
        pointer-events: none;
      }
    }
    // 自動補完欄
    .ti-autocomplete {
      max-height: 27 * 5px;
      overflow: auto;
      .ti-item {
        height: 27px;
      }
    }
    // 入力欄
    .ti-input {
      background-color: #fff;
      border-radius: 8px;
    }
    // 自動補完の選択時
    .ti-selected-item {
      background-color: #1ea7fd;
    }
    // タグ
    .ti-tag {
      background-color: $COLOR_KEY;
      .ti-tag-center {
        > span {
          word-break: break-all;
        }
      }
      // 編集不可
      &.fixed {
        opacity: 0.5;
        .ti-actions {
          display: none;
        }
      }
      // 削除直前
      &.ti-deletion-mark {
        background-color: $COLOR_SUB_LIGHT_TEXT !important;
      }
    }
  }
}
</style>
