<template>
  <div class="validPeriod" :class="classType">
    <p class="validPeriod_text">
      <span>{{ title }}</span>
      <span>：</span>
    </p>
    <p class="validPeriod_date" v-if="this.validFrom || this.validUntil">
      <span class="fontNum" v-if="this.validFrom">{{ from }}</span>
      ～
      <span class="fontNum" v-if="this.validUntil">{{ until }}</span>
    </p>
    <p class="validPeriod_date" v-else :title="textDate">{{ textDate }}</p>
  </div>
</template>

<script>
// components/benefit/ValidPeriod.vue の prop に title を追加
// TODO: ファイル統合して common に移動予定
// -----------------------
import formatDateText from "@/lib/formatDateText";

export default {
  props: {
    validFrom: String,
    validUntil: String,
    validText: String,
    state: String,
    title: {
      type: String,
      default: "適用期間",
    },
    type: String,
  },
  computed: {
    classType: function() {
      const isEmpty = !this.validFrom && !this.validUntil && !this.validText;
      const isText = this.validText;
      const isWithin = this.state === "within";
      const isSoon = this.state === "soon";
      const isEnd = this.state === "over";
      const isFuturePublication =
        this.type === "publication" && !isWithin && !isSoon && !isEnd;
      return { isEmpty, isText, isWithin, isSoon, isEnd, isFuturePublication };
    },
    from: function() {
      return formatDateText(this.validFrom);
    },
    until: function() {
      return formatDateText(this.validUntil);
    },
    textDate: function() {
      // 適用期間なし
      if (!this.validText) {
        return "なし";
      }
      return this.validText;
    },
  },
};
</script>

<style scoped lang="scss">
.validPeriod {
  align-items: center;
  background: $BG_COLOR_SUB;
  border-radius: $BOX_RADIUS;
  color: $COLOR_SUB_TEXT;
  display: inline-flex;
  font-size: 11px;
  height: 16px;
  padding: 0 8px 0 4px;
  .material-icons {
    font-size: 14px;
    margin-right: 5px;
  }
  &_text {
    &Sub {
      margin-left: 0.5em;
    }
    .isSoon &,
    .isEnd & {
      font-weight: bold;
    }
  }
  &_date {
    max-width: $GRID * 13;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .fontNum {
      font-size: 12px;
    }
  }
  &.isEmpty {
    background: none;
    color: $COLOR_DISABLE_TEXT;
  }
  &.isText,
  &.isWithin {
    background: none;
  }
  &.isSoon {
    background: $BG_COLOR_WARNING;
  }
  &.isEnd {
    background: $BG_COLOR_ALERT;
  }
  &.isFuturePublication {
    background: $BG_COLOR_SAFE;
  }
}
</style>
