/**
 * 第1引数が第2引数よりも前の日付だったらtrueを返す
 *
 * @param {String|Date} target 調べたい日付、文字列（yyyy-mm-dd）かDateオブジェクトのみ対応
 * @param {String|Date} comp 比較対象の日付、文字列（yyyy-mm-dd）かDateオブジェクトのみ対応
 * @return {Boolean}
 */

import isBefore from "date-fns/isBefore";
import startOfDay from "date-fns/startOfDay";
import toDate from "../toDate";

const isEarlierDate = (target, comp) => {
  if (!target || !comp) {
    return null;
  }
  const targetDate = startOfDay(toDate(target)),
    compDate = startOfDay(toDate(comp));
  return isBefore(targetDate, compDate);
};

export default isEarlierDate;
