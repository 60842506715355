<template>
  <nav class="maintenance-status">
    <p v-if="message" class="maintenance-status__message">
      {{ message }}
    </p>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { getCookieAccount } from "@/lib/cookie";

export default {
  computed: {
    ...mapGetters({
      message: "utility/maintenanceMessage/message",
    }),
  },
  mounted() {
    const { userable_type, hall_id } = getCookieAccount();
    if (userable_type === "Hall" || hall_id != null) {
      this.fetchExpiredBenefitMessage();
    }
  },
  methods: {
    ...mapActions({
      fetchExpiredBenefitMessage: "utility/maintenanceMessage/fetchExpiredBenefitMessage",
    }),
  },
};
</script>

<style scoped lang="scss">
.maintenance-status {
  &__message {
    border-radius: $BOX_RADIUS_FOR_LARGE_BOX;
    box-shadow: $DEPTH_LV_0;
    background-color: rgba(249, 62, 62, 0.8);
    color: #fff;
    font-size: 11px;
    padding: ($GRID * 0.5) ($GRID * 0.5);
  }
}
</style>
