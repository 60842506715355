const OPTION_LEVEL = {
  none: 0,
  optional: 1,
  required: 2,
};

export const optionLevel = (required) =>
  required ? OPTION_LEVEL.required : OPTION_LEVEL.optional;

export default OPTION_LEVEL;
