export const validState = {
  valid: {
    termType: true,
    description: true,
  },
};

export const validGetters = {
  isAllValid: ({ valid }) => {
    const validList = Object.values(valid);
    return validList.every((isValid) => isValid);
  },
  validTermType: ({ valid }) => valid.termType,
  validDescription: ({ valid }) => valid.description,
};

export const validMutations = {
  validTermType(state, isValid) {
    state.valid.termType = isValid;
  },
  validDescription(state, isValid) {
    state.valid.description = isValid;
  },
};
