<template>
  <div class="stateIcon" :class="classObject">
    <i class="material-icons" v-if="isError || isNotice || isWaiting">{{ icon }}</i>
    <p class="text">{{ title }}</p>
  </div>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";

export default {
  props: {
    id: Number,
    title: String,
    inUsage: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classObject: function() {
      // 設定済みで利用停止中の場合は最優先
      if (this.id > 0 && !this.inUsage) {
        return ["isInactive"];
      }
      return {
        isEmpty: this.id === 0,
        isChecking: this.id === 1,
        isConnected: this.id == 2,
        isCooperated: this.id === 3,
        isWaiting: this.isWaiting,
        isNotice: this.isNotice,
        isError: this.isError,
      };
    },
    isWaiting: function() {
      return this.inUsage && this.id === API_CODE.linkStatus.waiting;
    },
    isNotice: function() {
      return this.inUsage && this.id === API_CODE.linkStatus.notice;
    },
    isError: function() {
      return this.inUsage && this.id === API_CODE.linkStatus.fatalError;
    },
    icon: function() {
      if (this.isWaiting) return "pause_circle_filled";
      if (this.isNotice) return "notification_important";
      if (this.isError) return "error";
    },
  },
};
</script>

<style scoped lang="scss">
.stateIcon {
  align-items: center;
  display: flex;
  width: $GRID * 3;
  height: $GRID * 3;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
.text {
  font-size: 10px;
  line-height: 1.3;
  margin-left: 2px;
  position: relative;
  z-index: 2;
  width: 100%;
}
.isEmpty,
.isChecking {
  border: 3px solid #c8c9c9;
  border-radius: 50%;
  color: #9e9f9f;
}
.isChecking {
  border-style: dotted;
}
.isConnected,
.isCooperated {
  background: $COLOR_SUBMIT;
  border-radius: 50%;
  color: #fff;
}
.isConnected {
  .text {
    margin-top: 5px;
  }
}
.isCooperated {
  .text {
    font-size: 11px;
    margin-top: 1px;
  }
  &::before,
  &::after {
    -webkit-animation: ripple 2s infinite ease-out;
    animation: ripple 2s infinite ease-out;
    border-radius: 100%;
    border: 1px solid $COLOR_SUBMIT;
    content: "";
    display: block;
    left: -8px;
    top: -8px;
    position: absolute;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
  }
  &::after {
    left: -4px;
    top: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
  }
}
.isInactive {
  .text {
    color: $COLOR_UNPUBLIC;
    font-size: 11px;
    margin-top: 1px;
  }
  &::before {
    border-radius: 100%;
    border: 1px solid $COLOR_UNPUBLIC;
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.isError,
.isNotice,
.isWaiting {
  height: auto;
  width: $GRID * 4;
  .material-icons {
    font-size: 32px;
  }
}
.isError {
  color: $COLOR_ALERT;
}
.isNotice {
  color: $COLOR_WARNING;
  > .text {
    color: $COLOR_TEXT;
  }
}
.isWaiting {
  color: $COLOR_SUB_TEXT;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0.7);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.05);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    opacity: 1;
  }
  100% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    opacity: 0;
  }
}
</style>
