<template>
  <div class="optionIcon" :class="iconClass">
    <p class="optionIcon_name">{{ iconText }}</p>
  </div>
</template>

<script>
import OPTION_LEVEL from "@/assets/common/option-level";

const optionName = {
  [OPTION_LEVEL.none]: "", // アイコンなし
  [OPTION_LEVEL.optional]: "任意",
  [OPTION_LEVEL.required]: "必須",
};

export default {
  props: {
    optionLevel: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: "m",
    },
  },
  computed: {
    iconClass() {
      return {
        isHidden: this.optionLevel == OPTION_LEVEL.none,
        isOptional: this.optionLevel == OPTION_LEVEL.optional,
        isRequired: this.optionLevel == OPTION_LEVEL.required,
        isSizeSS: this.size == "ss",
        isSizeS: this.size == "s",
      };
    },
    iconText() {
      return optionName[this.optionLevel];
    },
  },
};
</script>

<style scoped lang="scss">
$BORDER_SIZE: 1px;
$ICON_SIZE: $GRID * 2;
$ICON_SIZE_SS: 16px;
$ICON_SIZE_S: 24px;
$gray: #ccc;

.optionIcon {
  align-items: center;
  border: $BORDER_SIZE solid #333;
  box-sizing: border-box;
  border-radius: 100%;
  display: flex;
  font-size: 10px;
  height: $ICON_SIZE;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: $ICON_SIZE;

  &_name {
    position: relative;
  }

  &.isHidden {
    visibility: hidden;
  }

  &.isOptional {
    border-color: #969696;
    color: #605f5f;
  }

  &.isRequired {
    border-color: #ff5c5c;
    color: #f93e3e;
  }

  &.isSizeSS {
    font-size: $FONT_SIZE_SS;
    height: $ICON_SIZE_SS;
    line-height: $ICON_SIZE_SS * 0.5;
    width: $ICON_SIZE_SS;
    text-align: center;
  }

  &.isSizeS {
    font-size: $FONT_SIZE_SS;
    height: $ICON_SIZE_S;
    line-height: $ICON_SIZE_S * 0.5;
    width: $ICON_SIZE_S;
    text-align: center;
  }
}
</style>
