/**
 * 数値を桁数を指定して0埋めする
 * @param number [Number]
 * @param length [Number]
 */
const zeroPad = (number, length) => {
  let strings;
  for (let i = 0; i < length; i++) {
    strings += "0";
  }
  return (strings + number).slice(-length);
};
export default zeroPad;
