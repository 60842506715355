<template>
  <button
    type="button"
    class="menuBtn"
    :class="{ small: isSmall }"
    :disabled="isDisabled"
    @click="onClick()"
  >
    <i
      v-if="icon"
      :class="{
        'material-icons': !useSymbol,
        'material-symbols-outlined': useSymbol,
      }"
    >
      {{ icon }}
    </i>
    <span class="menuBtnText">{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    clickHandler: Function,
    text: String,
    icon: {
      type: String,
      default: "",
    },
    useSymbol: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    onClick: function () {
      this.$emit("clickHandler");
    },
  },
};
</script>

<style scoped lang="scss">
.menuBtn {
  -webkit-appearance: none;
  border: none;
  box-sizing: border-box;
  display: block;
  min-width: $GRID * 8;
  outline: none;
  padding: $GRID * 0.5 $GRID $GRID * 0.5 $GRID * 0.5;
  text-align: left;
  width: 100%;

  .material-icons,
  .material-symbols-outlined {
    font-size: 20px;
    margin-right: 4px;
    vertical-align: middle;
  }

  &Text {
    vertical-align: middle;
  }

  &.small {
    font-size: 11px;
    min-width: $GRID * 4;
    padding: $GRID * 0.25 $GRID * 0.5 $GRID * 0.25 $GRID * 0.25;

    .material-icons,
    .material-symbols-outlined {
      font-size: 14px;
      margin-right: 3px;
    }
  }

  &:not(:disabled) {
    &:hover {
      background: $BG_COLOR_SUB;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;

    .material-icons,
    .material-symbols-outlined {
      opacity: 0.6;
    }
  }
}
</style>
