<template>
  <benefit-unit-period
    title="適用期間"
    :period-list="periodList"
    :is-by-media="isByMedia"
    :common-period="commonPeriod"
  />
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";

import periodText from "@/components/benefit/helper/period-text";
import isByMedia from "@/components/benefit/helper/is-by-media";

import BenefitUnitPeriod from "@/components/benefit/presentation/BenefitUnitPeriod.vue";

export default {
  components: {
    BenefitUnitPeriod,
  },
  props: {
    mediaIds: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    status: {
      type: Object,
      default: null,
    },
    statusMaster: {
      type: Object,
      default: () => ({}),
    },
    media: {
      type: Object,
      default: () => ({}),
    },
    periodFrom: {
      type: [Object, Array],
      default: () => ({}),
    },
    periodUntil: {
      type: [Object, Array],
      default: () => ({}),
    },
    validText: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  computed: {
    periodList() {
      return this.mediaIds.map((mediaId) => ({
        mediaId,
        mediaName: this.media[mediaId].short_name,
        value: this.periodText(mediaId) || null,
        status: this.status[mediaId],
      }));
    },
    isByMedia() {
      const fromIsByMedia = isByMedia(this.periodFrom);
      const untilIsByMedia = isByMedia(this.periodUntil);
      const textIsByMedia = isByMedia(this.validText);
      return fromIsByMedia || untilIsByMedia || textIsByMedia;
    },
    commonPeriod() {
      return {
        value: this.periodText(),
        status: this.status[API_CODE.mediaCommon],
      };
    },
  },
  methods: {
    periodText(mediaId = API_CODE.mediaCommon) {
      // 終了・終了間近の場合はステータスを取得する
      const status = this.status[mediaId];
      const statusText = status ? `${this.statusMaster[status]}: ` : "";

      // テキスト入力されている場合はステータスとテキストを取得する
      const validText = this.validText[mediaId];
      if (validText) {
        return `${statusText}${validText}`;
      }

      const from = this.periodFrom;
      const until = this.periodUntil;
      const text = periodText(from, until, mediaId);
      return text ? `${statusText}${text}` : null;
    },
  },
};
</script>
