<template>
  <input-form :title="title" :option-level="optionLevel([keyName])">
    <template v-slot:content>
      <input-item>
        <template v-slot:input>
          <hall-list-check
            v-for="(name, id) in items"
            class="media-item"
            :key="id"
            :text="name"
            :value="isSelectedMedia(id)"
            @update="selectMedia(id)"
          />
        </template>
      </input-item>
    </template>
  </input-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import HallListCheck from "@/components/atoms/common/HallListCheck.vue";
import InputForm from "@/components/molecules/contact/InputForm.vue";
import InputItem from "@/components/molecules/contact/InputItem.vue";

export default {
  components: {
    HallListCheck,
    InputForm,
    InputItem,
  },
  data() {
    return {
      selectedMediaIds: [],
    };
  },
  props: {
    title: String,
    keyName: String,
    items: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      data: "utility/contact/data",
      optionLevel: "utility/contact/optionLevel",
    }),
  },
  methods: {
    ...mapActions({
      setContactData: "utility/contact/setContactData",
    }),
    isSelectedMedia(mediaId) {
      return this.selectedMediaIds.includes(mediaId);
    },
    selectMedia(mediaId) {
      this.selectedMediaIds = this.isSelectedMedia(mediaId)
        ? this.selectedMediaIds.filter((id) => id !== mediaId)
        : [...this.selectedMediaIds, mediaId];
      this.setContactData({ keyName: this.keyName, value: this.selectedMediaIds });
    },
  },
};
</script>

<style scoped lang="scss">
.media-item {
  width: $GRID * 5.5;
}
</style>
