<template>
  <modal-header-window
    class="modal-image-select"
    icon="image"
    title="画像選択"
    :is-image-list="true"
    :is-set-footer="false"
    @close="cancel"
  >
    <template v-slot:content>
      <filter-image />
      <list-image @select="selectImage" />
    </template>
  </modal-header-window>
</template>

<script>
import { mapGetters } from "vuex";

import ModalHeaderWindow from "@/components/molecules/common/ModalHeaderWindow.vue";
import FilterImage from "./FilterImage.vue";
import ListImage from "./ListImage.vue";

export default {
  components: {
    ModalHeaderWindow,
    FilterImage,
    ListImage,
  },
  computed: mapGetters({ selectedImageId: "selectedImageId" }),
  methods: {
    selectImage(imageId) {
      this.closeModal(imageId);
    },
    cancel() {
      this.closeModal(this.selectedImageId);
    },
    closeModal(imageId) {
      this.$emit("submit", imageId);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-image-select {
  z-index: $LAYER_MODAL + 1;

  ::v-deep .modalHeaderWindow {
    height: calc(100% - #{$GRID} * 2);
  }
}
</style>
