// 登録時に不要な情報を消し込む
// 1. id
// 2. 更新日時
// 3. 使用中のフェアの数
// 4. 使用中のフェア一覧
const deleteUnneededProperties = (params) => {
  const unneedProperties = ["id", "updated_at", "used_count", "used_fairs"];
  const deletedParams = Object.entries(params).filter(
    ([key]) => !unneedProperties.includes(key)
  );
  return Object.fromEntries(deletedParams);
};

export default deleteUnneededProperties;
