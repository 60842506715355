<template>
  <a class="btn" :class="{ isDisabled, isLarge, isSmall, isSub }" :href="path">
    <span class="btnText">{{ text }}</span>
  </a>
</template>

<script>
export default {
  props: {
    text: String,
    path: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isSub: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  @include btn();
  background: $COLOR_KEY;
  border: none;
  border-radius: $GRID;
  box-shadow: $DEPTH_LV_0;
  color: #fff;
  display: block;
  font-size: 12px;
  padding: 3px 0;
  width: $GRID * 8.75;
  &Text {
    position: relative;
  }
  &.isDisabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
  &.isLarge {
    border-radius: 18px;
    font-size: 14px;
    padding: 7px 0 6px;
  }
  &.isSub {
    background-color: transparent;
    border: 1px solid $COLOR_KEY;
    box-shadow: none;
    color: $COLOR_KEY;
    font-size: 11px;
    padding: 2px 0;
    width: $GRID * 6;
    &::before {
      background-color: rgba($COLOR_KEY, 0.1);
    }
  }
  &.isSmall {
    border-radius: 9px;
    font-size: $FONT_SIZE_SS;
    padding: 2px 0 1px;
  }
}
</style>
