import { getCookieAccount, removeCookie, saveCookieAccount } from "@/lib/cookie";

/**
 * 共通設定
 * ディレクトリ情報
 * 同じページでもアイコンや順番が異なるため式場、企業、管理に分けて設定
 *
 * top: 常に表示
 * login: 管理, 企業ナビゲーション or 式場ナビゲーション のどちらかのみ
 * その他: permission による
 ***********************************/
/**
 * 式場ページ
 */
export const PAGE_CONFIG = [
  {
    dir: "top", // ディレクトリ名
    icon: "home", // アイコン
    name: "Top", // ナビゲーションに表示するテキスト
    secondLevel: false, // 第2階層かどうか
    hallListOrder: 1, // 式場一覧に表示するナビゲーションの順序
    hallListName: "式場 Top", // 式場一覧に表示するナビゲーション名（違う場合のみ）
    permission: null, // 権限 name: 権限名, type: ページで最低限できること, isHallList: 式場ページには追加しない
  },
  {
    dir: "fair-schedule",
    icon: "date_range",
    name: "フェア スケジュール設定",
    secondLevel: false,
    hallListOrder: 4,
    hallListName: "スケジュール設定",
    permission: { name: "fairs", type: "edit" },
  },
  {
    dir: "fair-master-list",
    icon: "view_list",
    name: "フェア マスタ一覧",
    secondLevel: false,
    hallListOrder: 5,
    permission: { name: "fairs", type: "edit" },
  },
  {
    dir: "benefit",
    icon: "stars",
    name: "特典管理",
    secondLevel: false,
    hallListOrder: 6,
    permission: { name: "benefits", type: "edit" },
  },
  {
    dir: "plan-list",
    icon: "cake",
    name: "プラン一覧",
    secondLevel: false,
    hallListOrder: 7,
    permission: { name: "plans", type: "edit" },
  },
  {
    dir: "image-list",
    icon: "photo_library",
    name: "画像管理",
    secondLevel: false,
    hallListOrder: 3,
    permission: { name: "images", type: "edit" },
  },
  {
    dir: "hall-init-setting",
    icon: "account_balance",
    name: "式場基本設定",
    secondLevel: false,
    hallListOrder: 2,
    permission: { name: "settings", type: "edit" },
  },
  {
    dir: "whats-new",
    icon: "campaign",
    name: "新着情報",
    secondLevel: false,
    hallListOrder: 2,
    permission: { name: "hallInfo", type: "edit" },
  },
  {
    dir: "media-account",
    icon: "settings_input_component",
    name: "媒体設定",
    secondLevel: false,
    permission: { name: "mediaAccounts", type: "edit" },
  },
  {
    dir: "job",
    icon: "history",
    name: "更新履歴",
    secondLevel: false,
    permission: { name: "logs", type: "view" },
  },
  {
    dir: "master-account",
    icon: "assignment_ind",
    name: "アカウント設定",
    secondLevel: false,
    permission: { name: "users", type: "edit" },
  },
  {
    dir: "hp-setting",
    icon: "sync_alt",
    name: "コレモネっと設定",
    secondLevel: false,
    permission: { name: "hpSettings", type: "edit" },
  },
  {
    dir: "login",
    icon: "lock_open",
    name: "ログアウト",
    secondLevel: false,
    permission: null,
  },
];
/**
 * 企業ページ
 * 同じページでもアイコンや順番が異なるため式場、企業、管理に分けて設定
 */
export const COMPANY_PAGE_CONFIG = [
  {
    dir: "top", // ディレクトリ名
    icon: "location_city", // アイコン
    name: "式場一覧", // ナビゲーションに表示するテキスト
    permission: { name: "halls", type: "view", isHallList: true }, // 権限 name: 権限名, type: ページで最低限できること, isHallList: 式場一覧を閲覧、編集するか
  },
  {
    dir: "job",
    icon: "history",
    name: "更新履歴",
    permission: { name: "logs", type: "view", isHallList: true },
  },
  {
    dir: "master-account",
    icon: "assignment_ind",
    name: "アカウント設定",
    permission: { name: "users", type: "edit", isHallList: false },
  },
  {
    dir: "login",
    icon: "lock_open",
    name: "ログアウト",
    permission: null,
  },
];
/**
 * 管理ページ
 * 同じページでもアイコンや順番が異なるため式場、企業、管理に分けて設定
 */
export const ADMIN_PAGE_CONFIG = [
  {
    dir: "top", // ディレクトリ名
    icon: "history", // アイコン
    name: "更新履歴", // ナビゲーションに表示するテキスト
    permission: { name: "logs", type: "view", isHallList: true }, // 権限 name: 権限名, type: ページで最低限できること, isHallList: 式場一覧を閲覧、編集するか
  },
  {
    dir: "error-log-list",
    icon: "error_outline",
    name: "エラー一覧",
    permission: { name: "logs", type: "view", isHallList: true },
  },
  {
    dir: "hall-admin",
    icon: "location_city",
    name: "式場アカウント一覧",
    permission: { name: "halls", type: "view", isHallList: true },
  },
  {
    dir: "company-admin",
    icon: "business",
    name: "企業アカウント一覧",
    permission: { name: "companies", type: "view", isHallList: true },
  },
  {
    dir: "news-admin",
    icon: "info",
    name: "お知らせ設定",
    permission: { name: "news", type: "edit", isHallList: false },
  },
  {
    dir: "master-account",
    icon: "assignment_ind",
    name: "アカウント設定",
    permission: { name: "users", type: "edit", isHallList: false },
  },
  {
    dir: "login",
    icon: "lock_open",
    name: "ログアウト",
    permission: null,
  },
];
/**
 * インポートツールページ
 * 階層は式場ページ扱い（式場選択後に移動）
 */
export const IMPORT_PAGE_CONFIG = [
  {
    dir: "import-image", // ディレクトリ名
    icon: "photo_library", // アイコン
    name: "画像設定", // ナビゲーションに表示するテキスト
    permission: { name: "associateImport", type: "edit" }, // 権限 name: 権限名, type: ページで最低限できること, isHallList: 式場ページには追加しない
  },
  {
    dir: "import-fair",
    icon: "flag",
    name: "フェア設定",
    permission: { name: "associateImport", type: "edit" },
  },
  {
    dir: "import-plan",
    icon: "cake",
    name: "プラン設定",
    permission: { name: "associateImport", type: "edit" },
  },
  {
    dir: "import-hall-benefit",
    icon: "stars",
    name: "特典設定",
    permission: { name: "associateImport", type: "edit" },
  },
  {
    dir: "complete",
    icon: "",
    name: "紐付け確定",
    permission: { name: "associateImport", type: "edit" },
  },
];
/**
 * 詳細ページ
 * 詳細ページパス: 上記設定のdir
 */
export const DETAIL_PAGE_CONFIG = {
  "fair-master-edit": "fair-master-list",
  "plan-detail": "plan-list",
  "image-detail": "image-list",
};

/**
 * 企業・管理⇔式場ページ切替処理
 ***********************************/
/**
 * 選択された操作対象の式場IDを取得
 */
export const getSelectedHallId = () => {
  return getCookieAccount().hall_id;
};
/**
 * 捜査対象の式場IDを選択
 * @param {String, Number} hallId
 */
export const selectHall = (hallId) => {
  saveCookieAccount({ hall_id: String(hallId) });
};
/**
 * 捜査対象の式場IDを選択解除
 */
export const deselectHall = () => {
  removeCookie("hall_id");
};
