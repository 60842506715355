<template>
  <section class="section" :style="{ width }">
    <div class="section__header">
      <input-option-icon
        class="header__icon"
        :option-level="optionLevel"
        :size="size"
      />
      <div class="header__title">
        <p class="header__text">{{ sectionName }}</p>
        <p v-if="subText" class="header__sub-text">{{ subText }}</p>
      </div>
      <help-popup class="header__help" v-if="helpText">
        <template v-slot:text>{{ helpText }}</template>
      </help-popup>
    </div>
    <div
      class="section__body"
      :class="{
        'section__body--border': hasBorder,
        'section__body--no-padding': noPadding,
        'section__body--by-column': byColumn,
      }"
    >
      <slot></slot>
    </div>
  </section>
</template>

<script>
import HelpPopup from "@/components/atoms/common/HelpPopup.vue";
import InputOptionIcon from "@/components/atoms/common/InputOptionIcon.vue";

export default {
  components: {
    HelpPopup,
    InputOptionIcon,
  },
  props: {
    hasBorder: {
      type: Boolean,
      default: () => false,
    },
    noPadding: {
      type: Boolean,
      default: () => false,
    },
    byColumn: {
      type: Boolean,
      default: () => false,
    },
    optionLevel: {
      type: Number,
      default: () => 0,
    },
    sectionName: {
      type: String,
      default: () => "",
    },
    subText: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: () => "l",
    },
    helpText: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "864px",
    },
  },
};
</script>

<style scoped lang="scss">
.section {
  @include Box();
  position: relative;
  display: flex;
  margin: $GRID 0;
  overflow: visible;
  align-items: stretch;

  &__header {
    flex: 1;
    display: flex;
    align-items: flex-start;
    padding: ($GRID * 1.25) $GRID;
  }

  &__body {
    width: 642px;
    height: auto;
    padding: $GRID $GRID $GRID 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &--border {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0 4px 4px 0;
    }

    &--no-padding {
      padding: 0;
    }

    &--by-column {
      flex-direction: column;
    }
  }

  .header {
    &__title {
      margin: ($GRID * 0.45) ($SUB_GRID * 2);
      color: $COLOR_KEY;
      white-space: pre-line;
      flex: 1;
    }

    &__text {
      font-weight: normal;
      font-size: 1rem;
      line-height: 1rem;
    }

    &__sub-text {
      margin-top: $SUB_GRID;
      font-size: $FONT_SIZE_S;
      line-height: $FONT_SIZE_S;
    }

    &__help {
      margin-top: 2px;
    }
  }
}
</style>
