import { createApp } from "vue";

/**
 * モーダルを動的に生成するための関数
 * componentでsubmitとcancelを設定しておけば、モーダルの結果をPromiseで取得できる
 * @param {*} component - 表示したいコンポーネント
 * @param {*} props - コンポーネントに渡すprops
 * @param {*} elementId - コンポーネントに自動的に付与するID. モーダルを重ねて表示したい場合は任意のIDを指定すること
 */
function modal(component, props, store = null, elementId = "modal") {
  return new Promise((resolve, reject) => {
    const distElement = document.createElement("div");
    const options = {
      ...props,
      id: elementId,
      onSubmit(event) {
        resolve(event);
        document.body.removeChild(distElement);
        app.unmount();
      },
      onCancel(event) {
        reject(event);
        document.body.removeChild(distElement);
        app.unmount();
      },
    };
    const app = createApp(component, options);
    if (store) {
      app.use(store);
    }
    app.mount(distElement);
    document.body.appendChild(distElement);
  });
}

export default modal;
