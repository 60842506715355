<template>
  <div class="chapter">
    <h2 class="chapter__title">
      <span class="title__number fontNum">{{ chapterNumber }}</span>
      <span class="title__chapter-name">{{ chapterName }}</span>
      <slot name="subInfo"></slot>
    </h2>
    <div class="chapter__sections">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chapterNumber: {
      type: String,
      default: "1",
    },
    chapterName: {
      type: String,
      default: "基本設定",
    }
  }
};
</script>

<style scoped lang="scss">
.chapter {
  margin-bottom: $GRID * 3;

  &__title {
    align-items: center;
    color: $COLOR_KEY;
    display: flex;
  }
}

.title__number {
  @include insertArrowicon(#b4b4b5);
  align-items: center;
  display: flex;
  font-size: 24px;

  &::after {
    margin: 0 $SUB_GRID + 4px 0 $SUB_GRID;
  }
}

.title__chapter-name {
  font-size: $FONT_SIZE_M;
  font-weight: bold;
}
</style>