<template>
  <transition name="slideUp">
    <div v-if="isShow" :class="windowClass">
      <float-setting-fair-info
        v-if="isScheduleSetting"
        :can-edit="canEdit"
        :error-data="errorData"
        :is-fair-group="isFairGroup"
        :is-daily-setting="isDailySetting"
        :is-error="isError"
        :selected-item="selectedItem"
        :selected-unit="selectedUnit"
        :target-id="targetId"
        :dates="dates"
      />
      <div class="selectedDetail" :class="{ isSetDetailSlot }" v-else-if="selectedItem">
        <dl class="selectedDetail_area">
          <dt class="selectedDetail_item">選択した{{ selectedItem }}</dt>
          <dd class="selectedDetail_num">
            <span class="fontNum">{{ selectedNum }}</span>
            <span class="selectedDetail_unit">{{ selectedUnit }}</span>
          </dd>
        </dl>
        <slot name="detail" />
      </div>
      <div class="floatSetting_main">
        <slot name="edit"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import LinkButton from "@/components/atoms/common/LinkButton.vue";
import FloatSettingFairInfo from "@/components/atoms/common/FloatSettingFairInfo.vue";

export default {
  components: {
    LinkButton,
    FloatSettingFairInfo,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "wide",
    },
    selectedNum: {
      type: Number,
      default: 0,
    },
    selectedDate: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: String,
      default: "",
    },
    selectedUnit: {
      type: String,
      default: "",
    },
    targetId: {
      type: Number,
      default: null,
    },
    errorData: {
      type: Object,
      default: () => {},
    },
    isScheduleSetting: {
      type: Boolean,
      default: false,
    },
    isFairGroup: {
      type: Boolean,
      default: false,
    },
    isDailySetting: {
      type: Boolean,
      default: false,
    },
    isSetAssist: {
      type: Boolean,
      default: false,
    },
    isSetDetailSlot: {
      type: Boolean,
      default: false,
    },
    isImageList: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    canEdit() {
      if (this.isError && this.errorData.isFatalError) return false;
      return true;
    },
    windowClass() {
      const c = ["floatSetting"];
      c.push(this.type);
      if (this.isSetAssist) c.push("isSetAssist");
      if (this.isImageList) c.push("isImageList");
      return c;
    },
    dates() {
      return this.selectedDate;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.floatSetting {
  background: #fff;
  box-shadow: $DEPTH_LV_2;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 50%;
  margin-left: $GRID * 1.5;
  overflow: hidden;
  position: fixed;
  transform: translateX(-50%);
  transition: margin $NAVI_SHOW_SPEED;
  z-index: $LAYER_FLOAT_WINDOW;
  &_main {
    display: flex;
    flex: 1px;
  }
  &.wide {
    border-radius: $BOX_RADIUS $BOX_RADIUS 0 0;
    width: $MAX_WIDTH + $GRID * 2;
  }
  &.windowSize {
    max-width: $SCHEDULE_MAX_WIDTH;
    min-height: $GRID * 6;
  }
  @media screen and (min-width: $MAIN_WIDTH) {
    margin-left: $NAVI_WIDTH * 0.5;
  }
  &.isSetAssist {
    @media screen and (min-width: $MAIN_L_WIDTH + $GRID) {
      margin-left: 0;
    }
  }
  &.isImageList {
    width: $MAX_WIDTH + $IMAGE_WIDTH * 2 + $GRID * 2;
    @media screen and (max-width: $NAVI_WIDTH + $MAX_WIDTH + $IMAGE_WIDTH * 2 + $GRID * 3) {
      width: $MAX_WIDTH + $IMAGE_WIDTH + $GRID * 2;
    }
    @media screen and (max-width: $NAVI_WIDTH + $MAX_WIDTH + $IMAGE_WIDTH + $GRID * 3) {
      width: $MAX_WIDTH + $GRID * 2;
    }
  }
}

.selectedDetail {
  @include gradientMain();
  border-right: 1px solid #e2e2e2;
  box-sizing: border-box;
  color: $COLOR_KEY;
  font-size: 0;
  font-weight: bold;
  padding: 0 0 $GRID * 0.5 $GRID;
  width: $GRID * 16;
  &_item {
    display: inline-block;
    font-size: 12px;
    padding-top: $GRID * 0.5;
    position: relative;
    &::after {
      background: $COLOR_KEY;
      content: "";
      display: block;
      height: 2px;
      left: -1px;
      position: absolute;
      top: 0;
      width: calc(100% + 2px);
    }
  }
  &_num {
    display: block;
    padding-left: $GRID;
    text-align: center;
    .fontNum {
      font-size: 40px;
    }
  }
  &_unit {
    font-size: 14px;
    margin-left: 0.5em;
  }
  &.isSetDetailSlot {
    display: flex;
    .selectedDetail {
      &_area {
        width: 50%;
      }
      &_num {
        padding: 0 $GRID * 0.5 0 0;
        text-align: right;
      }
    }
  }
}

.slideUp-leave-active,
.slideUp-enter-active {
  transition: bottom 0.3s;
}
.slideUp-enter,
.slideUp-leave-to {
  bottom: -15%;
}
</style>
