const mutations = {
  /**
   * 完了モーダル
   */
  // 表示・非表示
  setCompleteModal(state, isShow) {
    state.completeModal.isShow = isShow;
  },
  // 追加
  setAddText(state) {
    state.completeModal.text = "登録しました";
  },
  // 更新
  setUpdateText(state) {
    state.completeModal.text = "更新しました";
  },
  // 下書き保存
  setDraftSavedText(state) {
    state.completeModal.text = "下書きとして保存しました";
  },
  // 削除
  setDeleteText(state) {
    state.completeModal.text = "削除しました";
  },
  // 完了モーダルのテキスト変更
  setCompleteText(state, text) {
    state.completeModal.text = text;
  },
  /**
   * データ更新中モーダル
   */
  setUpdateModal(state, payload) {
    const { isShow, text } = payload;
    state.loadingModal.isShow = isShow;
    if (text) {
      state.loadingModal.text = text;
    }
  },
  /**
   * エラーモーダル
   */
  setErrorModal(state, payload) {
    const { isShow, text, path, confirmBtnText } = payload;
    state.errorModal.isShow = isShow;
    state.errorModal.text = text;
    state.errorModal.path = path;
    state.errorModal.confirmBtnText = confirmBtnText;
  },
  /**
   * 確認モーダル
   */
  setConfirmModal(state, payload) {
    const {
      isShow,
      targetId,
      status,
      text,
      subText,
      actionName,
      actionBtnText,
      closeBtnText,
      param,
      autoClose,
      colorType,
    } = payload;
    state.confirmModal.isShow = isShow;
    state.confirmModal.confirmed = false;
    state.targetId = targetId;
    state.confirmModal.status = status;
    state.confirmModal.text = text;
    state.confirmModal.subText = subText;
    state.confirmModal.actionName = actionName;
    state.confirmModal.actionBtnText = actionBtnText;
    state.confirmModal.closeBtnText = closeBtnText;
    state.confirmModal.param =
      typeof param !== "undefined" ? param : state.confirmModal.param;
    state.confirmModal.autoClose = autoClose;
    state.confirmModal.colorType = colorType;
  },
  modalConfirmed(state) {
    state.confirmModal.confirmed = true;
  },
};
export default mutations;
