<template>
  <benefit-form
    :title="title"
    :option-level="optionLevel([keyName])"
    :note="note"
  >
    <input-item-type-single v-if="groupType == groupTypes.fair" />
    <input-item-type-multiple v-else :is-disabled="isLinked()" />
  </benefit-form>
</template>

<script>
import { mapGetters } from "vuex";

import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputItemTypeSingle from "@/components/benefit/inputs/InputItemTypeSingle.vue";
import InputItemTypeMultiple from "@/components/benefit/inputs/InputItemTypeMultiple.vue";

export default {
  components: {
    BenefitForm,
    InputItemTypeSingle,
    InputItemTypeMultiple,
  },
  data() {
    return {
      title: "種別",
      keyName: "type",
      groupTypes: BENEFIT_GROUP_TYPE,
    };
  },
  computed: {
    ...mapGetters({
      groupType: "groupType",
      optionLevel: "optionLevel",
      onlyMediaName: "onlyMediaName",
      needNote: "needNote",
      targetId: "targetId",
      mediaIds: "mediaIds",
      $isLinked: "isLinked",
    }),
    note() {
      if (this.isLinked()) {
        return "※連携中のため変更できません";
      }
      // TODO: 要調査・改めて正しい注釈の表示条件を検討する
      // if (this.needNote(this.keyName)) {
      //   return this.onlyMediaName(this.keyName);
      // }
      return null;
    },
  },
  methods: {
    isLinked() {
      const isEdit = this.targetId;
      const isHallBenefit = this.groupType === BENEFIT_GROUP_TYPE.hall;
      if (!isEdit || !isHallBenefit) return false;

      const isLinked = this.mediaIds.some((id) => this.$isLinked(id));
      return isLinked;
    },
  },
};
</script>
