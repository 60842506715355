import {
  getSelectedHallId,
  deselectHall,
  IMPORT_PAGE_CONFIG,
} from "@/assets/common/PageConfig.js";
import API_CODE from "@/assets/common/ApiCode.js";
import { TargetType } from "@/assets/hall-admin/target-type";

import deepCopy from "@/lib/deepCopy";

import useHallRepository from "@/repositories/hall";

/**
 * インポートツール共通処理
 */
const ImportModule = {
  namespaced: true,
  state: {
    isComplete: false,
    mediaOrder: [
      { id: 99, name: "コレモネ" },
      { id: API_CODE.media.zexy, name: "ゼクシィ" },
      { id: API_CODE.media.mynavi, name: "マイナビ" },
      { id: API_CODE.media.ksm, name: "婚スタ" },
      { id: API_CODE.media.mw, name: "みんな" },
      { id: API_CODE.media.wp, name: "ウエパ" },
      { id: API_CODE.media.hanayume, name: "ハナユメ" },
    ], // 媒体タブ、リストの表示順
    coremoneId: 99,
    hallInfo: {},
  },
  getters: {
    mediaOrder: (state, getters, rootState) => (dir) => {
      const isImage = getters.isImagePage(dir);
      let isEmptyCoremone = true;
      if (isImage) {
        const data = rootState.data;
        isEmptyCoremone = !data.some(
          (item) => item.media == getters.coremoneId
        );
      }

      // 画像以外またはコレモネの紐づけ前データが存在しない場合、コレモネ列を非表示
      const order = deepCopy(state.mediaOrder);
      if (!isImage || isEmptyCoremone) order.shift(0);

      if (getters.isBenefitPage(dir)) {
        const hasHallBenefitMedia = [
          API_CODE.media.zexy,
          API_CODE.media.mw,
          API_CODE.media.wp,
          API_CODE.media.mynavi,
        ];
        return order.filter(({ id }) => hasHallBenefitMedia.includes(id));
      }
      return order;
    },
    coremoneId: (state) => {
      return state.coremoneId;
    },
    needShowPage: (state, getters) => (dir) => {
      const importTarget = getters.importTargetType(dir);
      if (importTarget === null) return true;

      // 準備中の場合
      const inPreparation = state.hallInfo.in_preparation_target;
      if (inPreparation != undefined && inPreparation.length > 0) {
        return inPreparation.includes(importTarget);
      }

      // 利用中の場合
      const inUsage = state.hallInfo.in_usage_target;
      return inUsage != undefined && inUsage.includes(importTarget);
    },
    importTargetType: (state, getters) => (dir) => {
      if (getters.isFairPage(dir)) return TargetType.FAIR;
      if (getters.isPlanPage(dir)) return TargetType.PLAN;
      if (getters.isBenefitPage(dir)) return TargetType.HALL_BENEFIT;
      return null;
    },
    isImagePage: (s) => (dir) => dir === "import-image",
    isFairPage: (s) => (dir) => dir === "import-fair",
    isPlanPage: (s) => (dir) => dir === "import-plan",
    isBenefitPage: (s) => (dir) => dir === "import-hall-benefit",
  },
  mutations: {
    SET_IS_COMPLETE: function (state, value) {
      state.isComplete = value;
    },
    SET_HALL_INFO: function (state, hallInfo) {
      state.hallInfo = hallInfo;
    },
  },
  actions: {
    /**
     * 紐づけ処理を開始する
     */
    async associate({ commit, dispatch }) {
      commit("modal/setUpdateModal", { isShow: true }, { root: true });
      commit("SET_IS_COMPLETE", true);
      // 実行
      const hall_id = getSelectedHallId();
      const { startAssociate } = useHallRepository();
      const { status } = await startAssociate(hall_id);
      commit("modal/setUpdateModal", { isShow: false }, { root: true });
      if (status !== API_CODE.response.success) {
        commit("SET_IS_COMPLETE", false);
        dispatch("setErrorText", "保存に失敗しました", { root: true });
        dispatch("showErrorModal", null, { root: true });
        return;
      }
      commit(
          "modal/setCompleteText",
          "紐付け処理を開始しました\r\n式場一覧に移動します",
          { root: true }
      );
      dispatch(
          "modal/showCompleteModal",
          {
            callBackAction: "import/moveMasterPage",
          },
          { root: true }
      );
    },
    /**
     * 式場一覧ページへ移動
     */
    moveMasterPage() {
      deselectHall();
      window.location.href = "/hall-admin";
    },
    /**
     * 式場情報を取得・保存
     */
    async getHallInfo({ commit, dispatch }) {
      const url = "/hall/usage";
      const response = await dispatch(
        "common/getInitialData",
        { apiUrl: url },
        { root: true }
      );
      const result = response && response.status === API_CODE.response.success;
      const data = response.data;
      if (result && data.data) commit("SET_HALL_INFO", data.data);
      return result;
    },
    /**
     * 次の設定ページに移動
     * @param {String} currentDir 現在の pageDir
     */
    moveNextPage({ getters, dispatch }, currentDir) {
      const pages = IMPORT_PAGE_CONFIG.filter(
        (page) =>
          page.dir != "complete" &&
          (page.dir === currentDir || getters.needShowPage(page.dir))
      );
      const index = pages.findIndex((page) => page.dir === currentDir);
      if (index + 1 === pages.length) return;

      const current = pages[index];
      const next = pages[index + 1];
      dispatch(
        "modal/showConfirmModal",
        {
          text: `${current.name}が完了しました\r\n${next.name}に移動しますか？`,
          status: 3,
          actionName: "import/movePage",
          actionBtnText: "移動する",
          closeBtnText: "キャンセル",
          param: { path: `/${next.dir}` },
        },
        { root: true }
      );
    },
    /**
     * ページ移動
     * @param {Object} { path: 移動先 }
     */
    movePage({}, payload) {
      const { path } = payload;
      window.location.href = path;
    },
  },
};

export default ImportModule;
