<template>
  <section class="inputForm">
    <div class="inputForm_title" :class="{ isSmallTitle }">
      <input-option-icon class="inputForm_icon" size="s" :option-level="optionLevel" />
      <p class="text" v-html="title"></p>
    </div>
    <div class="inputForm_block">
      <slot name="content" />
    </div>
  </section>
</template>

<script>
// component
import InputOptionIcon from "@/components/atoms/common/InputOptionIcon.vue";

export default {
  components: {
    InputOptionIcon,
  },
  props: {
    isSmallTitle: {
      type: Boolean,
      default: false,
    },
    optionLevel: {
      type: Number,
      default: 0,
    }, // 0:アイコン無し 1:任意 2:必須
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
$TITLE_WIDTH: $GRID * 13;
.inputForm {
  @include Box();
  display: flex;
  font-size: 13px;
  &_block {
    @include formSubBox();
    width: calc(100% - #{$TITLE_WIDTH});
  }
  &_icon {
    height: 30px;
    margin-right: 10px;
    width: 30px;
    flex-shrink: 0;
  }
  &_title {
    align-items: center;
    color: $COLOR_KEY;
    display: flex;
    font-size: 14px;
    min-height: $GRID * 5;
    padding: 0 0 0 $GRID;
    width: $TITLE_WIDTH;
    &.isSmallTitle {
      font-size: 13px;
    }
  }
  & + & {
    margin-top: $GRID;
  }
}
</style>
