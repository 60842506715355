<template>
  <div class="alertPopupBalloon" :class="addClass">
    <button class="alertPopupBalloon_btn" :title="title" @click="onClick()">
      <span class="alertPopupBalloon_btn_text">{{ text }}</span>
      <i class="alertPopupBalloon_btn_icon material-icons">help</i>
    </button>
    <transition name="popup">
      <div class="alertPopupBalloon_body" v-if="isShow" :class="{ typeOver }">
        <slot name="text" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "入力エラー",
    },
    about: {
      type: String,
      default: "",
    },
    arrowPosition: {
      type: String,
      default: "left",
    },
    popupAlign: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      isShow: false,
      aboutTitle: {
        close: this.about ? this.about + "についてを閉じる" : "ヘルプを閉じる",
        show: this.about ? this.about + "とは?" : "ヘルプを見る",
      },
      typeOver: false,
    };
  },
  computed: {
    addClass() {
      return {
        isArrowTop: this.arrowPosition === "top",
        isArrowBottom: this.arrowPosition === "bottom",
        isArrowRight: this.arrowPosition === "right",
      };
    },
    title() {
      return this.isShow ? this.aboutTitle.close : this.aboutTitle.show;
    },
  },
  methods: {
    onClick() {
      this.isShow = !this.isShow;
      if (this.isShow) {
        // ポップアップが見切れる場合はクラスをふる
        this.typeOver =
          this.popupAlign === "right" ||
          window.innerWidth - 240 < this.$el.getBoundingClientRect().left;
      }
    },
    closePopup() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
$mainClass: "alertPopupBalloon";

.#{$mainClass} {
  position: relative;
  z-index: $LAYER_HELP_POPUP;

  &.isArrowTop {
    .#{$mainClass} {
      &_btn {
        &::before {
          border-color: transparent transparent $COLOR_ALERT transparent;
          border-width: $SUB_GRID $SUB_GRID $SUB_GRID * 2 $SUB_GRID;
          left: calc(50% - #{$SUB_GRID});
          top: -12px;
        }
      }
      &_body {
        bottom: auto;
        margin-top: $GRID * 0.5;
        &::before {
          border-color: transparent transparent $BG_COLOR_SUB transparent;
          border-width: 0 $SUB_GRID $GRID * 0.5 $SUB_GRID;
          bottom: auto;
          top: -$GRID * 0.5;
        }
      }
    }
    .popup-enter,
    .popup-leave-to {
      transform: translateY(-20px);
    }
  }

  &.isArrowBottom {
    .#{$mainClass} {
      &_btn {
        &::before {
          border-color: $COLOR_ALERT transparent transparent transparent;
          border-width: $SUB_GRID * 2 $SUB_GRID $SUB_GRID $SUB_GRID;
          left: calc(50% - #{$SUB_GRID});
          bottom: -12px;
        }
      }
      &_body {
        top: auto;
        margin-top: $GRID * 0.5;
        &::before {
          border-color: $BG_COLOR_SUB transparent transparent transparent;
          border-width: $GRID * 0.5 $SUB_GRID 0 $SUB_GRID;
          top: auto;
          bottom: -$GRID * 0.5;
        }
      }
    }
    .popup-enter,
    .popup-leave-to {
      transform: translateY(20px);
    }
  }

  &.isArrowRight {
    .#{$mainClass} {
      &_btn {
        &::before {
          border-color: transparent transparent transparent $COLOR_ALERT;
          border-width: $SUB_GRID $SUB_GRID $SUB_GRID $SUB_GRID * 2;
          right: -12px;
          left: auto;
        }
      }
    }
  }

  &_btn {
    -webkit-appearance: none;
    align-items: center;
    background-color: $COLOR_ALERT;
    border: none;
    border-radius: 8px;
    box-shadow: $DEPTH_LV_0;
    color: #fff;
    display: flex;
    outline: none;
    padding: 3px 8px 1px 10px;
    position: relative;
    width: $GRID * 6 + $SUB_GRID;
    &::before {
      border-color: transparent $COLOR_ALERT transparent transparent;
      border-style: solid;
      border-width: $SUB_GRID $SUB_GRID * 2 $SUB_GRID $SUB_GRID;
      content: "";
      display: block;
      height: 0;
      left: -12px;
      position: absolute;
      width: 0;
    }
    &:hover {
      .#{$mainClass}_btn {
        &_icon {
          animation: shake 0.8s ease-in-out;
          transform-origin: bottom center;
        }
      }
    }
    &_icon {
      font-size: 20px;
    }
    &_text {
      font-size: $FONT_SIZE_S;
      margin-right: 3px;
    }
  }

  &_body {
    background: $BG_COLOR_SUB;
    border-radius: $BOX_RADIUS;
    bottom: 100%;
    box-shadow: $DEPTH_LV_0;
    color: $COLOR_TEXT;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    font-size: 12px;
    font-weight: normal;
    margin-bottom: $GRID * 0.5;
    padding: $GRID * 0.5 $SUB_GRID * 2;
    position: absolute;
    width: $GRID * 15;
    white-space: pre-line;
    word-break: break-all;
    &::before {
      border-color: $BG_COLOR_SUB transparent transparent transparent;
      border-style: solid;
      border-width: $GRID * 0.5 $SUB_GRID 0 $SUB_GRID;
      bottom: -$GRID * 0.5;
      content: "";
      display: block;
      height: 0;
      left: $GRID * 3;
      position: absolute;
      width: 0;
    }
    &.typeOver {
      left: auto;
      right: -$GRID;
      &::before {
        left: auto;
        right: $GRID + $SUB_GRID * 1.5;
      }
    }
    ::v-deep a {
      color: $COLOR_KEY;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.popup-enter-active,
.popup-leave-active {
  transition: opacity 0.6s, transform 0.6s;
}
.popup-enter,
.popup-leave-to {
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0;
  transform: translateY(20px);
}
</style>
