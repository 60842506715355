<template>
  <div class="downloadList">
    <div class="downloadListHeader">
      <div v-if="title" class="downloadListHeader_title">
        <i class="material-icons">menu_book</i>
        <p class="downloadListHeader_titleText">{{ title }}</p>
        <p v-if="date" class="downloadListHeader_titleDate">{{ date }}</p>
      </div>
      <action-button
        v-if="batchPath"
        text="一括ダウンロード"
        :is-save="true"
        @clickHandler="select(batchPath)"
      />
    </div>
    <ul class="downloadListContent">
      <li v-for="(file, i) in files" :key="i" class="downloadListItem">
        <div class="downloadListItem_title">
          <i class="material-icons">article</i>
          <p class="downloadListItem_titleText">{{ file.name }}</p>
        </div>
        <div class="downloadListItem_detail">
          <ul v-for="(column, j) in columns(file)" :key="j" class="downloadListItem_detailColumn">
            <li v-for="(detail, k) in column" :key="k">{{ detail }}</li>
          </ul>
        </div>
        <action-button
          text="ダウンロード"
          :is-disabled="!file.path"
          :is-save="true"
          @clickHandler="select(file.path)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
// Components
import ActionButton from "@/components/atoms/common/ActionButton.vue";

export default {
  components: {
    ActionButton,
  },
  props: {
    title: {
      type: String,
      default: "操作マニュアル",
    },
    date: {
      type: String,
      default: null,
    },
    batchPath: {
      type: String,
      default: null,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    columns(file) {
      let columns = [];
      if (Array.isArray(file.details)) {
        if (file.isSingleColumn || file.details.length == 1) {
          columns.push(file.details);
        } else if (file.details.length >= 2) {
          const num =
            file.details.length % 2 == 0 ? file.details.length / 2 : file.details.length / 2 + 1;
          columns.push(file.details.slice(0, num));
          columns.push(file.details.slice(num));
        }
      }
      return columns;
    },
    select: async function (path) {
      this.$emit("select", path);
    },
  },
};
</script>

<style scoped lang="scss">
.downloadList {
  @include DentedBox();
  padding: $GRID;
  width: $MAX_WIDTH;
  &Content {
    list-style: none;
  }
  &Header,
  &Item {
    align-items: center;
    display: flex;
    .btn {
      flex-shrink: 0;
      font-size: $FONT_SIZE_S;
      height: $GRID * 1.5;
      margin: 0 0 0 auto;
      padding: 3px 0;
      width: $GRID * 8.75;
    }
    &_detail {
      color: $COLOR_SUB_LIGHT_TEXT;
      display: flex;
      font-size: 10.5px;
      padding-left: $GRID;
      width: 100%;
      &Column {
        list-style: disc;
        padding: 0 $GRID;
        word-break: break-all;
        word-wrap: break-word;
        &:not(:only-child) {
          width: 50%;
        }
      }
    }
    &_title {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      font-size: 18px;
      > * {
        margin-right: $GRID;
      }
      &Date {
        color: $COLOR_SUB_LIGHT_TEXT;
        font-size: $FONT_SIZE_S;
        margin-top: $SUB_GRID;
        white-space: nowrap;
        &::after {
          content: "更新";
          margin-left: $SUB_GRID;
        }
      }
      &Text {
        margin-top: 4px;
      }
    }
  }
  &Item {
    @include Box();
    margin-top: $GRID;
    padding: $GRID;
    &_title {
      font-size: 15px;
      width: $GRID * 14;
      &Text {
        margin-top: 2px;
        white-space: wrap;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
    & + & {
      margin-top: $GRID * 0.5;
    }
  }
}
</style>
