/**
 * 公開設定で掲載期間を編集するために、編集対象の特典テンプレートを管理するモジュール
 */
import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";
import HALL_BENEFIT_TYPE from "@/assets/benefit/hall-benefit-type";

import deepCopy from "@/lib/deepCopy";

// 特典マスタから指定された媒体IDの掲載期間を抜き出して返却する
const period = (template, mediaId) => {
  if (!template) {
    return { from: null, until: null };
  }

  const { publication_from, publication_until } = template;
  const from =
    publication_from[mediaId] || publication_from[API_CODE.mediaCommon] || null;
  const until =
    publication_until[mediaId] ||
    publication_until[API_CODE.mediaCommon] ||
    null;
  return { from, until };
};

// 掲載中・予約中の特典の選択時に、式場特典に紐づく特典マスタ一覧の長さが不足している場合は、空のオブジェクトで配列を埋める
const fillInTemplateList = (linkedTemplateList, targetIndex) => {
  if (linkedTemplateList.length > targetIndex) {
    return linkedTemplateList;
  }

  const addCount = targetIndex + 1;
  const defaultTemplateList = [...Array(addCount)].map(() => ({
    scheduledTemplate: null,
    publishedTemplate: null,
  }));
  const filledTemplateList = [...linkedTemplateList, ...defaultTemplateList];
  return filledTemplateList.slice(0, addCount);
};

// 式場特典に紐づくテンプレート一覧
export const selectedState = {
  description: null,
  termType: null,
  linkedTemplateList: {
    [HALL_BENEFIT_TYPE.visit]: [],
    [HALL_BENEFIT_TYPE.contract]: [],
    [HALL_BENEFIT_TYPE.common]: [],
  },
};

export const selectedActions = {
  setupCommonInfo({ commit, getters }, { mediaId, benefitType }) {
    const description = getters.originalDescription(mediaId, benefitType);
    const termType = getters.originalTermType(mediaId, benefitType);

    commit("description", description);
    commit("termType", termType);
  },
  resetCommonInfo({ commit }) {
    commit("description", null);
    commit("termType", null);
  },
  // 媒体のタブを選択した際に、該当する特典のテンプレートを取得して保存しておく
  setupTemplateList(
    { commit, getters, rootGetters },
    { mediaId, benefitType }
  ) {
    const hallBenefits = getters.benefits(mediaId, benefitType);
    const templateList = hallBenefits.map(
      ({ published_benefit_id, scheduled_benefit_id }) => {
        const publishedTemplate = rootGetters.benefit(published_benefit_id);
        const scheduledTemplate = rootGetters.benefit(scheduled_benefit_id);
        return {
          publishedTemplate: deepCopy(publishedTemplate),
          scheduledTemplate: deepCopy(scheduledTemplate),
        };
      }
    );
    commit("templateList", { benefitType, templateList });
  },
  // 保存している特典の情報をリセットする
  resetTemplateList({ commit }) {
    Object.values(HALL_BENEFIT_TYPE).forEach((benefitType) => {
      commit("templateList", { benefitType, templateList: [] });
    });
  },
};

export const selectedGetters = {
  description: ({ description }) => description,
  termType: ({ termType }) => termType,
  templateList: ({ linkedTemplateList }) => linkedTemplateList,
  templateListByBenefitType:
    ({ linkedTemplateList }) =>
    (benefitType) =>
      linkedTemplateList[benefitType],
  // 掲載中の特典に関する処理
  publishedTemplate: (_, getters) => (targetIndex, benefitType) => {
    const templateList = getters.templateListByBenefitType(benefitType);
    const template = templateList.find((_, index) => index === targetIndex);

    return template ? template.publishedTemplate : null;
  },
  publishedPeriod: (_, getters) => (index, benefitType, mediaId) => {
    const template = getters.publishedTemplate(index, benefitType);
    return period(template, mediaId);
  },
  // 予約中の特典に関する処理
  scheduledTemplate: (_, getters) => (targetIndex, benefitType) => {
    const templateList = getters.templateListByBenefitType(benefitType);
    const template = templateList.find((_, index) => index === targetIndex);

    return template ? template.scheduledTemplate : null;
  },
  scheduledPeriod: (_, getters) => (index, benefitType, mediaId) => {
    const template = getters.scheduledTemplate(index, benefitType);
    return period(template, mediaId);
  },
  flattedTemplateList: (_, getters) => (benefitType) => {
    const templateList = getters.templateListByBenefitType(benefitType);
    return templateList.flatMap(({ publishedTemplate, scheduledTemplate }) => {
      const list = [];
      if (publishedTemplate) {
        list.push(publishedTemplate);
      }
      if (scheduledTemplate) {
        list.push(scheduledTemplate);
      }
      return list;
    });
  },
  // 公開設定モーダルで掲載期間が編集されている特典テンプレートを取得する
  changedTemplateList:
    (s, getters, rs, rootGetters) => (mediaId, benefitType) => {
      const benefits = rootGetters.benefits[BENEFIT_GROUP_TYPE.hall];
      const templateList = getters.flattedTemplateList(benefitType);
      return templateList
        .filter((template) => {
          const benefit = benefits.find(({ id }) => id == template.id);
          const templatePeriod = {
            from: template.publication_from,
            until: template.publication_until,
          };
          const benefitPeriod = {
            from: benefit.publication_from,
            until: benefit.publication_until,
          };
          return (
            JSON.stringify(templatePeriod) !== JSON.stringify(benefitPeriod)
          );
        })
        .map((template) => {
          // 変更されている場合は、共通入力をnullにして媒体個別として登録する
          const publication_from = {
            [API_CODE.mediaCommon]: null,
            [mediaId]: template.publication_from[mediaId],
          };
          const publication_until = {
            [API_CODE.mediaCommon]: null,
            [mediaId]: template.publication_until[mediaId],
          };
          return { id: template.id, publication_from, publication_until };
        });
    },
  hallBenefitParams: (_, getters) => (benefitType) => {
    const description = getters.description;
    const term_type = getters.termType;
    const templateList = getters.templateListByBenefitType(benefitType);
    const benefits = templateList
      .filter(
        ({ publishedTemplate, scheduledTemplate }) =>
          publishedTemplate || scheduledTemplate
      )
      .map(({ publishedTemplate, scheduledTemplate }) => ({
        published_benefit_id: publishedTemplate ? publishedTemplate.id : null,
        scheduled_benefit_id: scheduledTemplate ? scheduledTemplate.id : null,
      }));
    return { description, term_type, benefits };
  },
};

export const selectedMutations = {
  description(state, description) {
    state.description = description;
  },
  termType(state, termType) {
    state.termType = termType;
  },
  templateList(state, { benefitType, templateList }) {
    state.linkedTemplateList = {
      ...state.linkedTemplateList,
      [benefitType]: templateList,
    };
  },
  publishedTemplate(state, { benefitType, targetIndex, newTemplate }) {
    const linkedTemplateList = state.linkedTemplateList[benefitType];
    const templateList = fillInTemplateList(linkedTemplateList, targetIndex);
    const newTemplateList = templateList.map(
      ({ scheduledTemplate, publishedTemplate }, index) => {
        const template =
          index === targetIndex ? newTemplate : publishedTemplate;
        return { scheduledTemplate, publishedTemplate: template };
      }
    );

    state.linkedTemplateList = {
      ...state.linkedTemplateList,
      [benefitType]: newTemplateList,
    };
  },
  publishedPeriod(state, { benefitType, from, until, targetIndex, mediaId }) {
    const templateList = state.linkedTemplateList[benefitType];
    const newTemplateList = templateList.map(
      ({ publishedTemplate, scheduledTemplate }, index) => {
        if (index !== targetIndex) {
          return { publishedTemplate, scheduledTemplate };
        }

        const publication_from = {
          ...publishedTemplate.publication_from,
          [mediaId]: from,
        };
        const publication_until = {
          ...publishedTemplate.publication_until,
          [mediaId]: until,
        };
        return {
          scheduledTemplate,
          publishedTemplate: {
            ...publishedTemplate,
            publication_from,
            publication_until,
          },
        };
      }
    );

    state.linkedTemplateList = {
      ...state.linkedTemplateList,
      [benefitType]: newTemplateList,
    };
  },
  scheduledTemplate(state, { benefitType, targetIndex, newTemplate }) {
    const linkedTemplateList = state.linkedTemplateList[benefitType];
    const templateList = fillInTemplateList(linkedTemplateList, targetIndex);
    const newTemplateList = templateList.map(
      ({ scheduledTemplate, publishedTemplate }, index) => {
        const template =
          index === targetIndex ? newTemplate : scheduledTemplate;
        return { publishedTemplate, scheduledTemplate: template };
      }
    );

    state.linkedTemplateList = {
      ...state.linkedTemplateList,
      [benefitType]: newTemplateList,
    };
  },
  scheduledPeriod(state, { benefitType, from, until, targetIndex, mediaId }) {
    const templateList = state.linkedTemplateList[benefitType];
    const newTemplateList = templateList.map(
      ({ publishedTemplate, scheduledTemplate }, index) => {
        if (index !== targetIndex) {
          return { publishedTemplate, scheduledTemplate };
        }

        const publication_from = {
          ...scheduledTemplate.publication_from,
          [mediaId]: from,
        };
        const publication_until = {
          ...scheduledTemplate.publication_until,
          [mediaId]: until,
        };
        return {
          publishedTemplate,
          scheduledTemplate: {
            ...scheduledTemplate,
            publication_from,
            publication_until,
          },
        };
      }
    );

    state.linkedTemplateList = {
      ...state.linkedTemplateList,
      [benefitType]: newTemplateList,
    };
  },
};
