import API_CODE from "@/assets/common/ApiCode";

import { storeContact } from "@/repositories/contact";

const actions = {
  // モーダル表示
  async showModalContact({ commit }) {
    commit("SET_MODAL_SHOW", true);
  },
  async closeModalContact({ commit }) {
    commit("SET_MODAL_SHOW", false);
  },
  // 変更内容を state に保存
  setContactData({ commit }, payload) {
    commit("UPDATE_DATA", payload);
  },
  // データ送信
  async sendData({ commit, dispatch, getters }) {
    commit("modal/setUpdateModal", { isShow: true, text: "送信中です…" });
    const { data, master } = getters;
    const contact_type = master.contact_type[data.contact_type];
    const service_name = master.service_name[data.service_name] || null;
    // 媒体名をカンマ区切りの文字列に変換する
    const media_names = data.media_names.map((id) => master.media_names[id]).join(",");
    const params = { ...data, contact_type, service_name, media_names };
    await dispatch("postContactData", params);
  },
  async postContactData({ commit, dispatch }, payload) {
    const { status } = await storeContact(payload);
    if (status === API_CODE.response.created) {
      commit("SET_MODAL_SHOW", false);
      commit("CLEAR_DATA");
      commit("modal/setCompleteText", "送信しました");
      dispatch("modal/showCompleteModal");
    } else {
      const e = {
        isShow: true,
        text: "お問い合わせの送信に失敗しました　時間をおいて再度お試しください",
      };
      dispatch("modal/showErrorModal", e);
    }
  },
  /**
   * 入力済みかどうかの情報を登録
   * 入力済みかどうかのチェックの後、必須項目かどうかのチェックを行う
   * @param {*} payload { Key: 要素名, isValid: 正常に入力されているか }
   */
  setValidation({ commit }, payload) {
    commit("VALID_ITEM", payload);
    commit("COMPLETED_REQUIRED_ITEM", payload);
    commit("ITEMS_HAS_ERROR", payload);
  },
};
export default actions;
