/**
 * 文字列がURLとして妥当かを判別して返す
 * - nullや空文字の場合は未入力として判別せずにtrueを返す
 * - 入力文字数が少ない場合は、入力された範囲でのみ判別する
 * @param {String} url URL文字列
 * @return {Boolean} URLとして打倒ならtrue
 */

import typeToString from "../typeToString";

const protocols = ["http", "https"]; // 前方一致でチェック

const isValidUrl = (url) => {
  const type = typeToString(url);
  if (type === "Null") {
    return true;
  } else if (type !== "String") {
    return false;
  }
  const length = url.length;
  if (length === 0) {
    return true;
  }
  return protocols.some((protocol) => {
    let needle = `${protocol}://`;
    return url.startsWith(needle.substr(0, length));
  });
};

export default isValidUrl;
