<template>
  <input
    type="text"
    class="inputText"
    v-model="selfText"
    :class="className"
    :placeholder="placeholder"
    :disabled="isDisabled"
    :required="isRequired"
    :style="calcStyle"
  />
</template>

<script>
export default {
  props: {
    modelValue: String,
    placeholder: {
      text: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hasLimit: {
      type: Boolean,
      default: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: null,
    },
  },
  computed: {
    selfText: {
      get: function () {
        return this.modelValue;
      },
      set: function (value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        isSS: this.size == "ss",
        hasError: this.hasError,
      };
    },
    calcStyle() {
      let width;
      if (this.hasLimit) {
        width = this.size !== null ? this.size + "rem" : "20rem"; // 横幅を文字数から計算
      } else {
        width = "100%";
      }
      return {
        width: width,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.inputText {
  @include commonInputField();
}
</style>
