<template>
  <svg
    version="1.1"
    class="navTitle_logoImg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 350 140"
    style="enable-background:new 0 0 350 140;"
    xml:space="preserve"
    width="136"
    height="54"
  >
    <path
      class="st0"
      d="M24.6,28.9l-0.8,0.5v11.9l0.6,0.7l44.8,0v29H24.3l-0.8,0.5v11.9l0.6,0.7h59.6l0.6-0.7v-54l-0.8-0.5H24.6z
	 M155.5,41.7C138.7,59,128.8,64.9,115.2,70V25.6l-0.7-0.7h-12.7l-0.7,0.6v47.2l-0.4,8.4l7,6.3c0.6,0.5,1,0.8,1.5,0.8
	c0.4,0,1-0.1,1.9-0.4c21.2-8.7,35.5-16.4,50.4-32.2c0.5-0.7,0.7-1.2,0.7-1.7c0-0.4-0.3-0.9-0.5-1.5L155.5,41.7z M211.8,48.2V37.4
	H235l0.6-0.7V25.5l-0.7-0.6h-52.6l-0.8,0.5v11.3l0.6,0.7h15.4v10.8l-21.7,0l-0.8,0.5V60l0.6,0.7l21.8,0v11.4c0,4.3-0.1,8,2.2,10.9
	c3,3.9,7.1,5.2,17.1,5.2c10.8,0,17.2-0.4,20.9-0.8l0.5-0.6l-0.4-12.2l-0.6-0.5c-7.3,0.7-12.3,0.9-18.6,0.9c-4,0-5.9-0.6-6.3-1.3
	c-0.4-1-0.4-2-0.4-4.6v-8.3l32.6,0L245,60V48.8l-0.7-0.6L211.8,48.2z"
    />
    <g>
      <path
        class="st0"
        d="M130.5,120.7c-1.6,0-2.9-0.4-4.1-1.1c-1.2-0.7-2.1-1.7-2.8-2.9c-0.7-1.2-1-2.7-1-4.3c0-2.6,0.7-4.6,2.2-6.1
		c1.4-1.5,3.4-2.2,5.7-2.2s4.3,0.7,5.7,2.2c1.4,1.5,2.1,3.5,2.1,6c0,1.6-0.3,3.1-1,4.3c-0.7,1.2-1.6,2.2-2.8,2.9
		C133.4,120.4,132,120.7,130.5,120.7z M130.5,107.3c-1.1,0-2,0.5-2.8,1.4c-0.8,0.9-1.1,2.2-1.1,3.8c0,1.6,0.4,2.9,1.1,3.8
		c0.7,0.9,1.7,1.4,2.8,1.4c1.2,0,2.1-0.4,2.9-1.3c0.7-0.9,1.1-2.1,1.1-3.8c0-1.7-0.4-2.9-1.1-3.8
		C132.5,107.7,131.6,107.3,130.5,107.3z"
      />
      <path
        class="st0"
        d="M166.1,113.7h-11.2c0.1,1.3,0.5,2.3,1.3,3.1c0.7,0.8,1.7,1.1,2.9,1.1c0.9,0,1.6-0.2,2.2-0.6
		c0.6-0.4,1.2-1.2,2-2.4l3.1,1.7c-0.5,0.8-1,1.5-1.5,2.1c-0.5,0.6-1.1,1-1.7,1.4c-0.6,0.4-1.3,0.6-1.9,0.8c-0.7,0.2-1.5,0.3-2.3,0.3
		c-2.3,0-4.2-0.8-5.6-2.3c-1.4-1.5-2.1-3.5-2.1-6c0-2.5,0.7-4.5,2.1-6c1.4-1.5,3.2-2.3,5.5-2.3c2.3,0,4.1,0.7,5.5,2.2
		c1.3,1.5,2,3.5,2,6.1L166.1,113.7z M162.4,110.7c-0.5-1.9-1.7-2.9-3.7-2.9c-0.4,0-0.9,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-1.1,0.6
		c-0.3,0.3-0.6,0.6-0.8,0.9c-0.2,0.4-0.4,0.8-0.5,1.2H162.4z"
      />
      <path
        class="st0"
        d="M178.3,105h3.6v1.4c0.7-0.7,1.3-1.2,1.8-1.5c0.5-0.3,1.2-0.4,2-0.4c1.8,0,3.2,0.8,4.2,2.3
		c1.1-1.5,2.7-2.3,4.6-2.3c3.5,0,5.3,2.1,5.3,6.4v9.6H196v-8.7c0-1.5-0.2-2.6-0.5-3.2c-0.4-0.6-1-0.9-1.8-0.9c-1,0-1.7,0.4-2.2,1.1
		c-0.5,0.7-0.7,1.9-0.7,3.6v8.1h-3.6v-8.6c0-2.8-0.8-4.2-2.4-4.2c-1,0-1.8,0.4-2.2,1.1c-0.5,0.8-0.7,2-0.7,3.6v8.1h-3.6V105z"
      />
      <path
        class="st0"
        d="M209.3,121.1c-1.6,0-2.9-0.4-4.1-1.1c-1.2-0.7-2.1-1.7-2.8-2.9c-0.7-1.2-1-2.7-1-4.3c0-2.6,0.7-4.6,2.2-6.1
		c1.4-1.5,3.4-2.2,5.7-2.2s4.3,0.7,5.7,2.2c1.4,1.5,2.1,3.5,2.1,6c0,1.6-0.3,3.1-1,4.3c-0.7,1.2-1.6,2.2-2.8,2.9
		C212.3,120.8,210.9,121.1,209.3,121.1z M209.3,107.7c-1.1,0-2,0.5-2.8,1.4c-0.8,0.9-1.1,2.2-1.1,3.8c0,1.6,0.4,2.9,1.1,3.8
		c0.7,0.9,1.7,1.4,2.8,1.4c1.2,0,2.1-0.4,2.9-1.3c0.7-0.9,1.1-2.1,1.1-3.8c0-1.7-0.4-2.9-1.1-3.8
		C211.4,108.1,210.5,107.7,209.3,107.7z"
      />
      <path
        class="st0"
        d="M219.1,105h3.6v1.4c1.3-1.2,2.7-1.9,4.3-1.9c1.8,0,3.2,0.6,4.2,1.7c0.9,1,1.3,2.6,1.3,4.8v9.6h-3.6v-8.7
		c0-1.5-0.2-2.6-0.6-3.2c-0.4-0.6-1.2-0.9-2.3-0.9c-1.2,0-2,0.4-2.5,1.2c-0.5,0.8-0.7,2.1-0.7,4.1v7.5h-3.6V105z"
      />
      <path
        class="st0"
        d="M249.3,113.7h-11.2c0.1,1.3,0.5,2.3,1.3,3.1c0.7,0.8,1.7,1.1,2.9,1.1c0.9,0,1.6-0.2,2.2-0.6
		c0.6-0.4,1.2-1.2,2-2.4l3.1,1.7c-0.5,0.8-1,1.5-1.5,2.1c-0.5,0.6-1.1,1-1.7,1.4c-0.6,0.4-1.3,0.6-1.9,0.8c-0.7,0.2-1.5,0.3-2.3,0.3
		c-2.3,0-4.2-0.8-5.6-2.3c-1.4-1.5-2.1-3.5-2.1-6c0-2.5,0.7-4.5,2.1-6c1.4-1.5,3.2-2.3,5.5-2.3c2.3,0,4.1,0.7,5.5,2.2
		c1.3,1.5,2,3.5,2,6.1L249.3,113.7z M245.6,110.7c-0.5-1.9-1.7-2.9-3.7-2.9c-0.4,0-0.9,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-1.1,0.6
		c-0.3,0.3-0.6,0.6-0.8,0.9c-0.2,0.4-0.4,0.8-0.5,1.2H245.6z"
      />
      <g>
        <path
          class="st0"
          d="M140.2,105h3.8v1.4c0.7-0.7,1.3-1.2,1.9-1.4c0.5-0.3,1.2-0.4,2-0.4c1,0,2,0.3,3.1,0.9l-1.7,3.3
			c-0.7-0.5-1.4-0.7-2.1-0.7c-2.1,0-3.1,1.5-3.1,4.5v8.1h-3.8V105z"
        />
      </g>
      <rect x="168.7" y="111" class="st0" width="6.9" height="2.8" />
      <path
        class="st0"
        d="M105.9,112.3c0-1.2,0.2-2.2,0.5-3.2c0.3-1,0.9-1.9,1.5-2.6c0.7-0.7,1.5-1.3,2.5-1.7c1-0.4,2.1-0.6,3.4-0.6
		c2.1,0,3.8,0.5,5,1.6c1.3,1.1,2.1,2.7,2.4,4.7h-3.7l0-0.1c-0.2-0.9-0.3-1.5-1.2-2.3c-0.7-0.6-1.6-0.8-2.5-0.8c-1.1,0-2.2,0.5-3,1.6
		c-0.3,0.5-0.6,1-0.7,1.6c-0.2,0.6-0.2,1.2-0.2,1.8v0.2c0,0.6,0.1,1.2,0.2,1.8c0.2,0.6,0.4,1.1,0.7,1.6c0.8,1.1,1.9,1.6,3,1.6
		c0.9,0,1.8-0.2,2.5-0.8c0.9-0.8,1.1-1.4,1.2-2.3l0-0.1h3.7c-0.3,2-1.1,3.6-2.4,4.7c-1.3,1.1-2.9,1.6-5,1.6c-1.3,0-2.4-0.2-3.4-0.6
		c-1-0.4-1.8-1-2.5-1.7c-0.7-0.7-1.2-1.6-1.5-2.6c-0.3-1-0.5-2.1-0.5-3.2V112.3z"
      />
    </g>
    <path
      class="st0"
      d="M318.4,42.6c0.7-0.8,1.1-1.3,1.1-1.8c0-0.4-0.2-0.7-0.6-1.2l-4.8-5.7l-49.8,0l-0.8,0.5v11.4l0.6,0.7h31.4
	c-11.1,9.6-23.6,17.1-41.5,25l8.3,7.9c0.7,0.7,1,0.9,1.5,0.9c0.4,0,0.9-0.2,1.7-0.6c7.3-3.6,12.9-6.3,18.8-10l0,22.4h14.3l0-32.3
	C304.2,55.5,312.8,48.1,318.4,42.6z"
    />
    <path
      class="st0"
      d="M310.9,56l-0.8,0.1l-8.2,6.6l-0.1,0.7c5.4,4.2,11.7,9.9,16.1,14.9l0.8,0.2l8.6-7.5l0.1-0.8
	C323,65,317,60,310.9,56z"
    />
    <path
      class="st0"
      d="M284.4,12.9l-0.7,0.3l-5.6,8.8l0.1,0.7c6.5,2.4,14.4,5.8,20.2,9.1l0.8-0.1l5.6-9.7l-0.2-0.8
	C298.8,17.7,291.5,14.8,284.4,12.9z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
