import API_CODE from "@/assets/common/ApiCode";
import { PLAN_PERIODS, getPeriodCode } from "@/assets/plan/PeriodTypes";
import deepCopy from "@/lib/deepCopy";
import { classifyPeriodState } from "@/store/plan-list/helper";

// 媒体別の表示する期間の種類
const displayedTypes = {
  default: ["publication", "application", "target"],
  [API_CODE.media.wp]: ["publication"],
  [API_CODE.media.hanayume]: ["application", "target"],
};

/**
 * 掲載・申込・挙式・来館を媒体別にまとめた periods のデータを作成
 * @param {object} plan プランのデータ
 * @param {object} diffs 期限切れの通知日数
 */
const createPeriods = (plan, diffs) => {
  const periods = {};
  const periodTypes = Object.keys(PLAN_PERIODS);

  // ウエパの場合、掲載期間と選択した期間を表示
  const wpDateType = getPeriodCode(plan.date_type[API_CODE.media.wp]);
  const types = deepCopy(displayedTypes);
  types[API_CODE.media.wp].push(wpDateType);

  // 各期間の期限切れ・間近の判定
  // xxx_date[mediaId] -> periods[mediaId][xxx] に構成変更
  periodTypes.forEach((type) => {
    const dateType = `${type}_date`;
    const date = plan[dateType];

    // 共通・媒体別に表示内容を取得
    Object.keys(date).forEach((mediaId) => {
      // 表示する期間かどうか
      if (!(types[mediaId] || types.default).includes(type)) {
        return;
      }

      // 期間の設定が存在しない場合
      let target = date[mediaId];
      const isEmpty =
        target == undefined || (!target.start && !target.end && !target.text);
      if (isEmpty) return;

      // periods[mediaId][type] に表示内容をセット
      if (periods[mediaId] == undefined) periods[mediaId] = {};
      periods[mediaId][type] = target;
      // before:期間前・within:期間内・soon:期限切れ間近・over:期限切れ
      periods[mediaId][type].state = target.text
        ? null
        : classifyPeriodState(target.start, target.end, diffs[dateType]);
    });
  });

  // 共通・媒体個別がどちらも設定されている場合、存在しない媒体を空オブジェクトで返して媒体名だけ表示する
  const media = Object.keys(periods);
  const needFillInBlank = media.length >= 2 && media[0] == API_CODE.mediaCommon;
  if (needFillInBlank) {
    plan.media_ids.forEach((mediaId) => {
      if (periods[mediaId] == undefined) periods[mediaId] = {};
    });
  }

  // 表示する期間の種類を追加
  periods.types = types;

  return periods;
};

export default createPeriods;
