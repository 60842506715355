<template>
  <modal-header-window
    class="modalContact"
    icon="help_center"
    title="お問い合わせ・ご要望"
    :is-large-icon="true"
    :is-small-title="true"
    :is-disabled-close="false"
    :is-set-footer="true"
    @close="close"
  >
    <template #content>
      <div class="modalContactInr">
        <contact-detail />
        <div class="modalContactForm">
          <form-item title="種別" key-name="contact_type" :items="master['contact_type']" />
          <form-item
            title="問い合わせたい機能"
            key-name="service_name"
            :items="master['service_name']"
          />
          <form-text title="対象のID・タイトル" key-name="resource_identifier" />
          <form-media-select
            title="対象媒体"
            key-name="media_names"
            :items="master['media_names']"
          />
          <form-text title="内容" key-name="content" :is-textarea="true" :rows="4" />
          <customer-text title="ご担当者名" keyName="customer_name" />
          <customer-tel title="電話番号" keyName="customer_tel" />
          <customer-mail title="メールアドレス" keyName="customer_mail" />
          <customer-text
            title="返信方法に関するご要望"
            keyName="customer_time"
            placeholder="例）月・水曜日の15時～16時"
            :is-textarea="true"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <action-button
        text="送信する"
        :is-disabled="!isAllValid"
        :is-save="true"
        @clickHandler="send"
      />
    </template>
  </modal-header-window>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ActionButton from "@/components/atoms/common/ActionButton";
import ModalHeaderWindow from "@/components/molecules/common/ModalHeaderWindow";
import ContactDetail from "@/components/molecules/common/ContactDetail";
import FormItem from "@/components/organisms/contact/FormItem.vue";
import FormMediaSelect from "@/components/organisms/contact/FormMediaSelect.vue";
import FormText from "@/components/organisms/contact/FormText.vue";
import CustomerText from "@/components/organisms/contact/CustomerText";
import CustomerTel from "@/components/organisms/contact/CustomerTel";
import CustomerMail from "@/components/organisms/contact/CustomerMail";

export default {
  components: {
    ActionButton,
    ModalHeaderWindow,
    ContactDetail,
    FormItem,
    FormMediaSelect,
    FormText,
    CustomerText,
    CustomerTel,
    CustomerMail,
  },
  computed: {
    ...mapGetters({
      master: "utility/contact/master",
      validation: "utility/contact/validation",
    }),
    isAllValid() {
      if (this.validation.itemsHasError.length > 0) return false;
      return this.validation.completedRequiredItems.length == this.validation.requiredItems.length;
    },
  },
  methods: {
    ...mapActions({
      closeModalContact: "utility/contact/closeModalContact",
      sendData: "utility/contact/sendData",
    }),
    close() {
      this.closeModalContact();
    },
    send() {
      this.sendData();
    },
  },
};
</script>

<style scoped lang="scss">
.modalContact {
  ::v-deep .modalContentInr {
    width: $GRID * 60;
  }

  &Inr {
    align-items: start;
    display: flex;
  }

  &Form {
    flex: 1;
    margin-left: $GRID;
  }
}
</style>
