import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

import fairState from "./state/fair-benefit";
import hallState from "./state/hall-benefit";
import wpState from "./state/wp-benefit";

const rules = {
  namespaced: true,
  state: {
    [BENEFIT_GROUP_TYPE.fair]: fairState,
    [BENEFIT_GROUP_TYPE.wp]: wpState,
    [BENEFIT_GROUP_TYPE.hall]: hallState,
  },
  getters: {
    stateByGroupType: (state) => (groupType) => state[groupType],
    enabledByMedia: (_, getters) => (groupType) => {
      const { enabledByMedia } = getters.stateByGroupType(groupType);
      return enabledByMedia;
    },
    isByMedia: (_, getters) => (groupType) => {
      const enabledByMedia = getters.enabledByMedia(groupType);
      return Object.entries(enabledByMedia).reduce(
        (isByMedia, [key, enabled]) => {
          return enabled ? { ...isByMedia, [key]: false } : isByMedia;
        },
        {}
      );
    },
    isRequired:
      (s, getters, rs, rootGetters) =>
      (key, mediaId = API_CODE.mediaCommon) => {
        const groupType = rootGetters.groupType;
        const { isRequired } = getters.stateByGroupType(groupType);
        const requireList = isRequired[key];
        if (!requireList) {
          return false;
        }

        if (mediaId !== API_CODE.mediaCommon) {
          return requireList[mediaId];
        }
        const mediaIds = rootGetters.mediaIds;
        return Object.entries(requireList)
          .filter(([mediaId]) => mediaIds.includes(Number(mediaId)))
          .some(([_, required]) => required);
      },
    onlyMedia:
      (s, getters, rs, rootGetters) =>
      (key, groupType = null) => {
        const type = groupType || rootGetters.groupType;
        const { onlyMedia } = getters.stateByGroupType(type);
        const ids = onlyMedia[key] || [];

        if (ids.length > 0) {
          return rootGetters.filteredMediaIdsByCoremonet(ids, type);
        }
        return ids;
      },
    maxCharacters: (s, getters, rs, rootGetters) => (key, mediaId) => {
      const groupType = rootGetters.groupType;
      const { characters } = getters.stateByGroupType(groupType);
      const maxCharacters = characters[key];
      if (!maxCharacters) {
        return null;
      }

      if (mediaId === API_CODE.mediaCommon) {
        const onlyMedia = getters.onlyMedia(key);
        const onlyMediaCharacters = [...onlyMedia].map(
          (mediaId) => maxCharacters[mediaId]
        );
        return Math.min(...Object.values(onlyMediaCharacters));
      }
      return maxCharacters[mediaId];
    },
  },
};

export default rules;
