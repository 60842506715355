import deepCopy from "@/lib/deepCopy";

const mutations = {
  SET_MODAL_SHOW(state, isShow) {
    state.isShow = isShow;
  },
  UPDATE_DATA(state, payload) {
    const { keyName, value } = payload;
    state.data[keyName] = value;
  },
  CLEAR_DATA(state) {
    state.data = deepCopy(state.init);
  },
  /**
   * バリデーション
   */
  /**
   * 入力済み必須項目(state.completedRequiredItems)の更新
   * @param {*} state
   * @param {Object} payload { key: 要素名, isValid: 正常に入力されているか (true: keyを追加, false: keyを削除) }
   */
  COMPLETED_REQUIRED_ITEM(state, payload) {
    const { key, isValid } = payload;
    let index = state.validation.completedRequiredItems.indexOf(key);
    // 必須項目リストにkeyが存在しない場合
    if (state.validation.requiredItems.indexOf(key) < 0) {
      if (index < 0) {
        return;
      } else {
        state.validation.completedRequiredItems.splice(index, 1);
      }
      // 必須項目リストにkeyが存在する場合
    } else {
      if (isValid && index < 0) {
        state.validation.completedRequiredItems.push(key);
      } else if (!isValid && index >= 0) {
        state.validation.completedRequiredItems.splice(index, 1);
      }
    }
  },
  /**
   * 入力済み項目リスト(state.validation.isValidItems)の更新
   * @param {*} state
   * @param {Object} payload { key: 要素名, isValid: 正常に入力されているか (true: keyを追加, false: keyを削除) }
   */
  VALID_ITEM(state, payload) {
    const { key, isValid } = payload;
    let index = state.validation.validItems.indexOf(key);
    if (isValid && index < 0) {
      state.validation.validItems.push(key);
    } else if (!isValid && index >= 0) {
      state.validation.validItems.splice(index, 1);
    }
  },
  /**
   * エラーあり項目の更新
   * isRequired=false の項目は 空: isValid=true 文字制限超など: isValid=false が返る
   * InputTelNumber.vue,InputNumber.vueなど エラーになるものはあらかじめ入力できないコンポーネントもある
   * @param {*} state
   * @param {*} payload { Key: 要素名, isValid: 正常に入力されているか }
   */
  ITEMS_HAS_ERROR(state, payload) {
    const { key, isValid } = payload;
    const index = state.validation.itemsHasError.indexOf(key);
    if (isValid && index !== -1) {
      state.validation.itemsHasError.splice(index, 1);
    } else if (!isValid && index === -1) {
      state.validation.itemsHasError.push(key);
    }
  },
};
export default mutations;
