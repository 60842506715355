<template>
  <div class="activeUserNav-wrap">
    <nav class="activeUserNav" :class="getClass" @click="toggleActiveUserNav">
      <i class="material-icons">{{ activeUser.icon }}</i>
      <span class="fontNum">{{ activeUser.list.length }}</span>
    </nav>
    <ul class="dropDownMenu" v-show="display">
      <li>{{ menuMessage }}</li>
      <li v-for="user in activeUser.list" :key="user.id">
        <i class="material-icons">person</i>
        <span v-if="user.display_name || user.name" class="ellipsis">
          {{ user.display_name || user.name }}様
        </span>
        <span v-else class="ellipsis">マスターアカウント様</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      display: false,
    };
  },
  computed: {
    ...mapGetters("utility/activeUsers", ["users"]),
    activeUser() {
      return {
        icon: this.users.length ? "group" : "person",
        list: this.users,
      };
    },
    getClass() {
      return this.users.length ? "isWarning" : null;
    },
    menuMessage() {
      return this.users.length ? "使用中のユーザー" : "同時ログイン中のユーザーはいません";
    },
  },
  mounted() {
    this.getActiveHallUsers();
    window.addEventListener("click", this.close);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.close);
  },
  methods: {
    ...mapActions("utility/activeUsers", ["getActiveHallUsers"]),
    toggleActiveUserNav() {
      this.display = !this.display;
    },
    close(event) {
      if (this.display && !this.$el.contains(event.target)) {
        this.display = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.activeUserNav {
  background-color: $BG_COLOR_NAV;
  border-radius: $BOX_RADIUS_FOR_LARGE_BOX;
  box-shadow: $DEPTH_LV_0;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: $GRID * 2.25;
  min-width: $GRID * 3.5;
  padding: $SUB_GRID;

  &.isWarning {
    background-color: rgba($COLOR_WARNING, 0.6);
    color: $COLOR_TEXT;
  }

  span.fontNum {
    margin: 0 auto;
  }

  &-wrap {
    position: relative;
  }
}

.dropDownMenu {
  background-clip: padding-box;
  background-color: #f0efef;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: $BOX_RADIUS_FOR_LARGE_BOX;
  box-shadow: $DEPTH_LV_0;
  color: #212529;
  font-size: 12px;
  list-style: none;
  margin: 0;
  padding: $GRID * 0.8;
  position: absolute;
  right: 0;
  text-align: left;
  top: $GRID * 2.5 + $SUB_GRID;
  width: $GRID * 12;
  max-height: $GRID * 29;
  overflow-y: auto;
  z-index: $LAYER_FLOAT_WINDOW;

  li:not(:nth-child(1)) {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: $BOX_RADIUS_FOR_LARGE_BOX;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: $GRID * 0.5;
    padding: $GRID * 0.25;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
