import API_CODE from "@/assets/common/ApiCode";
import { selectHall, getSelectedHallId } from "@/assets/common/PageConfig";

import deepCopy from "@/lib/deepCopy";

import { indexUsers, indexHistories } from "@/repositories/switch-api";

import { getLogPath } from "@/store/common/helper";
import * as commonTypes from "@/store/common/mutationTypes";

const operationLogs = {
  namespaced: true,
  state: {
    users: [],
    logs: [],
    master: {},
    // 検索フィルター
    filter: {
      title: null,
      periodFrom: null,
      periodTo: null,
      userId: null,
      type: null,
      hallId: null,
    },
  },
  getters: {
    master: ({ master }) => {
      return master;
    },
    users: ({ users }) => {
      return users;
    },
    logs: ({ logs }) => {
      return logs;
    },
    filter: ({ filter }) => {
      return deepCopy(filter);
    },
    filterParams: (state) => {
      const filter = state.filter;
      const filterToParam = {
        title: "title",
        periodFrom: "date_from",
        periodTo: "date_to",
        userId: "user_id",
        type: "operationable_type",
      };
      const filterParams = Object.entries(filterToParam).reduce((filterParams, [key, paramKey]) => {
        if (filter[key]) filterParams[paramKey] = filter[key];
        return filterParams;
      }, {});
      return {
        ...filterParams,
        hall_id: filter.hallId || getSelectedHallId(),
      };
    },
    isFilterChanged: (state) => (filter) => {
      return JSON.stringify(filter) !== JSON.stringify(state.filter);
    },
  },
  mutations: {
    SET_USERS(state, { users }) {
      state.users = users;
    },
    SET_LOGS(state, { logs }) {
      state.logs = logs.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    },
    SET_MASTER(state, { master }) {
      state.master = master;
    },
    SET_FILTER(state, { newFilter }) {
      Object.entries(newFilter).forEach(([key, value]) => {
        state.filter[key] = value;
      });
    },
    RESET(state) {
      state.users = [];
      state.logs = [];
      state.master = {};
      state.filter = {
        title: null,
        periodFrom: null,
        periodTo: null,
        userId: null,
        type: null,
        hallId: null,
      };
    },
  },
  actions: {
    /**
     * 操作ログデータを取得
     */
    async init({ getters, commit, dispatch }) {
      const responseUsers = await indexUsers();
      const params = getters.filterParams;
      const responseLogs = await indexHistories(params);
      const [success, users, logs, master] = await Promise.all([responseUsers, responseLogs]).then(
        (responses) => {
          const success = responses.every(
            (response) => response && response.status === API_CODE.response.success
          );
          const [userResponse, logResponse] = responses;
          return [
            success,
            userResponse && userResponse.data && userResponse.data.data,
            logResponse && logResponse.data && logResponse.data.data,
            logResponse && logResponse.data && logResponse.data.master,
          ];
        }
      );

      if (success) {
        commit("SET_USERS", { users });
        commit("SET_LOGS", { logs });
        commit("SET_MASTER", { master });
        return;
      }

      commit("common/" + commonTypes.ERROR, null, { root: true });
      const e = {
        isShow: true,
        text: "操作ログの取得が失敗しました 時間をおいて再度お試しください",
      };
      dispatch("modal/showErrorModal", e, { root: true });
      return;
    },
    /**
     * リセット
     */
    reset({ commit }) {
      commit("RESET");
    },
    /**
     * フィルター設定を更新
     * @param {Object} newFilter
     */
    async updateFilter({ dispatch, commit }, newFilter) {
      commit("modal/setUpdateModal", { isShow: true }, { root: true });
      commit("SET_FILTER", { newFilter });
      await dispatch("init");
      commit("modal/setUpdateModal", { isShow: false }, { root: true });
    },
    /**
     * ページ移動
     * @param {Object} log
     */
    movePage({ rootGetters }, log) {
      const isViewableOwnHall = rootGetters["common/isViewableOwnHall"];
      const isViewableAnyHall = rootGetters["common/isViewableAnyHall"];
      const hallId = isViewableAnyHall || isViewableOwnHall ? log.hall_id : null;
      if (hallId) selectHall(hallId);

      const logType = getLogType(log);
      const needShowDetailType = ["Plan", "Fair", "Image", "Benefit"];

      if (needShowDetailType.includes(logType) && log.operationable_id) {
        const path = getLogPath(logType) + log.operationable_id;
        window.open(path, "_blank").focus();
        return;
      }

      const listPage = {
        Plan: "PlanList",
        Fair: "FairList",
        Image: "ImageList",
        HallBenefit: "BenefitList",
        BenefitPublish: "BenefitList",
      };
      const targetType = listPage[logType] || logType;
      const path = getLogPath(targetType);
      window.open(path, "_blank").focus();
    },
  },
};

const userName = (user) => {
  return user.is_master ? "マスターアカウント" : user.display_name || user.name || user.id;
};

const getLogType = (log) => {
  const type = log.operation_detail || log.operationable_type;
  const typeAlias = {
    FairOrder: "FairList",
    FairPublish: "Fair",
    FairUnpublish: "Fair",
    PlanPublish: "Plan",
    PlanUnpublish: "Plan",
    Schedule: "FairSchedule",
    Stock: "FairStock",
    Daily: "FairDaily",
    Recommended: "FairRepresent",
    OptionalRecommended: "FairRecommended",
    BenefitLink: "Benefit",
    BenefitReplace: "Benefit",
    BenefitUnlink: "Benefit",
    HallBenefitMedia: "HallBenefit",
    ImageTrim: "ImageList",
    ImageReplace: "ImageList",
    NewInfo: "NewInfo",
    HallInfoNewInfo: "HallInfoNewInfo",
    HallInfoMedia: "HallInfoNewInfo",
    NewInfoLink: "HallInfoNewInfo",
    MediaAccount: "MediaAccountConnect",
    MediaConnect: "MediaAccountConnect",
    MediaStartUsage: "MediaAccountConnect",
    MediaStopUsage: "MediaAccountConnect",
    MediaSyncStart: "MediaAccountConnect",
    MediaSyncStop: "MediaAccountConnect",
    Tag: "ImageTag",
    HallSetting: "HallSetting",
  };
  return typeAlias[type] || type;
};
export { operationLogs as default, userName, getLogType };
