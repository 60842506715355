/**
 * httpClientCommon
 * API実行に関する共通処理
 ***********************************/
import axios from "axios";
import API_CODE from "@/assets/common/ApiCode.js";
import {
  deselectHall,
  IMPORT_PAGE_CONFIG,
} from "@/assets/common/PageConfig.js";
import { getServerConfig } from "@/assets/common/ServerConfig.js";
import { getCookieAccount } from "@/lib/cookie";
import { movePage } from "@/lib/router";
import modal from "@/lib/modal";

import ModalError from "@/components/modals/ModalError.vue";

// ベースURLに式場IDを含めないAPI
const COMMON_API = [
  "/authorize",
  "/token/delete",
  "/mail-authenticate",
  "/ngWords",
  "/news",
  "/news/published",
  "/potential-halls",
  "/user/authorized",
  "/user/permissions",
];
// 認証エラー対象外API
const NOT_AUTH_API = [
  "/authorize",
  "/token/delete",
  "/mail-authenticate",
  "/media/accounts/connect",
];
// 管理ユーザと一般ユーザで式場IDの有無が異なるAPI
const SWITCH_API = [
  "users",
  "assignable-roles",
  "jobs",
  "logs",
  "histories",
];

// サーバー別APIベースURL
const API_URL_DEFAULT = getServerConfig().apiBaseUrl;
// アカウント情報
let account;
// テスト式場・管理アカウントのIDでログインしているか
let isTestId;

/**
 * axiosインスタンス作成
 */
export const create = () => {
  account = getAccount();
  return axios.create({
    baseURL: getBaseUrl(),
    headers: getHeaders(),
    withCredentials: true,
  });
};
/**
 * アカウント情報を取得
 */
const getAccount = () => {
  let a = getCookieAccount();
  const isUpdated = JSON.stringify(account) !== JSON.stringify(a);
  if (isUpdated) isTestId = a && a.userable_id && a.userable_id >= 900000;
  return a;
};
/**
 * APIベースURLを取得
 * @param {Number} id {ベースURL}/{id}
 * @param {Boolean} isForced false:cookieからアカウントのIDを取得
 */
export const getBaseUrl = (id, isForced = false) => {
  let i;
  if (isForced) {
    i = id;
  } else {
    i = id || account.hall_id || account.userable_id;
  }
  let url = API_URL_DEFAULT;
  if (i) url += "/" + i;
  return url;
};
/**
 * ヘッダーを取得
 * @param {String} token トークン
 */
export const getHeaders = (token) => {
  const t = token || account.token;
  let headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  if (t) headers.Authorization = `Bearer ${t}`;
  return headers;
};
/**
 * 設定を更新するか
 * @param {Object} request リクエスト
 */
export const needsUpdateConfig = (request) => {
  const { url } = request;
  let c = false;
  let r = false;
  // ログイン/認証系API以外かつアカウントが更新された
  if (!isLoginApi(url) && !isAuthApi(url) && updateAccount()) {
    c = true;
    r = true;
  }
  // 式場IDに依存しないAPIはbaseURLをデフォルトに変更
  if (isCommonApi(url) || isSwitchApi(url)) r = true;
  if (c || r) console.log("update api info");
  return { client: c, request: r };
};
/**
 * アカウント情報を更新
 */
const updateAccount = () => {
  const newAccount = getAccount();
  // トークンが未保存
  if (!newAccount.token) {
    // ログインページへ移動
    // トークン以外が保存されている場合はエラーメッセージを表示(?unauthorized)
    const path = Object.keys(account).some(
      (k) => k != "hall_id" && !!account[k]
    )
      ? "/login?unauthorized"
      : "/login";
    if (movePage(path, "/login")) return;
  }
  // アカウント情報が更新された場合
  const isUpdated = JSON.stringify(account) !== JSON.stringify(newAccount);
  if (isUpdated) {
    account = newAccount;
    return true;
  }
  return false;
};
/**
 * グローバル設定を更新
 * @param {Object} client インスタンス
 */
export const updateGlobal = (client) => {
  if (account.userable_id) client.defaults.baseURL = getBaseUrl();
  if (account.token) client.defaults.headers = getHeaders();
  return client;
};
/**
 * リクエスト設定を更新
 * @param {Object} request リクエスト
 */
export const updateRequest = (request) => {
  if (account.userable_id) {
    const { url } = request;
    const needUpdate = isCommonApi(url) || isSwitchApi(url);
    const baseUrl = needUpdate ? getBaseUrl(null, true) : getBaseUrl();
    request.baseURL = baseUrl;
  }
  if (account.token) request.headers = {...request.headers, ...getHeaders()};
  return request;
};
/**
 * レスポンスステータスを解析
 * @param {Object} response レスポンス
 */
export const checkResponseStatus = (response) => {
  if (!response) return;
  if (response.response) response = response.response; // エラーなど
  switch (response.status) {
    // 401 // 認証エラー
    case API_CODE.response.unauthorized:
      let url = response.config.url;
      const baseUrl = response.config.baseURL;
      const hasBaseUrl = url.indexOf(baseUrl) !== -1;
      url = hasBaseUrl ? url.slice(baseUrl.length) : url;
      if (!isNotAuthApi(url)) {
        if (movePage("/login?unauthorized", "/login")) return;
      }
      break;
    // 403 // アクセス制限
    case API_CODE.response.accessDenied:
      if (isImportPage(location.pathname)) deselectHall();
      if (movePage("/?permission", "/")) return;
      break;
    // 504 // タイムアウト
    case API_CODE.response.gatewayTimeout:
      // GET時のみエラー表示（POST、PUT等は更新中表示の解除が必要なので各処理のエラーハンドリングに任せる）
      if (response.config.method != "get") break;
      const elementId = "modalErrorGatewayTimeout";
      // エラー表示済みなら新たに表示はしない
      if (document.getElementById(elementId)) break;
      const props = { text: "データの取得に失敗しました　時間をおいて再度お試しください" };
      modal(ModalError, props, null, elementId);
      throw response;
    default:
      break;
  }
};
/**
 * ログインAPIであるか
 * @param {String} url 対象のURL
 */
const isLoginApi = (url) => {
  return url == "/authorize" || url === "/authorize/";
};
/**
 * 認証系のAPIか
 * @param {String} url
 * @returns {boolean}
 */
const isAuthApi = (url) => {
  return url === "/mail-authenticate" || url === "/mail-authenticate/";
};
/**
 * ベースURLに式場IDを含めないAPIであるか
 * @param {String} url 対象のURL
 */
const isCommonApi = (url) => {
  return COMMON_API.some((v) => v === url || url === v + "/");
};

/**
 * 認証エラー対象外APIであるか
 * @param {String} url 対象のURL
 */
const isNotAuthApi = (url) => {
  return NOT_AUTH_API.some((v) => url.search(v) === 0); // 正規表現
};

/**
 * 管理者権限の場合、ベースURLに式場IDを含めないAPIであるか
 */
const isSwitchApi = (url) => {
  const { userable_type, hall_id } = account;

  // 管理者権限で任意の式場の画面を開いている場合もURLに式場IDを含める
  if (userable_type === "Hall" || hall_id != null) {
    return false;
  }
  return SWITCH_API.some((apiUrl) => url.includes(apiUrl));
};

/**
 * インポートツールであるか
 * @param {String} path 対象のパス
 */
const isImportPage = (path) => {
  return (
    IMPORT_PAGE_CONFIG.findIndex(
      (v) => path === v.dir || path === "/" + v.dir
    ) !== -1
  );
};
/**
 * テスト式場・管理アカウントのIDでログインしているか
 */
export const isTest = () => {
  return isTestId;
};
