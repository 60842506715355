<template>
  <span class="material-icons rotated">autorenew</span>
</template>

<style scoped lang="scss">
.rotated {
  animation: rotated 8s infinite linear;
}
@keyframes rotated {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
