const state = {
  isLoadEdit: false,
  isUsed: null,
  anchor: {
    hash: null,
    id: null,
  },
  master: {},
  benefit: [],
  showPublishModal: null, // nullもしくは開きたい媒体のIDが代入される(通常は0が入る)
  setting: {},
};

export default state;
