<template>
  <benefit-form
    :title="title"
    :need-by-media="true"
    :note="onlyMediaName(keyName)"
    :option-level="optionLevel([keyName])"
  >
    <by-media-input
      :key-name="keyName"
      :is-by-media="isByMedium(keyName)"
      :title="title"
      @toggleByMedia="changeIsByMedia({ [keyName]: $event })"
    >
      <input-item-period-date
        v-if="!isByMedia"
        :disabled-dates="disabledDates(mediaCommon)"
        :key-name="keyName"
        :media-id="mediaCommon"
        :required="isRequired(keyName)"
        :value="periodFrom(mediaCommon)"
        @input="updatePeriod($event, mediaCommon)"
        @check="validateFrom(mediaCommon, $event)"
      />
      <template v-if="isByMedia">
        <input-item-period-date
          v-for="mediaId in selectedOnlyMedia(keyName)"
          :key="mediaId"
          :disabled-dates="disabledDates(mediaId)"
          :key-name="keyName"
          :media-id="mediaId"
          :required="isRequired(keyName)"
          :value="periodFrom(mediaId)"
          @input="updatePeriod($event, mediaId)"
          @check="validateFrom(mediaId, $event)"
        />
      </template>
    </by-media-input>
  </benefit-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isBefore, parseISO } from "date-fns";

import API_CODE from "@/assets/common/ApiCode";

import { disabledDateFrom } from "@/lib/date";

import * as types from "@/store/benefit/mutationTypes";

import InputItemPeriodDate from "@/components/benefit/inputs/InputItemPeriodDate.vue";
import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import ByMediaInput from "@/components/benefit/presentation/ByMediaInput.vue";

export default {
  components: {
    InputItemPeriodDate,
    BenefitForm,
    ByMediaInput,
  },
  data() {
    return {
      title: "適用 開始日",
      keyName: "valid_from",
    };
  },
  computed: {
    ...mapGetters({
      periodFrom: "periodFrom",
      periodUntil: "periodUntil",
      isByMedium: "isByMedium",
      groupType: "groupType",
      showInput: "showInput",
      optionLevel: "optionLevel",
      onlyMediaName: "onlyMediaName",
      selectedOnlyMedia: "selectedOnlyMedia",
      isRequired: "rules/isRequired",
      onlyMedia: "rules/onlyMedia",
    }),
    isByMedia() {
      return this.isByMedium(this.keyName);
    },
    mediaCommon() {
      return API_CODE.mediaCommon;
    },
  },
  beforeUnmount() {
    const mediaIds = [...this.onlyMedia(this.keyName), this.mediaCommon];
    mediaIds.forEach((mediaId) => {
      this.validateFrom(mediaId, true);
    });
  },
  methods: {
    ...mapActions({ changeIsByMedia: "changeIsByMedia" }),
    ...mapMutations({
      updateFrom: `${types.PERIOD_FROM}`,
      validatePeriodFrom: `validation/${types.PERIOD_FROM}`,
      validatePeriodUntil: `validation/${types.PERIOD_UNTIL}`,
    }),
    updatePeriod(from, mediaId) {
      this.updateFrom({ mediaId, from });
    },
    validateFrom(mediaId, isValid) {
      this.validatePeriodFrom({ mediaId, isValid });

      const from = parseISO(this.periodFrom(mediaId));
      const until = parseISO(this.periodUntil(mediaId));
      const isValidUntil = !isBefore(until, from);
      this.validatePeriodUntil({ mediaId, isValid: isValidUntil });
    },
    disabledDates(mediaId) {
      const validUntil = this.periodUntil(mediaId);
      return disabledDateFrom(validUntil);
    },
  },
};
</script>
