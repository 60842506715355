export default {
  // 基本設定
  link_type: null,
  hp_should_include_daily_fair_to_fair_master: 2,

  // フェア
  hp_stock_unit_type: 1,
  hp_benefit_group_type: 3,

  // プラン
  hp_plan_charge_type: [0, 1, 2],
  hp_plan_benefit_group_type: 2,
  hp_plan_content_type: [1, 3, 4, 7],

  // トークン
  token: [],
  fetchingToken: true,
  hasErrorToken: false,

  // コレモネっとの設定を取得中かどうか
  fetchingSetting: true,
  // コレモネっと設定の取得に失敗したかどうか
  failedFetchSetting: false,

  // master
  master: {},
};
