import API_CODE from "@/assets/common/ApiCode";

const requiredItem = (required) => ({
  [API_CODE.media.zexy]: required,
  [API_CODE.media.mw]: required,
  [API_CODE.media.mynavi]: required,
});

const hallState = {
  characters: {
    description: {
      [API_CODE.media.mw]: 300,
      [API_CODE.media.mynavi]: 500,
    },
    notes: {
      [API_CODE.media.zexy]: 100,
      [API_CODE.media.mw]: 300,
      [API_CODE.media.mynavi]: 250,
    },
    title: {
      [API_CODE.media.zexy]: 80,
      [API_CODE.media.mw]: 30,
      [API_CODE.media.mynavi]: 100,
    },
    price_notes: {
      [API_CODE.media.mw]: 50,
    },
    valid_period_text: {
      [API_CODE.media.zexy]: 30,
    },
    link_title: {
      [API_CODE.media.mynavi]: 20,
    },
    link_url: {
      [API_CODE.media.mynavi]: 2083,
    },
  },
  isRequired: {
    media_ids: requiredItem(true),
    description: {
      [API_CODE.media.mw]: true,
      [API_CODE.media.mynavi]: true,
    },
    notes: requiredItem(false),
    is_media_only: requiredItem(false),
    title: requiredItem(true),
    type: requiredItem(true),
    detail_type: requiredItem(true),
    is_highlighted: requiredItem(false),
    price_notes: requiredItem(false),
    valid_from: {
      [API_CODE.media.zexy]: true,
      [API_CODE.media.mw]: false,
      [API_CODE.media.mynavi]: false,
    },
    valid_period_text: {
      [API_CODE.media.zexy]: true,
      [API_CODE.media.mw]: false,
      [API_CODE.media.mynavi]: false,
    },
    valid_until: {
      [API_CODE.media.zexy]: true,
      [API_CODE.media.mw]: false,
      [API_CODE.media.mynavi]: false,
    },
    publication_from: requiredItem(true),
    publication_until: {
      [API_CODE.media.zexy]: true,
      [API_CODE.media.mw]: false,
      [API_CODE.media.mynavi]: false,
    },
    discount_type: requiredItem(true),
    application_from: requiredItem(false),
    application_until: requiredItem(false),
    link_title: requiredItem(false),
    link_url: requiredItem(false),
  },
  onlyMedia: {
    media_ids: [Object.values(API_CODE.media)],
    description: [API_CODE.media.mw, API_CODE.media.mynavi],
    image_id: [API_CODE.media.mw, API_CODE.media.mynavi],
    notes: [API_CODE.media.zexy, API_CODE.media.mw, API_CODE.media.mynavi],
    is_limited_to_fair: [],
    is_limited_to_webtsuku: [],
    is_media_only: [API_CODE.media.mynavi],
    recommended: [],
    title: [API_CODE.media.zexy, API_CODE.media.mw, API_CODE.media.mynavi],
    type: [API_CODE.media.zexy, API_CODE.media.mw, API_CODE.media.mynavi],
    detail_type: [API_CODE.media.mw],
    is_highlighted: [API_CODE.media.mw],
    price_notes: [API_CODE.media.mw],
    valid_from: [API_CODE.media.zexy, API_CODE.media.mw],
    valid_period_text: [API_CODE.media.zexy],
    valid_until: [API_CODE.media.zexy, API_CODE.media.mw],
    publication_from: [
      API_CODE.media.zexy,
      API_CODE.media.mw,
      API_CODE.media.mynavi,
    ],
    publication_until: [
      API_CODE.media.zexy,
      API_CODE.media.mw,
      API_CODE.media.mynavi,
    ],
    discount_type: [API_CODE.media.mw],
    application_from: [API_CODE.media.mw],
    application_until: [API_CODE.media.mw],
    link_title: [API_CODE.media.mynavi],
    link_url: [API_CODE.media.mynavi],
  },
  enabledByMedia: {
    media_ids: false,
    description: true,
    image_id: true,
    notes: true,
    is_media_only: true,
    title: true,
    type: false,
    detail_type: true,
    is_highlighted: true,
    price_notes: true,
    valid_from: true,
    valid_period_text: true,
    valid_until: true,
    publication_from: true,
    publication_until: true,
    discount_type: true,
    application_from: true,
    application_until: true,
    link_title: true,
    link_url: true,
  },
};

export default hallState;
