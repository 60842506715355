/**
 * Cookieに関する処理
 */

import { IS_DEVELOP_SERVER } from "@/assets/common/ServerConfig";
import typeToString from "@/lib/typeToString";

// cookie に保存する key 名
const accountKey = {
  userable_id: "_ui",
  userable_type: "_ut",
  name: "_nm",
  token: "_tk",
  hall_id: "_hi", // 企業アカウントの編集中式場
};

/**
 * Cookieの値を取得
 * @param {String} name 取得する項目名
 */
export const getCookie = (name) => {
  let value;
  if (document.cookie) {
    const cookies = document.cookie.split(";");
    cookies.forEach((v) => {
      if (value) return;
      const cookie = v.split("=");
      if (cookie[0].trim() === name) {
        value = cookie[1];
      }
    });
  }
  return value;
};

/**
 * Cookieの値を削除
 * @param {String} name 削除する項目名
 */
export const removeCookie = (name) => {
  if (Object.keys(accountKey).some((k) => k == name)) name = accountKey[name];
  saveCookie(name, "", -1);
};

/**
 * Cookieの値を保存
 * @param {String} name 保存する項目名
 * @param {String, Number} value 保存する値
 * @param {Number} valid 有効期限(時間) デフォルト:Session
 */
export const saveCookie = (name, value, valid) => {
  const date = new Date();
  let validDate;
  if (valid) {
    validDate = new Date(date.setHours(date.getHours() + valid)).toUTCString();
  }
  let options = {
    path: "/", // アクセスを許可するページ
    maxAge: valid ? 3600 * valid : null, // 有効期限(秒) // 優先
    expires: valid ? validDate : null, // 有効期限(日) // IE
    sameSite: "Strict", // Strict:他ドメインへ送信しない, Lax:送信先に遷移 and GETの時に送信する, None:送信する
    secure: IS_DEVELOP_SERVER ? null : "secure", // https通信時のみ送信する
    // domain: "", // アクセスを許可するドメイン (サブドメイン含む)
    // httpOnly: "HttpOnly", // JavaScriptから参照不可
  };
  let cookie = name + "=" + value + ";";
  cookie += Object.keys(options)
    .map((k) => {
      if (options[k]) return k + "=" + options[k];
    })
    .join(";");
  document.cookie = cookie;
};

/**
 * ログイン中アカウント情報をCookieから取得
 */
export const getCookieAccount = () => {
  let account = {};
  Object.keys(accountKey).forEach((k) => {
    let value = getCookie(accountKey[k]);
    if (value) {
      if (k != "token") {
        value = Buffer.from(getCookie(accountKey[k]), "base64").toString();
        if (k === "userable_id" || k === "hall_id") value = parseFloat(value);
      }
      account[k] = value;
    } else {
      account[k] = null;
    }
  });
  return account;
};

/**
 * ログイン中アカウント情報をCookieから削除
 * @param {Boolean} isSaveToken tokenは削除しない
 */
export const removeCookieAccount = (isSaveToken = false) => {
  Object.keys(accountKey).forEach((k) => {
    if (isSaveToken && k == "token") return;
    if (Object.keys(accountKey).indexOf(k) !== -1) {
      removeCookie(accountKey[k]);
    } else {
      removeCookie(k);
    }
  });
};

/**
 * ログイン中アカウント情報をCookieに保存
 * @param {Object} account { name: value } name: 保存するCookie名, value: 保存するCookieの値
 */
export const saveCookieAccount = (account) => {
  Object.keys(account).forEach((k) => {
    if (Object.keys(accountKey).indexOf(k) !== -1) {
      const key = accountKey[k];
      const value =
        k == "token"
          ? account[k]
          : Buffer.from(String(account[k])).toString("base64");
      saveCookie(key, value);
    } else {
      saveCookie(k, account[k]);
    }
  });
};
