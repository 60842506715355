export default {
  /**
   * APIで取得したデータを一括で格納する
   */
  setState: (state, { data, master }) => {
    // 基本設定
    state.link_type = data.link_type;
    state.hp_should_include_daily_fair_to_fair_master =
      data.hp_should_include_daily_fair_to_fair_master;

    // フェア
    state.hp_stock_unit_type = data.hp_stock_unit_type;
    state.hp_benefit_group_type = data.hp_benefit_group_type;

    // プラン
    state.hp_plan_charge_type = data.hp_plan_charge_type;
    state.hp_plan_benefit_group_type = data.hp_plan_benefit_group_type;
    state.hp_plan_content_type = data.hp_plan_content_type;

    state.master = master;
  },

  /**
   * 基本設定
   */
  includeDailyFair: (state, includeDailyFair) => {
    state.hp_should_include_daily_fair_to_fair_master = includeDailyFair;
  },

  /**
   * フェア
   */
  stockUnitType: (state, stockUnitType) => {
    state.hp_stock_unit_type = stockUnitType;
  },
  benefitGroupType: (state, benefitGroupType) => {
    state.hp_benefit_group_type = benefitGroupType;
  },

  /**
   * プラン
   */
  planChargeType: (state, planChargeType) => {
    state.hp_plan_charge_type = planChargeType;
  },
  planBenefitGroupType: (state, planBenefitGroupType) => {
    state.hp_plan_benefit_group_type = planBenefitGroupType;
  },
  planContentType: (state, planContentType) => {
    state.hp_plan_content_type = planContentType;
  },

  /**
   * トークン
   */
  token: (state, token) => {
    state.token = token;
  },
  deleteToken: (state, tokenId) => {
    state.token = state.token.filter((token) => token.id !== tokenId);
  },
  pushToken: (state, token) => {
    state.token.push(token);
  },
  fetchingToken: (state, fetchingToken) => {
    state.fetchingToken = fetchingToken;
  },
  hasErrorToken: (state, hasErrorToken) => {
    state.hasErrorToken = hasErrorToken;
  },

  /**
   * その他
   */
  fetchingSetting: (state, fetchingSetting) => {
    state.fetchingSetting = fetchingSetting;
  },
  failedFetchSetting: (state, failedFetchSetting) => {
    state.failedFetchSetting = failedFetchSetting;
  },
};
