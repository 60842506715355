<template>
  <dl class="fairTimetable" :class="{ r: isBgReverse }" v-if="parts">
    <dt class="fairTimetable_part">{{ parts }}部制</dt>
    <dd class="fairTimetable_time">/ {{ timetableText }}</dd>
  </dl>
</template>

<script>
export default {
  props: {
    parts: Number,
    timetable: Array,
    isBgReverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    timetableText: function() {
      return this.timetable.join(", ");
    },
  },
};
</script>

<style scoped lang="scss">
.fairTimetable {
  align-items: center;
  background: $BG_COLOR_SUB;
  border-radius: $BOX_RADIUS;
  color: $COLOR_SUB_TEXT;
  display: inline-flex;
  font-size: 11px;
  padding: 0 12px 0 8px;
  &_part {
    font-weight: bold;
    margin-right: 0.3em;
  }
  &.r {
    background: #fff;
  }
}
</style>
