import { isBefore, isEqual, parseISO, startOfToday, addDays } from "date-fns";

import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

// 終了または終了間近の期間があれば、そのステータスを取得する
const untilList = (until, period) => {
  const today = startOfToday();
  return Object.values(until).reduce((untilList, until) => {
    if (!until) return untilList;

    const untilDate = parseISO(until);
    const overed = isBefore(untilDate, today);
    if (overed) return [...untilList, "over"];

    const soonDate = addDays(today, period);
    const isSoon = isBefore(untilDate, soonDate) || isEqual(untilDate, soonDate);
    if (isSoon) return [...untilList, "soon"];

    return untilList;
  }, []);
};

const filter = {
  namespaced: true,
  state: {
    queries: {
      keyword: {
        query: null,
        method: ({ id, title, description, notes }, query) => {
          if (!query) return true;

          const titleList = Object.values(title);
          const descriptionList = Object.values(description);
          const notesList = Object.values(notes);
          const values = [...titleList, ...descriptionList, ...notesList].filter(
            (value) => value !== null
          );
          const distinctValues = [...new Set(values)];
          return [String(id), ...distinctValues].some((value) => value.includes(query));
        },
      },
      period: {
        query: null,
        keys: {
          soon: "終了間近",
          over: "終了",
          all: "終了または終了間近",
        },
        method: (
          { group_type, license_status, valid_until, application_until, publication_until },
          query,
          getters
        ) => {
          if (!query) return true;

          // フェア特典
          if (group_type !== BENEFIT_GROUP_TYPE.hall) {
            const status = Math.max(...Object.values(license_status));
            if (query === "soon") return status === 1;
            if (query === "over") return status === 2;
            if (query === "all") return status > 0;
          }

          // 式場特典
          const daysToValidExpired = getters.daysToValidExpired;
          const validUntilList = untilList(valid_until, daysToValidExpired);
          const daysToApplicationExpired = getters.daysToApplicationExpired;
          const applicationUntilList = untilList(application_until, daysToApplicationExpired);
          const daysToPublicationExpired = getters.daysToPublicationExpired;
          const publicationUntilList = untilList(publication_until, daysToPublicationExpired);
          const statusList = [...validUntilList, ...applicationUntilList, ...publicationUntilList];
          return query === "all" ? statusList.length > 0 : statusList.includes(query);
        },
      },
      inUse: {
        query: false,
        method: (
          { group_type, used_count, published_at, scheduled_at, is_for_hall, status },
          query
        ) => {
          if (!query) return true;
          if (group_type === BENEFIT_GROUP_TYPE.hall) {
            return published_at.length > 0 || scheduled_at.length > 0;
          }
          if (used_count > 0) return true;
          // 式場特典（ウエパ）であれば掲載中のものを表示する
          if (is_for_hall) return status === API_CODE.status.public;
          return false;
        },
      },
      groupType: {
        query: [],
        keys: {
          fair: {
            text: "フェア特典",
            value: 1,
          },
          hall: {
            text: "式場特典",
            value: 2,
          },
        },
        method: ({ is_for_fair, is_for_hall }, query) => {
          if (query.length < 1) return true;

          const querySum = query.reduce((sum, value) => sum + value, 0);
          if (querySum === 1) return is_for_fair;
          if (querySum === 2) return is_for_hall;
          if (querySum === 3) return true;
        },
      },
      benefitType: {
        query: [],
        keys: {
          visit: {
            text: "来館特典",
            value: 1,
          },
          contract: {
            text: "成約特典",
            value: 2,
          },
        },
        method: ({ type }, query) => {
          if (query.length < 1) return true;

          const querySum = query.reduce((sum, value) => sum + value, 0);
          const commonType = type[API_CODE.mediaCommon];
          return querySum < 3 ? commonType === querySum : true;
        },
      },
      media: {
        query: [],
        method: ({ media_ids }, query) => {
          if (query.length < 1) return true;
          return query.every((id) => media_ids.includes(id));
        },
      },
    },
    sort: {
      active: "1",
      key: {
        1: {
          text: "最終更新日 新しい順",
          method: (prevBenefit, nextBenefit) => {
            const prev = prevBenefit.updated_at;
            const next = nextBenefit.updated_at;
            if (prev === next) {
              return 0;
            }
            return prev > next ? -1 : 1;
          },
        },
        2: {
          text: "最終更新日 古い順",
          method: (prevBenefit, nextBenefit) => {
            const prev = prevBenefit.updated_at;
            const next = nextBenefit.updated_at;
            if (prev === next) {
              return 0;
            }
            return prev > next ? 1 : -1;
          },
        },
      },
    },
  },
  getters: {
    queries: ({ queries }) => queries,
    keywordQuery: ({ queries }) => queries.keyword.query,
    periodQuery: ({ queries }) => queries.period.query,
    periodKeys: ({ queries }) => queries.period.keys,
    inUseQuery: ({ queries }) => queries.inUse.query,
    groupTypeQuery: ({ queries }) => queries.groupType.query,
    groupTypeKeys: ({ queries }) => queries.groupType.keys,
    benefitTypeQuery: ({ queries }) => queries.benefitType.query,
    benefitTypeKeys: ({ queries }) => queries.benefitType.keys,
    mediaQuery: ({ queries }) => queries.media.query,
    sort: ({ sort }) => sort,
    activeSort: ({ sort }) => sort.key[sort.active],
    zexyBenefits: (s, getters, rs, rootGetters) => {
      const enabledHallBenefits = rootGetters.enabledHallBenefits;
      const fairBenefits = rootGetters.benefits[BENEFIT_GROUP_TYPE.fair];
      if (enabledHallBenefits) {
        const benefits = [...fairBenefits, ...rootGetters.benefits[BENEFIT_GROUP_TYPE.hall]];
        return getters.filterBenefits(benefits);
      }
      return getters.filterBenefits(fairBenefits);
    },
    fairBenefitCountZexy: (_, getters) => {
      const benefits = getters.zexyBenefits;
      const fairBenefits = benefits.filter(
        ({ group_type }) => group_type === BENEFIT_GROUP_TYPE.fair
      );
      return fairBenefits.length;
    },
    hallBenefitCountZexy: (_, getters) => {
      const benefits = getters.zexyBenefits;
      const hallBenefits = benefits.filter(
        ({ group_type }) => group_type === BENEFIT_GROUP_TYPE.hall
      );
      return hallBenefits.length;
    },
    wpBenefits: (s, getters, rs, rootGetters) => {
      const benefits = rootGetters.benefits[BENEFIT_GROUP_TYPE.wp];
      return getters.filterBenefits(benefits);
    },
    fairBenefitCountWp: (_, getters) => {
      const benefits = getters.wpBenefits;
      const fairBenefits = benefits.filter(({ is_for_fair }) => is_for_fair);
      return fairBenefits.length;
    },
    hallBenefitCountWp: (_, getters) => {
      const benefits = getters.wpBenefits;
      const hallBenefits = benefits.filter(({ is_for_hall }) => is_for_hall);
      return hallBenefits.length;
    },
    filterBenefits: (_, getters) => (benefits) => {
      const queries = Object.values(getters.queries);

      const filteredBenefits = benefits.filter((benefit) => {
        return queries.every(({ query, method }) => method(benefit, query, getters));
      });

      const { method } = getters.activeSort;
      return filteredBenefits.sort(method);
    },
    // 式場特典の期限切れ日数を取得する
    daysToValidExpired: (s, g, rs, rootGetters) =>
      rootGetters.setting.hall_benefit_valid_date_period,
    daysToApplicationExpired: (s, g, rs, rootGetters) =>
      rootGetters.setting.hall_benefit_application_date_period,
    daysToPublicationExpired: (s, g, rs, rootGetters) =>
      rootGetters.setting.hall_benefit_publication_date_period,
  },
  actions: {
    resetFilter({ commit }) {
      commit("keywordQuery", null);
      commit("periodQuery", null);
      commit("inUseQuery", false);
      commit("groupTypeQuery", []);
      commit("benefitTypeQuery", []);
      commit("mediaQuery", []);
      commit("sort", "1");
    },
    updateFilter({ commit }, { keyword, period, inUse, groupType, benefitType, media, sort }) {
      commit("keywordQuery", keyword);
      commit("periodQuery", period);
      commit("inUseQuery", inUse);
      commit("groupTypeQuery", groupType);
      commit("benefitTypeQuery", benefitType);
      commit("mediaQuery", media);
      commit("sort", sort);
    },
  },
  mutations: {
    keywordQuery(state, newQuery) {
      const keyword = { ...state.queries.keyword, query: newQuery };
      const queries = { ...state.queries, keyword };
      state.queries = queries;
    },
    periodQuery(state, newQuery) {
      const period = { ...state.queries.period, query: newQuery };
      const queries = { ...state.queries, period };
      state.queries = queries;
    },
    inUseQuery(state, newQuery) {
      const inUse = { ...state.queries.inUse, query: newQuery };
      const queries = { ...state.queries, inUse };
      state.queries = queries;
    },
    groupTypeQuery(state, newQuery) {
      const groupType = { ...state.queries.groupType, query: newQuery };
      const queries = { ...state.queries, groupType };
      state.queries = queries;
    },
    benefitTypeQuery(state, newQuery) {
      const benefitType = { ...state.queries.benefitType, query: newQuery };
      const queries = { ...state.queries, benefitType };
      state.queries = queries;
    },
    mediaQuery(state, newQuery) {
      const media = { ...state.queries.media, query: newQuery };
      const queries = { ...state.queries, media };
      state.queries = queries;
    },
    sort(state, newSort) {
      const sort = { ...state.sort, active: newSort };
      state.sort = sort;
    },
  },
};

export default filter;
