// Store Module
import commonModule from "@/store/common";
import modalModule from "@/store/modal";

import getters from "@/store/manual/getters";
import mutations from "@/store/manual/mutations";
import actions from "@/store/manual/actions";

const manualModule = {
  namespaced: true,
  modules: {
    common: commonModule,
    modal: modalModule,
  },
  state: {
    data: null,
    isShow: false,
  },
  getters,
  mutations,
  actions,
};

export default manualModule;
