/**
 * 暗号化・復号に関する処理
 */
const crypto = require("crypto");

/**
 * パスワードを暗号化
 * @param {String} password パスワード
 */
export const encrypt = (password) => {
  const iv = crypto.randomBytes(16);
  const key = Buffer.from(process.env.VUE_APP_ENCRYPTION_KEY);
  const cipher = crypto.createCipheriv("aes-256-cbc", key, iv);
  const pw = cipher.update(password);
  return {
    iv: iv.toString("hex"),
    password: Buffer.concat([pw, cipher.final()]).toString("base64"),
  };
};
/**
 * パスワードを復号
 * @param {String} password 暗号化されたパスワード
 * @param {String} initialVector 初期化ベクトル
 */
export const decrypt = (password, initialVector) => {
  const encrypted = Buffer.from(password, "base64");
  const iv = Buffer.from(initialVector, "hex");
  const key = Buffer.from(process.env.VUE_APP_ENCRYPTION_KEY);
  const decipher = crypto.createDecipheriv("aes-256-cbc", key, iv);
  const pw = decipher.update(encrypted);
  return Buffer.concat([pw, decipher.final()]).toString();
};
