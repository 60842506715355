import { addYears, parseISO, startOfToday } from "date-fns";

/**
 * 終了日を設定するDatepickerに渡すdisabledDateを取得する
 * 開始日がまだ入力されていない場合は現在日以降を選択できる
 * 開始日が入力されている場合は開始日以降を選択できる
 * 引数として年数を持たせた場合は指定の年数まで選択できる
 * @param {*} validFrom - 開始日
 * @param {Number} years - 選択できる年数
 */
const disabledDateUntil = (validFrom, years = null) => {
  const to = validFrom ? parseISO(validFrom) : startOfToday();
  if (!years) return { to };

  const from = addYears(to, years);
  return { to, from };
};

export default disabledDateUntil;