import API_CODE from "@/assets/common/ApiCode";

import formatDateText from "@/lib/formatDateText";

const periodText = (fromList, untilList, mediaId = API_CODE.mediaCommon) => {
  const from = fromList[mediaId];
  const until = untilList[mediaId];
  if (from === null && until === null) {
    return null;
  }

  // 開始日と終了日をそれぞれフォーマットしたものを取得する
  const fromText = from ? formatDateText(from) : "";
  const untilText = until ? formatDateText(until) : "";
  return `${fromText} ～ ${untilText}`;
};

export default periodText;
