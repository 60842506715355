import API_CODE from "@/assets/common/ApiCode";

const linkedMethod = (mediaId) => {
  return ({ media }, query) => {
    if (!query) {
      return true;
    }
    const linked = media.includes(mediaId);
    return query === "linked" ? linked : !linked;
  };
};

const filter = {
  namespaced: true,
  state: {
    keyword: {
      query: "",
      method: ({ id, name, original_file_name: fileName }, query) => {
        if (query === "") {
          return true;
        }
        const queryList = query.replace(/\u3000/g, " ").split(" ");
        return queryList.some((keyword) => {
          const matchedId = String(id).includes(keyword);
          const matchedName = name && name.includes(keyword);
          const matchedFileName = fileName.includes(keyword);
          return matchedId || matchedName || matchedFileName;
        });
      },
    },
    period: {
      query: null,
      keys: {
        soon: "終了間近",
        over: "終了",
        all: "終了または終了間近",
      },
      method: ({ license_status }, query) => {
        switch (query) {
          case "all":
            return license_status >= 1;
          case "soon":
            return license_status == 1;
          case "over":
            return license_status == 2;
          default:
            return true;
        }
      },
    },
    inUsed: {
      query: null,
      keys: {
        inUsed: "使用中のもの",
        unUsed: "使用されていないもの",
      },
      method: ({ used_at }, query) => {
        if (!query) {
          return true;
        }
        const inUsed = Object.values(used_at).some((usedAt) => usedAt.length > 0);
        return query === "inUsed" ? inUsed : !inUsed;
      },
    },
    linkedZexy: {
      query: null,
      method: linkedMethod(API_CODE.media.zexy),
    },
    linkedMynavi: {
      query: null,
      method: linkedMethod(API_CODE.media.mynavi),
    },
    notCroped: {
      query: false,
      method: ({ trimming }, query) => {
        if (!query) {
          return true;
        }
        return trimming.some(({ crop_x, crop_y, height, width }) =>
          [crop_x, crop_y, height, width].some((value) => value === null)
        );
      },
    },
    notTags: {
      query: false,
      method: ({ tags }, query) => (query ? !Array.isArray(tags) || tags.length === 0 : true),
    },
    tags: {
      query: [],
      method: ({ tags }, query) =>
        query.length === 0 || query.some((tagId) => tags.includes(tagId)),
    },
  },
  getters: {
    period: ({ period }) => period,
    periodQuery: ({ period }) => period.query,
    periodKeys: ({ period }) => period.keys,
    inUsed: ({ inUsed }) => inUsed,
    inUsedQuery: (_, { inUsed }) => inUsed.query,
    inUsedKeys: (_, { inUsed }) => inUsed.keys,
    linkedZexyQuery: ({ linkedZexy }) => linkedZexy.query,
    linkedMynaviQuery: ({ linkedMynavi }) => linkedMynavi.query,
    notCropedQuery: ({ notCroped }) => notCroped.query,
    notTagsQuery: ({ notTags }) => notTags.query,
    tagsQuery: ({ tags }) => tags.query,
    mediaStatus: () => ({ linked: "連携中のもの", unLinked: "連携されていないもの" }),
    filteredImages: (state, g, rs, rootGetters) => {
      const queries = Object.values(state);
      const filteredImages = rootGetters.images.filter((image) => {
        if (image.id === rootGetters.selectedImageId) {
          return true;
        }
        return queries.every(({ query, method }) => method(image, query));
      });
      return filteredImages.sort((a, b) => (a.id < b.id ? 1 : -1));
    },
  },
  mutations: {
    keywordQuery(state, query) {
      state.keyword = { ...state.keyword, query };
    },
    periodQuery(state, query) {
      state.period = { ...state.period, query };
    },
    inUsedQuery(state, query) {
      state.inUsed = { ...state.inUsed, query };
    },
    linkedZexyQuery(state, query) {
      state.linkedZexy = { ...state.linkedZexy, query };
    },
    linkedMynaviQuery(state, query) {
      state.linkedMynavi = { ...state.linkedMynavi, query };
    },
    notCropedQuery(state, query) {
      state.notCroped = { ...state.notCroped, query };
    },
    notTagsQuery(state, query) {
      state.notTags = { ...state.notTags, query };
    },
    tagsQuery(state, tagId) {
      const tagsCopy = [...state.tags.query];
      const existsTag = tagsCopy.includes(tagId);
      const query = existsTag ? tagsCopy.filter((id) => id !== tagId) : [...tagsCopy, tagId];
      state.tags = { ...state.tags, query };
    },
  },
  actions: {
    reset({ commit, state }) {
      commit("keywordQuery", "");
      commit("periodQuery", null);
      commit("inUsedQuery", null);
      commit("linkedZexyQuery", null);
      commit("linkedMynaviQuery", null);
      commit("notCropedQuery", false);
      commit("notTagsQuery", false);
      state.tags = { ...state.tags, query: [] };
    },
  },
};
export default filter;
