import API_CODE from "@/assets/common/ApiCode";
import deepCopy from "@/lib/deepCopy";

/**
 * APIで取得した値に特定の媒体の値が含まれていない場合、これを穴埋めする
 *
 * @param {any} object - APIで返却された値
 * @return {any} 穴埋め処理をした値
 */
const fillInBlankValue = (object, generator, needCommon = true) => {
  let mediaIds = Object.values(API_CODE.media);
  mediaIds = needCommon ? [API_CODE.mediaCommon, ...mediaIds] : mediaIds;

  return mediaIds.reduce((accumulator, mediaId) => {
    accumulator[mediaId] = object[mediaId]
      ? deepCopy(object[mediaId])
      : generator(mediaId);
    return accumulator;
  }, {});
};

export default fillInBlankValue;
