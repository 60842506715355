<template>
  <div class="pageNav_wrap">
    <!-- ページナビゲーション -->
    <div class="pageNav theme-light" :class="navClass">
      <div class="navTitle">
        <p class="navTitle_logo">
          <a href="/" class="navTitle_link" title="結婚スタイルマガジン コレモネ">
            {{ mediaName }}
            <br />
            <coremone-logo class="navTitle_logoImg" />
          </a>
        </p>
        <p v-if="account" class="navTitle_login navTitle_text" :class="loginClass">
          <span>{{ hallId || account.userable_id }}</span>
          <span>{{ hallName }}</span>
          <span v-if="account.display_name"> {{ account.display_name }} 様 </span>
        </p>
      </div>
      <!-- 式場ページナビゲーション -->
      <ul class="nav" v-if="!isParentAccount || hallSelected">
        <li v-for="v in hallMenus" :key="v.dir" class="nav_list">
          <p v-if="v.dir == ''" class="nav_nolink">
            <i class="material-icons">{{ v.icon }}</i>
            <span class="nav_text">{{ v.name }}</span>
          </p>
          <a v-else :href="link(v.dir)" :class="linkClass(v)" :title="v.name" @click="click(v.dir)">
            <i class="material-icons">{{ v.icon }}</i>
            <span class="nav_text">{{ v.name }}</span>
          </a>
        </li>
      </ul>
      <!-- 管理・企業ページナビゲーション -->
      <ul class="nav" v-if="isParentAccount">
        <li v-for="v in parentMenus" :key="v.dir" class="nav_list">
          <button :class="linkClassParent(v)" :title="v.name" @click="clickParentLink(v.dir)">
            <i class="material-icons">{{ v.icon }}</i>
            <span class="nav_text">{{ v.name }}</span>
          </button>
        </li>
      </ul>
      <a
        v-if="hallSelected || hallType == 'hall'"
        href="https://www.niwaka-ksm.com/edit/"
        target="_blank"
        class="guide_link"
        >結婚スタイルマガジン 管理画面</a
      >
      <nav-link-contact
        class="navContact"
        :class="{ toggled: contactToggled }"
        :toggled="contactToggled"
        @click="contactToggled = !contactToggled"
      />
      <ul class="appInfo">
        <li class="appInfo_term">
          <a class="appInfo_termLink" href="/terms/terms_ver20220901.pdf" target="_blank"
            >利用規約</a
          >
        </li>
      </ul>
      <img src="/img/bird_contact.png" class="navBird" />
    </div>
    <div class="topRightNav">
      <!-- ジョブ反映停止コントロール -->
      <job-status-nav v-if="isShowJobStatusNav" class="topRightNavItem topRightNav_job" />
      <!-- 同時ログインしているアカウント表示 -->
      <active-user-nav v-if="isShowActiveUserNav" class="topRightNavItem topRightNav_user" />
      <!-- メンテナンスが必要な情報 -->
      <maintenance-status-nav
        v-if="isShowShouldMaintenanceMessage"
        class="topRightNavItem topRightNav_error"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import {
  PAGE_CONFIG,
  COMPANY_PAGE_CONFIG,
  ADMIN_PAGE_CONFIG,
  getSelectedHallId,
  deselectHall,
} from "@/assets/common/PageConfig.js";
import { IS_DEVELOP_SERVER, IS_STAGING_SERVER } from "@/assets/common/ServerConfig";

import { removeCookieAccount } from "@/lib/cookie";

import CoremoneLogo from "@/components/atoms/common/CoremoneLogo.vue";
import NavLinkContact from "@/components/organisms/common/NavLinkContact.vue";
import JobStatusNav from "@/components/organisms/common/JobStatusNav.vue";
import MaintenanceStatusNav from "@/components/organisms/common/MaintenanceStatusNav.vue";
import ActiveUserNav from "@/components/organisms/common/ActiveUserNav.vue";

export default {
  components: {
    CoremoneLogo,
    NavLinkContact,
    JobStatusNav,
    MaintenanceStatusNav,
    ActiveUserNav,
  },
  props: {
    dir: String,
  },
  data() {
    return {
      pages: PAGE_CONFIG,
      companyPages: COMPANY_PAGE_CONFIG,
      adminPages: ADMIN_PAGE_CONFIG,
      contactToggled: true,
    };
  },
  computed: {
    ...mapGetters({
      isViewableOwnHall: "common/isViewableOwnHall",
      isViewableAnyHall: "common/isViewableAnyHall",
      isAuthorized: "common/isAuthorized",
      hallType: "common/hallType",
      account: "common/account",
      isUsed: "common/isUsed",
      beforeUsageStarted: "common/beforeUsageStarted",
      loading: "common/loading",
      hall: "common/hall",
    }),
    isParentAccount() {
      return this.isViewableAnyHall || this.isViewableOwnHall;
    },
    hallSelected() {
      return !!getSelectedHallId();
    },
    hallId() {
      return getSelectedHallId();
    },
    hallName() {
      if (this.isParentAccount && !this.hallSelected) {
        return `コレモネ管理ユーザー`;
      }
      const { display_name } = this.account;
      const suffix = display_name ? "" : "様";
      const { hall_name } = this.hall;
      return hall_name ? `${hall_name}${suffix}` : "";
    },
    isShowJobStatusNav() {
      this.updateIsDisable(!this.isAuthorized({ name: "settings", type: "edit" }));
      if (this.isParentAccount && !this.hallSelected) {
        return this.isAuthorized({
          name: "jobs",
          type: "view",
          isHallList: false,
        }); // 企業・管理ページ: view.self.jobs
      } else if (this.beforeUsageStarted) {
        return false; // 初期設定
      } else if (!this.isAuthorized({ name: "jobs", type: "view" })) {
        return false; // 権限なし
      } else if (this.isUsed) {
        return true; // 利用中の場合は表示する
      } else {
        // TODO: 媒体のフェア非公開/削除機能が実装されるまで非表示
        return false; // return true; // 初期設定完了 AND 利用前
      }
    },
    isShowActiveUserNav() {
      const isUsersViewable = this.isAuthorized({
        name: "users",
        type: "view",
      });
      return !this.loading && isUsersViewable;
    },
    isShowShouldMaintenanceMessage() {
      return !this.loading && this.account;
    },
    hallMenus() {
      return this.pages.filter((v) => {
        if (v.dir == "top") {
          return true;
        } else if (v.dir == "login") {
          return !this.isParentAccount;
        } else if (v.dir) {
          return !v.permission || this.isAuthorized(v.permission);
        } else if (v.children) {
          return v.children.some(
            (c) => !this.pages.c.permission || this.isAuthorized(this.pages.c.permission)
          );
        } else {
          return false;
        }
      });
    },
    parentMenus() {
      let pages = this.isViewableAnyHall ? this.adminPages : this.companyPages;
      return pages.filter((v) => {
        if (v.dir == "top" || v.dir == "login") {
          return true;
        } else if (v.dir) {
          return !v.permission || this.isAuthorized(v.permission);
        } else if (v.children) {
          return v.children.some(
            (c) => !this.pages.c.permission || this.permission(this.pages.c.permission)
          );
        } else {
          return false;
        }
      });
    },
    loginClass() {
      // TODO: 開発環境/本番環境を強調するためのクラス
      const isDevelop = IS_DEVELOP_SERVER || IS_STAGING_SERVER;
      const isTestId = this.account.userable_id == 9999 || this.account.userable_id == 999999;
      return {
        warning: isDevelop && !isTestId,
      };
    },
  },
  created() {
    this.navClass = { isDev: IS_DEVELOP_SERVER, isStaging: IS_STAGING_SERVER };
    this.mediaName = "結婚スタイルマガジン";
    if (IS_DEVELOP_SERVER) this.mediaName = "開発サーバー";
    if (IS_STAGING_SERVER) this.mediaName = "ステージングサーバー";
  },
  methods: {
    ...mapActions({
      updateIsDisable: "utility/jobs/updateIsDisable",
    }),
    click(dir) {
      if (dir.indexOf("login") !== -1) removeCookieAccount(true);
    },
    clickParentLink(dir) {
      const d = this.link(dir);
      this.click(d);
      if (this.hallSelected) deselectHall();
      window.location.href = d;
    },
    link(dir) {
      if (dir == "login" && this.account && this.account.userable_id) {
        return "/" + dir + "/" + this.account.userable_id;
      } else if (dir == "top") {
        return "/";
      } else {
        return "/" + dir;
      }
    },
    linkClass(target) {
      let c = ["nav_link"];
      if (target.secondLevel) c.push("nav_linkD");
      if (target.dir == this.dir) c.push("current");
      return c.join(" ");
    },
    linkClassParent(target) {
      let c = ["nav_link"];
      if (!this.hallSelected && target.dir == this.dir) c.push("current");
      return c.join(" ");
    },
  },
};
</script>

<style scoped lang="scss">
$MEDIUM_DISPLAY_HEIGHT: 760px + $GRID * 7;
$SMALL_DISPLAY_HEIGHT: 760px + $GRID * 7;

.pageNav {
  background: #28282b;
  color: #dcdcdc;
}

.navTitle {
  box-sizing: border-box;
  overflow: hidden;
  padding: $SUB_GRID * 2 $SUB_GRID * 2 $SUB_GRID * 3;
  width: $NAVI_WIDTH;

  @media screen and (max-width: $MAIN_WIDTH) {
    padding: $SUB_GRID * 1 $SUB_GRID * 2;

    &_logo {
      line-height: 1;

      &Img {
        width: 90px;
      }
    }

    &_login {
      display: none;
    }
  }

  &_link {
    text-decoration: none;
  }

  &_logo {
    font-size: 10px;
    line-height: 1.8;
    text-align: center;

    &Img {
      width: 136px;
    }

    @media screen and (max-width: $MAIN_WIDTH) {
      display: none;
    }
  }

  &_login {
    @include Box();
    background: rgba(255, 255, 255, 0.05);
    font-size: 12px;
    margin-top: $SUB_GRID * 2;
    min-height: $GRID * 3;
    padding: $SUB_GRID * 2;
    word-break: break-word;

    &.warning {
      color: $COLOR_ALERT;
    }

    span {
      display: block;
    }
  }

  @media screen and (max-width: $MAIN_WIDTH) {
    &_text {
      opacity: 0;
    }
  }

  @media screen and (max-height: $MEDIUM_DISPLAY_HEIGHT) {
    padding: $SUB_GRID * 1 $SUB_GRID * 2 $SUB_GRID * 2;

    &_logo {
      line-height: 1;

      &Img {
        width: 100px;
      }
    }
  }

  @media screen and (max-height: $SMALL_DISPLAY_HEIGHT) {
    padding: $SUB_GRID * 1 $SUB_GRID * 2;

    &_logo {
      &Img {
        width: 90px;
      }
    }
  }
}

.nav {
  border-top: 1px solid #020202;
  border-bottom: 1px solid #3c3c3e;
  width: $NAVI_WIDTH;

  &_list {
    border-top: 1px solid #3c3c3e;
    border-bottom: 1px solid #020202;
    display: block;
  }

  &_link,
  &_nolink {
    -webkit-appearance: none;
    align-items: center;
    border: none;
    color: #dcdcdc;
    display: flex;
    height: 30px;
    outline: none;
    padding-left: $SUB_GRID * 2.5;
    text-decoration: none;
    width: 100%;
  }

  &_link {
    &D {
      padding-left: $GRID * 2;
    }

    &:hover {
      background: #3c3c3e;
    }

    &.current {
      @include gradientMain();
      color: $COLOR_KEY;
      position: relative;

      &::before {
        border-color: transparent transparent transparent $COLOR_KEY;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 0;
        right: $GRID * 0.5;
      }
    }
  }

  &Ksm {
    margin: $SUB_GRID * 3 $SUB_GRID * 2;
  }

  .material-icons {
    font-size: 19px;
  }

  &_text {
    font-size: 13px;
    margin-left: $SUB_GRID * 3;
  }

  @media screen and (max-width: $MAIN_WIDTH) {
    width: 100%;

    &_link {
      justify-content: center;
      padding-left: 0;
    }

    &_link.current::before,
    &_nolink .material-icons,
    &_text {
      display: none;
    }
  }

  & + & {
    margin-top: $GRID * 2;
  }

  &Contact {
    bottom: $GRID * 3.5;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: calc(100% - #{$SUB_GRID} * 5);
    z-index: 1;

    @media screen and (max-height: $SMALL_DISPLAY_HEIGHT) {
      bottom: $GRID * 3;
    }

    @media screen and (max-width: $MAIN_WIDTH) {
      width: auto;
    }

    @media screen and (min-width: $MAIN_WIDTH) {
      bottom: -65px;
      left: 10px;
      cursor: pointer;
    }

    &.toggled {
      @media screen and (min-width: $MAIN_WIDTH) {
        bottom: $GRID * 1.5;
        left: 10px;
      }
    }
  }
}

.navBird {
  display: inline-block;
  left: $SUB_GRID * (-1);
  position: absolute;
  bottom: -2px;
  width: 70px;
  z-index: 5;

  @media screen and (max-width: $MAIN_WIDTH) {
    left: auto;
    right: 0;
  }

  @media screen and (max-height: $SMALL_DISPLAY_HEIGHT) {
    right: 2px;
    width: 50px;
  }

  @media screen and (min-width: $MAIN_WIDTH) {
    left: -20px;
  }
}

.appInfo {
  align-items: center;
  bottom: 0;
  color: $COLOR_SUB_LIGHT_TEXT;
  display: flex;
  left: 20%;
  list-style: none;
  padding: $GRID * 0.5 $SUB_GRID * 3 2px;
  position: absolute;
  width: 80%;
  z-index: 10;

  &_term {
    text-align: right;
    width: 100%;
  }

  &_termLink {
    font-size: 12px;
    color: $COLOR_SUB_LIGHT_TEXT;
    text-decoration: none;
  }

  @media screen and (max-width: $MAIN_WIDTH) {
    display: none;
  }
}

.guide_link {
  align-items: center;
  border: 1px solid #dcdcdc;
  border-radius: $BOX_RADIUS * 0.5;
  color: $COLOR_TEXT;
  display: flex;
  font-size: $FONT_SIZE_S;
  justify-content: center;
  margin: $GRID * 1.5 $GRID * 0.5 0;
  padding: $GRID * 0.5 0;
  text-decoration: none;

  &:hover {
    background: $COLOR_KEY;
    color: #dcdcdc;
  }

  @media screen and (max-height: $MEDIUM_DISPLAY_HEIGHT) {
    display: none;
  }

  @media screen and (max-width: $MAIN_WIDTH) {
    display: none;
  }
}

.topRightNav {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 60px;
  row-gap: $SUB_GRID;
  column-gap: $SUB_GRID;
  position: fixed;
  right: 0;
  top: $GRID * 0.5;
  z-index: $LAYER_LOADING; // $LAYER_NAVI ではローディングより上、$LAYER_FLOAT_WINDOW ではアシストエリアより下になる

  &_job {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    background: #faf9f9;
  }

  &_user {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    background: #faf9f9;
  }

  &_error {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
  }
}

// Light Theme
.theme-light {
  $NAV_BG_COLOR: lighten($BG_COLOR_FLOOR, 2%);
  $NAV_BG_COLOR_LIGHT: lighten($BG_COLOR_FLOOR, 2%);
  $NAV_BORDER_COLOR: darken($BG_COLOR_FLOOR, 10%);
  $NAV_KEY_COLOR: $COLOR_KEY;
  background: $NAV_BG_COLOR;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1);
  color: $NAV_KEY_COLOR;

  .navTitle {
    &_login {
      background: rgba(255, 255, 255, 0.8);
    }

    &_link {
      color: $COLOR_KEY;
    }
  }

  .nav {
    border-top: 1px solid $NAV_BORDER_COLOR;
    border-bottom: 1px solid $NAV_BG_COLOR_LIGHT;

    &_list {
      border-top: none;
      border-bottom: 1px solid $NAV_BORDER_COLOR;
    }

    &_link,
    &_nolink {
      color: lighten($COLOR_TEXT, 5%);
      height: 32px;
    }

    &_link {
      border-top: 1px solid $NAV_BG_COLOR_LIGHT;

      &.current {
        @include gradientNavigationHighlight($NAV_KEY_COLOR);
        border-top: 1px solid rgba($NAV_KEY_COLOR, 0.05);
        color: #fff;

        &::before {
          border-color: transparent transparent transparent #fff;
        }

        .material-icons {
          color: #fff;
        }
      }

      &:not(.current) {
        transition: background-color border-color 700ms;

        &:hover {
          background: rgba($NAV_KEY_COLOR, 0.2);
          border-top: 1px solid rgba($NAV_KEY_COLOR, 0.05);
          transition: background-color border-color 100ms;
        }
      }
    }

    &Ksm {
      color: $COLOR_SUB_LIGHT_TEXT;
    }

    &Title {
      &_link {
        color: $NAV_KEY_COLOR;
      }

      &_logo {
        &Img {
          fill: $NAV_KEY_COLOR;
        }
      }
    }

    .material-icons {
      color: $NAV_KEY_COLOR;
    }
  }

  .guide_link {
    color: lighten($COLOR_TEXT, 5%);

    &:hover {
      background: rgba($NAV_KEY_COLOR, 0.2);
    }
  }

  // 開発環境
  &.isDev {
    $NAV_KEY_COLOR: #ec0fe1;
    color: $NAV_KEY_COLOR;

    .nav {
      &_link {
        &.current {
          @include gradientNavigationHighlight($NAV_KEY_COLOR);

          .material-icons {
            color: #fff;
          }
        }

        &:not(.current) {
          &:hover {
            background: rgba($NAV_KEY_COLOR, 0.2);
            border-top: 1px solid rgba($NAV_KEY_COLOR, 0.05);
          }
        }
      }

      &Title {
        &_link {
          color: $NAV_KEY_COLOR;
        }

        &_logo {
          &Img {
            fill: $NAV_KEY_COLOR;
          }
        }
      }

      .material-icons {
        color: $NAV_KEY_COLOR;
      }
    }

    .guide_link {
      &:hover {
        background: rgba($NAV_KEY_COLOR, 0.2);
      }
    }
  }

  // ステージング環境
  &.isStaging {
    $NAV_KEY_COLOR: #32e5ee;
    color: $NAV_KEY_COLOR;

    .nav {
      &_link {
        &.current {
          @include gradientNavigationHighlight($NAV_KEY_COLOR);

          .material-icons {
            color: #fff;
          }
        }
        &:not(.current) {
          &:hover {
            background: rgba($NAV_KEY_COLOR, 0.2);
            border-top: 1px solid rgba($NAV_KEY_COLOR, 0.05);
          }
        }
      }

      &Title {
        &_link {
          color: $NAV_KEY_COLOR;
        }

        &_logo {
          &Img {
            fill: $NAV_KEY_COLOR;
          }
        }
      }

      .material-icons {
        color: $NAV_KEY_COLOR;
      }
    }

    .guide_link {
      &:hover {
        background: rgba($NAV_KEY_COLOR, 0.2);
      }
    }
  }
}
</style>
