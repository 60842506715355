<template>
  <label class="fairId">
    <input-checkbox v-model="selfIsSelected" />
    <span class="fontNum">#{{ id }}</span>
  </label>
</template>

<script>
import InputCheckbox from "@/components/atoms/common/InputCheckbox.vue";

export default {
  components: {
    InputCheckbox,
  },
  props: {
    id: Number,
    isChecked: Boolean,
  },
  computed: {
    selfIsSelected: {
      get: function () {
        return this.isChecked;
      },
      set: function (isChecked) {
        this.$emit("update", this.id, isChecked);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.fairId {
  align-items: center;
  display: flex;
  .fontNum {
    margin-left: $GRID * 0.5;
  }
}
</style>
