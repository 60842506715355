<template>
  <div class="datepicker" :class="{ hasError: !isValid }">
    <datepicker
      :value="currentDate"
      :name="name"
      :disabled-dates="disabledDates"
      :icon-width="0"
      :icon-height="0"
      :required="isRequired"
      :clear-button="!isRequired"
      language="ja"
      format="yyyy-MM-dd"
      maximum-view="day"
      @selected="currentDate = $event"
    />
  </div>
</template>

<script>
import Datepicker from "vuejs3-datepicker";

export default {
  components: {
    Datepicker,
  },
  props: {
    modelValue: String,
    name: String,
    disabledDates: {
      type: Object,
      default: () => ({}),
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isCheckingRequired: {
      type: Boolean,
      default: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isShowOnlyCalender: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentDate: {
      get() {
        return this.modelValue;
      },
      set(value) {
        let formatValue = null;
        if (value) {
          // 0埋め
          const month = ("0" + (value.getMonth() + 1)).slice(-2);
          const day = ("0" + value.getDate()).slice(-2);
          formatValue = value.getFullYear() + "-" + month + "-" + day;
        }
        this.$emit("update:modelValue", formatValue);
      },
    },
    hasRequiredError() {
      if (this.hasError) return true;
      if (!this.isCheckingRequired || !this.isRequired) {
        return false;
      }
      return this.modelValue ? false : true;
    },
    isValid() {
      let isValid = this.hasRequiredError ? false : true;
      this.sendIsValid(isValid);
      return isValid;
    },
  },
  methods: {
    sendIsValid(isValid) {
      this.$emit("check", isValid);
    },
  },
};
</script>

<style lang="scss">
.datepicker {
  display: inline-block;
  font-size: 14px;
  text-align: left;

  .vuejs3-datepicker {
    #calendar-div {
      border-radius: $SUB_GRID * 2;
    }

    &__calendar {
      width: $GRID * 12.5;
      z-index: 500;
    }

    &__calendar .cell {
      height: $GRID * 1.75;
      line-height: $GRID * 1.75;
      padding: 0;

      &.day {
        &.sat {
          color: $COLOR_SATURDAY;
        }

        &.sun {
          color: $COLOR_SUNDAY;
        }
      }
    }

    &__calendar-topbar {
      display: none;
    }

    &__calendar-actionarea {
      padding: 0;
    }

    &__value {
      padding: ($SUB_GRID * 1.2) ($GRID * 0.5);
      border: 1px solid #ccc;
      border-radius: $SUB_GRID * 2;
      align-items: center;
      min-width: 0;
      width: 100px;
    }

    &__content {
      margin: 0;
      font-size: $FONT_SIZE_S;
      line-height: $FONT_SIZE_S;
    }

    &__clear-button {
      line-height: 33px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 30px;
    }
  }

  &.hasError {
    .vuejs3-datepicker__value {
      background: #fee;
    }
  }
}
</style>
