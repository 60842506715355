<template>
  <button
    type="button"
    class="tab"
    :class="{ active: isActive, disabled: isDisabled }"
    @click="change"
  >
    {{ text }}
    <span class="tabNum fontNum" v-if="num != null">{{ num }}</span>
  </button>
</template>

<script>
export default {
  props: {
    text: String,
    num: {
      type: [Number, String],
      default: null,
    },
    tabChange: Function,
    isActive: Boolean,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change: function (value) {
      if (this.isDisabled) return;
      this.$emit("tabChange", value);
    },
  },
};
</script>

<style scoped lang="scss">
.tab {
  -webkit-appearance: none;
  background: #fff;
  border: none;
  border-radius: $BOX_RADIUS;
  box-shadow: $DEPTH_LV_0;
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  outline: none;
  margin-right: $GRID * 0.5;
  min-width: $GRID * 4;
  padding: 7px $GRID * 0.5;
  vertical-align: bottom;
  &Num {
    background: $COLOR_KEY;
    border-radius: 50%;
    box-sizing: border-box;
    color: #fff;
    display: inline-block;
    font-size: 11px;
    line-height: 17px;
    padding: 1px 1px 0 0;
    text-align: center;
    width: 17px;
    .active & {
      background: rgba(255, 255, 255, 0.9);
      color: $COLOR_KEY;
      font-weight: bold;
      margin: 1px;
      line-height: $GRID - 1px;
      width: $GRID;
    }
  }
  &.active {
    background: $COLOR_KEY;
    color: #fff;
    position: relative;
    &::before {
      border-color: $COLOR_KEY transparent transparent transparent;
      border-style: solid;
      border-width: $GRID * 0.5 6px 0 6px;
      bottom: -$GRID * 0.5;
      content: "";
      display: block;
      height: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      width: 0;
    }
  }

  &.disabled {
    background: $COLOR_UNPUBLIC;
    cursor: not-allowed;
  }
}
</style>
