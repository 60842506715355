import { getSelectedHallId } from "@/assets/common/PageConfig.js";
import deepCopy from "@/lib/deepCopy";
import typeToString from "@/lib/typeToString";

const mutations = {
  UPDATE_EXECUTED_JOBS(state, payload) {
    // 媒体個別( Job 共通ではない)データの key
    const mediaKeys = ["id", "media_id", "status", "message", "responded_at", "requested_at"];
    let jobs = [];
    // 渡された /logs/done のデータをjobId ごとに纏める
    payload.forEach((a) => {
      let index = jobs.findIndex((b) => b.id === a.job_id);
      if (index === -1) {
        const job = deepCopy(a);
        mediaKeys.forEach((key) => {
          delete job[key]; // job 共通ではないデータを削除
        });
        job.media = [];
        job.id = job.job_id; // job_id -> id
        delete job.job_id;
        jobs.push(job);
        index = jobs.length - 1;
      }
      // jobs[].media に媒体情報をセット
      const media = {};
      mediaKeys.forEach((key) => {
        if (key === "id") {
          media.log_id = a[key];
        } else {
          media[key] = a[key];
        }
      });
      jobs[index].media.push(media);
    });

    state.executedJobs = jobs
      .map((job) => {
        // 実行済み job のステータスを媒体全体の status からセット
        let status;
        Object.keys(state.master.status)
          .sort((a, b) => b - a)
          .map(Number)
          .forEach((i) => {
            if (status) return;
            if (job.media.some((media) => media.status === i)) status = i;
          });
        job.status = status;

        let reqAt = null; // 実行日時
        let resAt = null; // 完了日時
        job.media.forEach((value, index) => {
          // 媒体の中で最も早い実行日時 requested_at をセット
          if (value.requested_at) {
            const mReqAt = new Date(value.requested_at.replace(" ", "T")).getTime();
            if (!reqAt || reqAt.at > mReqAt) {
              reqAt = { index, at: mReqAt };
            }
          }
          // 媒体の中で最も遅い完了日時 responded_at をセット
          if (value.responded_at) {
            const mResAt = new Date(value.responded_at.replace(" ", "T")).getTime();
            if (!resAt || resAt.at < mResAt) {
              resAt = { index, at: mResAt };
            }
          }
        });
        job.requested_at = reqAt ? job.media[reqAt.index].requested_at : "";
        job.responded_at = resAt ? job.media[resAt.index].responded_at : "";
        return job;
      })
      .filter((d) => {
        return d.requested_at ? true : false;
      });
  },
  UPDATE_ERROR_TEXT(state, payload) {
    state.errorText = payload;
  },
  UPDATE_MASTER(state, payload) {
    state.master = payload;
  },
  UPDATE_WAITING_JOBS(state, payload) {
    state.waitingJobs = payload;
  },
  SET_TAB_KEY(state, key) {
    state.tab.active = key;
  },
  SET_IS_SHOW_PARENT(state, payload) {
    state.isShowParent = payload;
  },
  SET_IS_AUTHORIZED_LINK(state, payload) {
    const { name, value } = payload;
    if (typeToString(value) == "Object") {
      Object.keys(value).forEach((k) => {
        state.isAuthorizedLink[name][k] = value[k];
      });
    }
  },
};
export default mutations;
