<template>
  <div class="datepicker" :class="customClass">
    <datepicker
      :value="setDate"
      :name="name"
      :disabled="isDisabled"
      :disabled-dates="disabledDates"
      :inline="isShowOnlyCalender"
      :icon-width="0"
      :icon-height="0"
      :required="isRequired"
      :clear-button="!isRequired && clearButton"
      :format="format"
      :maximum-view="maximumView"
      :minimum-view="minimumView"
      :initial-view="initialView"
      language="ja"
      :calendar-class="calendarClass"
      @selected="setDate = $event"
    />
  </div>
</template>

<script>
import Datepicker from "vuejs3-datepicker";

export default {
  components: {
    Datepicker,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    clearButton: {
      type: Boolean,
      default: true,
    },
    disabledDates: {
      type: Object,
      default: () => ({}),
    },
    format: {
      type: [String, Function],
      default: "yyyy-MM-dd",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isShowOnlyCalender: {
      type: Boolean,
      default: false,
    },
    maximumView: {
      type: String,
      default: "day",
    },
    minimumView: {
      type: String,
      default: "day",
    },
    initialView: {
      type: String,
      default: "day",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      temp: this.modelValue, // datepicker 入力検知用
    };
  },
  computed: {
    setDate: {
      get() {
        return this.modelValue;
      },
      set(value) {
        let formatValue = null;
        if (value) {
          // 0埋め
          const month = ("0" + (value.getMonth() + 1)).slice(-2);
          const day = ("0" + value.getDate()).slice(-2);
          formatValue = value.getFullYear() + "-" + month + "-" + day;
        }
        this.temp = value;
        this.$emit("update:modelValue", formatValue);
      },
    },
    hasRequiredError() {
      if (!this.isRequired) {
        return false;
      }
      return this.setDate ? false : true;
    },
    customClass() {
      return {
        disabled: this.isDisabled,
        hasError: this.hasRequiredError || this.hasError,
        fullWidth: this.fullWidth,
      };
    },
    calendarClass() {
      return `vuejs3-datepicker__calendar--${this.align}`;
    },
  },
  watch: {
    setDate: {
      immediate: true,
      handler() {
        const hasError = this.hasRequiredError || this.hasError;
        this.$emit("check", !hasError);
      },
    },
    hasRequiredError(newValue) {
      const hasError = newValue || this.hasError;
      this.$emit("check", !hasError);
    },
  },
};
</script>

<style lang="scss">
.datepicker {
  display: inline-block;
  font-size: 14px;
  text-align: left;

  &.fullWidth {
    width: 100%;

    .vuejs3-datepicker {
      width: 100%;

      &__calendar {
        width: 100%;
      }
    }
  }

  .vuejs3-datepicker {
    #calendar-div {
      border-radius: $SUB_GRID * 2;
    }

    &__calendar {
      width: $GRID * 15;
      z-index: 500;

      &--right {
        right: 0;
      }
    }

    &__calendar .cell {
      height: $GRID * 2.5;
      line-height: $GRID * 2.5;
      padding: 0;

      &.day {
        height: $GRID * 2;
        line-height: $GRID * 2;

        &.sat {
          color: $COLOR_SATURDAY;
        }

        &.sun {
          color: $COLOR_SUNDAY;
        }
      }
    }

    &__calendar .disabled {
      color: #ddd !important;
    }

    &__calendar-topbar {
      display: none;
    }

    &__calendar-actionarea {
      padding: 0;
    }

    &__value {
      padding: ($SUB_GRID * 1.2) ($GRID * 0.75);
      border: 1px solid #ccc;
      border-radius: $SUB_GRID * 2;
      align-items: center;
      min-width: 0;
      width: 110px;
    }

    &__content {
      margin: 0;
      font-size: $FONT_SIZE_S;
      line-height: $FONT_SIZE_S;
    }

    &__clear-button {
      line-height: 33px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 30px;
    }
  }

  &.disabled {
    .vuejs3-datepicker__value {
      background: $BG_COLOR_SUB;
      color: $COLOR_SUB_LIGHT_TEXT;
      cursor: not-allowed;
    }
  }

  &.hasError {
    .vuejs3-datepicker__value {
      background: #fee;
    }
  }
}
</style>
