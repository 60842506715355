<template>
  <ul class="radioList" :class="{ hasError: !isValid }">
    <li v-for="(item, i) in items" class="radioList_item" :key="i">
      <label class="radioWrapper">
        <input class="inputRadio" type="radio" v-model="selfValue" :value="i" @change="sendInput" />
        <span class="radioText">{{ item }}</span>
      </label>
    </li>
  </ul>
</template>

<script>
// TODO: InputRadio.vue がページごとに存在する
export default {
  props: {
    modelValue: Number,
    items: Object,
    isRequired: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      selfValue: this.modelValue,
      tmpIsValid: null,
    };
  },
  computed: {
    isValid() {
      let isValid;
      if (!this.isRequired) {
        isValid = true;
      } else {
        isValid = this.selfValue != null ? true : false;
      }
      if (isValid !== this.tmpIsValid) {
        this.$emit("check", isValid);
        this.tmpIsValid = isValid;
      }
      return isValid;
    },
  },
  methods: {
    sendInput(event) {
      this.$emit("update:modelValue", Number(event.target.value));
    },
  },
};
</script>

<style scoped lang="scss">
.radioList {
  list-style: none;
  &_item {
    display: block;
    margin: 0 1em $SUB_GRID 0;
  }
}
.radioWrapper {
  cursor: pointer;
  padding: 5px;
}
.inputRadio {
  cursor: pointer;
}
.radioText {
  margin-left: 5px;
}
</style>
