<template>
  <modal-window
    v-if="isError"
    class="modal-state"
    :is-error="true"
    :close-text="error.closeBtnText"
    @close="modalClose"
  >
    <template #text>
      <animated-icon :is-error="true" />
      <p class="text">{{ error.text }}</p>
    </template>
  </modal-window>

  <modal-window
    v-else-if="complete.isShow"
    class="modal-state"
    :is-completed="isCompleted"
  >
    <template #text>
      <animated-icon :is-safe="true" />
      <p class="text">{{ complete.text }}</p>
    </template>
  </modal-window>

  <modal-window
    v-else-if="loading.isShow"
    class="modal-state"
    :is-uploaded="isUploaded"
  >
    <template #text>
      <p>{{ loading.text }}</p>
    </template>
  </modal-window>

  <modal-window
    v-else-if="confirm.isShow"
    class="modal-state"
    :close-text="confirm.closeBtnText"
    @close="modalClose"
  >
    <template #text>
      <animated-icon
        :is-delete="confirm.status == 1"
        :is-private="confirm.status == 2"
        :is-next="confirm.status == 3"
        :is-note="!confirm.status"
        :color-type="confirm.colorType"
      />
      <p class="text confirmText">{{ confirm.text }}</p>
      <p v-if="confirm.subText" class="text confirmSubText">
        {{ confirm.subText }}
      </p>
    </template>
    <template #footerBtn v-if="confirm.actionBtnText">
      <action-button
        :is-large="true"
        :is-alert-fill="confirm.status == 1 || confirm.status == 2"
        :text="confirm.actionBtnText"
        @clickHandler="updateData"
      />
    </template>
  </modal-window>
</template>

<script>
import { mapState, mapActions } from "vuex";

import ModalWindow from "@/components/molecules/common/ModalWindow.vue";
import ModalConfirm from "@/components/modals/ModalConfirm.vue";
import AnimatedIcon from "@/components/atoms/common/AnimatedIcon.vue";
import ActionButton from "@/components/atoms/common/ActionButton.vue";

export default {
  components: {
    ModalWindow,
    ModalConfirm,
    AnimatedIcon,
    ActionButton,
  },
  computed: {
    ...mapState("modal", {
      complete: (state) => state.completeModal,
      loading: (state) => state.loadingModal,
      confirm: (state) => state.confirmModal,
      error: (state) => state.errorModal,
    }),
    isCompleted() {
      return !this.error.isShow && !this.loading.isShow;
    },
    isUploaded() {
      return this.loading.isShow;
    },
    isError() {
      return this.error.isShow;
    },
  },
  methods: {
    ...mapActions({
      close: "modal/closeModal",
      update: "modal/updateConfirmData",
    }),
    modalClose() {
      if (this.error.path) {
        window.location.href = this.error.path;
      } else {
        this.close();
      }
    },
    updateData() {
      this.update();
    },
  },
};
</script>

<style scoped lang="scss">
.modal-state {
  z-index: $LAYER_MODAL + 1;
}
.text {
  white-space: pre-wrap;
}
.confirmText {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
}
.confirmSubText {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.75;
}
</style>
