const TARGET_ITEMS = ["valid_from", "valid_until", "valid_period_text"];

// 特典情報を保存する際に、入力欄が非表示になっている項目の情報を消し込む
const deleteHiddenInputValues = (params, showInput) => {
  const deletedParams = TARGET_ITEMS.map((item) => {
    const param = params[item];
    if (showInput(item)) {
      return [item, param];
    }
    const emptyParam = Object.keys(param).map(([mediaId]) => [mediaId, null]);
    return [item, Object.fromEntries(emptyParam)];
  });
  return Object.fromEntries(deletedParams);
};

export default deleteHiddenInputValues;
