<template>
  <div class="inputSelectbox_wrapper">
    <select
      class="inputSelectbox"
      v-model="selfValue"
      :class="{ hasError: !isValid }"
      :required="isRequired"
    >
      <option value="default_disabled" v-if="defaultText" :disabled="!canSelectNull ? true : false">
        {{ defaultText }}
      </option>
      <option
        v-for="(option, val) in selfOptions"
        :value="val"
        :selected="selfValue == val"
        :disabled="option.isDisabled"
        :key="val"
      >
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script>
// fair-master-edit をベースに修正
export default {
  props: {
    modelValue: [String, Number],
    canSelectNull: {
      type: Boolean,
      default: false,
    },
    defaultText: {
      type: String,
      default: "-- 選択 --",
    },
    isShowDefault: {
      type: Boolean,
      default: false,
    },
    options: {
      type: [Array, Object],
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    part: {
      type: Object,
      default: () => {},
    },
    mediaName: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      tmpIsValid: null,
    };
  },
  computed: {
    selfValue: {
      get: function () {
        if (this.modelValue !== null && this.modelValue !== undefined) {
          return Number(this.modelValue);
        } else {
          return this.isShowDefault ? "default_disabled" : "";
        }
      },
      set: function (value) {
        if (value !== this.selfValue) {
          if (this.mediaName) {
            this.$emit("update:modelValue", Number(value), this.part, this.mediaName);
          } else if (!value) {
            this.$emit("update:modelValue", null);
          } else {
            this.$emit("update:modelValue", Number(value));
          }
        }
      },
    },
    selfOptions() {
      // this.optionsが配列かオブジェクトかで分岐
      if (Array.isArray(this.options)) {
        return this.getOptionsFromArray();
      } else if (typeof this.options === "object") {
        return this.getOptionsFromObject();
      }
    },
    isValid() {
      let isValid;
      if (!this.isRequired) {
        isValid = true;
      } else {
        isValid = this.selfValue === "" || this.selfValue == null ? false : true;
      }
      if (isValid !== this.tmpIsValid) {
        this.$emit("check", isValid);
        this.tmpIsValid = isValid;
      }
      return isValid;
    },
  },
  methods: {
    getOptionsFromArray() {
      let options = {};
      this.options.forEach((item) => {
        options[item] = {
          text: item,
          isDisabled: false,
        };
      });
      return options;
    },
    getOptionsFromObject() {
      let options = {};
      Object.keys(this.options).forEach((key) => {
        let item = this.options[key];
        if (typeof item === "object" && !Array.isArray(item)) {
          if ("isDisabled" in item) {
            options[key] = item;
          } else {
            item.isDisabled = false;
            options[key] = item;
          }
        } else {
          options[key] = {
            text: item,
            isDisabled: false,
          };
        }
      });
      return options;
    },
  },
};
</script>

<style scoped lang="scss">
.inputSelectbox {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  font-size: 11px;
  height: 100%;
  outline: none;
  padding: 0 $GRID * 0.5;
  position: relative;
  width: 100%;
  option {
    padding: $SUB_GRID;
  }
  &_wrapper {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: inline-block;
    height: $GRID * 1.5 - 2;
    margin: 0 auto;
    position: relative;
    width: $GRID * 4.5;
    &::before {
      border-style: solid;
      border-color: #bc9e74 transparent transparent transparent;
      border-width: 6px 4px 0 4px;
      content: "";
      display: block;
      margin-top: -3px;
      position: absolute;
      right: 8px;
      top: 50%;
      z-index: 50;
    }
  }
}
</style>
