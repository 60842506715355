<template>
  <benefit-form
    v-if="showInput(keyName)"
    :title="title"
    :need-by-media="true"
    :note="onlyMediaName(keyName)"
    :option-level="optionLevel([keyName])"
  >
    <by-media-input
      :key-name="keyName"
      :is-by-media="isByMedia"
      :title="title"
      @toggleByMedia="changeIsByMedia({ [keyName]: $event })"
    >
      <input-item-period-date
        v-if="!isByMedia"
        :disabled-dates="disabledDates(mediaCommon)"
        :key-name="keyName"
        :media-id="mediaCommon"
        :required="isRequired(keyName)"
        :value="periodUntil(mediaCommon)"
        :has-error="hasErrorUntil(mediaCommon)"
        @input="updatePeriod($event, mediaCommon)"
        @check="validateUntil(mediaCommon, $event)"
      />
      <template v-if="isByMedia">
        <input-item-period-date
          v-for="mediaId in selectedOnlyMedia(keyName)"
          :key="mediaId"
          :disabled-dates="disabledDates(mediaId)"
          :key-name="keyName"
          :media-id="mediaId"
          :required="isRequired(keyName)"
          :value="periodUntil(mediaId)"
          :has-error="hasErrorUntil(mediaId)"
          @input="updatePeriod($event, mediaId)"
          @check="validateUntil(mediaId, $event)"
        />
      </template>
    </by-media-input>
  </benefit-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isBefore, parseISO } from "date-fns";

import API_CODE from "@/assets/common/ApiCode";

import { disabledDateUntil } from "@/lib/date";

import * as types from "@/store/benefit/mutationTypes";

import InputItemPeriodDate from "@/components/benefit/inputs/InputItemPeriodDate.vue";
import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import ByMediaInput from "@/components/benefit/presentation/ByMediaInput.vue";

export default {
  components: {
    InputItemPeriodDate,
    BenefitForm,
    ByMediaInput,
  },
  data() {
    return {
      title: "適用 終了日",
      keyName: "valid_until",
    };
  },
  computed: {
    ...mapGetters({
      periodFrom: "periodFrom",
      periodUntil: "periodUntil",
      isByMedium: "isByMedium",
      groupType: "groupType",
      showInput: "showInput",
      optionLevel: "optionLevel",
      onlyMediaName: "onlyMediaName",
      selectedOnlyMedia: "selectedOnlyMedia",
      isRequired: "rules/isRequired",
    }),
    isByMedia() {
      return this.isByMedium(this.keyName);
    },
    mediaCommon() {
      return API_CODE.mediaCommon;
    },
  },
  methods: {
    ...mapActions({ changeIsByMedia: "changeIsByMedia" }),
    ...mapMutations({
      updateUntil: `${types.PERIOD_UNTIL}`,
      validatePeriodUntil: `validation/${types.PERIOD_UNTIL}`,
    }),
    updatePeriod(until, mediaId) {
      this.updateUntil({ mediaId, until });
    },
    disabledDates(mediaId) {
      const validFrom = this.periodFrom(mediaId);
      return disabledDateUntil(validFrom);
    },
    hasErrorUntil(mediaId) {
      const from = parseISO(this.periodFrom(mediaId));
      const until = parseISO(this.periodUntil(mediaId));
      return isBefore(until, from);
    },
    validateUntil(mediaId, isValid) {
      if (!isValid) {
        this.validatePeriodUntil({ mediaId, isValid });
        return;
      }
      const isvalidUntil = !this.hasErrorUntil(mediaId);
      this.validatePeriodUntil({ mediaId, isValid: isvalidUntil });
    },
  },
};
</script>
