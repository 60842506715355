<template>
  <transition>
    <div class="announce">
      <div @mouseenter="hovered = true" @mouseleave="hovered = false">
        <img class="icon" src="/img/coremonet-announce/announce_color.png" />
      </div>
      <p class="message">オプション</p>
      <p v-if="hovered" class="toast">
        コレモネっとはオプション機能です。ご興味がございましたらご連絡ください。
      </p>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      hovered: false,
    };
  },
};
</script>

<style scoped lang="scss">
@mixin bubble($borderColor: $BG_COLOR_BOX, $bottom: -$GRID * 0.5) {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  left: ($toastWidth * 0.5) - ($GRID * 0.25);
  bottom: $bottom;
  border-style: solid;
  border-color: $borderColor transparent transparent transparent;
  border-width: $GRID * 0.5 $SUB_GRID 0 $SUB_GRID;
}

$scale: 0.6;
$toastWidth: $GRID * 10;

.announce {
  width: 80px;
  height: max-content;
  position: relative;
}

.icon {
  width: 100%;
  height: 32px;
  position: relative;
  image-rendering: -webkit-optimize-contrast;
  object-fit: contain;
  margin-top: -8px;

  &__full {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -$GRID * 0.5;
  }
}

.message {
  margin-top: 5px;
  font-size: $FONT_SIZE_SS;
}

.toast {
  position: absolute;
  width: $toastWidth;
  top: -$GRID * 8;
  left: -($toastWidth * 0.25);
  background-color: $BG_COLOR_BOX;
  border: 1px solid $COLOR_KEY;
  border-radius: $GRID * 0.5;
  padding: $GRID * 0.5;
  color: $COLOR_KEY;
  font-size: $FONT_SIZE_S;

  &::before {
    @include bubble($COLOR_KEY, -$GRID * 0.57);
  }

  &::after {
    @include bubble();
  }
}

@keyframes typing-left {
  0% {
    transform: rotate(-20deg) scale($scale);
  }
  100% {
    transform: rotate(10deg) scale($scale);
  }
}

@keyframes typing-right {
  0% {
    transform: rotate(60deg) scale($scale);
  }
  100% {
    transform: rotate(15deg) scale($scale);
  }
}

// トーストのアニメーション
.v-enter-active {
  transition: all 0.2s cubic-bezier(0.5, 1.7, 0.4, 1.5);
}

.v-leave-active {
  transition: all 0.2s;
}

.v-enter-from,
.v-leave-to {
  transform: translateY($SUB_GRID);
  opacity: 0;
}
</style>
