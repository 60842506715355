import API_CODE from "@/assets/common/ApiCode";
import { ConfirmModalStatus } from "@/assets/modal/confirm-modal-status";

const actions = {
  // 完了モーダルを開閉
  showCompleteModal({ commit, dispatch }, payload) {
    let t = 2500;
    let p = null;
    let callback = null;
    let callBackParam = {};
    const pause = 10;
    if (payload) {
      const { timeout, path, callBackAction, callBackActionParam } = payload;
      if (timeout) t = timeout;
      if (path) p = path;
      if (callBackAction) callback = callBackAction;
      if (callBackParam) callBackParam = callBackActionParam;
    }
    commit("setUpdateModal", { isShow: false });
    commit("setConfirmModal", { isShow: false });
    setTimeout(() => {
      commit("setCompleteModal", true);
    }, pause);
    setTimeout(() => {
      commit("setCompleteModal", false);
      if (p) window.location.href = p;
      if (callback)
        dispatch(callback, callBackParam, {
          root: true,
        });
    }, t + pause);
  },

  // エラー・確認モーダルを閉じる
  closeModal({ commit }) {
    const isShow = { isShow: false };
    commit("setErrorModal", isShow);
    commit("setConfirmModal", isShow);
  },

  // エラーモーダルを開く
  showErrorModal({ commit, state }, payload) {
    const { path, closeBtnText } = payload;

    let { text } = payload;
    if (text === "Sorry, something went wrong.") text = "保存に失敗しました";

    const e = {
      isShow: true,
      text,
      path,
      closeBtnText: closeBtnText || state.confirmModal.closeBtnText,
    };
    commit("setUpdateModal", { isShow: false });
    commit("setErrorModal", e);
  },

  // 確認モーダルの表示
  async showConfirmModal({ commit, state }, payload) {
    const {
      colorType,
      targetId,
      status,
      text,
      subText,
      type,
      actionName,
      actionBtnText,
      closeBtnText,
      param,
      autoClose,
    } = payload;

    // テキスト調整
    let t = text || state.confirmModal.text,
      sub = subText || null;
    if (!t) {
      const selectText = type
        ? `選択した${state.confirmModal.master.type[type]}を`
        : "";
      switch (status) {
        case ConfirmModalStatus.DELETE:
          t =
            selectText +
            "削除しますか？\r\nデータは完全に削除され、復元できません。";
          break;

        case ConfirmModalStatus.UNPUBLISHING:
          if (type === "plan") {
            t = `選択した${state.confirmModal.master.type[type]}の連携を解除しますか？`;
          } else {
            t = selectText + "非公開に設定しますか？";
          }
          t += "\r\n掲載媒体から削除されます。";
          break;

        case ConfirmModalStatus.PUBLISHING:
          t = selectText + "公開に設定しますか？";
          break;

        default:
          break;
      }
    }

    // テキスト調整
    let abt = actionBtnText || state.confirmModal.actionBtnText;
    if (!abt) {
      switch (status) {
        case ConfirmModalStatus.DELETE:
          abt = "削除する";
          break;
        case ConfirmModalStatus.UNPUBLISHING:
          abt = type === "plan" ? "連携を解除する" : "非公開にする";
          break;
        case ConfirmModalStatus.PUBLISHING:
          abt = "公開にする";
          break;

        default:
          break;
      }
    }

    let cbt = closeBtnText || state.confirmModal.closeBtnText;
    if (!cbt) {
      switch (status) {
        case ConfirmModalStatus.DELETE:
        case ConfirmModalStatus.UNPUBLISHING:
        case ConfirmModalStatus.PUBLISHING:
          cbt = "キャンセル";
          break;

        default:
          break;
      }
    }
    const p = {
      colorType,
      isShow: true,
      targetId,
      status,
      text: t,
      subText: sub,
      actionName,
      actionBtnText: abt,
      closeBtnText: cbt,
      param,
      autoClose,
    };
    commit("setConfirmModal", p);

    // 確認モーダルでボタンがクリックされるまでループを使って処理を疑似的に中断する
    while (state.confirmModal.isShow) {
      await new Promise((_resolve) => setTimeout(_resolve, 300));
    }

    // 確認モーダルで押されたボタンの結果を呼び出し元に返却する
    return state.confirmModal.confirmed;
  },

  // 確認モーダルのアクション振り分け
  updateConfirmData({ commit, dispatch, state }) {
    const params = state.confirmModal.param;
    params.id = state.targetId;

    const actionName = state.confirmModal.actionName;
    if (actionName) {
      dispatch(actionName, params, {
        root: true,
      });
    }

    const autoClose = state.confirmModal.autoClose;
    if (autoClose) dispatch("closeModal");

    commit("modalConfirmed");
  },
};
export default actions;
