<template>
  <p class="checkbox" :class="addClass">
    <span class="checkbox_icon">
      <svg class="checkbox_icon_img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.45 19.85">
        <polygon
          class="checkbox_icon_img_polygon checkbox_icon_img_polygon-edge"
          points="5.26 19.85 0 13.44 1.93 11.85 4.73 15.26 12.2 0 14.45 1.1 5.26 19.85"
        />
        <polygon
          class="checkbox_icon_img_polygon checkbox_icon_img_polygon-inside"
          points="5.1 18.47 1.06 13.54 1.83 12.91 4.89 16.63 12.55 1 13.44 1.44 5.1 18.47"
        />
      </svg>
    </span>
    <input
      class="checkbox_input"
      type="checkbox"
      v-model="selfIsSelected"
      :id="id"
      :disabled="isDisabled || null"
    />
  </p>
</template>

<script>
/**
 * 注意：labelタグで囲んで使うこと前提
 * labelでidを指定したい場合はpropsのidに入れる
 */

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    // チェック時に内部を塗りつぶすパターンにするか
    isFilled: {
      type: Boolean,
      default: false,
    },
    // trueにすると入力切替ができなくなる
    // 注意：強制的にチェックが外れるわけではない
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selfIsSelected: {
      get() {
        return this.modelValue;
      },
      set(isSelected) {
        if (isSelected !== this.modelValue) {
          this.$emit("update:modelValue", isSelected);
        }
      },
    },
    addClass() {
      return {
        isFilled: this.isFilled,
        isChecked: this.selfIsSelected,
        isDisabled: this.isDisabled,
      };
    },
  },
};
</script>

<style scoped lang="scss">
$iconHeight: 16px;
$iconWidth: 11px;
$fillColor: $COLOR_KEY;

.checkbox {
  background-color: #fff;
  border: 1px solid #595757;
  border-radius: 3px;
  cursor: pointer;
  height: $GRID;
  position: relative;
  width: $GRID;
  transition: 0.3s;
  &.isChecked & {
    &_icon {
      width: $iconWidth;
      transition: width 0.2s ease-in;
    }
  }
  &.isDisabled {
    background-color: $COLOR_DISABLE_TEXT;
    cursor: not-allowed;
    opacity: 0.8;
  }
  &.isFilled & {
    &_icon {
      &_img {
        &_polygon {
          fill: #fff;
          &-edge {
            display: block;
            stroke: $fillColor;
          }
        }
      }
    }
  }
  &.isFilled.isChecked {
    background-color: $fillColor;
    border-color: $fillColor;
  }
  &.isFilled.isChecked & {
    background-color: $fillColor;
    border-color: $fillColor;
    &_icon {
      &_img {
        &_polygon {
          &-edge {
            display: block;
          }
        }
      }
    }
  }
  &_icon {
    bottom: 3px;
    height: $iconHeight;
    left: 2px;
    overflow: hidden;
    position: absolute;
    width: 0;
    &_img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: $iconWidth;
      height: $iconHeight;
      &_polygon {
        &-edge {
          display: none;
        }
      }
    }
  }
  &_input {
    visibility: hidden;
  }
}
</style>
