import * as types from "@/store/common/mutationTypes";

const mutations = {
  [types.LOGIN](state, payload) {
    const { hall_id } = payload;
    state.isLogin = true;
    state.hallId = hall_id;
  },
  [types.LOGOUT](state) {
    state.isLogin = false;
    state.hallId = null;
    state.data = null;
  },
  [types.ERROR](state) {
    state.isError = true;
  },
  [types.SET_INITIAL_DATA](state, payload) {
    state.data = payload.data;
  },
  [types.SET_LOADING_FINISH](state) {
    state.loading = false;
  },
  [types.SET_ACCOUNT](state, payload) {
    state.account = payload;
  },
  account(state, account) {
    state.account = account;
  },
  permissions(state, permissions) {
    state.permissions = permissions;
  },
  hall(state, hall) {
    state.hall = hall;
  },
  master(state, master) {
    state.master = master;
  },
};
export default mutations;
