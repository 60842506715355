<template>
  <input-item :media-name="mediaShortName(mediaId)" :is-by-media="!isCommon">
    <input-datepicker
      v-model="date"
      :key-name="keyName"
      :is-required="required"
      :disabled-dates="disabledDates"
      :has-error="hasError"
      @check="$emit('check', $event)"
    />
  </input-item>
</template>

<script>
import { mapGetters } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import InputDatepicker from "@/components/benefit/inputs/InputDatepicker.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";

export default {
  components: {
    InputDatepicker,
    InputItem,
  },
  props: {
    value: String,
    mediaId: Number,
    keyName: String,
    required: Boolean,
    disabledDates: Object,
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["mediaShortName"]),
    date: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isCommon() {
      return this.mediaId === API_CODE.mediaCommon;
    },
  },
};
</script>
