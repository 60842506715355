<template>
  <modal-header-window :icon="icon" :title="title" :is-set-footer="false" @close="$emit('close')">
    <template v-slot:content>
      <slot name="replaceContent" />
      <div v-if="needShowUsed" class="replace-contents" :class="contentsClass">
        <used-contents
          v-for="(item, key) in contents"
          class="replace-block"
          :key="key"
          :used-items="item"
          :need-title="true"
          :used-type="key"
        />
      </div>
    </template>
  </modal-header-window>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import ModalHeaderWindow from "@/components/molecules/common/ModalHeaderWindow.vue";
import UsedContents from "@/components/molecules/common/UsedContents.vue";

export default {
  components: {
    ActionButton,
    ModalHeaderWindow,
    UsedContents,
  },
  props: {
    usedContents: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: "利用中のコンテンツ",
    },
    icon: {
      type: String,
      default: "swap_horiz",
    },
    needShowUsed: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    contents() {
      // 使用されていない機能を除外する
      return Object.entries(this.usedContents)
        .filter(([key, value]) => value.length >= 1)
        .reduce((accumulator, [key, value]) => {
          accumulator[key] = value;
          return accumulator;
        }, {});
    },
    contentsClass() {
      const usedAtAnyFeature = Object.keys(this.contents).length > 1;
      return {
        "replace-contents--flex": usedAtAnyFeature,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.replace-contents {
  margin-top: $GRID;

  &--flex {
    display: flex;
  }
}

.replace-block {
  width: 100%;

  & + & {
    margin-left: $GRID;
  }

  .replace-contents--flex & {
    width: $GRID * 27.5;
  }
}
</style>
