<template>
  <div class="circleError">
    <p class="circleError_finish">
      <span class="circleError_finishWord">Oh</span>
      <span class="circleError_finishWord">my</span>
      <span class="circleError_finishWord">God...</span>
    </p>
    <div class="circleError_icon">
      <div class="circleError_boy">
        <img class="circleError_body" src="/img/modal/error-boy.png" />
        <img class="circleError_wing left" src="/img/modal/error-left.png" />
        <img class="circleError_wing right" src="/img/modal/error-right.png" />
        <img class="circleError_tie" src="/img/modal/error-tie.png" />
      </div>
      <div class="circleError_ball"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.circleError {
  height: 66px;
  margin: $GRID * 1.5 auto;
  position: relative;
  width: 66px;
  &_finish {
    bottom: 80%;
    color: $COLOR_SUB_TEXT;
    font-size: 10px;
    left: 85%;
    position: absolute;
    transform: rotate(8deg);
    width: 10em;
    &Word {
      animation: fall-finish 0.5s ease-out forwards;
      display: inline-block;
      margin-left: 0.5em;
      opacity: 0;
      &:nth-child(1) {
        animation-delay: 2.4s;
      }
      &:nth-child(2) {
        animation-delay: 2.8s;
      }
      &:nth-child(3) {
        animation-delay: 3.2s;
      }
    }
  }
  &_icon,
  &_ball {
    height: 100%;
    position: relative;
    width: 100%;
  }
  &_icon {
    animation: fall 0.8s ease-out 0.3s forwards;
    transform: translateY(-160%);
  }
  &_ball {
    background: $COLOR_ALERT;
    border-radius: 50%;
    z-index: 5;
    &::before,
    &::after {
      background: $BG_COLOR_ALERT;
      content: "";
      display: block;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 6px;
    }
    &::before {
      height: 20px;
      top: 17px;
    }
    &::after {
      bottom: 17px;
      height: 6px;
    }
  }
  &_boy {
    animation: show-boy 1.4s linear 1.1s forwards;
    bottom: 0;
    left: 40px;
    line-height: 1;
    position: absolute;
    transform: translate(-20px, 0px) rotate(-30deg) scale(0.9);
    transform-origin: left bottom;
    z-index: 2;
  }
  &_body {
    position: relative;
    z-index: 2;
  }
  &_wing {
    animation: hands-on 1.4s linear 1.1s forwards;
    position: absolute;
    transform: rotate(20deg) scale(0.7);
    transform-origin: left top;
    &.left {
      bottom: 2px;
      left: 18px;
      z-index: 4;
    }
    &.right {
      bottom: 0;
      right: -8px;
      z-index: 1;
    }
  }
  &_tie {
    animation: boto 0.15s ease-out 3.2s forwards;
    bottom: 4px;
    position: absolute;
    right: 21px;
    transform: rotate(10deg);
    z-index: 3;
  }
}

@keyframes show-boy {
  0% {
    transform: translate(-20px, 0px) rotate(-30deg) scale(0.9);
  }
  25% {
    transform: translate(10px, -10px) rotate(-10deg) scale(1);
  }
  50% {
    transform: translate(20px, 0px) rotate(0deg);
  }
  80% {
    transform: translate(30px, 0px) rotate(-5deg);
  }
  100% {
    transform: translate(30px, 0px) rotate(0deg) scale(1);
  }
}

@keyframes hands-on {
  0% {
    transform: rotate(20deg) scale(0.7);
  }
  25% {
    transform: rotate(-35deg) scale(1);
  }
  50% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes boto {
  0% {
    transform: rotate(10deg);
  }
  100% {
    bottom: 0;
    transform: rotate(0deg);
    right: 20px;
  }
}

@keyframes fall {
  0% {
    transform: scale(0.8, 1.4) translateY(-100%);
  }
  15% {
    transform: scale(0.8, 1.4) translateY(-15%);
  }
  30% {
    transform: scale(1.4, 0.6) translateY(30%);
  }
  45% {
    transform: scale(0.9, 1.1) translateY(-10%);
  }
  60% {
    transform: scale(0.95, 1.2) translateY(-30%);
  }
  75% {
    transform: scale(0.95, 1.2) translateY(-10%);
  }
  90% {
    transform: scale(1.1, 0.9) translateY(5%);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes fall-finish {
  0% {
    opacity: 0;
    transform: translateY(-30%);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
