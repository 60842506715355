import API_CODE from "@/assets/common/ApiCode.js";
import MESSAGE_CONFIG from "@/assets/common/MessageConfig.js";
import { selectHall } from "@/assets/common/PageConfig.js";
import { DemoAccountType } from "@/assets/hall-admin/demo-account-type";

import { httpClient, setForcedBaseUrl } from "@/lib/httpClient";

import useHallRepository from "@/repositories/hall";

import * as commonTypes from "@/store/common/mutationTypes";

const PERMISSION = {
  import: "startImport",
  associate: "associateImport",
  create: "completeData",
  confirm: "publish",
  halls: "halls",
  settings: "settings",
  mediaAccounts: "mediaAccounts",
};

// デモアカウントを生成する際のプランやフェアの数
const DATA_SET_NUMBER = {
  [DemoAccountType.HALL]: 1,
  [DemoAccountType.VENDOR]: 10,
};

const actions = {
  /*
   * 初期データを受け取る
   ***********************************/
  /**
   * 初期値の取得
   */
  async getInitialData({ commit, dispatch, state, getters }) {
    await dispatch("common/checkPagePermission");
    const { index } = useHallRepository();
    const response = await index();
    const success = await dispatch("setHallList", response);
    // edit.any.*** の権限確認
    Object.keys(PERMISSION).forEach((type) => {
      const value = getters["common/isAuthorized"]({
        name: PERMISSION[type],
        type: "edit",
      });
      commit("SET_IS_AUTHORIZED", { type, value });
    });
    if (!state.isAuthorized.halls) commit("filter/SET_RESTRICTED_MASTER");
    if (success) {
      commit("common/" + commonTypes.SET_LOADING_FINISH);
    } else {
      commit("common/" + commonTypes.ERROR);
      dispatch("showErrorModal");
    }
  },
  /**
   * 式場アカウントリストを state に反映
   * @param {Object} response
   */
  async setHallList({ commit }, { status, data }) {
    if (status === API_CODE.response.success) {
      if (data) {
        if (data.data) {
          let halls = data.data;
          // client_name を { media_id: client_name } の形式で別途保存
          halls = halls.map((hallData) => {
            const clientNameByMedia = {};
            hallData.media.forEach((mediaData) => {
              clientNameByMedia[mediaData.media_id] = mediaData.client_name;
            });
            hallData.clientNameByMedia = clientNameByMedia;
            return hallData;
          });
          await commit("SET_HALL_DATA", data.data);
          commit("SET_HALL_COUNT");
        }
        if (data.master) commit("SET_HALL_MASTER", data.master);
        return true;
      }
    }
    return false;
  },
  /**
   * 利用開始前式場の情報を取得する
   */
  async fetchPotentialHalls({ dispatch }, hallIds = []) {
    const params = hallIds.length > 0 ? { hall_ids: hallIds.join(",") } : {};
    const { status, data } = await dispatch("common/getInitialData", {
      apiUrl: `/potential-halls`,
      params,
    });
    if (status !== API_CODE.response.success) {
      const text = "式場情報の取得に失敗しました";
      dispatch("modal/showErrorModal", { text, isShow: true });
      return;
    }
    const potentialHalls = data.data;
    if (potentialHalls.length === 0) {
      const text = "該当の式場が見つかりませんでした";
      dispatch("modal/showErrorModal", { text, isShow: true });
      return;
    }
    return potentialHalls;
  },

  /*
   * 設定
   ***********************************/
  /**
   * APIを実施する
   */
  async request({ commit, dispatch }, { url, method, payload, successStatus, text }) {
    const modalPayload = { isShow: true, text: "更新中です…" };
    commit("modal/setUpdateModal", modalPayload);
    const { data, status } = await httpClient[method](url, payload);
    if (status !== successStatus) {
      const errorText = data.message || text;
      dispatch("modal/showErrorModal", { text: errorText, isShow: true });
      return false;
    }
    return data || true;
  },
  /**
   * 式場マスタアカウント新規作成(開始前⇒準備中)
   * @param {Object} payload { hall_id, media_ids, feature_types }
   */
  async addMasterAccount({ commit, dispatch }, payload) {
    commit("modal/setUpdateModal", { isShow: true, text: "作成中です…" });
    const { store } = useHallRepository();
    const { status } = await store(payload);
    if (status !== API_CODE.response.created) {
      dispatch("modal/showErrorModal", {
        text: "初期アカウントの作成に失敗しました",
        isShow: true,
      });
      return false;
    }
    commit("modal/setCompleteText", "初期アカウントを作成しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
    return true;
  },
  /**
   * インポート開始
   * @param {Object} payload { hall_id, import_target }
   */
  async startImport({ commit, dispatch }, { hall_id }) {
    commit("modal/setUpdateModal", { isShow: true, text: "開始しています…" });
    const { startImport } = useHallRepository();
    const { status } = await startImport(hall_id);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { text: "インポート開始に失敗しました", isShow: true });
      return;
    }
    commit("modal/setCompleteText", "インポート開始しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
    commit("filter/SET_FILTER", {
      label: "usageState",
      value: "account_created_at",
    });
    commit("filter/SET_TAB_KEY", "account_created_at");
  },
  /**
   * データ作成を完了
   * @param {Object} payload { hall_id }
   */
  async completeCreateData({ commit, dispatch }, { hall_id }) {
    commit("modal/setUpdateModal", { isShow: true, text: "更新しています…" });
    const { completeMaintenance } = useHallRepository();
    const { status } = await completeMaintenance(hall_id);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { text: "メンテナンスの完了に失敗しました", isShow: true });
      return;
    }
    commit("modal/setCompleteText", "メンテナンスを完了しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * 式場にデータを公開
   * @param {Object} payload { hall_id }
   */
  async publish({ commit, dispatch }, { hall_id }) {
    commit("modal/setUpdateModal", { isShow: true, text: "更新しています…" });
    const { activate } = useHallRepository();
    const { status } = await activate(hall_id);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { text: "データの公開に失敗しました", isShow: true });
      return;
    }
    commit("modal/setCompleteText", "データを公開しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * パスワード再発行
   * @param {Object} payload { hall_id }
   */
  async resetPassword({ commit, dispatch }, { hall_id }) {
    commit("modal/setUpdateModal", { isShow: true, text: "更新しています…" });
    const { resetPassword } = useHallRepository();
    const { status } = await resetPassword(hall_id);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { text: "パスワードの再発行に失敗しました", isShow: true });
      return;
    }
    commit("modal/setCompleteText", "パスワードを再発行しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * コメントを保存
   * @param {Object} payload { hall_id, comment }
   */
  async updateComment({ commit, dispatch }, { hall_id, ...payload }) {
    commit("modal/setUpdateModal", { isShow: true, text: "更新しています…" });
    const { updateComment } = useHallRepository();
    const { data, status } = await updateComment(hall_id, payload);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { text: "保存に失敗しました", isShow: true });
      return;
    }
    commit("UPDATE_HALL_COMMENT", data);
    commit("modal/setCompleteText", "保存しました");
    dispatch("modal/showCompleteModal");
  },

  /**
   * 対象媒体を取得
   * @param {Object} payload { hall_id }
   */
  async getTargetMedia(_, { hall_id }) {
    setForcedBaseUrl(hall_id); // 式場ID を API ベース URLにセット
    const { status, data } = await httpClient.get("/media/accounts");
    if (data && data.data) {
      return Object.values(data.data).map((d) => d.media.media_id);
    } else {
      return [];
    }
  },
  /**
   * 対象媒体を更新
   * @param {Object} payload { hall_id, media_ids }
   */
  async updateTargetMedia({ commit, dispatch }, { hall_id, ...payload }) {
    commit("modal/setUpdateModal", { isShow: true, text: "更新しています…" });
    const { resetTargetMedia } = useHallRepository();
    const { status } = await resetTargetMedia(hall_id, payload);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { text: "保存に失敗しました", isShow: true });
      return;
    }
    commit("modal/setCompleteText", "保存しました");
    dispatch("modal/showCompleteModal");
  },
  /**
   * デモアカウントを作成
   * @param {Object} payload { hallName }
   */
  async putDemoAccount({ commit, dispatch }, { hallName, accountType }) {
    const dataset_number = DATA_SET_NUMBER[accountType];
    const payload = { dataset_number, hall_name: hallName };
    commit("modal/setUpdateModal", { isShow: true, text: "作成中です…" });
    const { storeDemo } = useHallRepository();
    const { status } = await storeDemo(payload);
    if (status !== API_CODE.response.created) {
      dispatch("modal/showErrorModal", {
        text: "デモアカウントの作成に失敗しました",
        isShow: true,
      });
      return false;
    }
    commit("modal/setCompleteText", "デモアカウントを作成しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
    return true;
  },
  /**
   * インポートやり直し
   */
  async resetImport({ commit, dispatch }, { hall_id }) {
    commit("modal/setUpdateModal", { isShow: true, text: "更新中です…" });
    const { resetImport } = useHallRepository();
    const { status } = await resetImport(hall_id);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { text: "インポートやり直しに失敗しました", isShow: true });
      return;
    }
    commit("modal/setCompleteText", "式場利用状況を更新しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * 紐づけやり直し
   */
  async resetAssociate({ commit, dispatch }, { hall_id }) {
    commit("modal/setUpdateModal", { isShow: true, text: "更新中です…" });
    const { resetAssociate } = useHallRepository();
    const { status } = await resetAssociate(hall_id);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { text: "紐付けやり直しに失敗しました", isShow: true });
      return;
    }
    commit("modal/setCompleteText", "式場利用状況を更新しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * 式場利用停止
   * @param {Object} payload { hall_id, suspended_at }
   */
  async suspend({ commit, dispatch }, { hall_id, ...payload }) {
    commit("modal/setUpdateModal", { isShow: true, text: "更新中です…" });
    const { suspend } = useHallRepository();
    const { status } = await suspend(hall_id, payload);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", {
        text: "式場利用終了日の更新に失敗しました",
        isShow: true,
      });
      return;
    }
    commit("modal/setCompleteText", "式場利用終了日を更新しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * 式場データ削除
   * @param {Object} payload { hall_id }
   */
  async deleteHall({ commit, dispatch }, { hall_id }) {
    commit("modal/setUpdateModal", { isShow: true, text: "削除中です…" });
    const { destroy } = useHallRepository();
    const { status } = await destroy(hall_id);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { text: "削除に失敗しました", isShow: true });
      return;
    }
    commit("modal/setCompleteText", "削除しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * サービスの利用開始
   * @param {Object} payload { hall_id, feature_types }
   */
  async startFeatureUsage({ commit, dispatch }, { hall_id, ...payload }) {
    commit("modal/setUpdateModal", { isShow: true, text: "更新中です…" });
    const { activateUsageFeature } = useHallRepository();
    const { status } = await activateUsageFeature(hall_id, payload);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { text: "利用開始に失敗しました", isShow: true });
      return;
    }
    commit("modal/setCompleteText", "利用開始しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /**
   * サービスの利用終了
   * @param {Object} payload { hall_id, feature_types }
   */
  async stopFeatureUsage({ commit, dispatch }, { hall_id, ...payload }) {
    commit("modal/setUpdateModal", { isShow: true, text: "更新中です…" });
    const { deactivateUsageFeature } = useHallRepository();
    const { status } = await deactivateUsageFeature(hall_id, payload);
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { text: "利用終了に失敗しました", isShow: true });
      return;
    }
    commit("modal/setCompleteText", "利用終了しました");
    dispatch("modal/showCompleteModal", {
      callBackAction: "hallAdmin/getInitialData",
    });
  },
  /*
   * 共通
   ***********************************/
  /**
   * API の response.data を取得する
   * @param {Object}} response レスポンス
   */
  /**
   * エラーモーダルのメッセージを変更する
   * @param {String} text メッセージ
   */
  setErrorText({ commit }, text) {
    commit("SET_ERROR_TEXT", text);
  },
  /**
   * エラーモーダルを表示する
   * @param {*} type セット済みのメッセージが無い場合に type 別デフォルトメッセージを表示
   */
  showErrorModal({ commit, state }, type) {
    const defaultText = MESSAGE_CONFIG.response[type] || MESSAGE_CONFIG.error;
    commit("common/" + commonTypes.ERROR);
    const errorModal = {
      isShow: true,
      text: state.errorText || defaultText,
    };
    commit("modal/setErrorModal", errorModal);
  },
  /**
   * ページ移動
   * @param {Object} payload { path, hall_id }
   */
  movePage({}, payload) {
    const { path, hallId } = payload;
    if (hallId) selectHall(hallId);
    window.location.href = path;
  },

  async jobRetry({ commit, dispatch }, { hallId, jobId }) {
    setForcedBaseUrl(hallId);
    commit("modal/setUpdateModal", { isShow: true });
    const url = `/jobs/${jobId}/retry`;
    const response = await httpClient.post(url);
    const isSuccess = response && response.status == API_CODE.response.success;
    if (isSuccess) {
      commit("modal/setCompleteText", "更新しました");
      dispatch("modal/showCompleteModal");
    } else {
      dispatch("modal/showErrorModal", { text: MESSAGE_CONFIG.error });
    }
  },
};

export default actions;
