<template>
  <section class="by-media-input">
    <div class="by-media-input__content">
      <slot />
    </div>
    <icon-btn
      :icon="btnIcon"
      :text="btnText"
      :with-bg="btnBg"
      :is-disabled="isDisabled"
      class="by-media-input__toggle-button"
      @clickHandler="onClick"
    />
  </section>
</template>

<script>
import InputItem from "@/components/benefit/inputs/InputItem";
import IconBtn from "@/components/atoms/common/IconButton.vue";

export default {
  components: {
    InputItem,
    IconBtn,
  },
  props: {
    isByMedia: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnIcon() {
      return this.isByMedia ? "vertical_align_top" : "tune";
    },
    btnText() {
      const operation = this.isByMedia ? "解除" : "設定";
      return `媒体ごとの${this.title}を${operation}する`;
    },
    btnBg() {
      return this.isByMedia;
    },
  },
  methods: {
    onClick() {
      this.$emit("toggleByMedia", !this.isByMedia);
    },
  },
};
</script>

<style scoped lang="scss">
.by-media-input {
  width: 100%;

  &__toggle-button {
    margin: ($SUB_GRID * 2) 0 $GRID;
  }
}
</style>
