<template>
  <div class="inputBlock">
    <form-media />
    <form-type v-if="showInput('type')" />

    <template v-if="groupType == 1">
      <form-media-only v-if="showInput('is_media_only')" />
      <form-title />
      <form-description v-if="showInput('description')" title="備考" />
      <form-period v-if="showInput('valid_from')" />
    </template>

    <template v-if="groupType == 2">
      <form-title />
      <form-description title="備考" />
      <form-period-start v-if="showInput('valid_from')" />
      <form-period-end />
      <form-image />
      <form-wp-limit />
      <form-wp-recommended />
    </template>

    <div class="inputBlockFooter">
      <action-button
        text="キャンセル"
        :is-cancel="true"
        @clickHandler="closeInput"
      />
      <action-button
        class="saveBtn"
        text="保存する"
        :is-disabled="isInvalid"
        :is-save="true"
        @clickHandler="save"
      />
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton";
import FormDescription from "@/components/benefit/forms/common/FormDescription.vue";
import FormImage from "@/components/benefit/forms/common/FormImage.vue";
import FormMedia from "@/components/benefit/forms/common/FormMedia.vue";
import FormMediaOnly from "@/components/benefit/forms/common/FormMediaOnly.vue";
import FormTitle from "@/components/benefit/forms/common/FormTitle.vue";
import FormType from "@/components/benefit/forms/common/FormType.vue";
import FormPeriod from "@/components/benefit/forms/fair-benefit/FormPeriod.vue";
import FormPeriodStart from "@/components/benefit/forms/fair-benefit/FormPeriodStart.vue";
import FormPeriodEnd from "@/components/benefit/forms/fair-benefit/FormPeriodEnd.vue";
import FormWpLimit from "@/components/benefit/forms/fair-benefit/FormWpLimit.vue";
import FormWpRecommended from "@/components/benefit/forms/fair-benefit/FormWpRecommended.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ActionButton,
    FormDescription,
    FormImage,
    FormMedia,
    FormMediaOnly,
    FormTitle,
    FormType,
    FormPeriod,
    FormPeriodStart,
    FormPeriodEnd,
    FormWpLimit,
    FormWpRecommended,
  },
  computed: {
    ...mapGetters({
      groupType: "groupType",
      mediaIds: "mediaIds",
      showInput: "showInput",
      isAllValid: "validation/isAllValid",
    }),
    isInvalid() {
      return this.mediaIds.length < 1 || !this.isAllValid;
    },
  },
  methods: {
    ...mapActions({ saveData: "saveData" }),
    save() {
      this.saveData();
    },
    closeInput() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.inputBlock {
  &Footer {
    display: flex;
    justify-content: flex-end;
    padding: $GRID * 2 $GRID $GRID;
    .saveBtn {
      margin-left: $GRID;
      width: $GRID * 11;
    }
  }
}
</style>
