<template>
  <transition name="modal" appear>
    <div class="modal modalOverlay" @click.self="close">
      <div class="modalWindow" :class="{ isCompleted, isUploaded, isError }">
        <div class="modalContent">
          <slot name="text" />
        </div>
        <footer class="modalFooter" v-if="isShowClose">
          <action-button :text="closeText" :is-cancel="true" @clickHandler="close" />
          <slot name="footerBtn" />
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton.vue";

export default {
  components: {
    ActionButton,
  },
  props: {
    closeText: {
      type: String,
      default: "閉じる",
    },
    hasClose: {
      type: Boolean,
      default: true,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    isUploaded: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    isShowClose() {
      return this.hasClose && !(this.isCompleted || this.isUploaded);
    },
  },
  methods: {
    close() {
      if (!this.hasClose) {
        return;
      }
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  &Overlay {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $LAYER_MODAL;
  }
  &Window {
    background: #fff;
    border-radius: $BOX_RADIUS;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    max-height: calc(100% - #{$GRID} * 4);
    max-width: calc(100% - #{$GRID} * 4);
    min-width: $GRID * 30;
    overflow: hidden;
    padding: $GRID $GRID * 2;
    text-align: center;
    &.isCompleted {
      background: #eafaf9;
      color: $COLOR_SUBMIT;
      font-weight: bold;
      padding-top: 0;
    }
    &.isUploaded {
      animation: gradeMove 2s ease infinite;
      @include gradientMain();
      background-size: 600% 600%;
      padding: $GRID * 4;
    }
    &.isError {
      background: $BG_COLOR_ALERT;
      color: $COLOR_ALERT;
      font-weight: bold;
      padding-top: 0;
    }
  }
  &Content {
    height: 100%;
    overflow: auto;
  }
  &Footer {
    align-items: center;
    display: flex;
    flex: 1 auto;
    justify-content: center;
    margin-top: $GRID * 1.5;
    .isError & {
      justify-content: center;
    }
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.6s;
  .modalWindow {
    transition: opacity 0.6s, transform 0.6s;
  }
}
.modal-enter,
.modal-leave-to {
  transition: opacity 0.3s;
  opacity: 0;
  .modalWindow {
    transition: opacity 0.3s, transform 0.3s;
    opacity: 0;
    transform: translateY(-20px);
  }
}

.modalFooter ::v-deep .btn {
  font-size: 14px;
  padding: 6px 0 5px;
  & + .btn {
    margin-left: $GRID;
  }
}

@keyframes gradeMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
