import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { token } = getCookieAccount();
  const resource = "halls";
  const { instance, index, store, destroy } = Repository(`${resource}`, token);
  return {
    index,
    store,
    destroy,
    storeDemo: (payload) => instance.post("/demo", payload),
    resetTargetMedia: (hallId, payload) => instance.put(`${hallId}/media-accounts`, payload),
    resetPassword: (hallId) => instance.delete(`${hallId}/password`),
    updateUsageFeatures: (hallId, payload) => instance.put(`${hallId}/features`, payload),
    activateUsageFeature: (hallId, payload) => instance.put(`${hallId}/features/activate`, payload),
    deactivateUsageFeature: (hallId, payload) => instance.put(`${hallId}/features/deactivate`, payload),
    startImport: (hallId) => instance.post(`${hallId}/import`),
    resetImport: (hallId) => instance.delete(`${hallId}/import`),
    startAssociate: (hallId) => instance.post(`${hallId}/associate`),
    resetAssociate: (hallId) => instance.delete(`${hallId}/associate`),
    completeMaintenance: (hallId) => instance.put(`${hallId}/maintenance`),
    activate: (hallId) => instance.put(`${hallId}/activate`),
    updateComment: (hallId, payload) => instance.put(`${hallId}/comment`, payload),
    suspend: (hallId, payload) => instance.put(`${hallId}/suspend`, payload),
  };
};
