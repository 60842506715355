/**
 * API 側の共通コード
 */
const TEL_NUMBER_RULES = {
  digits: {
    1: {
      min: 2,
      max: 4,
    },
    2: {
      min: 2,
      max: 4,
    },
    3: {
      min: 3,
      max: 4,
    },
  }, // ハイフン区切りの番号群の桁数
  maxLength: 14, // 全体の桁数(ハイフンを含む)
};
Object.freeze(TEL_NUMBER_RULES);
export default TEL_NUMBER_RULES;
