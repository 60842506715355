<template>
  <ul class="radioList" :class="customClass">
    <li v-for="item in items" class="radioList_item" :key="item.id">
      <label class="radioWrapper">
        <input
          class="inputRadio"
          type="radio"
          v-model="currentValue"
          :disabled="isDisabled"
          :value="item.id"
        />
        <span>{{ item.name }}</span>
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    modelValue: Number,
    items: Object,
    isRequired: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    displayBlock: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tmpIsValid: null,
    };
  },
  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    isValid() {
      let isValid;
      if (!this.isRequired) {
        isValid = true;
      } else {
        isValid = this.currentValue != null;
      }
      if (isValid !== this.tmpIsValid) {
        this.$emit("check", isValid);
        this.tmpIsValid = isValid;
      }
      return isValid;
    },
    customClass() {
      return {
        block: this.displayBlock,
        disabled: this.isDisabled,
        "has-error": !this.isValid,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.radioList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  font-size: 13px;

  &_item {
    display: block;
    margin-right: $GRID;
  }

  &.block {
    display: block;
    .radioList_item {
      margin: ($GRID * 0.25) 0;
    }
  }

  &.disabled {
    color: $COLOR_DISABLE_TEXT;
    cursor: not-allowed;

    .radioWrapper {
      pointer-events: none;
    }
  }
}

.radioWrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.inputRadio {
  cursor: pointer;
}
</style>
