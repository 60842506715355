export const StatusTypes = {
  PREPARATION_STARTED: 0,
  ACCOUNT_CREATED: 1,
  IMPORT_ENDED: 2,
};

export const VenueState = {
  // テスト用の式場
  TEST: 0,
  // コレモネ利用してない
  UNUSED: 1,
  // 入力中：アカウント入力中
  APPLYING: 2,
  // 取込中：入力完了、インポート開始前
  IMPORT_WAITING: 3,
  // 紐付け：インポート完了、紐付け開始前
  BIND_WAITING: 4,
  // メンテナンス：紐付け完了、資料作成前
  DATA_MAINTENANCE: 5,
  // 確認中：資料作成完了、利用開始前
  DATA_CHECKING: 6,
  // 利用中
  USING: 7,
  // 利用終了
  SUSPENDED: 8,
};
