/**
 * queueable_type に対応する権限・リンク先の設定
 * 操作ログの operationable_type も適用
 * name は LinkCaution.vue で使用
 */
const LOG_PARAMS = {
  Fair: {
    permissionName: "fairs",
    name: "フェア",
    path: "/fair-master-edit/",
  },
  FairList: {
    permissionName: "fairs",
    name: "フェア",
    path: "/fair-master-list/",
  },
  FairOrder: {
    permissionName: "fairs",
    name: "フェア 並び替え",
    path: "/fair-master-list/?order_media_id=",
  },
  FairSchedule: {
    permissionName: "fairs",
    name: "フェア 開催日設定",
    path: "/fair-schedule/",
  },
  FairStock: {
    permissionName: "fairs",
    name: "フェア 残席設定",
    path: "/fair-schedule/stock",
  },
  FairDaily: {
    permissionName: "fairs",
    name: "フェア 日毎設定",
    path: "/fair-schedule/daily",
  },
  FairRecommended: {
    permissionName: "fairs",
    name: "イチオシ",
    path: "/fair-schedule/chargingRecommended",
  },
  FairRepresent: {
    permissionName: "fairs",
    name: "フェア オススメ設定",
    path: "/fair-schedule/recommended",
  },
  Benefit: {
    permissionName: "benefits",
    name: "フェア 特典",
    path: "/benefit/?edit#b-",
  },
  HallBenefit: {
    permissionName: "benefits",
    name: "式場特典",
    path: "/benefit/",
  },
  BenefitList: {
    permissionName: "benefits",
    name: "特典",
    path: "/benefit",
  },
  Plan: {
    permissionName: "plans",
    name: "プラン",
    path: "/plan-detail/",
  },
  PlanList: {
    permissionName: "plans",
    name: "プラン",
    path: "/plan-list/",
  },
  PlanOrder: {
    permissionName: "plans",
    name: "プラン 並び替え・オススメ設定",
    path: "/plan-list/",
  },
  ImageList: {
    permissionName: "images",
    name: "画像",
    path: "/image-list/",
  },
  Image: {
    permissionName: "images",
    name: "画像",
    path: "/image-detail/",
  },
  HallSetting: {
    permissionName: "settings",
    name: "式場基本設定",
    path: "/hall-init-setting/",
  },
  HpSetting: {
    permissionName: "settings",
    name: "コレモネっと設定",
    path: "/hp-setting/",
  },
  Venue: {
    permissionName: "settings",
    name: "式場基本設定 その他の会場",
    path: "/hall-init-setting/#venue-",
  },
  FairGroup: {
    permissionName: "settings",
    name: "式場基本設定 フェアグループ",
    path: "/hall-init-setting/#fair-group",
  },
  FairGroupOrder: {
    permissionName: "settings",
    name: "式場基本設定 フェアグループ並び替え",
    path: "/hall-init-setting/#fair-group-order",
  },
  ImageTag: {
    permissionName: "settings",
    name: "画像 タグ",
    path: "/hall-init-setting/#image-tag",
  },
  MediaAccountConnect: {
    permissionName: "mediaAccounts",
    name: "媒体",
    path: "/media-account/",
  },
  User: {
    permissionName: "users",
    name: "アカウント設定",
    path: "/master-account/",
  },
  NewInfo: {
    permissionName: "hallInfo",
    name: "新着情報",
    path: "/whats-new/",
  },
  HallInfoNewInfo: {
    permissionName: "hallInfo",
    name: "新着情報",
    path: "/whats-new/",
  },
  HallInfo: {
    permissionName: "hallInfo",
    name: "式場情報",
    path: "/whats-new/?hall_info_id=",
  },
};
const LOG_ACTIONS = {
  created: "作成",
  updated: "更新",
  deleted: "削除",
  linked: "連携",
};

const hasLogParam = (type) => Object.keys(LOG_PARAMS).includes(type);

export const getLogParam = (type) => {
  if (!hasLogParam(type)) {
    return {
      permissionName: null,
      name: null,
      path: null,
    };
  }
  return LOG_PARAMS[type];
};

export const getLogPermissionName = (type) => {
  const param = getLogParam(type);
  return param.permissionName;
};

// トップ - 連携状態のタイトルで使用 // top\link\LinkCaution.vue
// ログのタイトルは logs[].header で返された値を表示する様に変更
export const getLogTitle = (type, action = null) => {
  const param = getLogParam(type),
    name = param.name;
  if (!action) {
    return name;
  }
  return `${name}${LOG_ACTIONS[action]}`;
};

export const getLogPath = (type) => {
  const param = getLogParam(type);
  return param.path;
};
