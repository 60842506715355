import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "media/accounts";
  const { instance, index, show } = Repository(`${hallId}/${resource}`, token);
  return {
    index,
    show,
    patch: (mediaId, payload) => instance.patch(mediaId, payload),
    connectToMedia: (payload) => instance.put("connect", payload),
  };
};
