/**
 * 式場アカウント：サービス設定用モーダル
 */
import API_CODE from "@/assets/common/ApiCode.js";
import { ServiceType } from "@/assets/hall-admin/service-type";
import { usageTargets as coremonetTargets } from "@/assets/hall-admin/coremonet-config";

import useHallRepository from "@/repositories/hall";

import { VenueState } from "@/store/hall-admin/status-types";

const serviceSetting = {
  namespaced: true,
  state: {
    targetHallId: null,
    isShowModal: false,
    // 式場使用を開始する対象媒体
    mediaIds: null,
  },
  getters: {
    targetHallId: ({ targetHallId }) => targetHallId,
    hall: ({ targetHallId }, g, rs, rootGetters) =>
      rootGetters["hallAdmin/hallInfo"](targetHallId),
    hallState: (s, getters, rs, rootGetters) =>
      rootGetters["hallAdmin/hallState"](getters.hall),
    serviceOption: (s, g, rs, rootGetters) =>
      rootGetters["hallAdmin/SystemFeatureType"],
    inUsageTarget: (s, getters) => getters.hall.in_usage_target || [],
    inPreparationTarget: (s, getters) =>
      getters.hall.in_preparation_target || [],
    isServiceSet: (s, getters) => (service) =>
      [...getters.inUsageTarget, ...getters.inPreparationTarget].includes(
        service
      ),
    isShowModal: ({ isShowModal }) => isShowModal,
    hallServices: (_, getters) => getters.hall.services || {},
    isServiceStarted: (_, getters) => (serviceType) => {
      const service = getters.hallServices[serviceType] || null;
      if (service === null) {
        return false;
      }
      return service.started_at !== null;
    },
    fairImportStarted: (_, getters) => {
      const serviceType = ServiceType.FAIR_IMPORT;
      return getters.isServiceStarted(serviceType);
    },
    planImportStarted: (_, getters) => {
      const serviceType = ServiceType.PLAN_IMPORT;
      return getters.isServiceStarted(serviceType);
    },
    newInfoImportStarted: (_, getters) => {
      const serviceType = ServiceType.NEW_INFO_IMPORT;
      return getters.isServiceStarted(serviceType);
    },
    hallBenefitImportStarted: (_, getters) => {
      const serviceType = ServiceType.HALL_BENEFIT_IMPORT;
      return getters.isServiceStarted(serviceType);
    },
    coremonetInUse: (_, getters) => {
      const inUsageTarget = getters.inUsageTarget;
      return inUsageTarget.some((target) => coremonetTargets.includes(target));
    },
  },
  mutations: {
    PREPARE_SERVICE_SETTING(state, { hallId, isShowModal }) {
      state.targetHallId = hallId;
      state.isShowModal = isShowModal;
    },
    RESET_SERVICE_SETTING(state) {
      state.targetHallId = null;
      state.isShowModal = false;
      state.mediaIds = null;
    },
    SET_MEDIA_IDS(state, mediaIds) {
      state.mediaIds = mediaIds;
    },
  },
  actions: {
    /**
     * モーダルを表示
     */
    prepareServiceSetting({ commit }, { hallId, mediaIds }) {
      commit("PREPARE_SERVICE_SETTING", { hallId, isShowModal: true });
      if (mediaIds) commit("SET_MEDIA_IDS", mediaIds);
    },
    /**
     * モーダルを非表示
     */
    resetServiceSetting({ commit }) {
      commit("RESET_SERVICE_SETTING");
    },
    /**
     * サービス設定
     */
    async setService({ state, getters, dispatch, commit }, services) {
      const hallState = getters["hallState"];
      if (hallState === VenueState.UNUSED) {
        const payload = {
          hall_id: state.targetHallId,
          media_ids: state.mediaIds,
          feature_types: services,
        };
        await dispatch("hallAdmin/addMasterAccount", payload, { root: true });
      } else {
        const payload = {
          hall_id: state.targetHallId,
          feature_types: services,
        };
        await dispatch("updateServiceFeature", payload);
      }

      commit("RESET_SERVICE_SETTING");
    },
    /**
     * サービス利用機能の更新
     */
    async updateServiceFeature({ dispatch, commit }, { hall_id, ...payload} ) {
      const mutation = "modal/setUpdateModal";
      const paramModal = { isShow: true, text: "更新中です…" };
      const root = { root: true };
      commit(mutation, paramModal, root);

      const { updateUsageFeatures } = useHallRepository();
      const { status } = await updateUsageFeatures(hall_id, payload);
      if (status !== API_CODE.response.success) {
        dispatch("modal/showErrorModal", { text: "利用サービスの更新に失敗しました", isShow: true }, root);
        return;
      }
      await dispatch("hallAdmin/getInitialData", null, root);
      dispatch("modal/showCompleteModal", null, root);
      commit("modal/setCompleteText", "利用サービスを更新しました", root);
    },
  },
};
export default serviceSetting;
