<template>
  <input-selectbox
    v-model="value"
    :is-required="isRequired(keyName)"
    :options="options"
    class="type-single"
  />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import InputSelectbox from "@/components/benefit/inputs/InputSelectbox.vue";

export default {
  components: {
    InputSelectbox,
  },
  data() {
    return {
      keyName: "type",
    };
  },
  computed: {
    ...mapGetters({
      benefitTypes: "benefitTypes",
      benefitTypeOptions: "benefitTypeOptions",
      optionLevel: "optionLevel",
      isRequired: "rules/isRequired",
    }),
    value: {
      get() {
        return Object.values(this.benefitTypes).find((value) => value !== null) || null;
      },
      set(value) {
        const type = parseInt(value);
        this.updateType({ type, mediaId: API_CODE.mediaCommon });
      },
    },
    options() {
      const groupType = 1;
      return this.benefitTypeOptions(groupType);
    },
  },
  methods: mapMutations({ updateType: `${types.BENEFIT_TYPE}` }),
};
</script>

<style lang="scss" scoped>
.type-single {
  align-self: center;
}
</style>
