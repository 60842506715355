<template>
  <animated-safe v-if="isSafe" />
  <animated-error v-else-if="isError" />
  <span
    v-else-if="isNote"
    class="material-icons circleNote"
    :class="iconClass"
    >{{ icon }}</span
  >
  <div v-else-if="isDelete" class="circleDelete">
    <span class="circleDelete_icon material-icons">delete</span>
    <p class="circleDelete_pop">
      <span class="circleDelete_popIcon material-icons">undo</span>
    </p>
  </div>
  <div v-else-if="isPrivate" class="circlePrivate">
    <span class="circlePrivate_icon material-icons">remove_circle</span>
  </div>
  <div v-else-if="isNext" class="circleNext">
    <p class="circleNext_finish">
      <span class="circleNext_finishWord">What’s</span>
      <span class="circleNext_finishWord">next??</span>
    </p>
    <p class="circleNext_cover" :class="iconClass">
      <span class="circleNext_icon material-icons">swap_horiz</span>
    </p>
  </div>
</template>

<script>
import { ModalColorType } from "@/assets/modal/modal-color-type";

import AnimatedSafe from "@/components/atoms/common/AnimatedSafe.vue";
import AnimatedError from "@/components/atoms/common/AnimatedError.vue";

export default {
  components: {
    AnimatedSafe,
    AnimatedError,
  },
  props: {
    isSafe: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isNote: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    isNext: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "textsms",
    },
    colorType: {
      type: Number,
      default: null,
    },
  },
  computed: {
    iconClass() {
      const classNames = {
        [ModalColorType.WARNING]: "warning",
        [ModalColorType.ALERT]: "alert",
      };
      const className = classNames[this.colorType];
      return className || null;
    },
  },
};
</script>

<style scoped lang="scss">
.circleNote {
  color: $COLOR_KEY;
  font-size: 64px;
  margin-bottom: $GRID * 0.5;

  &.alert {
    color: $COLOR_ALERT;
  }

  &.warning {
    color: $COLOR_WARNING;
  }
}
.circlePrivate {
  &_icon {
    animation: jump 4s linear 0.5s forwards infinite;
    color: $COLOR_ALERT;
    font-size: 58px;
    margin: $GRID 0;
  }
}
.circleNext {
  margin: 24px auto 16px;
  position: relative;

  &_icon {
    animation: worry 4s ease-out 0.5s forwards infinite;
    color: #fff;
    font-size: 50px;
  }

  &_finish {
    color: $COLOR_SUB_TEXT;
    font-size: 10px;
    left: 0;
    position: absolute;
    top: -2.5em;
    width: 100%;
    &Word {
      animation: jump-finish 0.5s ease-out 0.5s forwards;
      display: inline-block;
      margin-left: 0.5em;
      opacity: 0;
      &:nth-child(1) {
        animation-delay: 0.65s;
      }
      &:nth-child(2) {
        animation-delay: 0.8s;
      }
    }
  }

  &_cover {
    align-items: center;
    background: $COLOR_KEY;
    border-radius: 50%;
    display: inline-flex;
    height: 64px;
    justify-content: center;
    width: 64px;

    &.alert {
      background-color: $COLOR_ALERT;
    }

    &.warning {
      background-color: $COLOR_WARNING;
    }
  }
}
.circleDelete {
  position: relative;
  &_icon {
    animation: shake 4s ease-out 0.5s forwards infinite;
    color: $COLOR_ALERT;
    font-size: 58px;
    margin: $GRID * 2.5 0 $GRID;
  }
  &_pop {
    align-items: center;
    background: $BG_COLOR_ALERT;
    box-shadow: 3px 3px 6px 2px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: flex;
    height: 60px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 3px;
    transform: translateX(60%);
    width: 60px;
    &Icon {
      color: rgba($COLOR_ALERT, 0.7);
      font-size: 36px;
      position: relative;
      &::before,
      &::after {
        content: "";
        height: calc(90% + 2px);
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform: rotate(-45deg) translate(60%, -60%);
        transform: rotate(-45deg) translate(60%, -60%);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        width: 3px;
      }
      &::before {
        background: $BG_COLOR_ALERT;
        margin-left: 1px;
      }
      &::after {
        background: rgba($COLOR_ALERT, 0.7);
        margin-top: 2px;
      }
    }
    &::before,
    &::after {
      background: $BG_COLOR_ALERT;
      border-radius: 50%;
      box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.15);
      content: "";
      display: block;
      position: absolute;
    }
    &::before {
      bottom: -12px;
      height: 12px;
      left: 0;
      width: 12px;
    }
    &::after {
      bottom: -20px;
      height: 8px;
      left: -8px;
      width: 8px;
    }
  }
}

@keyframes jump {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  5% {
    transform: scale(0.8, 1.2) translateY(-15%);
  }
  10% {
    transform: scale(1.2, 0.7) translateY(20%);
  }
  15% {
    transform: scale(0.9, 1.05) translateY(-10%);
  }
  20% {
    transform: scale(0.95, 1.1) translateY(-20%);
  }
  25% {
    transform: scale(0.95, 1.1) translateY(-10%);
  }
  30% {
    transform: scale(1.05, 0.9) translateY(5%);
  }
  35% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes jump-finish {
  0% {
    opacity: 0;
    transform: translateY(3px);
  }
  30% {
    opacity: 1;
    transform: translateY(-3px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shake {
  0% {
    transform: translate(0, 0) rotateZ(0deg);
  }
  2% {
    transform: translate(2px, -2px) rotateZ(5deg);
  }
  4% {
    transform: translate(0, 0) rotateZ(0deg);
  }
  6% {
    transform: translate(0, 0) rotateZ(-5deg);
  }
  8% {
    transform: translate(0, -2px) rotateZ(0deg);
  }
  10% {
    transform: translate(0, 0) rotateZ(0deg);
  }
  12% {
    transform: translate(2px, -2px) rotateZ(5deg);
  }
  14% {
    transform: translate(0, 0) rotateZ(0deg);
  }
  20% {
    transform: translate(0, 0) rotateZ(0deg);
  }
  22% {
    transform: translate(0, -2px) rotateZ(5deg);
  }
  24% {
    transform: translate(0, 0) rotateZ(0deg);
  }
  26% {
    transform: translate(0, -2px) rotateZ(-5deg);
  }
  28% {
    transform: translate(0, 0) rotateZ(0deg);
  }
  100% {
    transform: translate(0px, 0px) rotateZ(0deg);
  }
}

@keyframes worry {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(10deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  15% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  25% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
