import API_CODE from "@/assets/common/ApiCode";

const requiredItem = (required) => ({
  [API_CODE.media.zexy]: required,
  [API_CODE.media.mw]: required,
  [API_CODE.media.mynavi]: required,
  [API_CODE.media.homepage]: required,
});

const fairState = {
  characters: {
    description: {
      [API_CODE.media.zexy]: 50,
      [API_CODE.media.homepage]: 120,
    },
    title: {
      [API_CODE.media.zexy]: 50,
      [API_CODE.media.mw]: 50,
      [API_CODE.media.mynavi]: 500,
      [API_CODE.media.homepage]: 500,
    },
    valid_period_text: {
      [API_CODE.media.zexy]: 50,
      [API_CODE.media.homepage]: 100,
    },
  },
  isRequired: {
    media_ids: requiredItem(true),
    description: requiredItem(false),
    is_media_only: requiredItem(false),
    title: requiredItem(true),
    type: requiredItem(false),
    valid_from: requiredItem(false),
    valid_period_text: requiredItem(false),
    valid_until: requiredItem(false),
  },
  onlyMedia: {
    media_ids: [Object.values(API_CODE.media)],
    description: [API_CODE.media.zexy, API_CODE.media.homepage],
    image_id: [],
    is_limited_to_fair: [],
    is_limited_to_webtsuku: [],
    is_media_only: [API_CODE.media.mynavi],
    recommended: [],
    title: [
      API_CODE.media.zexy,
      API_CODE.media.mw,
      API_CODE.media.mynavi,
      API_CODE.media.homepage,
    ],
    type: [API_CODE.media.mynavi, API_CODE.media.homepage],
    valid_from: [API_CODE.media.zexy, API_CODE.media.homepage],
    valid_period_text: [API_CODE.media.zexy, API_CODE.media.homepage],
    valid_until: [API_CODE.media.zexy, API_CODE.media.homepage],
  },
  enabledByMedia: {
    media_ids: false,
    description: true,
    is_media_only: false,
    title: true,
    type: false,
    valid_from: true,
    valid_period_text: true,
    valid_until: true,
  },
};

export default fairState;
