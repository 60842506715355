import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";
import { ConfirmModalStatus } from "@/assets/modal/confirm-modal-status";
import { ModalColorType } from "@/assets/modal/modal-color-type";

import deepCopy from "@/lib/deepCopy";

import useBenefitRepository from "@/repositories/benefit";

import * as types from "@/store/benefit/mutationTypes";
import emptyPastDates from "@/store/benefit/helper/empty-past-dates";

const errorText = (error, action) => {
  console.log(error);
  if (error.status === API_CODE.response.sync) {
    return error.data.message;
  }
  return `特典の${action}に失敗しました　時間をおいて再度お試しください`;
};

const actions = {
  setSelectedData({ commit, dispatch, getters }, originalData) {
    const groupType = originalData.group_type;
    const template = getters.template(groupType);
    const isNew = originalData.id === null;
    const selectedData = isNew
      ? template
      : getters.mergeSelectedDataAndTemplate(template, originalData);

    // 値の初期化
    commit(types.SELECTED_DATA, selectedData);

    // isByMediaの初期化
    const isByMedia = getters["rules/isByMedia"](groupType);
    if (selectedData.id) {
      Object.keys(isByMedia).forEach((key) => (isByMedia[key] = getters.initialByMedia(key)));
    }

    // 式場特典の場合、特定の項目はisByMediaを常にtrueにしておく
    if (groupType === BENEFIT_GROUP_TYPE.hall) {
      const alwaysTrueKeys = [
        "is_media_only",
        "detail_type",
        "is_highlighted",
        "price_notes",
        "valid_from",
        "valid_until",
        "valid_period_text",
        "application_from",
        "application_until",
        "discount_type",
        "link_title",
        "link_url",
      ];
      Object.keys(isByMedia).forEach((key) => {
        const alwaysTrue = alwaysTrueKeys.includes(key);
        isByMedia[key] = alwaysTrue ? true : getters.initialByMedia(key);
      });
    }
    commit(types.IS_BY_MEDIA, isByMedia);

    // validationの初期化
    dispatch("validation/init");

    if (groupType === BENEFIT_GROUP_TYPE.wp) {
      return;
    }

    // ひとつだけ掲載用の場合、適用期間が日付／テキストのどちらになっているかを
    // 管理するためのstateを生成する
    const mediaIds = getters.mediaIdsByGroupType(groupType);
    const periodTypes = mediaIds.reduce((types, mediaId) => ({ ...types, [mediaId]: "date" }), {
      [API_CODE.mediaCommon]: "date",
    });

    // 編集の場合は、実際の値を見て日付／テキストのstateを上書きする
    if (selectedData.id !== null) {
      Object.keys(periodTypes).forEach((mediaId) => {
        const text = selectedData.valid_period_text[mediaId];
        periodTypes[mediaId] = text === null ? "date" : "text";
      });
    }
    commit(types.PERIOD_TYPES, periodTypes);
  },
  copyBenefitData({ commit, dispatch }, { data, group_type }) {
    const copy = deepCopy(data);
    if (data.group_type !== group_type) {
      dispatch("setSelectedData", {
        ...copy,
        group_type,
        description: null,
        notes: { ...data.description },
        valid_until: null,
        valid_period_text: null,
        valid_from: null,
      });
    } else {
      const { valid_until, publication_until, application_until } = copy;
      dispatch("setSelectedData", {
        ...copy,
        status: 2,
        published_at: [],
        scheduled_at: [],
        valid_until: emptyPastDates(valid_until),
        publication_until: emptyPastDates(publication_until),
        application_until: emptyPastDates(application_until),
      });
    }

    commit("usedFairs", data.used_fairs);
  },
  // 特典名の媒体個別設定を切り替え
  changeIsByMedia({ commit }, param) {
    commit("UPDATE_IS_BY_MEDIA", param);
  },
  // 編集IDを保存
  setTargetId({ commit }, id) {
    commit("SET_TARGET_ID", id);
  },
  // 限定表示またはおすすめ設定を有効にするの確認
  confirmEnableWpSetting({ dispatch }, param) {
    const { keyName } = param;
    const target = {
      limited: { toset: "フェア限定/Webつく限定表示", unset: "おすすめ設定" },
      recommended: {
        toset: "おすすめ設定",
        unset: "フェア限定/Webつく限定表示",
      },
    };
    const text = `「${target[keyName].toset}」にする場合、「${target[keyName].unset}」は解除されます\r\n変更しますか？`;
    const payload = {
      text,
      param,
      status: 0,
      actionName: "enableWpSetting",
      actionBtnText: "変更する",
      closeBtnText: "キャンセル",
    };
    dispatch("modal/showConfirmModal", payload);
  },
  // 限定表示またはおすすめ設定を有効にする
  enableWpSetting({ commit }, payload) {
    const { keyName, value } = payload;

    // 1. フェア限定／Webつく限定が有効になったら、おすすめ設定を無効にする
    // 2. オススメ設定が有効になったら、フェア限定／Webつく限定を無効にする
    const unsetMutations = {
      limited: [types.RECOMMENDED],
      recommended: [types.LIMITED_TO_FAIR, types.LIMITED_TO_WEBTSUKU],
    }[keyName];

    unsetMutations.forEach((mutation) => commit(mutation, false));

    // 設定を有効にする
    if (keyName === "limited") {
      commit(types.LIMITED_TO_FAIR, value === 1);
      commit(types.LIMITED_TO_WEBTSUKU, value === 2);
    } else {
      commit(types.RECOMMENDED, value);
    }

    commit("modal/setConfirmModal", { isShow: false }, { root: true });
  },

  /**
   * 保存処理
   */
  // 追加・更新 URL+パラメーター作成
  async saveData({ getters, dispatch }) {
    const targetId = getters.targetId;
    const actionName = targetId ? "putBenefitData" : "postBenefitData";
    await dispatch(actionName);
  },

  // 更新
  async putBenefitData({ getters, dispatch, commit }) {
    commit("modal/setUpdateModal", { isShow: true });
    const id = getters.targetId;
    const { update } = useBenefitRepository();
    const params = getters.benefitParamsForSave;
    const { data, status } = await update(id, params);
    if (status !== API_CODE.response.success) {
      const text = errorText({ data, status }, "更新");
      dispatch("modal/showErrorModal", { text, isShow: true });
      return;
    }
    commit(types.UPDATE_BENEFIT, { id, data });
    commit(types.RESET_OTHER_RECOMMENDED_WP, { ...data, id });
    commit("modal/setUpdateText");
    dispatch("modal/showCompleteModal");
    dispatch("utility/jobs/getJobsData");
  },

  // 追加
  async postBenefitData({ getters, dispatch, commit }) {
    commit("modal/setUpdateModal", { isShow: true });

    const { store } = useBenefitRepository();
    const params = getters.benefitParamsForSave;
    const { data, status } = await store(params);
    if (status !== API_CODE.response.created) {
      const text = errorText({ data, status }, "追加");
      dispatch("modal/showErrorModal", { text, isShow: true });
      return;
    }
    commit(types.ADD_BENEFIT, { data });
    commit(types.RESET_OTHER_RECOMMENDED_WP, data);
    commit("modal/setAddText");
    await dispatch("modal/showCompleteModal");
    await dispatch("showRelatedModal", data);
    dispatch("utility/jobs/getJobsData");
    dispatch("showNavigationModal", params);
  },

  // コピーして編集を実行した際に、紐づけられているフェアもコピーするかどうかを確認する
  async showRelatedModal({ commit, dispatch, getters }, { group_type, id: benefitId }) {
    if (group_type === BENEFIT_GROUP_TYPE.hall || getters.usedFairs.length === 0) {
      commit("usedFairs", []);
      return;
    }

    const subText =
      group_type === BENEFIT_GROUP_TYPE.fair
        ? `ゼクシィ・みんな・マイナビ向けの特典は一つのフェアに対して一つの特典しか紐づけできないため、
作成した特典とフェアを紐づけた場合、コピー元の特典とフェアの紐づけは解除されます。`
        : null;
    const payload = {
      subText,
      targetId: benefitId,
      text: `作成された特典にコピー元と同じフェアを紐づけますか？`,
      actionBtnText: "紐づける",
      actionName: "relateBenefitToFair",
      status: ConfirmModalStatus.PUBLISHING,
    };
    await dispatch("modal/showConfirmModal", payload);
    commit("usedFairs", []);
  },

  // コピーして編集を実行した際に、紐づけられているフェアも紐づける
  async relateBenefitToFair({ commit, dispatch, getters }, { id: benefit_id }) {
    commit("modal/setUpdateModal", { isShow: true });
    const { linkFairs } = useBenefitRepository();
    const fair_ids = getters.usedFairs.map(({ id }) => id);
    const { status } = await linkFairs(benefit_id, { benefit_id, fair_ids });
    if (status !== API_CODE.response.success) {
      const text = "フェアの紐づけに失敗しました。";
      dispatch("modal/showErrorModal", { text, isShow: true });
      return;
    }

    const apiUrl = "/benefits";
    const initialData = await dispatch("common/getInitialData", { apiUrl });
    dispatch("setInitialData", initialData);
    commit("modal/setUpdateText");
    await dispatch("modal/showCompleteModal");
  },

  showNavigationModal(
    { getters, dispatch },
    { group_type, media_ids, is_limited_to_fair, is_limited_to_webtsuku }
  ) {
    // 式場特典を利用できない場合は誘導モーダルを表示しない
    const enabledHallBenefits = getters.enabledHallBenefits;
    if (!enabledHallBenefits) {
      return;
    }

    // フェア特典(ゼクシィ・みんな・マイナビ)の場合は誘導モーダルを表示しない
    if (group_type === BENEFIT_GROUP_TYPE.fair) {
      return;
    }

    // ウエパ・KSM用の特典の場合、以下のいずれかの条件を満たす場合は誘導モーダルを表示しない
    // 1. 掲載媒体でウエパが選択されていない
    // 2. フェア限定・Webつく限定が選択されている
    const wpId = API_CODE.media.wp;
    const isWp = group_type === BENEFIT_GROUP_TYPE.wp;
    const selectedWp = media_ids.includes(wpId);
    const limited = is_limited_to_fair[wpId] || is_limited_to_webtsuku[wpId];
    const needBreak = !selectedWp || limited;
    if (isWp && needBreak) {
      return;
    }

    const targetId = isWp ? wpId : API_CODE.mediaCommon;
    const wpText = `式場特典の並び替えをする場合は、
引き続き並び替え設定をしてください`;
    const hallText = `登録した式場特典はまだ媒体に公開されていません。
引き続き公開設定をしてください`;
    const payload = {
      targetId,
      text: isWp ? wpText : hallText,
      actionBtnText: "設定する",
      actionName: "guideToPublishModal",
      autoClose: true,
      status: ConfirmModalStatus.PUBLISHING,
      colorType: isWp ? null : ModalColorType.WARNING,
    };
    dispatch("modal/showConfirmModal", payload);
  },
};

export default actions;
