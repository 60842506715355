<template>
  <modal-window
    :is-error="true"
    :close-text="closeText"
    @close="$emit('cancel')"
  >
    <template #text>
      <animated-icon :is-error="true" />
      <p class="text errorText">{{ text }}</p>
      <p v-if="subText" class="text errorSubText">{{ subText }}</p>
    </template>
    <template #footerBtn v-if="buttonText">
      <action-button
        :is-large="true"
        :text="buttonText"
        @clickHandler="$emit('submit')"
      />
    </template>
  </modal-window>
</template>

<script>
import ModalWindow from "@/components/molecules/common/ModalWindow.vue";
import AnimatedIcon from "@/components/atoms/common/AnimatedIcon.vue";
import ActionButton from "@/components/atoms/common/ActionButton.vue";

export default {
  components: {
    ModalWindow,
    AnimatedIcon,
    ActionButton,
  },
  props: {
    closeText: {
      type: String,
      default: "閉じる",
    },
    text: {
      type: String,
      default: null,
    },
    subText: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  white-space: pre-wrap;
}
.errorSubText {
  font-size: 12px;
  font-weight: normal;
  color: $COLOR_TEXT;
  margin-top: $GRID;
}
</style>
