import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

import * as types from "@/store/benefit/mutationTypes";
import hasCoremonet from "@/store/benefit/helper/has-coremonet";

const Validation = {
  namespaced: true,
  state: {
    description: {},
    notes: {},
    title: {},
    type: {},
    valid_from: {},
    valid_until: {},
    valid_period_text: {},
    publication_from: {},
    publication_until: {},
    application_from: {},
    application_until: {},
    detail_type: {},
    discount_type: {},
    price_notes: {},
    link_titles: {},
    link_urls: {},
  },
  getters: {
    isAllValid: (state, g, rs, rootGetters) => {
      // 特典種別に応じて判定が必要な項目を取得する
      const groupType = rootGetters.groupType;
      const validList = Object.entries(state).filter(([key, _]) => {
        if (groupType === BENEFIT_GROUP_TYPE.fair) {
          return key !== "valid_from";
        } else if (groupType === BENEFIT_GROUP_TYPE.wp) {
          return key !== "valid_period_text";
        }
        return true;
      });

      // 判定
      // (コレモネっとが「使用する特典種類」に含まれない場合、バリデーション対象媒体からコレモネっとを除外)
      const mediaIdsByGroupType = rootGetters.mediaIdsByGroupType(groupType);
      const mediaIds = hasCoremonet(mediaIdsByGroupType)
        ? [...rootGetters.mediaIds]
        : rootGetters.mediaIds.filter(
            (mediaId) => mediaId !== API_CODE.media.homepage
          );

      const isAllValid = validList.every(([key, isValid]) => {
        const isByMedia = rootGetters.isByMedium(key);
        const checkMedia = isByMedia ? mediaIds : [API_CODE.mediaCommon];
        const result = checkMedia.every((mediaId) => isValid[mediaId]);
        return result;
      });
      return isAllValid;
    },
  },
  mutations: {
    [types.BENEFIT_TYPE](state, { mediaId, isValid }) {
      state.type = {
        ...state.type,
        [mediaId]: isValid,
      };
    },
    [types.TITLE](state, { mediaId, isValid }) {
      state.title = {
        ...state.title,
        [mediaId]: isValid,
      };
    },
    [types.DESCRIPTION](state, { mediaId, isValid }) {
      state.description = {
        ...state.description,
        [mediaId]: isValid,
      };
    },
    [types.NOTES](state, { mediaId, isValid }) {
      state.notes = {
        ...state.notes,
        [mediaId]: isValid,
      };
    },
    [types.PERIOD_FROM](state, { mediaId, isValid }) {
      state.valid_from = {
        ...state.valid_from,
        [mediaId]: isValid,
      };
    },
    [types.PERIOD_UNTIL](state, { mediaId, isValid }) {
      state.valid_until = {
        ...state.valid_until,
        [mediaId]: isValid,
      };
    },
    [types.PERIOD_TEXT](state, { mediaId, isValid }) {
      state.valid_period_text = {
        ...state.valid_period_text,
        [mediaId]: isValid,
      };
    },
    [types.PUBLICATION_FROM](state, { mediaId, isValid }) {
      state.publication_from = {
        ...state.publication_from,
        [mediaId]: isValid,
      };
    },
    [types.PUBLICATION_UNTIL](state, { mediaId, isValid }) {
      state.publication_until = {
        ...state.publication_until,
        [mediaId]: isValid,
      };
    },
    [types.APPLICATION_FROM](state, { mediaId, isValid }) {
      state.application_from = {
        ...state.application_from,
        [mediaId]: isValid,
      };
    },
    [types.APPLICATION_UNTIL](state, { mediaId, isValid }) {
      state.application_until = {
        ...state.application_until,
        [mediaId]: isValid,
      };
    },
    [types.DISCOUNT_TYPE](state, { mediaId, isValid }) {
      state.discount_type = {
        ...state.discount_type,
        [mediaId]: isValid,
      };
    },
    [types.DETAIL_TYPE](state, { mediaId, isValid }) {
      state.detail_type = {
        ...state.detail_type,
        [mediaId]: isValid,
      };
    },
    [types.PRICE_NOTES](state, { mediaId, isValid }) {
      state.price_notes = {
        ...state.price_notes,
        [mediaId]: isValid,
      };
    },
    [types.LINK_TITLE](state, { mediaId, isValid }) {
      state.link_titles[mediaId] = isValid;
    },
    [types.LINK_URL](state, { mediaId, isValid }) {
      state.link_urls[mediaId] = isValid;
    },
  },
  actions: {
    init({ state, rootGetters }) {
      // 初期化
      state.description = {};
      state.notes = {};
      state.title = {};
      state.type = {};
      state.valid_from = {};
      state.valid_until = {};
      state.valid_period_text = {};
      state.publication_from = {};
      state.publication_until = {};
      state.application_from = {};
      state.application_until = {};
      state.detail_type = {};
      state.discount_type = {};
      state.price_notes = {};
      state.link_titles = {};
      state.link_urls = {};

      // 特典種別に応じて初期値を設定する
      const groupType = rootGetters.groupType;
      const mediaIds = rootGetters.mediaIdsByGroupType(groupType);
      const initialValue = mediaIds.reduce(
        (initialValue, mediaId) => ({ ...initialValue, [mediaId]: true }),
        { [API_CODE.mediaCommon]: true }
      );
      Object.keys(state).forEach((key) => {
        state[key] = initialValue;
      });
    },
  },
};

export default Validation;
