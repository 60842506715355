const mutations = {
  /*
   * 取得データ
   ***********************************/
  /**
   * ダウンロードファイル一覧
   * @param {Object} payload manualconfig.json の内容
   */
  async SET_INITIAL_DATA(state, payload) {
    state.data = payload;
  },
  /*
   * モーダル
   ***********************************/
  SET_MODAL_SHOW(state, isShow) {
    state.isShow = isShow;
  },
};
export default mutations;
