<template>
  <div class="importNav">
    <div class="importNav_name">
      <p class="id fontNum">{{ displayInfo.hall_id }}</p>
      <p class="text">{{ displayInfo.hall_name }}</p>
    </div>
    <div class="importNavTop">
      <ul class="importNavList">
        <li v-for="(v, i) in menus" :key="i" class="importNavList_item" :class="classType(v.dir)">
          <button
            class="link"
            :class="{ isDisabled: !v.isAuthorized || !needShowPage(v.dir) }"
            @click="link(v.dir)"
          >
            <span>{{ v.name }}</span>
          </button>
        </li>
        <button class="hall" title="式場一覧" @click="linkAdmin('hall-admin')">
          <span>式場一覧</span>
        </button>
      </ul>
    </div>
    <div v-if="!loading">
      <page-title v-if="currentPage" :icon="currentPage.icon" :title="currentPage.name" />
      <import-tab :order="mediaOrder(pageDir)" />
      <slot />
    </div>
  </div>
</template>

<script>
import { IMPORT_PAGE_CONFIG } from "@/assets/common/PageConfig.js";

import ImportTab from "@/components/molecules/common/ImportTab.vue";
import PageTitle from "@/components/atoms/common/PageTitle.vue";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
  components: {
    ImportTab,
    PageTitle,
  },
  props: {
    pageDir: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pages: IMPORT_PAGE_CONFIG,
      isLastPage: false,
    };
  },
  computed: {
    ...mapState({
      loading: "common/loading",
      isComplete: "import/isComplete",
    }),
    ...mapGetters({
      displayInfo: "common/displayInfo",
      isAuthorized: "common/isAuthorized",
      mediaOrder: "import/mediaOrder",
      needShowPage: "import/needShowPage",
      isFairPage: "import/isFairPage",
      isPlanPage: "import/isPlanPage",
    }),
    currentPage() {
      let current = this.pages.filter((v) => location.pathname.indexOf(v.dir) !== -1);
      return current[0] || null;
    },
    menus() {
      return this.pages.map((v) => {
        v.isAuthorized = !v.permission || this.isAuthorized(v.permission);
        return v;
      });
    },
  },
  created: async function () {
    await this.getLoginAccount();
  },
  methods: {
    ...mapActions("common", ["getLoginAccount"]),
    ...mapActions({ showConfirm: "modal/showConfirmModal" }),
    classType(dir) {
      return {
        isCurrent: this.isComplete
          ? this.pages[this.pages.length - 1].dir == dir
          : location.pathname.indexOf(dir) !== -1,
      };
    },
    linkAdmin(dir) {
      const payload = {
        text: "管理ページに移動しますか？\r\n設定は完了していません",
        status: 0,
        actionName: "import/moveMasterPage",
        actionBtnText: "移動する",
        closeBtnText: "キャンセル",
      };
      this.showConfirm(payload);
    },
    link(dir) {
      if (dir) {
        if (this.pages[this.pages.length - 1].dir == dir) {
          const payload = {
            text: "紐付け処理を開始しますか？",
            status: 3,
            actionName: "import/associate",
            actionBtnText: "開始する",
            closeBtnText: "キャンセル",
          };
          this.showConfirm(payload);
        } else {
          let path = "/" + dir;
          window.location.href = path;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.importNav {
  background: $BG_COLOR_FLOOR;
  padding: $GRID;
  &Top {
    align-items: center;
    display: flex;
    margin-bottom: $GRID;
  }
  &_name {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: $GRID * 1.5;
    margin-bottom: $GRID * 0.5;
    white-space: nowrap;
    .id {
      margin-right: $GRID;
      &:before {
        content: "ID:";
        padding-right: $SUB_GRID;
      }
    }
  }
  &List {
    align-items: center;
    display: flex;
    list-style: none;
    &_item {
      align-items: center;
      display: flex;
      justify-content: center;
      &:not(:last-of-type)::after {
        border-top: 1px solid $COLOR_SUB_LIGHT_TEXT;
        content: "";
        top: $GRID * 0.75;
        width: $GRID;
      }
      &.isCurrent .link {
        border-color: $COLOR_SUBMIT;
        background: $COLOR_SUBMIT;
      }
    }
    .link,
    .hall {
      background: $COLOR_KEY;
      border: 1px solid $COLOR_KEY;
      border-radius: 2px;
      color: #fff;
      font-size: 12px;
      height: $GRID * 1.5;
      width: $GRID * 8.75;
      &:hover {
        opacity: 0.7;
      }
    }
    .link {
      &.isDisabled {
        box-shadow: none;
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .hall {
      @include btn(rgba($COLOR_SUB_TEXT, 0.1));
      background: $BG_COLOR_FLOOR;
      border: 1px solid $COLOR_SUB_TEXT;
      color: $COLOR_SUB_TEXT;
      top: $GRID * 3;
      right: $GRID;
      position: fixed;
    }
  }
  .importTab {
    right: $GRID;
    position: fixed;
    top: $GRID * 5.5 + $SUB_GRID;
  }
  .pageTitle {
    margin-bottom: $GRID * 0.5;
  }
}
</style>
