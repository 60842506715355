import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_TYPE from "@/assets/common/benefit-type";
import HALL_BENEFIT_TYPE from "@/assets/benefit/hall-benefit-type";

import { httpClient } from "@/lib/httpClient";

import useBenefitRepository from "@/repositories/benefit";

// stateの値は初期設定時以外は基本的に編集しない
// 実際にモーダルで変更する値はselected-hall-benefit.jsで管理する
export const state = {
  [API_CODE.media.zexy]: {
    media_id: [API_CODE.media.zexy],
    [HALL_BENEFIT_TYPE.visit]: {
      id: null,
      term_type: 1,
      description: null,
      benefits: [],
      link_status: null,
    },
    [HALL_BENEFIT_TYPE.contract]: {
      id: null,
      term_type: 1,
      description: null,
      benefits: [],
      link_status: null,
    },
  },
  [API_CODE.media.mw]: {
    media_id: [API_CODE.media.mw],
    [HALL_BENEFIT_TYPE.visit]: {
      id: null,
      term_type: 1,
      description: null,
      benefits: [],
      link_status: null,
    },
    [HALL_BENEFIT_TYPE.contract]: {
      id: null,
      term_type: 1,
      description: null,
      benefits: [],
      link_status: null,
    },
  },
  [API_CODE.media.mynavi]: {
    media_id: [API_CODE.media.mynavi],
    [HALL_BENEFIT_TYPE.common]: {
      id: null,
      term_type: 1,
      description: null,
      benefits: [],
      link_status: null,
    },
  },
  max_registrable_num: {
    [API_CODE.media.zexy]: {
      [BENEFIT_TYPE.visit]: 0,
      [BENEFIT_TYPE.contract]: 0,
    },
    [API_CODE.media.mw]: {
      [BENEFIT_TYPE.visit]: 0,
      [BENEFIT_TYPE.contract]: 0,
    },
    [API_CODE.media.mynavi]: {
      [BENEFIT_TYPE.visit]: 0,
      [BENEFIT_TYPE.contract]: 0,
    },
  },
  hasChangedWp: false,
};

export const actions = {
  onErrorProcess({ dispatch }, { data, status, defaultMessage }) {
    const hasValidationError = status === API_CODE.response.sync;
    const text = hasValidationError ? data.message : defaultMessage;
    const payload = { text, isShow: true };
    dispatch("modal/showErrorModal", payload, { root: true });
  },
  async fetchHallBenefits({ commit, dispatch }) {
    const option = { root: true };
    const payload = { apiUrl: "/hall-benefits" };
    const { data } = await dispatch("common/getInitialData", payload, option);
    if (data) {
      Object.entries(data.data).forEach(([mediaId, benefit]) => {
        commit("hallBenefitByMedia", { mediaId, benefit });
      });
      commit("maxRegistrableNum", data.master.max_registrable_num);
    } else {
      const defaultMessage =
        "式場特典の取得に失敗しました　時間をおいて再度お試しください";
      dispatch("onErrorProcess", { defaultMessage });
    }
  },
  async putHallBenefits(
    { commit, dispatch, getters },
    { mediaId, benefitType }
  ) {
    const option = { root: true };

    commit("modal/setUpdateModal", { isShow: true }, option);

    // 公開設定モーダルで掲載期間が編集されている場合は、特典テンプレートを先に更新する
    // テンプレートの更新に失敗した場合は処理を中断する
    const changedTemplateList = getters.changedTemplateList(
      mediaId,
      benefitType
    );
    const success = await dispatch("putTemplateList", changedTemplateList);
    if (!success) {
      return;
    }

    const { id } = getters.hallBenefit(mediaId, benefitType);
    const params = getters.hallBenefitParams(benefitType);
    const url = `/hall-benefits/${id}`;
    const { data, status } = await httpClient.put(url, params);

    if (status === API_CODE.response.success) {
      await dispatch("setHallBenefits");
      commit("modal/setUpdateText", null, option);
      dispatch("modal/showCompleteModal", null, option);
      return;
    }

    const defaultMessage =
      "式場特典の公開に失敗しました　時間をおいて再度お試しください";
    dispatch("onErrorProcess", { data, status, defaultMessage });
  },

  async putHallBenefitsZexy({ commit, dispatch, getters }) {
    const option = { root: true };
    commit("modal/setUpdateModal", { isShow: true }, option);

    const mediaId = API_CODE.media.zexy;
    const templateList = [
      ...getters.changedTemplateList(mediaId, HALL_BENEFIT_TYPE.contract),
      ...getters.changedTemplateList(mediaId, HALL_BENEFIT_TYPE.visit),
    ];
    const succeedPutTemplates = await dispatch("putTemplateList", templateList);
    if (!succeedPutTemplates) {
      return false;
    }

    const types = [HALL_BENEFIT_TYPE.contract, HALL_BENEFIT_TYPE.visit];
    const promises = types.map((benefitType) => {
      const { id } = getters.hallBenefit(mediaId, benefitType);
      const params = getters.hallBenefitParams(benefitType);
      const url = `/hall-benefits/${id}`;
      return httpClient.put(url, params);
    });

    const responseList = await Promise.all(promises);
    const succeed = responseList.every(
      ({ status }) => status === API_CODE.response.success
    );
    if (succeed) {
      // 公開設定モーダルで設定した内容をテンプレートに反映する
      await dispatch("setHallBenefits");
      commit("modal/setUpdateText", null, option);
      dispatch("modal/showCompleteModal", null, option);
      return;
    }

    const { data, status } = responseList.find(
      ({ status }) => status !== API_CODE.response.success
    );
    const defaultMessage =
      "式場特典の公開に失敗しました　時間をおいて再度お試しください";
    dispatch("onErrorProcess", { data, status, defaultMessage });
  },
  // 公開設定モーダルで掲載期間が編集されている特典テンプレートを更新する
  async putTemplateList({ dispatch }, templateList) {
    const { patch } = useBenefitRepository();
    const promises = templateList.map(({ id, ...params }) =>
      patch(id, params)
    );

    const responseList = await Promise.all(promises);
    const succeed = responseList.every(
      ({ status }) => status === API_CODE.response.success
    );
    if (succeed) {
      return true;
    }

    const { data, status } = responseList.find(
      ({ status }) => status !== API_CODE.response.success
    );
    const defaultMessage = "掲載期間の変更に失敗しました";
    dispatch("onErrorProcess", { data, status, defaultMessage });
    return false;
  },
  // 公開設定モーダルで設定した内容をテンプレートに反映する
  async setHallBenefits({ dispatch }) {
    const option = { root: true };
    const url = { apiUrl: "/benefits" };
    const response = await dispatch("common/getInitialData", url, option);
    const { status } = response;
    const succeed = status === API_CODE.response.success;
    if (!succeed) return;

    dispatch("setInitialData", response, option);

    // 式場特典を再取得する
    await dispatch("fetchHallBenefits");
  },
};

export const getters = {
  hallBenefit: (state) => (mediaId, benefitType) => state[mediaId][benefitType],
  originalTermType: (_, getters) => (mediaId, benefitType) => {
    const benefit = getters.hallBenefit(mediaId, benefitType);
    return benefit.term_type;
  },
  originalDescription: (_, getters) => (mediaId, benefitType) => {
    const benefit = getters.hallBenefit(mediaId, benefitType);
    return benefit.description;
  },
  linkStatus: (_, getters) => (mediaId, benefitType) => {
    const benefit = getters.hallBenefit(mediaId, benefitType);
    return benefit.link_status;
  },
  benefits: (_, getters) => (mediaId, benefitType) => {
    const benefit = getters.hallBenefit(mediaId, benefitType);
    return benefit.benefits;
  },
  hasChanged: (_, getters) => (mediaId, benefitType) => {
    if (mediaId === API_CODE.media.wp) {
      return getters.hasChangedWp;
    }

    const origin = getters.hallBenefit(mediaId, benefitType);
    const edited = getters.hallBenefitParams(benefitType);
    const hasChangedTermType = origin.term_type !== edited.term_type;
    const hasChangedDescription = origin.description !== edited.description;
    const hasChangedBenefits =
      JSON.stringify(origin.benefits) !== JSON.stringify(edited.benefits);
    return hasChangedTermType || hasChangedDescription || hasChangedBenefits;
  },
  maxRegistrableNum:
    ({ max_registrable_num }) =>
    (mediaId, benefitType) => {
      if (mediaId === API_CODE.media.mynavi) {
        return max_registrable_num[mediaId][BENEFIT_TYPE.both];
      }
      const type = BENEFIT_TYPE[benefitType];
      return max_registrable_num[mediaId][type];
    },
  hasChangedWp: ({ hasChangedWp }) => hasChangedWp,
  // 式場特典IDから特典種別を逆引き
  benefitTypeByHallBenefit: (state) => (mediaId, hallBenefitId) => {
    const hallBenefits = state[mediaId];
    const benefitType = Object.keys(hallBenefits).find((benefitType) => {
      const { id } = hallBenefits[benefitType];
      return id === hallBenefitId;
    });
    return benefitType;
  },
};

export const mutations = {
  hallBenefitByMedia(state, { mediaId, benefit }) {
    state[mediaId] = benefit;
  },
  hallBenefitByType(state, { mediaId, benefitType, benefit }) {
    const benefitByMedia = {
      ...state[mediaId],
      [benefitType]: benefit,
    };
    state[mediaId] = benefitByMedia;
  },
  benefits(state, { mediaId, benefitType, benefits }) {
    const benefit = {
      ...state[mediaId][benefitType],
      benefits,
    };
    state[mediaId] = {
      ...state[mediaId],
      [benefitType]: benefit,
    };
  },
  maxRegistrableNum(state, num) {
    state.max_registrable_num = num;
  },
  hasChangedWp(state, hasChangedWp) {
    state.hasChangedWp = hasChangedWp;
  },
};
