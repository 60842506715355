<template>
  <div class="pageLink">
    <a class="link" :href="path">{{ text }}</a>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped lang="scss">
.pageLink {
  align-items: center;
  border-bottom: solid 1px $COLOR_DISABLE_TEXT;
  box-sizing: border-box;
  font-size: 12px;
  height: $GRID * 2;
  margin-bottom: $GRID * 2;
  .link {
    color: $COLOR_SUB_LIGHT_TEXT;
    text-decoration: none;
    &::before {
      content: "keyboard_arrow_left";
      font-family: "Material Icons";
      font-feature-settings: "liga";
      font-size: 22px;
      position: relative;
      top: 7px;
    }
  }
}
</style>
