<template>
  <benefit-form
    :title="title"
    :note="note"
    :need-by-media="true"
    :option-level="optionLevel([keyName])"
  >
    <by-media-input
      :key-name="keyName"
      :is-by-media="isByMedia"
      :title="title"
      @toggleByMedia="changeIsByMedia({ [keyName]: $event })"
    >
      <input-item-title v-if="!isByMedia" :media-id="mediaCommon" />
      <template v-if="isByMedia">
        <input-item-title
          v-for="mediaId in selectedOnlyMedia(keyName)"
          :key="mediaId"
          :media-id="mediaId"
        />
      </template>
    </by-media-input>
  </benefit-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import InputItemTitle from "@/components/benefit/inputs/InputItemTitle.vue";
import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import ByMediaInput from "@/components/benefit/presentation/ByMediaInput.vue";

export default {
  components: {
    InputItemTitle,
    BenefitForm,
    ByMediaInput,
  },
  data() {
    return {
      title: "特典名",
      keyName: "title",
    };
  },
  computed: {
    ...mapGetters({
      isByMedium: "isByMedium",
      optionLevel: "optionLevel",
      onlyMediaName: "onlyMediaName",
      needNote: "needNote",
      selectedOnlyMedia: "selectedOnlyMedia",
    }),
    isByMedia() {
      return this.isByMedium(this.keyName);
    },
    mediaCommon() {
      return API_CODE.mediaCommon;
    },
    note() {
      // TODO: 要調査・改めて正しい注釈の表示条件を検討する
      // if (this.needNote(this.keyName)) {
      //   return this.onlyMediaName(this.keyName);
      // }
      return null;
    },
  },
  methods: mapActions({ changeIsByMedia: "changeIsByMedia" }),
};
</script>
