<template>
  <div v-if="benefitImages.length" class="benefit__image">
    <p v-for="image in benefitImages" :key="image.id">
      <span v-if="Number(image.mediaId)" class="benefit__image-media">
        {{ image.mediaName }}
      </span>
      <benefit-img
        :path="image.thumb"
        height="64px"
        width="64px"
        class="benefit__image-img"
      />
    </p>
  </div>
</template>

<script>
import BenefitImg from "@/components/atoms/common/FairImg.vue";

import API_CODE from "@/assets/common/ApiCode";
import { mapGetters } from "vuex";

export default {
  components: {
    BenefitImg,
  },
  props: {
    groupType: {
      type: Number,
      default: 1,
    },
    // ウエパ用の特典で画像が選択されていない場合、[null, null]の形で配列が返却される場合がある
    imageIds: {
      type: [Array, Object],
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      image: "image",
      mediaShortName: "mediaShortName",
      onlyMedia: "rules/onlyMedia",
    }),
    benefitImages() {
      const imageIds = Array.isArray(this.imageIds) ? {} : this.imageIds;

      // 画像が媒体個別になっているかどうかを設定する
      const isByMedia = Object.entries(imageIds).every(([mediaId, imageId]) => {
        if (mediaId === API_CODE.mediaCommon) return imageId === null;
        return imageId != null;
      });

      // 1. 共通設定の場合は、対象のIDを0のみとする
      // 2. 媒体個別の場合は、対象のIDを設定可能な媒体とする
      const needMedia = isByMedia
        ? this.onlyMedia("image_id", this.groupType)
        : [API_CODE.mediaCommon];

      return Object.entries(imageIds)
        .filter(([mediaId, imageId]) => {
          const existValue = imageId !== null;
          return existValue && needMedia.includes(Number(mediaId));
        })
        .map(([mediaId, imageId]) => {
          const image = this.image(imageId);
          const thumb = image.urls ? image.urls.thumb : null;
          return {
            mediaId,
            thumb,
            mediaName: this.mediaShortName(mediaId),
          };
        });
    },
  },
};
</script>

<style scoped lang="scss">
.benefit__image {
  display: flex;
  gap: $SUB_GRID;

  &-media {
    font-size: $FONT_SIZE_SS;
  }
}
</style>
