/**
 * 配列内に共通で含まれる要素の配列と
 * 部分的に含まれる要素の配列を返す
 * @param {} arrays
 */

export const compareArrays = arrays => {
  let common = arrays[0];
  let some = [];
  arrays.forEach(v => {
    const c = compareArray(common, v);
    common = c.common;
    c.some.forEach(v2 => {
      if (!some.includes(v2)) some.push(v2);
    });
  });
  return {
    common,
    some: some.sort((a, b) => a - b)
  };
};

export const compareArray = (a, b) => {
  const common = [];
  const some = [];
  if (Array.isArray(a) && a.length > 0) {
    a.forEach((v, k) => {
      const i = b.indexOf(v);
      if (i === -1) {
        if (!some.includes(v)) some.push(v);
      } else {
        common.push(v);
      }
    });
  }
  if (Array.isArray(b)) {
    b.forEach(v => {
      if (!common.includes(v) && !some.includes(v)) some.push(v);
    });
  }
  return {
    common: common.sort((a, b) => a - b),
    some: some.sort((a, b) => a - b)
  };
};
