import getters from "@/store/common/getters";
import mutations from "@/store/common/mutations";
import actions from "@/store/common/actions";

const state = {
  isLogin: false,
  hallId: null,
  isError: false,
  data: null,
  loading: true,
  account: null,
  permissions: null,
  hall: {},
  user: {},
  master: {},
};

const commonModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default commonModule;
