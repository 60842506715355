<template>
  <div class="pageTitle">
    <i class="material-icons pageTitle_icon">{{ icon }}</i>
    <h1 class="pageTitle_title">{{ title }}</h1>
    <slot name="subInfo"></slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
  },
};
</script>

<style scoped lang="scss">
.pageTitle {
  align-items: center;
  color: $COLOR_KEY;
  display: flex;
  margin-bottom: $GRID;
  &_icon {
    font-size: 28px;
    margin: 3px $GRID * 0.5 $GRID * 0.25 0;
  }
  &_title {
    font-size: 24px;
    font-weight: normal;
    @media screen and (max-width: $MAIN_S_WIDTH) {
      font-size: 20px;
      font-weight: 500;
    }
  }
}
</style>
