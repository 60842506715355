<template>
  <label class="mediaCheck">
    <input-checkbox class="mediaCheck_input" v-model="selfIsSelected" />
    <span class="mediaCheck_text">{{ text }}</span>
  </label>
</template>

<script>
import InputCheckbox from "@/components/atoms/common/InputCheckbox.vue";

export default {
  components: {
    InputCheckbox,
  },
  props: {
    id: Number,
    value: Boolean,
    text: String,
  },
  computed: {
    selfIsSelected: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("update", this.id, value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.mediaCheck {
  cursor: pointer;
  font-size: 11px;
  margin: 0 $SUB_GRID $SUB_GRID 0;
  width: 100%;
  &_input {
    border: none;
    height: 0;
    overflow: hidden;
    width: 0;
    &.isChecked + .mediaCheck_text {
      background: $COLOR_KEY;
      border-color: $COLOR_KEY;
      color: #fff;
    }
    &:not(.isChecked) + .mediaCheck_text:hover {
      box-shadow: 0 0 4px 1px rgba(#ccc, 0.5) inset;
    }
  }
  &_text {
    border: 2px solid $COLOR_DISABLE_TEXT;
    box-sizing: border-box;
    color: $COLOR_DISABLE_TEXT;
    display: block;
    padding: 2px 0 1px;
    text-align: center;
  }
}
</style>
