<template>
  <div class="dateOverlay" :class="{ isCenter: isCenter }">
    <div class="date" :class="periodClass">
      <i class="material-icons">schedule</i>
      <p class="date_text" :class="periodClass" v-if="text && status >= 1">
        <span>{{ text }}</span>
        <span class="date_textSub">{{ statusMaster[status] }}</span>
        <span>：</span>
      </p>
      <span class="fontNum-l">{{ valid_until }}</span>
    </div>
  </div>
</template>

<script>
import formatDateText from "@/lib/formatDateText";

export default {
  props: {
    date: String,
    isCenter: {
      type: Boolean,
      default: false,
    },
    text: String,
    status: Number,
    statusMaster: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    valid_until: function() {
      return formatDateText(this.date);
    },
    periodClass: function() {
      let c = "";
      if (this.status == 2) {
        c = "isEnd";
      } else if (this.status == 1) {
        c = "isSoon";
      }
      return c;
    },
  },
};
</script>

<style scoped lang="scss">
.date {
  &Overlay {
    align-items: flex-end;
    bottom: 0;
    box-shadow: 0px -70px 30px -35px rgba(0, 0, 0, 0.5) inset;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    width: 100%;
    &.isCenter {
      justify-content: center;
      .date {
        margin-left: 0;
      }
    }
  }
  & {
    align-items: center;
    border-radius: $BOX_RADIUS;
    color: #fff;
    display: inline-flex;
    font-size: 12px;
    height: 16px;
    margin: 0 0 8px 8px;
    padding: 0 8px 0 4px;
  }
  .material-icons {
    font-size: 14px;
    margin-right: 5px;
  }
  span {
    display: inline-block;
    letter-spacing: 0.06em;
    padding-top: 1px;
  }
  &.isSoon {
    background: $BG_PERIOD_WARNING;
  }
  &.isEnd {
    background: $BG_PERIOD_ALERT;
  }
  &_text {
    font-size: 11px;
    &Sub {
      margin-left: 0.5em;
    }
    .isSoon &,
    .isEnd & {
      font-weight: bold;
    }
  }
}
</style>
