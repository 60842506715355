import API_CODE from "@/assets/common/ApiCode";

// 特典情報を保存する際に、不要な媒体の情報を消し込む
// 1. 共通設定の場合は各媒体の情報を消す
// 2. 媒体個別の場合は共通の情報を消す
const deleteMediaValue = (params, isByMedia) => {
  const deletedParams = Object.entries(params).map(([key, param]) => {
    const byMedia = isByMedia[key];

    // 媒体個別設定が存在しない項目はスキップ
    if (byMedia === undefined) {
      return [key, param];
    }

    const needResetMedia = byMedia
      ? [API_CODE.mediaCommon]
      : Object.values(API_CODE.media);
    const deletedParam = { ...param };
    needResetMedia.forEach((mediaId) => (deletedParam[mediaId] = null));
    return [key, deletedParam];
  });
  return Object.fromEntries(deletedParams);
};

export default deleteMediaValue;
