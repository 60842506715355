/**
 * サーバー環境情報
 *
 */

const url = process.env.VUE_APP_LOCAL_URL;
const urlDev = process.env.VUE_APP_DEV_URL;
// ドメインリスト
export const SERVER_DOMAIN = {
  local: url,
  develop: urlDev,
  productionAdmin: "admin.core-mone.com", // ステージング環境
  production: ["core-mone.com", "www.core-mone.com"],
};
Object.freeze(SERVER_DOMAIN);
// API ベース URL リスト
const port = process.env.VUE_APP_API_PORT;
const apiUrlDev = process.env.VUE_APP_API_DEV_URL;
export const API_BASE_URL = {
  local: `http://${url}:${port}/api`,
  develop: `https://${apiUrlDev}/api`,
  productionAdmin: "https://api.admin.core-mone.com/api", // ステージング環境
  production: "https://api.core-mone.com/api",
};
Object.freeze(API_BASE_URL);

/**
 * サーバー確認用フラグ
 */
const hostname = location.hostname;
export const IS_DEVELOP_SERVER =
  hostname === SERVER_DOMAIN.local || hostname === SERVER_DOMAIN.develop;
export const IS_STAGING_SERVER = false;
export const IS_PRODUCTION_SERVER =
  SERVER_DOMAIN.production.includes(hostname) ||
  hostname === SERVER_DOMAIN.productionAdmin;

// デフォルト動作モード
const MODE = Object.keys(SERVER_DOMAIN).find(
  (mode) => SERVER_DOMAIN[mode] === hostname || mode === "production"
);

/**
 * サーバー設定を取得
 */
export const getServerConfig = () => {
  const config = {
    apiBaseUrl: API_BASE_URL[MODE],
    mode: MODE,
  };
  return config;
};
