<template>
  <div class="assistNav" :class="addClass">
    <slot name="assist" />
  </div>
</template>

<script>
export default {
  props: {
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    addClass() {
      return {
        isExpanded: this.isExpanded
      };
    }
  }
};
</script>

<style scoped lang="scss">
.assistNav {
  background: #fff;
}
</style>
