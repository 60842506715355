<template>
  <p class="telList">
    <input-number
      class="inputTel"
      v-model="num1"
      ref="num1"
      :is-required="isRequired"
      :max-digits="rules.digits[1].max"
      :min-digits="rules.digits[1].min"
      :need-validate="needValidate"
      :has-error="!isValidLength || hasError"
      :size="size"
      @check="checkEachValidation(1, $event)"
    />
    <span>-</span>
    <input-number
      class="inputTel"
      v-model="num2"
      ref="num2"
      :is-required="isRequired"
      :max-digits="rules.digits[2].max"
      :min-digits="rules.digits[2].min"
      :need-validate="needValidate"
      :has-error="!isValidLength || hasError"
      :size="size"
      @check="checkEachValidation(2, $event)"
    />
    <span>-</span>
    <input-number
      class="inputTel"
      v-model="num3"
      ref="num3"
      :is-required="isRequired"
      :max-digits="rules.digits[3].max"
      :min-digits="rules.digits[3].min"
      :need-validate="needValidate"
      :has-error="!isValidLength || hasError"
      :size="size"
      @check="checkEachValidation(3, $event)"
    />
  </p>
</template>

<script>
import InputNumber from "@/components/atoms/common/InputNumber.vue";
import TEL_NUMBER_RULES from "@/assets/validationRules/TelNumber.js";

export default {
  emits: ["check", "update:modelValue"],
  components: {
    InputNumber,
  },
  props: {
    modelValue: String,
    isRequired: {
      type: Boolean,
      default: false,
    },
    /*
     * バリデーションチェックをするか
     * - trueにすると、requiredじゃない場合でもValidかどうかを返すようになる
     * - isRequiredの場合は設定不要
     */
    needValidate: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: TEL_NUMBER_RULES,
      maxUnit: 3, // ハイフン区切りの番号群の数
      numbers: this.splitValue(this.modelValue),
      size: "ss",
      isValid: {
        1: false,
        2: false,
        3: false,
      },
      isValidLength: true,
    };
  },
  computed: {
    num1: {
      get() {
        return this.numbers[0];
      },
      set(value) {
        this.sendInput(0, value);
      },
    },
    num2: {
      get() {
        return this.numbers[1];
      },
      set(value) {
        this.sendInput(1, value);
      },
    },
    num3: {
      get() {
        return this.numbers[2];
      },
      set(value) {
        this.sendInput(2, value);
      },
    },
  },
  methods: {
    splitValue(str) {
      if (!str) {
        str = "";
      }
      let values = str.split("-");
      if (values.length < this.maxUnit) {
        for (let i = 0; i < this.maxUnit; i++) {
          if (!values[i]) {
            values[i] = "";
          }
        }
      }
      return values;
    },
    combineValue() {
      // 番号が3つ揃っていない場合はnullを返す
      for (let i = 0; i < 3; i++) {
        if (!this.numbers[i]) {
          return null;
        }
      }
      return this.numbers[0] + "-" + this.numbers[1] + "-" + this.numbers[2];
    },
    sendInput(i, value) {
      this.numbers[i] = value;
      const tel = this.combineValue();
      this.$emit("update:modelValue", tel);
      const unit = i + 1;
      if (unit < this.maxUnit && value.length >= this.rules.digits[unit].max) {
        this.$refs[`num${unit + 1}`].$el.focus();
      }
    },
    checkValidation() {
      let isValidUnit = true;
      if (this.needValidate || this.isRequired) {
        // 各入力欄
        for (let i = 1; i <= this.maxUnit; i++) {
          if (!this.isValid[i]) {
            isValidUnit = false;
            break;
          }
        }
      }
      // 全体の桁数(ハイフンを含む)
      this.isValidLength = true;
      const tel = this.combineValue();
      if (tel && tel.length > this.rules.maxLength) {
        this.isValidLength = false;
      }
      this.$emit("check", this.isValidLength && isValidUnit);
    },
    checkEachValidation(i, value) {
      this.isValid[i] = value;
      this.checkValidation();
    },
  },
};
</script>
