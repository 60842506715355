import { startOfToday } from "date-fns";

import isEarlierDate from "@/lib/isEarlierDate";

// 媒体ごとの日付情報の中から過去日になっているものをnullにする
const emptyPastDates = (dates) => {
  const today = startOfToday();
  const dateList = Object.entries(dates).map(([mediaId, targetDate]) => {
    const date = isEarlierDate(targetDate, today) ? null : targetDate;
    return [mediaId, date];
  });

  return Object.fromEntries(dateList);
};

export default emptyPastDates;
