<template>
  <benefit-form
    :title="title"
    :note="onlyMediaName(keyName)"
    :need-by-media="true"
    :option-level="optionLevel([keyName])"
  >
    <by-media-input
      :key-name="keyName"
      :is-by-media="isByMedia"
      :title="title"
      @toggleByMedia="toggleByMedia"
    >
      <input-item-period v-if="!isByMedia" :media-id="mediaCommon" />
      <template v-if="isByMedia">
        <input-item-period
          v-for="mediaId in selectedOnlyMedia(keyName)"
          :key="mediaId"
          :media-id="mediaId"
        />
      </template>
    </by-media-input>
  </benefit-form>
</template>

<script>
import InputItemPeriod from "@/components/benefit/inputs/InputItemPeriod.vue";
import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import ByMediaInput from "@/components/benefit/presentation/ByMediaInput.vue";

import API_CODE from "@/assets/common/ApiCode";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    InputItemPeriod,
    ByMediaInput,
    BenefitForm,
  },
  data() {
    return {
      title: "適用期間",
      keyName: "valid_from",
      mediaCommon: API_CODE.mediaCommon,
    };
  },
  computed: {
    ...mapGetters({
      isByMedium: "isByMedium",
      groupType: "groupType",
      showInput: "showInput",
      optionLevel: "optionLevel",
      onlyMediaName: "onlyMediaName",
      selectedOnlyMedia: "selectedOnlyMedia",
    }),
    isByMedia() {
      return this.isByMedium(this.keyName);
    },
  },
  methods: {
    ...mapActions({ changeIsByMedia: "changeIsByMedia" }),
    toggleByMedia(value) {
      this.changeIsByMedia({
        [this.keyName]: value,
        valid_until: value,
        valid_period_text: value,
      });
    },
  },
};
</script>
