import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "settings";
  const { instance, index } = Repository(`${hallId}/${resource}`, token);
  return {
    index,
    update: (payload) => instance.put("", payload),
    patch: (payload) => instance.patch("", payload),
  };
};
