<template>
  <div class="title">
    <div class="title__header">
      <p class="title__label">特典名</p>
      <p v-if="common !== null" class="title__common" :class="modifierClass">
        {{ common }}
      </p>
    </div>
    <ul v-if="common === null" class="title__by-media">
      <li
        v-for="({ value, mediaName }, index) in byMediaItems"
        class="title__media-item"
        :key="index"
      >
        <span class="title__media-name">{{ mediaName }}</span>
        <span class="title__media-value" :class="modifierClass">{{
          value
        }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";

export default {
  props: {
    title: {
      type: Object,
      default: () => ({}),
    },
    textExpand: {
      type: Boolean,
      default: false,
    },
    noBenefitType: {
      type: Boolean,
      default: false,
    },
    mediaIds: {
      type: Array,
      default: () => [],
    },
    media: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    common() {
      return this.title[API_CODE.mediaCommon] || null;
    },
    byMediaItems() {
      return Object.entries(this.title)
        .filter(([mediaId, _]) => this.mediaIds.includes(parseInt(mediaId)))
        .map(([mediaId, value]) => {
          return {
            mediaId: parseInt(mediaId),
            value,
            mediaName: this.media[mediaId].short_name,
          };
        });
    },
    modifierClass() {
      return {
        expand: this.textExpand,
        noBenefitType: this.noBenefitType,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;

  &__header {
    display: flex;
    color: $COLOR_KEY;
    font-size: 15px;
    font-weight: bold;
  }

  &__label {
    width: $GRID * 3.5;
  }

  &__common {
    flex: 1;
    word-break: break-all;
  }

  // 媒体個別
  &__by-media {
    margin-left: $GRID;
    list-style: none;
  }

  &__media-item {
    display: flex;
    font-weight: bold;
    line-height: 20px;
  }

  &__media-name {
    width: $GRID * 5;
    color: $COLOR_SUB_LIGHT_TEXT;
    font-size: 11px;
  }

  &__media-value {
    flex: 1;
    color: $COLOR_KEY;
    font-size: 13px;
    word-break: break-all;
  }

  .expand {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .noBenefitType {
    padding-right: $GRID * 2;
  }
}
</style>
