<template>
  <button
    type="button"
    class="btn"
    :class="className"
    :disabled="isDisabled"
    @click.stop="onClick()"
  >
    <slot>
      <span class="btnText">{{ text }}</span>
    </slot>
  </button>
</template>

<script>
export default {
  props: {
    clickHandler: Function,
    text: String,
    isLarge: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSave: {
      type: Boolean,
      default: false,
    },
    isSetting: {
      type: Boolean,
      default: false,
    },
    isCancel: {
      type: Boolean,
      default: false,
    },
    isAlert: {
      type: Boolean,
      default: false,
    },
    isAlertFill: {
      type: Boolean,
      default: false,
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
    isSub: {
      type: Boolean,
      default: false,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    isLongTextSelect: {
      type: Boolean,
      default: false,
    },
    isSecondaryFunction: {
      type: Boolean,
      default: false,
    },
    isImport: {
      type: Boolean,
      default: false,
    },
    isCheck: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    className: function () {
      return {
        isSave: this.isSave,
        isSetting: this.isSetting,
        isCopy: this.isCopy,
        isSub: this.isSub,
        isCancel: this.isCancel,
        isAlert: this.isAlert,
        isAlertFill: this.isAlertFill,
        isLarge: this.isLarge,
        isSmall: this.isSmall,
        isSelect: this.isSelect,
        isLongTextSelect: this.isLongTextSelect,
        isSecondaryFunction: this.isSecondaryFunction,
        isImport: this.isImport,
        isCheck: this.isCheck,
      };
    },
  },
  methods: {
    onClick: function () {
      this.$emit("clickHandler");
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  @include btn();
  background: $COLOR_KEY;
  border: none;
  border-radius: $GRID;
  box-shadow: $DEPTH_LV_0;
  color: #fff;
  display: block;
  font-size: 12px;
  padding: 3px 0;
  width: $GRID * 8.75;
  &Text {
    position: relative;
  }
  &.isLarge {
    border-radius: 20px;
    width: 187px;
  }
  &.isSmall {
    font-size: 10px;
    width: $GRID * 4;
  }
  &.isSave {
    background: $COLOR_SUBMIT;
    color: #fff;
    font-size: 14px;
    padding: 6px 0 5px;
    &.isLarge {
      padding: 9px 0 8px;
    }
  }
  &.isSecondaryFunction {
    background: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: $GRID;
    box-shadow: $DEPTH_LV_0;
    color: $COLOR_SUB_TEXT;
    width: 80px;
  }
  &.isSetting {
    background: transparent;
    border: 1px solid $COLOR_SUBMIT;
    color: $COLOR_SUBMIT;
    font-size: 14px;
    padding: 5px 0 4px;
    &::before {
      background: rgba($COLOR_SUBMIT, 0.1);
    }
    &.isLarge {
      padding: 9px 0 8px;
    }
  }
  &.isCopy,
  &.isSub {
    background: transparent;
    border: 1px solid $COLOR_KEY;
    box-shadow: none;
    color: $COLOR_KEY;
    &::before {
      background: rgba($COLOR_SUB_TEXT, 0.1);
    }
  }
  &.isCancel {
    background: #f7f7f7;
    color: $COLOR_SUB_TEXT;
    padding: 7px 0;
    &::before {
      background: rgba($COLOR_SUB_TEXT, 0.1);
    }
    &.isLarge {
      padding: 11px 0;
    }
  }
  &.isAlert {
    background: transparent;
    border: 1px solid $COLOR_ALERT;
    color: $COLOR_ALERT;
    font-size: 14px;
    padding: 5px 0 4px;
    &::before {
      background: rgba($COLOR_ALERT, 0.1);
    }
    &.isLarge {
      padding: 9px 0 8px;
    }
  }
  &.isAlertFill {
    background: $COLOR_ALERT;
    color: #fff;
    &::before {
      background: rgba(#fff, 0.25);
    }
  }
  &.isSelect {
    background: transparent;
    border: 1px solid $COLOR_SUB_TEXT;
    border-radius: $GRID;
    box-shadow: none;
    color: $COLOR_SUB_TEXT;
    width: 80px;
    &::before {
      background: rgba($COLOR_SUB_LIGHT_TEXT, 0.1);
    }
    &.isExtraLarge {
      width: $GRID * 9;
    }
    &.isLarge {
      width: $GRID * 7;
    }
    &.isSmall {
      width: $GRID * 4;
    }
  }
  &:disabled {
    box-shadow: none;
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
  &.isLongTextSelect {
    background: $BG_COLOR_BOX;
    border: none;
    border-radius: $GRID;
    color: $COLOR_SUB_TEXT;
    width: $GRID * 9;
  }
  &.isImport {
    // 通常:
    background: $COLOR_IMPORT_SELECTED;
    color: #fff;
    padding: 0;
    font-size: 12px;
    height: $GRID * 1.5;
    &.isLarge {
      font-size: 14px;
      height: $GRID * 3;
    }
    // 設定:
    &.isSetting {
      background: transparent;
      border: 1px solid $COLOR_IMPORT_SELECTED;
      color: $COLOR_IMPORT_SELECTED;
      &::before {
        background: rgba($COLOR_IMPORT_SELECTED, 0.1);
      }
    }
    // 保存:
    &.isSave {
      background: #fff;
      color: $COLOR_IMPORT_SELECTED;
      &::before {
        background: rgba($COLOR_IMPORT_SELECTED, 0.1);
      }
    }
    // キャンセル:
    &.isCancel {
      background: $COLOR_SUB_TEXT;
      color: #fff;
      &::before {
        background: rgba(#fff, 0.1);
      }
    }
    // 確認
    &.isCheck {
      background: $COLOR_IMPORT_CHECK;
      color: #fff;
      &::before {
        background: rgba(#fff, 0.1);
      }
    }
  }
}
</style>
