<template>
  <div class="fairImg" :class="{ noImg: !path }" :style="style">
    <img v-if="path" :src="path" loading="lazy" />
  </div>
</template>

<script>
export default {
  props: {
    path: String,
    height: {
      type: String,
      default: "100px",
    },
    width: {
      type: String,
      default: "100px",
    },
  },
  computed: {
    style: function () {
      return {
        height: this.height,
        width: this.width,
      };
    },  
  },
};
</script>

<style scoped lang="scss">
.fairImg {
  align-items: center;
  background: rgba($COLOR_KEY, 0.1);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  img {
    flex-shrink: 0;
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
  }
  &.noImg {
    &::after {
      color: rgba($COLOR_KEY, 0.8);
      content: "未設定";
      font-size: 12px;
    }
  }
}
img[lazy="loaded"] {
  animation: fadeIn 1.4s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
