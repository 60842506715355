<template>
  <dl class="sortBlock" v-if="Object.keys(sortItem).length > 0">
    <dt class="sortBlockLabel">
      <i class="material-icons">sort</i>並び順：
    </dt>
    <dd class="sortBlockSelectWrap">
      <select class="sortBlockSelect" name="sort" @change="updateValue">
        <option
          v-for="(v, i) in sortItem.key"
          :key="i"
          :value="i"
          :selected="i == defaultSort"
        >{{ v.text }}</option>
      </select>
    </dd>
  </dl>
</template>

<script>
export default {
  props: {
    sortItem: {
      type: Object,
      default: () => ({}),
    },
    defaultSort: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    updateValue: function(e) {
      this.$emit("update", e.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.sortBlock {
  align-items: baseline;
  background: $BG_COLOR_BOX;
  border-radius: $BOX_RADIUS;
  box-shadow: $DEPTH_LV_0-5;
  display: inline-flex;
  padding: $SUB_GRID $GRID * 0.5 $SUB_GRID + 2px;
  &Label {
    color: $COLOR_KEY;
    font-size: 12px;
    font-weight: bold;
    margin-right: $SUB_GRID;
  }
  .material-icons {
    font-size: 18px;
    vertical-align: middle;
  }
  &Select {
    &Wrap {
      display: inline-block;
      position: relative;
      &:after {
        border-style: solid;
        border-color: $COLOR_TEXT transparent transparent transparent;
        border-width: 4px 4px 0 4px;
        content: "";
        display: block;
        margin-top: -1px;
        position: absolute;
        right: 7px;
        top: 50%;
      }
    }
    & {
      background: $BG_COLOR_SUB;
      border: none;
      border-radius: $BOX_RADIUS * 0.5;
      box-shadow: $DEPTH_LV_0;
      cursor: pointer;
      font-size: 10px;
      font-weight: bold;
      outline: none;
      padding: 4px 23px 3px 10px;
    }
  }
}
</style>
