<template>
  <benefit-form
    v-if="showInput(keyName)"
    title="おすすめ設定"
    :note="onlyMediaName(keyName)"
    :option-level="optionLevel([keyName])"
  >
    <input-item class="input-item">
      <label class="checkbox" @click.prevent="isRecommendedWp = !isRecommendedWp">
        <input-checkbox :model-value="isRecommendedWp" />
        <span class="checkbox__text">おすすめ設定にする</span>
      </label>
      <p class="notice">
        <span>
          1つの特典にしか設定できません<br />設定すると他の特典の「おすすめ設定」が解除されます
        </span>
      </p>
    </input-item>
  </benefit-form>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import InputCheckbox from "@/components/atoms/common/InputCheckbox.vue";
import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";

export default {
  components: {
    InputCheckbox,
    BenefitForm,
    InputItem,
  },
  data() {
    return {
      keyName: "recommended",
      mediaId: API_CODE.media.wp,
    };
  },
  computed: {
    ...mapGetters({
      limitedToFair: "limitedToFair",
      limitedToWebtsuku: "limitedToWebtsuku",
      recommended: "recommended",
      showInput: "showInput",
      optionLevel: "optionLevel",
      onlyMediaName: "onlyMediaName",
    }),
    isRecommendedWp: {
      get() {
        return this.recommended();
      },
      set(value) {
        const toFair = this.limitedToFair(this.mediaId);
        const toWebtsuku = this.limitedToWebtsuku(this.mediaId);
        if (value && (toFair || toWebtsuku)) {
          return this.confirmEnableWpSetting({
            value,
            keyName: this.keyName,
            mediaId: this.mediaId,
          });
        }

        this.updateRecommended(value);
      },
    },
  },
  methods: {
    ...mapActions({ confirmEnableWpSetting: "confirmEnableWpSetting" }),
    ...mapMutations({ updateRecommended: `${types.RECOMMENDED}` }),
  },
};
</script>

<style scoped lang="scss">
.input-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.checkbox {
  align-items: center;
  display: flex;

  &__text {
    margin-left: $SUB_GRID;
    font-size: 13px;
  }
}

.notice {
  display: flex;
  font-size: $FONT_SIZE_SS;
  margin-top: $SUB_GRID;
  width: 100%;

  &:before {
    content: "※";
    margin-right: $SUB_GRID;
  }
}
</style>
