/**
 * セットアップ時の状態を管理するモジュール
 */
const Setup = {
  namespaced: true,
  state: {
    errorTexts: {
      get: "データ取得に失敗しました　時間をおいて再度お試しください",
    },
  },
};

export default Setup;
