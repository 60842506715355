import { TargetType as $targetType } from "@/assets/hall-admin/target-type";
import { ServiceType as $serviceType } from "@/assets/hall-admin/service-type";

export const usageTargets = [$targetType.COREMONET_PAGE, $targetType.COREMONET_API];
export const serviceType = $serviceType.COREMONET_STARTED;
export const serviceStatus = {
  NOT_IN_SERVICE: 0,
  IN_PREPARATION: 1,
  IN_SERVICE: 2,
  SUSPENDED: 3,
};
