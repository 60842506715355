<template>
  <div class="used-contents">
    <p v-if="needTitle" class="list-title">
      {{ title }}
    </p>
    <ul class="list" :class="{ 'list--scroll': needScroll }">
      <li
        class="unit"
        v-for="item in usedItems"
        :key="`${item.type}-${item.id}`"
      >
        <a class="link" :href="link(item.id, item.type)">
          <log-img :path="item.image_urls.thumb" height="64px" width="64px" />
          <div class="content">
            <p v-if="title" class="id">{{ item.id }}</p>
            <p v-else class="icon">
              <i class="material-icons">{{ unitHeaderIcon(item.type) }}</i>
              <span>{{ unitHeaderText(item.type) }}</span>
            </p>
            <p class="title">{{ item.title }}</p>
            <p class="detail" v-if="item.detail">
              {{ item.detail }}
            </p>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import LogImg from "@/components/atoms/common/FairImg.vue";

const isPlan = (type) => type === "plans";
const isBenefit = (type) => type === "benefits";
const isNewInfo = (type) => type === "new_info";

export default {
  components: {
    LogImg,
  },
  props: {
    usedItems: {
      type: Array,
      default: () => [],
    },
    needTitle: {
      type: Boolean,
      default: false,
    },
    needScroll: {
      type: Boolean,
      default: false,
    },
    usedType: {
      type: String,
      default: null,
    },
  },
  computed: {
    title() {
      return this.unitHeaderText(this.usedType);
    },
  },
  methods: {
    link(id, type) {
      if (isPlan(type)) return "/plan-detail/" + id;
      if (isBenefit(type)) return "/benefit/?edit#b-" + id;
      if (isNewInfo(type)) return "/whats-new";
      return "/fair-master-edit/" + id;
    },
    unitHeaderIcon(type) {
      if (isPlan(type)) return "cake";
      if (isBenefit(type)) return "stars";
      return "flag";
    },
    unitHeaderText(type) {
      if (isPlan(type)) return "プラン";
      if (isBenefit(type)) return "特典";
      if (isNewInfo(type)) return "新着";
      return "フェア";
    },
  },
};
</script>

<style scoped lang="scss">
.used-contents {
  .list {
    list-style: none;

    &--scroll {
      max-height: ($GRID * 5 * 5) + (2 * 4);
      overflow-y: auto;
    }

    &-title {
      color: $COLOR_SUB_TEXT;
      margin-bottom: $SUB_GRID;
    }
  }

  .unit {
    & + .unit {
      margin-top: 2px;
    }
  }

  .link {
    align-items: center;
    background: #fff;
    color: $COLOR_KEY;
    display: flex;
    height: $GRID * 5;
    justify-content: center;
    padding: $GRID * 0.5;
    position: relative;
    text-decoration: none;
    overflow: hidden;

    &::before {
      background: $COLOR_KEY;
      content: "";
      height: 100%;
      left: 0;
      margin: auto;
      opacity: 0.1;
      position: absolute;
      right: 0;
      top: 0;
      transition: width 0.2s;
      width: 0;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }
  }

  .content {
    padding-left: $GRID;
    flex: 1;
    overflow: hidden;
  }

  .icon {
    font-size: 12px;
    .material-icons {
      font-size: 15px;
      margin-right: $GRID * 0.25;
    }
  }

  .title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.4;
  }

  .detail {
    font-size: 11px;
  }

  .id {
    margin-right: $SUB_GRID;
    min-width: $GRID * 2;
    font-size: $FONT_SIZE_SS;

    &::before {
      content: "#";
    }
  }
}
</style>
