<template>
  <label class="radio-button">
    <input class="radio-button__input" type="radio" :value="checkedValue" v-model="currentValue" />
    <span v-if="label !== ''" class="radio-button__label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Number, String],
      default: null,
    },
    checkedValue: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.check(value);
      },
    },
  },
  methods: {
    check(value) {
      const isValid = this.isRequired ? value !== null : true;
      this.$emit("check", isValid);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-button {
  cursor: pointer;
  padding: $SUB_GRID;
  display: inline-flex;
  align-items: center;

  &__label {
    margin-left: $SUB_GRID;
  }
}
</style>
