const getters = {
  data: ({ data }) => data,
  master: ({ master }) => master,
  rules: ({ rules }) => rules,
  validation: ({ validation }) => validation,
  /**
   * 必須かどうか
   */
  optionLevel:
    ({ rules }) =>
    (keyNames) => {
      const isRequired = keyNames.some((k) => rules[k].is_required);
      return isRequired ? 2 : 1;
    },
  /**
   * 指定の項目が正常に入力されているか
   */
  isValid:
    ({ validation }) =>
    (key) => {
      return validation.validItems.indexOf(key) >= 0;
    },
};

export default getters;
