<template>
  <input-form :title="title" :option-level="optionLevel([keyName])">
    <template v-slot:content>
      <input-item>
        <template v-slot:input>
          <validating-textarea
            v-model="value"
            :is-full-size="true"
            :is-textarea="isTextarea"
            :is-required="rules[keyName].is_required"
            :max-length="rules[keyName].max_characters"
            :placeholder="placeholder"
            :rows="rows"
            @check="sendValidation"
          />
        </template>
      </input-item>
    </template>
  </input-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import InputForm from "@/components/molecules/contact/InputForm";
import InputItem from "@/components/molecules/contact/InputItem";
import ValidatingTextarea from "@/components/molecules/common/ValidatingTextarea";

export default {
  components: {
    InputForm,
    InputItem,
    ValidatingTextarea,
  },
  props: {
    title: String,
    keyName: String,
    placeholder: {
      text: String,
      default: null,
    },
    isTextarea: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      data: "utility/contact/data",
      rules: "utility/contact/rules",
      optionLevel: "utility/contact/optionLevel",
    }),
    value: {
      get() {
        return this.data[this.keyName];
      },
      set(value) {
        this.setContactData({ keyName: this.keyName, value });
      },
    },
  },
  methods: {
    ...mapActions({
      setContactData: "utility/contact/setContactData",
      setValidation: "utility/contact/setValidation",
    }),
    /**
     * validationModuleに入力情報を送信
     * @param isValid 入力済みフラグ false: 未入力 or エラー
     */
    sendValidation(isValid) {
      this.setValidation({ key: this.keyName, isValid });
    },
  },
};
</script>
