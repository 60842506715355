export const weekdayStrings = [
  {
    key: 1,
    name: "月",
    value: [1]
  },
  {
    key: 2,
    name: "火",
    value: [2]
  },
  {
    key: 3,
    name: "水",
    value: [3]
  },
  {
    key: 4,
    name: "木",
    value: [4]
  },
  {
    key: 5,
    name: "金",
    value: [5]
  },
  {
    key: 6,
    name: "土",
    value: [6]
  },
  {
    key: 0,
    name: "日",
    value: [0]
  },
  {
    key: 88,
    name: "祝日",
    value: [88]
  },
  {
    key: 100,
    name: "平日",
    value: [1, 2, 3, 4, 5]
  },
  {
    key: 101,
    name: "土日祝",
    value: [6, 0, 88]
  },
  {
    key: 102,
    name: "全て",
    value: [1, 2, 3, 4, 5, 6, 0]
  }
];
