<template>
  <benefit-form
    v-if="showInput(keyName)"
    title="フェア限定/Webつく限定表示"
    :note="onlyMediaName(keyName)"
    :option-level="optionLevel([keyName])"
  >
    <input-item>
      <input-radio
        v-model="value"
        :key="key"
        :items="items"
        :is-required="isRequired(keyName)"
        :display-block="true"
      />
    </input-item>
  </benefit-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import InputRadio from "@/components/benefit/inputs/InputRadio.vue";
import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";

export default {
  components: {
    InputRadio,
    BenefitForm,
    InputItem,
  },
  data() {
    return {
      keyName: "is_limited_to_fair",
      mediaId: API_CODE.media.wp,
      items: {
        0: { id: 0, name: "設定しない(フェア・式場特典にする)" },
        1: { id: 1, name: "フェア限定特典にする" },
        2: { id: 2, name: "Webつく限定特典にする" },
      },
      key: null,
    };
  },
  computed: {
    ...mapGetters({
      limitedToFair: "limitedToFair",
      limitedToWebtsuku: "limitedToWebtsuku",
      recommended: "recommended",
      showInput: "showInput",
      optionLevel: "optionLevel",
      onlyMediaName: "onlyMediaName",
      isRequired: "rules/isRequired",
    }),
    value: {
      get() {
        const limitedToFair = this.limitedToFair();
        const limitedToWebtsuku = this.limitedToWebtsuku();
        if (limitedToFair && !limitedToWebtsuku) {
          return 1;
        }
        if (!limitedToFair && limitedToWebtsuku) {
          return 2;
        }
        return 0;
      },
      set(value) {
        const recommended = this.recommended(this.mediaId);

        // keyを変更することで強制的に再レンダリングする
        // TODO: できれば再レンダリングしなくて済むように改修する
        this.key = this.key === value ? this.value : value;
        if (value !== 0 && recommended) {
          return this.confirmEnableWpSetting({
            value,
            keyName: "limited",
            mediaId: this.mediaId,
          });
        }

        this.updateToFair(value === 1);
        this.updateToWebtsuku(value === 2);
      },
    },
  },
  methods: {
    ...mapActions({ confirmEnableWpSetting: "confirmEnableWpSetting" }),
    ...mapMutations({
      updateToFair: `${types.LIMITED_TO_FAIR}`,
      updateToWebtsuku: `${types.LIMITED_TO_WEBTSUKU}`,
    }),
  },
};
</script>
