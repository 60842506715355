<template>
  <status-label
    v-if="isShow"
    :[flag]="true"
    :medias="media"
    :error-media-ids="errorMediaIds"
  />
</template>

<script>
import { mapGetters } from "vuex";

import API_CODE from "@/assets/common/ApiCode.js";

import StatusLabel from "@/components/molecules/common/StatusLabel.vue";

export default {
  components: {
    StatusLabel,
  },
  props: {
    linkStatuses: {
      type: [Object, Array],
      default: () => ({}),
    },
    mediaMaster: {
      type: Object,
      default: null,
    },
  },
  computed: {
    currentState() {
      const status = this.linkStatuses;
      if (Array.isArray(status)) return 0;

      const filtered = Object.values(status).filter((state) =>
        this.targetStatus.includes(state)
      );
      return filtered.length >= 1 ? Math.max(...filtered) : 0;
    },
    isShow() {
      return this.targetStatus.includes(this.currentState);
    },
    errorMediaIds() {
      const status = this.linkStatuses;
      if (
        Array.isArray(status) ||
        this.currentState !== API_CODE.linkStatus.error
      ) {
        return [];
      }

      return Object.keys(status).filter(
        (mediaId) => status[mediaId] >= API_CODE.linkStatus.error
      );
    },
    flag() {
      switch (this.currentState) {
        case API_CODE.linkStatus.fatalError:
          return "is-fatal-error";
        case API_CODE.linkStatus.error:
          return "is-linkage-error";
        case API_CODE.linkStatus.waiting:
          return "is-locked";
      }
      return null;
    },
  },
  created() {
    this.targetStatus = [
      API_CODE.linkStatus.waiting,
      API_CODE.linkStatus.error,
      API_CODE.linkStatus.fatalError,
    ];
    this.media = this.mediaMaster || this.$store.getters["master/media"];
  },
};
</script>

<style scoped lang="scss"></style>
