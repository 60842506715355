<template>
  <div class="stateUnit" :class="classType">
    <action-button
      v-if="isShowButton"
      class="stateUnitButton"
      :is-disabled="isDisabled"
      :is-save="isSaveButton"
      :text="stateText"
      @clickHandler="click"
    />
    <p v-else class="stateUnitBox">{{ stateText }}</p>
    <p v-if="date" class="stateUnitSub fontNum">{{ dateText }}</p>
  </div>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import formatDateText from "@/lib/formatDateText";

export default {
  emits: ["click"],
  components: {
    ActionButton,
  },
  props: {
    date: String,
    text: String,
    isButton: Boolean, // ボタン（$COLOR_KEY）
    isSaveButton: Boolean, // ボタン（$COLOR_SUBMIT:処理が進む）
    isDisabled: Boolean, // ボタン disabled
    isSmall: Boolean, // サイズ
    isBefore: Boolean,
    isWorking: Boolean,
    isReady: Boolean,
    isEnd: Boolean,
    isShowUsage: Boolean, // 利用状態を表示
  },
  computed: {
    classType() {
      return {
        isBefore: this.isBefore,
        isEnd: this.isEnd,
        isReady: this.isReady,
        isWorking: this.isWorking,
        isSmall: this.isSmall,
      };
    },
    dateText() {
      let d = formatDateText(this.date);
      if ((this.isShowUsage && !this.isEnd) || this.isWorking) d += "～";
      return d;
    },
    stateText() {
      if (this.isShowUsage) {
        if (this.isEnd) {
          return "利用終了";
        } else if (this.isSaveButton) {
          return "利用を開始する";
        } else if (this.isReady) {
          return "利用準備中";
        } else {
          return "利用中";
        }
      }
      if (this.isButton || this.isSaveButton) return this.text;
      let t = this.text;
      if (this.isWorking) {
        t += "中";
      } else if (!this.isBefore && !this.isReady) {
        t += "完了";
      }
      return t;
    },
    isShowButton() {
      return this.isButton || this.isSaveButton || (this.isShowUsage && this.isBefore);
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.stateUnit {
  text-align: center;
  width: $GRID * 8.5;
  &Box,
  &Button.btn {
    box-sizing: border-box;
    font-size: 12px;
    height: $GRID * 2;
    padding: 0;
    .isSmall & {
      height: $GRID * 1.5;
      width: 100%;
    }
  }
  &Box {
    align-items: center;
    border: 1px solid $COLOR_SUBMIT;
    border-radius: 2px;
    color: $COLOR_SUBMIT;
    display: flex;
    justify-content: center;
    .isBefore & {
      border-color: $COLOR_KEY;
      color: $COLOR_KEY;
    }
    .isWorking & {
      border-color: $COLOR_KEY;
      background: $COLOR_KEY;
      color: #fff;
    }
    .isReady & {
      border-color: $COLOR_WARNING;
      color: $COLOR_WARNING;
    }
    .isEnd & {
      border-color: $COLOR_SUB_LIGHT_TEXT;
      color: $COLOR_SUB_LIGHT_TEXT;
    }
  }
  &Sub {
    color: $COLOR_SUB_LIGHT_TEXT;
    font-size: 12px;
    width: inherit;
    .isSmall & {
      margin-top: $SUB_GRID;
    }
  }
}
</style>
