<template>
  <transition name="loading" appear type="transition">
    <div class="loading">
      <div class="loader">
        <img class="loader_bird" src="/img/modal/loading.png" />
        <p class="loader_txt">
          <span>L</span>
          <span>O</span>
          <span>A</span>
          <span>D</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
        </p>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.loading {
  align-items: center;
  animation: moveGradient 5s linear infinite;
  background: -moz-linear-gradient(
    135deg,
    rgba($BG_MAIN_TOP, 0.8),
    rgba($BG_MAIN_BOTTOM, 0.8)
  );
  background: -webkit-linear-gradient(
    135deg,
    rgba($BG_MAIN_TOP, 0.8),
    rgba($BG_MAIN_BOTTOM, 0.8)
  );
  background: linear-gradient(
    135deg,
    rgba($BG_MAIN_TOP, 0.8),
    rgba($BG_MAIN_BOTTOM, 0.8)
  );
  background-size: 200% 200%;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $LAYER_LOADING;
  #page:not(.isImport) & {
    padding-left: $NAVI_WIDTH;
    @media screen and (max-width: $MAIN_WIDTH) {
      padding-left: $NAVI_S_WIDTH;
    }
  }
}
.loader {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  transform: translate(0px, -75px);
  width: 150px;
  &_bird {
    animation: rotateBird 6s infinite linear;
  }
  &_txt {
    margin-top: 10px;
    span {
      animation: boundText 0.7s ease infinite;
      color: rgba($COLOR_KEY, 0.8);
      display: inline-block;
      font-weight: bold;
      letter-spacing: 0.15em;
      &:nth-child(1) {
        animation-delay: 0.1s;
      }
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.3s;
      }
      &:nth-child(4) {
        animation-delay: 0.4s;
      }
      &:nth-child(5) {
        animation-delay: 0.5s;
      }
      &:nth-child(6) {
        animation-delay: 0.6s;
      }
      &:nth-child(7) {
        animation-delay: 0.7s;
      }
    }
  }
}
.loading-enter-active,
.loading-leave-active {
  transition: opacity 0.2s;
}
.loading-enter,
.loading-leave-to {
  transition: opacity 0.1s;
  opacity: 0;
}
@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes rotateBird {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes boundText {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(3px);
  }
}
</style>
