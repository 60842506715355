<template>
  <div class="history-list__wrap">
    <log-filter v-if="isOperationLog" @change="isShowMore = false" />
    <job-filter v-else @change="isShowMore = false" />
    <div class="history-list">
      <h2 class="history-list__title">
        {{ title }}
      </h2>
      <div class="history-list__area">
        <div class="history-unit" v-for="(log, i) in showLogs" :key="i">
          <p class="history-unit__date fontNum">{{ date(log) }}</p>
          <log-unit v-if="isOperationLog" class="history-unit__box" :log="log" />
          <job-unit v-else class="history-unit__box" :job="log" />
        </div>
        <div v-if="!showLogs.length" class="history-list__message">{{ title }}はありません</div>
        <button
          v-if="enableShowMore && !isShowMore"
          class="history-list__btn-more"
          title="もっと見る"
          @click="showMore"
        >
          <i class="material-icons">more_horiz</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import formatDateText from "@/lib/formatDateText";

import LogFilter from "@/components/organisms/job/LogFilter.vue";
import JobFilter from "@/components/organisms/job/JobFilter.vue";
import LogUnit from "@/components/organisms/job/LogUnit.vue";
import JobUnit from "@/components/organisms/job/JobUnit.vue";

export default {
  components: {
    LogFilter,
    JobFilter,
    LogUnit,
    JobUnit,
  },
  data() {
    return {
      isShowMore: false,
    };
  },
  computed: {
    ...mapGetters({
      tab: "job/tab",
      title: "job/title",
      filteredData: "job/filter/filteredData",
      operationLogs: "job/operationLogs/logs",
    }),
    ...mapGetters("job/operationLogs", ["logs"]),
    isOperationLog() {
      return this.tab.active === "operationLogs";
    },
    logs() {
      return this.isOperationLog ? this.operationLogs : this.filteredData;
    },
    enableShowMore() {
      return this.logs.length > 10;
    },
    showLogs() {
      return this.isShowMore ? this.logs : this.logs.slice(0, 10);
    },
  },
  methods: {
    showMore() {
      this.isShowMore = true;
    },
    date(log) {
      if (this.isOperationLog) return log.created_at;

      let dt = log.requested_at.replace(" ", "T").split("T");
      return formatDateText(log.requested_at) + " " + dt[1];
    },
  },
};
</script>

<style scoped lang="scss">
.history-list {
  background: $BG_COLOR_BOX;
  color: $COLOR_KEY;
  overflow: hidden;
  padding: 0 $GRID 12px $GRID * 0.5;

  &__title {
    font-size: 16px;
    display: inline-block;
    height: $GRID * 2.75;
    line-height: $GRID * 2.75;
    margin-left: $GRID * 0.5;
    position: relative;
    &::before {
      background: $COLOR_KEY;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      top: 0px;
      width: 100%;
    }
  }
  &__area {
    position: relative;
  }
  &__message {
    margin-bottom: $GRID * 1.75;
    text-align: center;
  }
  &__btn-more {
    background: $COLOR_KEY;
    border: none;
    border-radius: 50%;
    bottom: 14px;
    color: #fff;
    display: block;
    left: 50%;
    position: absolute;
    &:hover {
      box-shadow: $DEPTH_LV_2;
      opacity: 0.8;
    }
  }
}
.history-unit {
  border-left: 1px solid $BG_COLOR_FLOOR;
  color: $COLOR_SUB_TEXT;
  font-size: 14px;
  margin-left: $GRID * 0.5;
  padding-bottom: $GRID;
  &__box {
    margin-left: $GRID * 0.5;
  }
  &__date {
    align-items: center;
    display: flex;
    height: $GRID * 1.5;
    position: relative;
    left: -5.5px;
    &::before {
      content: "fiber_manual_record";
      font-family: "Material Icons";
      font-feature-settings: "liga";
      font-size: $FONT_SIZE_SS;
      margin-right: 12px;
    }
    &::after {
      border-top: 1px solid;
      content: "";
      left: 6px;
      position: absolute;
      top: calc(50% - 0.5px);
      width: 12px;
    }
  }
  &:nth-last-of-type(2) {
    padding-bottom: 26px;
  }
  &:nth-last-of-type(1) {
    position: relative;
    top: -10px;
  }
  &:last-child {
    border: none;
    padding-bottom: 0;
  }
}
</style>
