import { state, actions, getters, mutations } from "./publishing";
import {
  selectedState,
  selectedActions,
  selectedGetters,
  selectedMutations,
} from "./selected-hall-benefit";
import { validState, validGetters, validMutations } from "./validation";

const publishing = {
  namespaced: true,
  actions: {
    ...actions,
    ...selectedActions,
  },
  state: {
    ...state,
    ...selectedState,
    ...validState,
  },
  getters: {
    ...getters,
    ...selectedGetters,
    ...validGetters,
  },
  mutations: {
    ...mutations,
    ...selectedMutations,
    ...validMutations,
  },
};

export default publishing;
