/**
 * API 側の共通コード
 */
const API_CODE = {
  response: {
    success: 200,
    created: 201,
    accepted: 202,
    deleted: 204, // no content
    // 以下、エラーコード
    badRequest: 400,
    unauthorized: 401,
    accessDenied: 403, // forbidden
    notFound: 404,
    conflict: 409,
    sync: 422, // unprocessable entity
    locked: 423,
    internal: 500,
    gatewayTimeout: 504,
  },
  status: {
    draft: 0,
    private: 1,
    public: 2,
  },
  linkStatus: {
    waiting: 10,
    inSync: 1,
    public: 2,
    locked: 3,
    notice: 80,
    error: 90,
    fatalError: 99,
  },
  mediaCommon: 0,
  media: {
    ksm: 1,
    zexy: 2,
    mw: 3,
    wp: 4,
    mynavi: 5,
    hanayume: 6,
    homepage: 100,
  },
  weekdayString: ["日", "月", "火", "水", "木", "金", "土"],
  role: {
    temporary: "temporary_user",
    initial: "initial_user",
    waiting: "waiting_user",
    user: "user",
    creation: "data_creation_user",
    admin: "admin",
    superAdmin: "super_admin",
    ksmAdmin: "ksm_admin",
    test: "test",
  },
  stockAvailable: {
    notAvailable: 0, // ×
    few: 1, // △
    ok: 2, // 〇
    full: 3, // 満席
    default: 4, // デフォルト
  },
};
Object.freeze(API_CODE);
export default API_CODE;
