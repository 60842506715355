import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "plans";
  const { instance, index, store, show, update, destroy } = Repository(
    `${hallId}/${resource}`,
    token
  );
  return {
    index,
    store,
    show,
    update,
    destroy,
    showTemplate: () => show("template"),
    showRules: () => show("rules"),
    publish: (planId) => instance.put(`/${planId}/publish`),
    unpublish: (planId) => instance.put(`/${planId}/unpublish`),
    indexOrder: () => instance.get("/order"),
    updateOrder: (payload) => instance.put("/order", payload),
    showRegularCharge: (payload) => instance.put("/regular-charge", payload),
  };
};
