<template>
  <div class="inoperable" :class="addClass" :title="tooltip">
    <div class="inoperableInr" :class="{ isVertical }">
      <icon-sync v-if="iconType == 'autorenew'" class="inoperable_icon" />
      <i v-else class="inoperable_icon material-icons">{{ iconType }}</i>
      <p class="inoperable_text" v-html="displayText"></p>
    </div>
  </div>
</template>

<script>
import IconSync from "@/components/atoms/common/IconSync.vue";

export default {
  components: {
    IconSync,
  },
  props: {
    icon: {
      type: String,
      default: "autorenew",
    },
    text: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "inSync",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isLighten: {
      type: Boolean,
      default: false,
    },
    isVertical: {
      // 縦長かどうか
      type: Boolean,
      default: false,
    },
  },
  computed: {
    addClass: function() {
      return {
        isLarge: this.isLarge,
        isSmall: this.isSmall,
        isLighten: this.isLighten,
        isClosed: this.type === "isClosed",
        isError: this.type === "isError",
        isDisabled: this.type === "isDisabled",
        isWarning: this.type === "isWarning",
      };
    },
    iconType: function() {
      return this.type === "isError" ? "error" : this.icon;
    },
    displayText: function() {
      if (this.text == null) {
        return this.type === "isError"
          ? "エラーが発生しているため<br>編集できません"
          : "媒体とデータを同期中のため<br>編集できません";
      } else {
        return this.text;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.inoperable {
  align-items: center;
  color: #fff;
  background-color: rgba($COLOR_KEY, 0.75);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: $LAYER_INOPERABLE;
  &.isLighten {
    background-color: rgba($COLOR_KEY, 0.45);
  }
  &.isClosed {
    background-color: rgba(120, 120, 120, 0.5);
  }
  &.isDisabled {
    background-color: rgba(120, 120, 120, 0.7);
  }
  &.isWarning {
    background-color: rgba($COLOR_WARNING, 0.6);
    color: $COLOR_TEXT;
  }
  &.isError {
    background-color: rgba($COLOR_ALERT, 0.6);
    &.isLighten {
      background-color: rgba($COLOR_ALERT, 0.45);
    }
  }
  &Inr {
    &.isVertical {
      margin-top: -4em;
    }
    .isSmall & {
      align-items: center;
      display: flex;
    }
  }
  &_icon {
    font-size: 48px;
    margin: -12px 0 12px;
    .isLarge & {
      font-size: 54px;
      margin: 0 0 $GRID;
    }
    .isSmall & {
      font-size: 34px;
      margin: 0 $GRID * 0.5 0 0;
    }
  }
  &_text {
    font-size: 12px;
    letter-spacing: 0.05em;
    line-height: 1.7;
    .isLarge & {
      font-size: 16px;
    }
  }
}
</style>
