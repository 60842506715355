import API_CODE from "@/assets/common/ApiCode";

import deepCopy from "@/lib/deepCopy";

import * as types from "@/store/benefit/mutationTypes";

const mutations = {
  [types.SELECTED_DATA](state, selectedData) {
    state.selectedData = selectedData;
  },
  [types.MEDIA_IDS]({ selectedData }, mediaIds) {
    selectedData.media_ids = mediaIds;
  },
  [types.GROUP_TYPE]({ selectedData }, groupType) {
    selectedData.group_type = groupType;
  },
  [types.BENEFIT_TYPES]({ selectedData }, types) {
    selectedData.type = types;
  },
  [types.BENEFIT_TYPE]({ selectedData }, { mediaId, type }) {
    if (!selectedData.type[mediaId]) {
      selectedData.type = { ...selectedData.type, [mediaId]: null };
    }
    selectedData.type[mediaId] = type;
  },
  [types.TITLES]({ selectedData }, titles) {
    selectedData.title = titles;
  },
  [types.TITLE]({ selectedData }, { mediaId, title }) {
    if (!selectedData.title[mediaId]) {
      selectedData.title = { ...selectedData.title, [mediaId]: null };
    }
    selectedData.title[mediaId] = title;
  },
  [types.DESCRIPTIONS]({ selectedData }, descriptions) {
    selectedData.description = descriptions;
  },
  [types.DESCRIPTION]({ selectedData }, { mediaId, description }) {
    if (!selectedData.description[mediaId]) {
      selectedData.description = {
        ...selectedData.description,
        [mediaId]: null,
      };
    }
    selectedData.description[mediaId] = description;
  },
  [types.LIMITED_TO_FAIR]({ selectedData }, isLimited) {
    selectedData.is_limited_to_fair[API_CODE.media.wp] = isLimited;
  },
  [types.LIMITED_TO_WEBTSUKU]({ selectedData }, isLimited) {
    selectedData.is_limited_to_webtsuku[API_CODE.media.wp] = isLimited;
  },
  [types.PERIOD_FROMS]({ selectedData }, froms) {
    selectedData.valid_from = froms;
  },
  [types.PERIOD_FROM]({ selectedData }, { mediaId, from }) {
    if (!selectedData.valid_from[mediaId]) {
      selectedData.valid_from = { ...selectedData.valid_from, [mediaId]: null };
    }
    selectedData.valid_from[mediaId] = from;
  },
  [types.PERIOD_UNTILS]({ selectedData }, untils) {
    selectedData.valid_until = untils;
  },
  [types.PERIOD_UNTIL]({ selectedData }, { mediaId, until }) {
    if (!selectedData.valid_until[mediaId]) {
      selectedData.valid_until = {
        ...selectedData.valid_until,
        [mediaId]: null,
      };
    }
    selectedData.valid_until[mediaId] = until;
  },
  [types.PERIOD_TEXTS]({ selectedData }, texts) {
    selectedData.valid_period_text = texts;
  },
  [types.PERIOD_TEXT]({ selectedData }, { mediaId, text }) {
    if (!selectedData.valid_period_text[mediaId]) {
      selectedData.valid_period_text = {
        ...selectedData.valid_period_text,
        [mediaId]: null,
      };
    }
    selectedData.valid_period_text[mediaId] = text;
  },
  [types.IMAGE_IDS]({ selectedData }, imageIds) {
    selectedData.image_id = imageIds;
  },
  [types.IMAGE_ID]({ selectedData }, { mediaId, imageId }) {
    if (!selectedData.image_id[mediaId]) {
      selectedData.image_id = { ...selectedData.image_id, [mediaId]: null };
    }
    selectedData.image_id[mediaId] = imageId;
  },
  [types.MEDIA_ONLY]({ selectedData }, isMediaOnly) {
    selectedData.is_media_only[API_CODE.media.mynavi] = isMediaOnly;
  },
  [types.RECOMMENDED]({ selectedData }, recommended) {
    selectedData.recommended[API_CODE.media.wp] = recommended;
  },
  [types.USED_COUNT]({ selectedData }, usedCount) {
    selectedData.used_count = usedCount;
  },
  [types.USED_FAIRS]({ selectedData }, usedFairs) {
    selectedData.used_fars = usedFairs;
  },
  [types.LICENSE_STATUS]({ selectedData }, status) {
    selectedData.license_status = status;
  },
  [types.LINK_STATUSES]({ selectedData }, statuses) {
    selectedData.link_statuses = statuses;
  },
  [types.LINK_STATUS]({ selectedData }, { mediaId, status }) {
    if (!selectedData.link_statuses[mediaId]) {
      selectedData.link_statuses = {
        ...selectedData.link_statuses,
        [mediaId]: null,
      };
    }
    selectedData.link_statuses[mediaId] = status;
  },
  [types.UPDATED_AT]({ selectedData }, updatedAt) {
    selectedData.updated_at = updatedAt;
  },
  [types.TARGET_ID](state, targetId) {
    state.targetId = targetId;
  },
  [types.PERIOD_TYPES](state, types) {
    state.periodTypes = types;
  },
  [types.PERIOD_TYPE](state, { mediaId, type }) {
    state.periodTypes[mediaId] = type;
  },
  [types.IS_BY_MEDIA](state, isByMedia) {
    state.isByMedia = isByMedia;
  },
  [types.IS_BY_MEDIUM](state, { formName, isByMedia }) {
    state.isByMedia[formName] = isByMedia;
  },
  [types.APPLICATION_FROMS]({ selectedData }, froms) {
    selectedData.application_from = froms;
  },
  [types.APPLICATION_FROM]({ selectedData }, { mediaId, from }) {
    const { application_from } = selectedData;
    selectedData.application_from = {
      ...application_from,
      [mediaId]: from,
    };
  },
  [types.APPLICATION_UNTILS]({ selectedData }, untils) {
    selectedData.application_until = untils;
  },
  [types.APPLICATION_UNTIL]({ selectedData }, { mediaId, until }) {
    const { application_until } = selectedData;
    selectedData.application_until = {
      ...application_until,
      [mediaId]: until,
    };
  },
  [types.PUBLICATION_FROMS]({ selectedData }, froms) {
    selectedData.publication_from = froms;
  },
  [types.PUBLICATION_FROM]({ selectedData }, { mediaId, from }) {
    const { publication_from } = selectedData;
    selectedData.publication_from = {
      ...publication_from,
      [mediaId]: from,
    };
  },
  [types.PUBLICATION_UNTILS]({ selectedData }, untils) {
    selectedData.publication_until = untils;
  },
  [types.PUBLICATION_UNTIL]({ selectedData }, { mediaId, until }) {
    const { publication_until } = selectedData;
    selectedData.publication_until = {
      ...publication_until,
      [mediaId]: until,
    };
  },
  [types.IS_HIGHLIGHTEDS]({ selectedData }, highlighted) {
    selectedData.is_highlighted = highlighted;
  },
  [types.IS_HIGHLIGHTED]({ selectedData }, { mediaId, highlighted }) {
    const { is_highlighted } = selectedData;
    selectedData.is_highlighted = {
      ...is_highlighted,
      [mediaId]: highlighted,
    };
  },
  [types.DETAIL_TYPES]({ selectedData }, types) {
    selectedData.detail_type = types;
  },
  [types.DETAIL_TYPE]({ selectedData }, { mediaId, type }) {
    const { detail_type } = selectedData;
    selectedData.detail_type = {
      ...detail_type,
      [mediaId]: type,
    };
  },
  [types.DISCOUNT_TYPES]({ selectedData }, types) {
    selectedData.discount_type = types;
  },
  [types.DISCOUNT_TYPE]({ selectedData }, { mediaId, type }) {
    const { discount_type } = selectedData;
    selectedData.discount_type = {
      ...discount_type,
      [mediaId]: type,
    };
  },
  [types.NOTES_LIST]({ selectedData }, notes) {
    selectedData.notes = notes;
  },
  [types.NOTES]({ selectedData }, { mediaId, note }) {
    const { notes } = selectedData;
    selectedData.notes = {
      ...notes,
      [mediaId]: note,
    };
  },
  [types.PRICE_NOTES_LIST]({ selectedData }, notes) {
    selectedData.price_notes = notes;
  },
  [types.PRICE_NOTES]({ selectedData }, { mediaId, note }) {
    const { price_notes } = selectedData;
    selectedData.price_notes = {
      ...price_notes,
      [mediaId]: note,
    };
  },
  [types.LINK_TITLES]({ selectedData }, titles) {
    selectedData.link_title = titles;
  },
  [types.LINK_TITLE]({ selectedData }, { mediaId, title }) {
    const { link_title } = selectedData;
    selectedData.link_title = {
      ...link_title,
      [mediaId]: title,
    };
  },
  [types.LINK_URLS]({ selectedData }, urls) {
    selectedData.link_url = urls;
  },
  [types.LINK_URL]({ selectedData }, { mediaId, url }) {
    const { link_url } = selectedData;
    selectedData.link_url = {
      ...link_url,
      [mediaId]: url,
    };
  },
  // 媒体個別設定を切り替え
  UPDATE_IS_BY_MEDIA(state, payload) {
    Object.entries(payload).forEach(([key, value]) => {
      state.isByMedia[key] = value;
    });
  },
  // 編集IDを保存
  SET_TARGET_ID(state, id) {
    state.targetId = id;
  },
  // 追加を反映
  [types.ADD_BENEFIT](state, payload) {
    const { data } = payload;
    state.benefit[data.group_type].unshift(data);
  },
  // 指定以外の特典のウエパおすすめ設定をfalseに一括変更
  [types.RESET_OTHER_RECOMMENDED_WP](state, benefitNew) {
    if (benefitNew.group_type !== 2) return;

    const mediaWp = API_CODE.media.wp;
    if (!benefitNew.recommended[mediaWp]) return;

    state.benefit[2].forEach((benefit) => {
      if (benefit.id === benefitNew.id) return;
      if (!benefit.recommended[mediaWp]) return;
      benefit.recommended[mediaWp] = false;
    });
  },
  usedFairs(state, fairs) {
    state.usedFairs = [...fairs];
  }
};

export default mutations;
