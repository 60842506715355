import actions from "@/store/modal/actions";
import getters from "@/store/modal/getters";
import mutations from "@/store/modal/mutations";

const state = {
  completeModal: {
    isShow: false,
    text: "",
  },
  loadingModal: {
    isShow: false,
    text: "データを更新しています…",
  },
  errorModal: {
    isShow: false,
    text: "",
    path: "",
    closeBtnText: "閉じる",
  },
  confirmModal: {
    isShow: false,
    confirmed: false,
    text: "",
    subText: null,
    status: 0,
    actionName: "",
    actionBtnText: "",
    closeBtnText: "閉じる",
    colorType: null,
    param: {},
    master: {
      status: {
        0: "note",
        1: "delete",
        2: "private",
        3: "next",
      },
      type: {
        benefit: "特典",
        fair: "フェア",
        image: "画像",
        plan: "プラン",
        venue: "会場",
        user: "ユーザー",
        whatsNew: "新着マスタ",
      },
    },
  },
  targetId: "",
};

const modalModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

export default modalModule;
