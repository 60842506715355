<template>
  <div class="swap-button">
    <action-button
      class="button-top"
      :class="{ 'button-top--disabled': isFirst }"
      text="一番上へ"
      :is-small="true"
      :is-select="true"
      :disabled="isFirst"
      @clickHandler="$emit('moveToUp')"
    />
    <div class="button-swap">
      <button
        class="button-swap__btn"
        :class="{ 'button-swap__btn--disabled': isFirst }"
        @click="$emit('swap', true)"
      >
        <span class="material-icons">arrow_drop_up</span>
      </button>
      <button
        class="button-swap__btn"
        :class="{ 'button-swap__btn--disabled': isLast }"
        @click="$emit('swap', false)"
      >
        <span class="material-icons">arrow_drop_down</span>
      </button>
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton";

export default {
  components: {
    ActionButton,
  },
  props: {
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.swap-button {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-left: auto;

  .button-top {
    background: #fff;
    margin-right: $GRID * 0.5;
  }

  .button-swap {
    &__btn {
      align-items: center;
      background: $COLOR_SUB_LIGHT_TEXT;
      border: 0;
      border-radius: 50%;
      color: #fff;
      display: flex;
      height: $GRID;
      justify-content: center;
      margin: 0 0 auto 0;
      outline: none;
      width: $GRID;

      .material-icons {
        font-size: 18px;
        margin: -1px auto auto 1px;
      }

      &:hover {
        opacity: 0.5;
      }

      &--disabled {
        opacity: 0.7;
        pointer-events: none;
      }

      & + .button-swap__btn {
        margin-top: $SUB_GRID;
      }
    }
  }
}
</style>
