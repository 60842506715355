import API_CODE from "@/assets/common/ApiCode";

/**
 * ファイルをダウンロード
 * @param {String} path ファイルパス
 */
export const downloadFile = async (path) => {
  let fileName = path.split("/");
  fileName = Array.isArray(fileName) ? fileName[fileName.length - 1] : path;
  return await new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", path, true);
    xhr.responseType = "blob";
    xhr.onload = (res) => {
      if (res && res.target && res.target.status == API_CODE.response.success) {
        return resolve(res.target.response);
      } else {
        return reject(res && res.target ? res.target : res);
      }
    }; // response あり // 404 なども含む
    xhr.onerror = (e) => reject(e); // request 失敗
    xhr.send(); // 実行
  })
    .then((blob) => {
      downloadBlob(blob, fileName);
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

/**
 * Blob形式をダウンロード
 * @param {Object} blob Blob形式データ
 * @param {String} fileName ダウンロードするファイル名
 */
export const downloadBlob = (blob, fileName) => {
  if (window.navigator.msSaveOrOpenBlob) {
    // IE11, Edge
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    // Chrome など
    let url = (window.URL || window.webkitURL).createObjectURL(blob);
    let link = document.createElement("a"); // a タグをダウンロードの時のみ作成
    link.download = fileName;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
