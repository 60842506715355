<template>
  <div
    :class="{ isEdit, isSelected, isSelectMode, isDisabled }"
    :id="`b-${data.id}`"
    class="fair-benefit"
    @click="selectBenefit"
  >
    <unit-head :id="data.id" :date="data.updated_at" :is-small-id="true" />

    <transition v-if="isEdit" name="benefit-animation" tag="div">
      <fair-benefit-edit
        v-if="showBenefitEdit"
        class="fair-benefit__edit"
        @close="editBenefit"
      />
    </transition>

    <transition v-else name="benefit-animation" tag="div">
      <fair-benefit-detail
        :benefit="data"
        :class="{ noHoverStyle }"
        class="fair-benefit__detail"
      >
        <template #benefitFooter v-if="showBenefitFooter">
          <use-count
            v-if="mode == 'view'"
            status-text="フェア使用"
            :count="data.used_count"
            @click="$emit('replace')"
          />
          <use-count
            v-if="data.is_for_hall"
            status-text="掲載"
            :count="data.status === status.public ? 1 : 0"
            :show-count="false"
            :need-link="false"
          />
          <p v-if="extraInfo" class="fair-benefit__extra-label">
            {{ extraInfo }}
          </p>
          <setting-btn
            v-if="isViewMode"
            text="編集する"
            :is-disabled="isFatalError"
            class="fair-benefit__button-edit"
            @clickHandler="editBenefit(data.id)"
          />
        </template>
        <template #benefitMenu v-if="!isSelectMode">
          <fair-benefit-menu
            :is-disabled="isFatalError"
            :is-in-used="data.used_count > 0"
            :is-published="isPublished"
            :show-wepa-publish-button="showWepaPublishButton"
            :can-copy-as-hall-benefit="canCopyAsHallBenefit"
            @menuToggleBenefitPublishStatus="togglePublishStatus"
            @menuCopyBenefit="copyBenefit"
            @menuCopyAsHallBenefit="copyAsHallBenefit"
            @menuDeleteBenefit="deleteBenefit"
          />
        </template>
        <template #benefitStatus>
          <status-label v-if="isFatalError" :is-fatal-error="true" />
          <status-label
            v-else-if="isLinkageError"
            :is-linkage-error="true"
            :medias="master.media"
            :error-media-ids="linkageErrorMediaIds"
          />
          <inoperable-cover
            v-if="isDisabled"
            :icon="inoperableMessage.icon"
            :text="inoperableMessage.text"
          />
        </template>
      </fair-benefit-detail>
    </transition>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import API_CODE from "@/assets/common/ApiCode";
import { ConfirmModalStatus } from "@/assets/modal/confirm-modal-status";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

import FairBenefitEdit from "@/components/benefit/benefit-list/FairBenefitEdit.vue";
import FairBenefitDetail from "@/components/benefit/benefit-list/FairBenefitDetail.vue";
import UnitHead from "@/components/molecules/common/ListUnitHead.vue";
import UseCount from "@/components/atoms/common/UseCount.vue";
import StatusLabel from "@/components/molecules/common/StatusLabel.vue";
import SettingBtn from "@/components/atoms/common/ActionButton.vue";
import FairBenefitMenu from "@/components/benefit/presentation/FairBenefitMenu.vue";
import InoperableCover from "@/components/atoms/common/InoperableCover.vue";

// Store
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    FairBenefitEdit,
    FairBenefitDetail,
    UnitHead,
    SettingBtn,
    UseCount,
    StatusLabel,
    FairBenefitMenu,
    InoperableCover,
  },
  props: {
    data: Object,
    isEdit: Boolean,
    isLoading: Boolean,
    mode: {
      type: String,
      default: "view",
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    noHoverStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: API_CODE.status,
    };
  },
  computed: {
    ...mapState(["anchor"]),
    ...mapGetters({
      enabledHallBenefits: "enabledHallBenefits",
      master: "master",
      mediaShortName: "mediaShortName",
    }),
    mediaCodes() {
      return { ...API_CODE.media, common: API_CODE.mediaCommon };
    },
    showBenefitEdit() {
      if (this.isLoading) return false;
      if (this.isSelectMode) return false;
      return true;
    },
    showBenefitFooter() {
      return this.isViewMode || this.isSelectMode;
    },
    showWepaPublishButton() {
      const isWp = this.data.group_type === BENEFIT_GROUP_TYPE.wp;
      const mediaIncludeWp = this.data.media_ids.includes(this.mediaCodes.wp);
      return isWp && mediaIncludeWp;
    },
    canCopyAsHallBenefit() {
      if (!this.enabledHallBenefits) {
        return false;
      }

      const mediaIds = this.data.media_ids;
      return (
        mediaIds.includes(this.mediaCodes.zexy) ||
        mediaIds.includes(this.mediaCodes.mw) ||
        mediaIds.includes(this.mediaCodes.mynavi)
      );
    },
    isViewMode() {
      return this.mode === "view";
    },
    isSelectMode() {
      return this.mode === "select";
    },
    inSync() {
      return Object.values(this.data.link_statuses).some(
        (status) => status == API_CODE.linkStatus.inSync
      );
    },
    isFatalError() {
      return Object.values(this.data.link_statuses).some(
        (status) => status == API_CODE.linkStatus.fatalError
      );
    },
    isLinkageError() {
      return Object.values(this.data.link_statuses).some(
        (status) => status == API_CODE.linkStatus.error
      );
    },
    isPublished() {
      return this.data.status === API_CODE.status.public;
    },
    isDisabled() {
      if (this.inSync) return true;
      return this.isSelectMode && this.data.license_status == 2;
    },
    linkageErrorMediaIds() {
      return Object.keys(this.data.link_statuses).filter(
        (mediaId) =>
          this.data.link_statuses[mediaId] >= API_CODE.linkStatus.error
      );
    },
    inoperableMessage() {
      const message = {};
      if (this.inSync) {
        message.text = "媒体とデータを同期中のため<br>選択できません";
      } else if (this.data.license_status == 2) {
        message.icon = "error";
        message.text = "適用期間が終了しているため<br>選択できません";
      }
      return message;
    },
    extraInfo() {
      const {
        is_limited_to_fair,
        is_limited_to_webtsuku,
        is_media_only,
        recommended,
      } = this.data;
      if (is_media_only[this.mediaCodes.mynavi]) {
        return "マイナビ限定";
      }
      if (is_limited_to_fair[this.mediaCodes.wp]) {
        return "フェア限定";
      }
      if (is_limited_to_webtsuku[this.mediaCodes.wp]) {
        return "Webつく限定";
      }
      if (recommended[this.mediaCodes.wp]) {
        return "ウエパおすすめ";
      }
      return null;
    },
  },
  mounted() {
    if (this.anchor.hash && this.anchor.id == this.data.id) {
      VueScrollTo.scrollTo(this.anchor.hash);
    }
  },
  methods: {
    ...mapActions({
      setSelectedData: "setSelectedData",
      copyBenefitData: "copyBenefitData",
      setTargetId: "setTargetId",
      showConfirm: "modal/showConfirmModal",
    }),
    selectBenefit() {
      if (this.isDisabled) return;
      this.$emit("setSelect", this.data);
    },
    editBenefit(id = null) {
      // データ初期化
      this.setSelectedData(this.data);
      // 編集ID保存
      this.setTargetId(this.data.id);
      VueScrollTo.scrollTo("#b-" + this.data.id); // 編集、編集キャンセル時に該当の特典を最上部にする
      this.$emit("updateEditId", id);
    },
    copyBenefit() {
      this.$emit("updateEditId", null);
      // データ初期化
      this.copyBenefitData({
        data: this.data,
        group_type: this.data.group_type,
      });
      // 編集IDを初期化
      this.setTargetId(null);
      // 追加モーダルを表示
      this.$emit("showCopyEditModal");
    },
    copyAsHallBenefit() {
      this.$emit("updateEditId", null);
      // データ初期化
      this.copyBenefitData({
        data: this.data,
        group_type: BENEFIT_GROUP_TYPE.hall,
      });
      // 編集IDを初期化
      this.setTargetId(null);
      // 追加モーダルを表示
      this.$emit("showCopyEditModal");
    },
    deleteBenefit() {
      const payload = {
        targetId: this.data.id,
        type: "benefit",
        status: 1,
        param: {
          group_type: this.data.group_type,
        },
        actionName: "deleteData",
      };
      this.showConfirm(payload);
    },
    togglePublishStatus() {
      const payload = {
        targetId: this.data.id,
        type: "benefit",
        status: this.isPublished
          ? ConfirmModalStatus.UNPUBLISHING
          : ConfirmModalStatus.PUBLISHING,
        param: {
          id: this.data.id,
          status: this.isPublished ? 1 : 2,
        },
        autoClose: true,
        actionName: "setPublishStatus",
      };

      if (this.isPublished) {
        payload.text = `選択した特典を非公開に設定しますか？
ウエパから削除されます。`;
      }

      this.showConfirm(payload);
    },
  },
};
</script>

<style scoped lang="scss">
.fair-benefit {
  transition: all 0.3s;

  &__edit {
    background: $BG_COLOR_BOX;
    box-shadow: $DEPTH_LV_2;
  }

  &:not(.isDisabled).isSelectMode & {
    &__detail {
      position: relative;
      transition: all 400ms ease-in;

      &:not(.noHoverStyle) {
        cursor: pointer;

        &:hover {
          box-shadow: $DEPTH_LV_2;
          transform: scale(1.015);
          transition: transform 160ms ease-out;
        }
      }
    }
  }
  &.isSelectMode.isDisabled & {
    &__detail {
      cursor: not-allowed;
    }
  }
  &.isSelected & {
    &__detail {
      &::after {
        border: 2px solid #5c5;
        border-radius: $BOX_RADIUS_FOR_LARGE_BOX;
        content: "";
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        width: 100%;
      }
      &:hover {
        &::after {
          opacity: 0.7;
        }
      }
    }
  }

  &__extra-label {
    @include benefitExtraLabel();
  }

  &__button-edit {
    margin-left: auto;
  }
}

.benefit-animation {
  &-enter-active {
    transition: opacity 0.3s;
  }
  &-enter-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
