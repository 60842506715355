<template>
  <filter-block class="filter-image" title="画像">
    <template #filter-l>
      <filter-input
        class="keyword filter-item"
        placeholder="ID・画像名・ファイル名"
        @update="updateKeyword"
      />
      <div class="selectbox-item filter-item">
        <p class="selectbox-item__label">版権期限：</p>
        <filter-selectbox v-model="period" :options="periodKeys" />
      </div>
      <div class="selectbox-item filter-item">
        <p class="selectbox-item__label">フェア、プラン、特典での利用状況：</p>
        <filter-selectbox v-model="inUsed" :options="inUsedKeys" />
      </div>
      <div class="selectbox-item filter-item">
        <p class="selectbox-item__label">ゼクシィに連携状態：</p>
        <filter-selectbox v-model="linkedZexy" :options="mediaStatus" />
      </div>
      <div class="selectbox-item filter-item">
        <p class="selectbox-item__label">マイナビに連携状態：</p>
        <filter-selectbox v-model="linkedMynavi" :options="mediaStatus" />
      </div>
      <div class="filter-item">
        <filter-checkbox
          label="not-croped"
          text="トリミング設定されていないもの"
          :value="notCropedQuery"
          @update="updateNotCroped"
        />
      </div>
      <div class="filter-item">
        <filter-checkbox
          label="not-tags"
          text="タグが設定されていないもの"
          :value="notTagsQuery"
          @update="updateNotTags"
        />
      </div>
    </template>
    <template #filter-r>
      <div>
        <p class="tag-title">
          <i class="material-icons">local_offer</i>
          <span>タグ</span>
        </p>
        <filter-checkbox
          v-for="({ id, name }, index) in tags"
          :key="index"
          :label="String(index)"
          :text="name"
          :value="selectedTag(id)"
          :is-btn-type="true"
          @update="updateTagsQuery(id)"
        />
      </div>
    </template>
  </filter-block>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import FilterCheckbox from "@/components/atoms/common/FilterCheckbox.vue";
import FilterInput from "@/components/atoms/common/FilterInput.vue";
import FilterSelectbox from "@/components/atoms/common/FilterSelectbox.vue";
import FilterBlock from "@/components/molecules/common/FilterBlock.vue";

export default {
  components: {
    FilterCheckbox,
    FilterInput,
    FilterSelectbox,
    FilterBlock,
  },
  unmounted() {
    this.reset();
  },
  computed: {
    ...mapGetters({
      tags: "tags",
      periodQuery: "filter/periodQuery",
      periodKeys: "filter/periodKeys",
      inUsedQuery: "filter/inUsedQuery",
      inUsedKeys: "filter/inUsedKeys",
      linkedZexyQuery: "filter/linkedZexyQuery",
      linkedMynaviQuery: "filter/linkedMynaviQuery",
      notCropedQuery: "filter/notCropedQuery",
      notTagsQuery: "filter/notTagsQuery",
      tagsQuery: "filter/tagsQuery",
      mediaStatus: "filter/mediaStatus",
    }),
    period: {
      get() {
        return this.periodQuery;
      },
      set(period) {
        this.updatePeriodQuery(period);
      },
    },
    inUsed: {
      get() {
        return this.inUsedQuery;
      },
      set(inUsed) {
        this.updateInUsedQuery(inUsed);
      },
    },
    linkedZexy: {
      get() {
        return this.linkedZexyQuery;
      },
      set(linked) {
        this.updateLinkedZexyQuery(linked);
      },
    },
    linkedMynavi: {
      get() {
        return this.linkedMynaviQuery;
      },
      set(linked) {
        this.updateLinkedMynaviQuery(linked);
      },
    },
  },
  methods: {
    ...mapActions({
      reset: "filter/reset",
    }),
    ...mapMutations({
      keywordQuery: "filter/keywordQuery",
      updatePeriodQuery: "filter/periodQuery",
      updateInUsedQuery: "filter/inUsedQuery",
      updateLinkedZexyQuery: "filter/linkedZexyQuery",
      updateLinkedMynaviQuery: "filter/linkedMynaviQuery",
      updateNotCropedQuery: "filter/notCropedQuery",
      updateNotTagsQuery: "filter/notTagsQuery",
      updateTagsQuery: "filter/tagsQuery",
    }),
    updateKeyword(_, keyword) {
      this.keywordQuery(keyword);
    },
    updateNotCroped(_, notCroped) {
      this.updateNotCropedQuery(notCroped);
    },
    updateNotTags(_, notTags) {
      this.updateNotTagsQuery(notTags);
    },
    selectedTag(tagId) {
      return this.tagsQuery.includes(tagId);
    },
  },
};
</script>

<style scoped lang="scss">
.filter-image {
  width: calc(100% - #{$GRID} * 0.5);
  margin: $GRID * 0.25 $GRID * 0.25 $GRID;
  font-size: 14px;
  color: $COLOR_SUB_TEXT;

  .material-icons {
    font-size: 16px;
    margin-right: $GRID * 0.25;
  }

  .filter-item + .filter-item {
    margin-top: $GRID * 0.75;
  }

  .keyword {
    width: 100%;
  }

  .selectbox-item {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: $FONT_SIZE_S;

    &__label {
      width: $GRID * 8;
    }
  }
}
</style>
