<template>
  <div class="statusLabel" :class="className">
    <i v-if="isLinkageError || isFatalError" class="statusLabel_icon material-icons">error</i>
    <icon-sync v-if="inSync" class="statusLabel_icon" />
    <p class="statusLabel_text">{{ statusText }}</p>
    <p v-if="isLinkageError && moreCount > 0" class="linkageError_more">
      <span>… 他</span>
      <span class="linkageError_num fontNum-b">{{ moreCount }}</span>
      <span>媒体</span>
    </p>
  </div>
</template>

<script>
import IconSync from "@/components/atoms/common/IconSync.vue";

export default {
  components: {
    IconSync,
  },
  props: {
    isLinkageError: {
      type: Boolean,
      default: false,
    },
    isFatalError: {
      type: Boolean,
      default: false,
    },
    inSync: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    medias: {
      type: Object,
      default: () => {},
    },
    errorMediaIds: {
      type: Array,
      default: () => [],
    },
    text: String,
  },
  computed: {
    className() {
      return {
        error: this.isLinkageError || this.isFatalError,
        inSync: this.inSync,
        isSmall: this.isSmall,
      };
    },
    statusText() {
      if (this.text) return this.text;
      if (this.isLinkageError) return "媒体連携エラー：" + this.mediaText();
      if (this.isFatalError) return "エラー検証中のため編集できません";
      if (this.inSync) return "同期中";
    },
    moreCount() {
      return this.errorMediaIds.length > 1 ? this.errorMediaIds.length - 1 : 0;
    },
  },
  methods: {
    mediaText() {
      if (this.isFatalError) return false;
      return this.medias[this.errorMediaIds[0]].short_name;
    },
  },
};
</script>

<style scoped lang="scss">
.statusLabel {
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  display: inline-flex;
  height: 32px;
  padding: 0 $GRID * 0.7;
  position: absolute;
  right: 2+2+32px;
  top: 2px;
  &_icon {
    margin-right: $GRID * 0.25;
    .isSmall & {
      font-size: 14px;
      margin-right: 2px;
    }
  }
  &_text {
    .error & {
      padding-top: 2px;
    }
    .isSmall & {
      padding-top: 1px;
    }
  }
  &.error {
    background: $BG_COLOR_ALERT;
    color: $COLOR_ALERT;
  }
  &.inSync {
    background: rgba($COLOR_KEY, 0.75);
    color: #fff;
  }
  &.isSmall {
    font-size: 10.5px;
    height: auto;
    padding: 0 4px;
    right: $SUB_GRID;
    top: $SUB_GRID;
  }
}
.linkageError {
  &_more {
    font-size: 10px;
    padding: 2px 0 0 2px;
    .isSmall & {
      padding-top: 0;
    }
  }
  &_num {
    font-size: 15px;
    margin: 0 2px;
  }
}
</style>
