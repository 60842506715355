/**
 * 並び替え - １番上に移動する
 * @param {array} order 並び替え対象の配列
 * @param {number} id 対象のID
 * @param {string} keyName IDの要素名
 */
const moveToUp = (order, id, keyName = null) => {
  const key = keyName || "id";
  const targetItem = order.find((item) => item[key] === id);
  const sortOrder = order.filter((item) => item[key] !== id);
  sortOrder.unshift(targetItem);
  return sortOrder;
};

export default moveToUp;
