<template>
  <p class="calendarHeader fontNum" :class="cssClass">
    {{ dayString }}
    <span class="calendarHeader_weekday">
      {{ weekdayString }}
    </span>
    <slot name="header-button" />
  </p>
</template>

<script>
import { weekdayStrings } from "@/assets/fair-schedule/WeekdayConfig.js";

export default {
  props: {
    date: Object,
    isWeekendBg: Boolean,
    isAlert: {
      type: Boolean,
      default: false,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
    unitWithBtn: {
      type: Boolean,
      default: false,
    },
    isStockFull: {
      type: Function,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    weekdayString() {
      const d = weekdayStrings.find((day) => day.key === this.date.weekday);
      return d.name;
    },
    dayString() {
      return this.date.is_first_day
        ? `${this.date.month}/${this.date.day}`
        : this.date.day;
    },
    fullDate() {
      return `${this.date.year_month}-${this.date.day}`;
    },
    cssClass() {
      return {
        isSaturday: this.date.weekday === 6,
        isSunday: this.date.weekday === 0,
        isHoliday: this.date.is_holiday,
        isFirstday: this.date.is_first_day,
        bg:
          ((this.date.weekday === 6 || this.date.weekday === 0) &&
            this.isWeekendBg) ||
          this.date.is_holiday,
        isAlert: this.isAlert,
        unitWithBtn: this.unitWithBtn,
        isHovered: this.isHovered,
        isSelected: this.isSelected,
      };
    },
    isSelected() {
      return this.unitWithBtn && this.isStockFull(this.fullDate);
    },
  },
};
</script>

<style scoped lang="scss">
.calendarHeader {
  display: inline-block;
  border-left: 1px solid $SCHEDULE_BORDER_COLOR;
  box-sizing: border-box;
  font-size: 13px;
  height: $SCHEDULE_HEADER_DAY_HEIGHT;
  padding: $SUB_GRID * 1.5 0 0;
  text-align: center;
  width: $SCHEDULE_CELL_WIDTH;
  transition: background 800ms;
  &_weekday {
    display: inline-block;
    font-size: 9px;
    width: 100%;
  }
  &.unitWithBtn {
    height: $SCHEDULE_HEADER_DAY_HEIGHT_LARGE;
  }
  &.isSaturday {
    color: $COLOR_SATURDAY;
    &.bg {
      background: $COLOR_SATURDAY_LIGHT;
      &.isHovered,
      &.isSelected {
        background: mix($COLOR_SUBMIT, $COLOR_SATURDAY_LIGHT);
      }
    }
  }
  &.isSunday,
  &.isHoliday {
    color: $COLOR_SUNDAY;
    &.bg {
      background: $COLOR_SUNDAY_LIGHT;
      &.isHovered,
      &.isSelected {
        background: mix($COLOR_SUBMIT, $COLOR_SUNDAY_LIGHT);
      }
    }
  }
  &.isFirstday {
    border-left: none;
  }
  &.isAlert {
    position: relative;
    &:after {
      background: $BG_CALENDAR_ALERT;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  &.isHovered {
    transition: background 125ms;
  }
  &.isHovered,
  &.isSelected {
    background: lighten($COLOR_SUBMIT, 3%);
  }
}
</style>
