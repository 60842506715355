export const SET_CALENDAR = "SET_CALENDAR";
export const SET_CALENDAR_DICTIONARY = "SET_CALENDAR_DICTIONARY";
export const SET_SCHEDULE_DATA = "SET_SCHEDULE_DATA";
export const SET_SCHEDULE_COUNT = "SET_SCHEDULE_COUNT";
export const SET_FAIR_GROUPS = "SET_FAIR_GROUPS";
export const SET_MASTER_DATA = "SET_MASTER_DATA";
export const SET_GROUPED_ORIGINAL_DATA = "SET_GROUPED_ORIGINAL_DATA";

export const SET_IS_READY = "SET_IS_READY";
export const SET_BY_MEDIA_IS_READY = "SET_BY_MEDIA_IS_READY";

export const ADD_SELECTED_DAY = "ADD_SELECTED_DAY";
export const REMOVE_SELECTED_DAY = "REMOVE_SELECTED_DAY";
export const UPDATE_FAIR_COUNT = "UPDATE_FAIR_COUNT";

export const SET_FLAG_FAIR_BY_MEDIA = "SET_FLAG_FAIR_BY_MEDIA";
export const ADD_SELECTED_DAY_BY_MEDIA = "ADD_SELECTED_DAY_BY_MEDIA";
export const REMOVE_SELECTED_DAY_BY_MEDIA = "REMOVE_SELECTED_DAY_BY_MEDIA";

export const UPDATE_CHANGED_HELD_DATES = "UPDATE_CHANGED_HELD_DATES";

export const SAVE_ORIGINAL_DATA = "SAVE_ORIGINAL_DATA";
export const UPDATE_ORIGINAL_DATA = "UPDATE_ORIGINAL_DATA";
export const CANCEL_SCHEDULE_DATA = "CANCEL_SCHEDULE_DATA";

export const ADD_DIFF_FAIR_ID = "ADD_DIFF_FAIR_ID";
export const REMOVE_DIFF_FAIR_ID = "REMOVE_DIFF_FAIR_ID";
export const CLEAR_DIFF_FAIR_ID = "CLEAR_DIFF_FAIR_ID";

export const ADD_DIFF_FAIR_ID_BY_MEDIA = "ADD_DIFF_FAIR_ID_BY_MEDIA";
export const REMOVE_DIFF_FAIR_ID_BY_MEDIA = "REMOVE_DIFF_FAIR_ID_BY_MEDIA";
export const CLEAR_DIFF_FAIR_ID_BY_MEDIA = "CLEAR_DIFF_FAIR_ID_BY_MEDIA";
export const SET_BY_MEDIA_FLAG = "SET_BY_MEDIA_FLAG";

export const SET_MWEB_PICKUP_FAIR_ENABLED = "SET_MWEB_PICKUP_FAIR_ENABLED";

// 表示
export const SET_FILTER = "SET_FILTER";
export const SET_FILTER_CHECKBOX = "SET_FILTER_CHECKBOX";
export const SHOW_ONLY_TARGET_FAIRS = "SHOW_ONLY_TARGET_FAIRS";
export const SET_SORT_TYPE = "SET_SORT_TYPE";

export const ADD_DAILY_ID = "ADD_DAILY_ID";
export const REMOVE_DAILY_DATE = "REMOVE_DAILY_DAT";

// Modal
export const SHOW_FAIR_PUBLIC_MODAL = "SHOW_FAIR_PUBLIC_MODAL";
export const SET_FAIR_PUBLIC_MODAL_ID = "SET_FAIR_PUBLIC_MODAL_ID";
export const HIDE_FAIR_PUBLIC_MODAL = "HIDE_FAIR_PUBLIC_MODAL";
export const MAKE_FAIR_PUBLIC = "MAKE_FAIR_PUBLIC";
export const MAKE_FAIR_PRIVATE = "MAKE_FAIR_PRIVATE";

// EditingModule
export const SET_EDITING_MODE = "SET_EDITING_MODE";
export const SET_EDITING_LOCK = "SET_EDITING_LOCK";
export const SET_EDITING_ID = "SET_EDITING_ID";
export const SET_EDITING_GROUP_ID = "SET_EDITING_GROUP_ID";
export const ADD_EDITING_DAY = "ADD_EDITING_DAY";
export const SET_EXIST_DATA = "SET_EXIST_DATA";
export const REMOVE_EDITING_DAY = "REMOVE_EDITING_DAY";
export const RESET_EDITING_ID = "RESET_EDITING_ID";
export const RESET_EDITING_DATES = "RESET_EDITING_DATES";
export const RESET_EXIST_DATA = "RESET_EXIST_DATA";
export const ADD_EDIT_BY_MEDIA = "ADD_EDIT_BY_MEDIA";
export const REMOVE_EDIT_BY_MEDIA = "REMOVE_EDIT_BY_MEDIA";
export const SET_ADJUST_PADDING = "SET_ADJUST_PADDING";
export const SET_DAILY_DATA = "SET_DAILY_DATA";
export const SET_FORCE_UPDATE = "SET_FORCE_UPDATE";
export const REMOVE_FORCE_UPDATE = "REMOVE_FORCE_UPDATE";
export const SET_LOADING = "SET_LOADING";

// StockModule
export const SET_STOCK_DATA = "SET_STOCK_DATA";
export const UPDATE_STOCK_DATA = "UPDATE_STOCK_DATA";
export const SET_FAIR_DATA_FOR_STOCK = "SET_FAIR_DATA_FOR_STOCK";
export const SET_STOCK_IS_READY = "SET_STOCK_IS_READY";
export const SET_FULL_STOCK_NUMBER = "SET_FULL_STOCK_NUMBER";
export const SET_FULL_MEDIA_ID = "SET_FULL_MEDIA_ID";
export const SET_FULL_MEDIA_IDS = "SET_FULL_MEDIA_IDS";
export const SET_STOCK_SETTING = "SET_STOCK_SETTING";
export const SET_STOCK_SETTING_ORIGIN = "SET_STOCK_SETTING_ORIGIN";
export const RESET_STOCK_SETTING = "RESET_STOCK_SETTING";

// 媒体のイチオシ情報
export const SET_IS_FETCHING_MEDIA_BEST_RECOMMENDED =
  "SET_IS_FETCHING_MEDIA_BEST_RECOMMENDED";
export const SET_IS_FAILED_FETCH_MEDIA_BEST_RECOMMENDED =
  "SET_IS_FAILED_FETCH_MEDIA_BEST_RECOMMENDED";
export const SET_MEDIA_ONLY_FAIRS = "SET_MEDIA_ONLY_FAIRS";
export const UPDATE_MEDIA_BEST_RECOMMENDED = "UPDATE_MEDIA_BEST_RECOMMENDED";

// Recommended
export const SET_ZEXY_BEST_RECOMMENDED_STOCK =
  "SET_ZEXY_BEST_RECOMMENDED_STOCK";
export const RESET_ZEXY_RECOMMEND_STOCK = "RESET_ZEXY_RECOMMEND_STOCK";
export const SET_ZEXY_REPORT_PLUS = "SET_ZEXY_REPORT_PLUS";

// 媒体個別
export const SET_MEDIA_DATA = "SET_MEDIA_DATA";
