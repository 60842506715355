// 共通設定
const getters = {
  executedJobs: (state) => {
    return state.executedJobs;
  },
  master: (state) => {
    return state.master;
  },
  waitingJobs: (state) => {
    return state.waitingJobs;
  },
  tab: (state) => {
    return state.tab;
  },
  isShowParent: (state) => {
    return state.isShowParent;
  },
  title: (state) => {
    return state.tab.key[state.tab.active];
  },
};
export default getters;
