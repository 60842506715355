<template>
  <section class="list-image">
    <div class="list-image__header">
      <h1 class="list-image__title">画像一覧</h1>
      <action-button class="reload-button" text="最新の情報に更新する" @clickHandler="init" />
    </div>
    <div class="list-image__body">
      <unit-image
        v-for="({ id }, index) in filteredImages"
        :image-id="id"
        :key="index"
        @select="$emit('select', id)"
      />
    </div>
  <inoperable-cover v-if="loading" :is-small="true" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionButton from "@/components/atoms/common/ActionButton.vue";
import InoperableCover from "@/components/atoms/common/InoperableCover.vue";
import UnitImage from "./UnitImage.vue";

export default {
  components: {
    ActionButton,
    InoperableCover,
    UnitImage,
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: -1,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      filteredImages: "filter/filteredImages",
    }),
  },
  methods: {
    ...mapActions({
      fetchImages: "fetchImages",
    }),
    async init() {
      this.loading = true;
      await this.fetchImages();
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.list-image {
  color: $COLOR_KEY;
  font-size: 12px;
  padding: 0 $GRID * 0.25;
  position: relative;

  &__header {
    display: flex;

    .reload-button {
      margin-left: $GRID;
      height: 24px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: $GRID * 0.5;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    gap: $GRID;
  }
}
</style>
