<template>
  <section class="input-description" :class="{ 'by-media': !isCommon }">
    <div v-if="!isCommon" class="input-description__media-name">
      {{ mediaShortName(mediaId) }}
    </div>
    <validating-textarea
      v-model="description"
      :is-full-size="true"
      :is-required="isRequired(keyName, mediaId)"
      :is-textarea="isTextarea"
      :max-length="maxCharacters(keyName, mediaId)"
      :media-ids="[mediaId]"
      :ng-word="ngWord(keyName)"
      :rows="isTextarea ? 5 : null"
      :full-width="fullWidth"
      @check="textValidation"
    />
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

import * as types from "@/store/benefit/mutationTypes";

import InputOptionIcon from "@/components/atoms/common/InputOptionIcon.vue";
import ValidatingTextarea from "@/components/molecules/common/ValidatingTextarea";
import InputItem from "@/components/benefit/inputs/InputItem.vue";

export default {
  components: {
    InputOptionIcon,
    ValidatingTextarea,
    InputItem,
  },
  props: {
    mediaId: {
      type: Number,
      default: API_CODE.mediaCommon,
    },
    optionLevel: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      keyName: "description",
    };
  },
  computed: {
    ...mapGetters({
      mediaShortName: "mediaShortName",
      $description: "description",
      selectedOnlyMedia: "selectedOnlyMedia",
      ngWord: "ngWord",
      isRequired: "rules/isRequired",
      onlyMedia: "rules/onlyMedia",
      groupType: "groupType",
      maxCharacters: "rules/maxCharacters",
    }),
    description: {
      get() {
        return this.$description(this.mediaId);
      },
      set(description) {
        this.updateDescription({ description, mediaId: this.mediaId });
      },
    },
    isCommon() {
      return this.mediaId === API_CODE.mediaCommon;
    },
    isTextarea() {
      return this.groupType === BENEFIT_GROUP_TYPE.hall;
    },
    fullWidth() {
      return this.mediaId !== API_CODE.media.zexy;
    },
  },
  beforeUnmount() {
    this.textValidation(true);
  },
  methods: {
    ...mapMutations({
      updateDescription: `${types.DESCRIPTION}`,
      validateDescription: `validation/${types.DESCRIPTION}`,
    }),
    textValidation(isValid) {
      this.validateDescription({ isValid, mediaId: this.mediaId });
    },
  },
};
</script>

<style scoped lang="scss">
.input-description {
  width: 100%;
  padding: $SUB_GRID * 2;
  padding-left: 0;

  &.by-media {
    @include gradientMediaSet();
    padding-left: $SUB_GRID * 2;
  }

  &__media-name {
    margin-bottom: $SUB_GRID;
    color: $COLOR_KEY;
    font-size: 11px;
  }
}
</style>
