/**
 * マイナビ方式で文字数をカウント（改行を2文字扱い）
 *
 * 改行:2 / その他:1 として算出
 * @param {String} text 対象の文字列（注意：NGワードを除外する事）
 */
const countTextLengthMynavi = (text) => {
  if (!text || text.length === 0) return 0;
  return text.length + countLineBreak(text);
};

/**
 * 改行の文字数をカウント
 * @param {String} text 対象の文字列
 */
const countLineBreak = (text) => {
  return (text.match(/\n/g) || []).length;
};

export default countTextLengthMynavi;
