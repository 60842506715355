import API_CODE from "@/assets/common/ApiCode";
import * as initItems from "@/store/fair-master-detail/initItemsList.js";

// オンライン受付できる媒体
export const bookOnlineMedia = [
  API_CODE.media.ksm,
  API_CODE.media.mw,
  API_CODE.media.hanayume,
];
// オンライン受付が必須の媒体
export const requireOnlineMedia = [API_CODE.media.mw];
// 電話受付が必須の媒体
export const requireByPhoneMedia = [API_CODE.media.zexy];

// 共通設定で、受付設定対応する媒体
export const typeSettableMedia = [API_CODE.media.zexy, API_CODE.media.mynavi];

export const bookingTypes = {
  online: 1,
  byPhone: 2,
  all: 3,
};

export const bookingParams = {
  booking: null,
  isBookingReflecting: false,
};

export const selectKeysByBookType = (bookTypes) => {
  // bookTypes <Array> 電話受付、ネット受付

  const selectKeys = [];
  if (bookTypes.includes("online")) {
    selectKeys.push([
      initItems.BOOK_ONLINE_AVAILABLE_DAYS,
      initItems.BOOK_ONLINE_AVAILABLE_TIME,
    ]);
  }

  if (bookTypes.includes("by_phone")) {
    selectKeys.push([
      initItems.BOOK_BY_PHONE_AVAILABLE_DAYS,
      initItems.BOOK_BY_PHONE_AVAILABLE_TIME,
    ]);
  }

  return selectKeys.flat();
};
