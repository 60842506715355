export const ServiceType = {
  FAIR_IMPORT: 1,
  IMAGE_IMPORT: 2,
  BENEFIT_IMPORT: 3,
  VENUE_IMPORT: 4,
  FAIR_ASSOCIATE: 5,
  IMAGE_ASSOCIATE: 6,
  FAIR_PUBLISH: 7,
  FAIR_UNPUBLISH: 8,
  PLAN_IMPORT: 9,
  PLAN_ASSOCIATE: 10,
  PLAN_PUBLISH: 11,
  DIFF_IMPORT: 12,
  FAIR_STARTED: 13,
  PLAN_STARTED: 14,
  COREMONET_STARTED: 15,
  HALL_INFO_IMPORT: 16,
  NEW_INFO_IMPORT: 17,
  HALL_BENEFIT_IMPORT: 21,
  NEW_INFO_STARTED: 25,
};
