<template>
  <div>
    <button
      class="setByMediaBtn"
      type="button"
      :aria-pressed="selfIsSet || null"
      @click="toggleBtn"
    >
      <p class="setByMediaBtn_inner" v-if="selfIsSet">
        <i class="material-icons">vertical_align_top</i>
        <span>
          媒体<br />
          個別設定<br />
          を解除
        </span>
      </p>
      <p class="setByMediaBtn_inner" v-else>
        <i class="material-icons">tune</i>
        <span>
          媒体
          <br />個別設定
        </span>
      </p>
    </button>
    <modal-window v-if="showModal" :close-text="'キャンセル'" @close="closeModal">
      <template v-slot:text>
        <p>
          共通設定を適用して保存すると、設定済みの媒体個別設定は削除されます。<br />
          媒体個別設定を解除しますか？
        </p>
      </template>
      <template v-slot:footerBtn>
        <action-button :text="'解除する'" :is-alert="true" @clickHandler="cancelSet" />
      </template>
    </modal-window>
  </div>
</template>

<script>
import ModalWindow from "@/components/molecules/common/ModalWindow.vue";
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import VueScrollTo from "vue-scrollto";

export default {
  components: {
    ActionButton,
    ModalWindow,
  },
  props: {
    anchor: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    useCancelNotice: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    selfIsSet: {
      get() {
        return this.modelValue;
      },
      set() {
        this.toggleBtn();
      },
    },
  },
  methods: {
    toggleBtn() {
      const toSet = !this.modelValue;
      if (toSet) {
        this.$emit("update:modelValue", toSet);
      } else {
        if (this.useCancelNotice) {
          this.showModal = true;
        } else {
          this.cancelSet();
        }
      }
    },
    closeModal() {
      this.showModal = false;
    },
    cancelSet() {
      if (this.useCancelNotice) {
        this.closeModal();
      }
      const anchor = this.anchor;
      this.$emit("update:modelValue", false);
      if (anchor) {
        VueScrollTo.scrollTo("#" + anchor);
      }
    },
  },
};
</script>

<style scoped lang="scss">
$btnHeight: $GRID * 5;
$arrowHeight: calc($GRID * 0.5);
$arrowSideLength: round(calc($arrowHeight / 0.7));

.setByMediaBtn {
  background-color: $COLOR_KEY;
  border: none;
  border-radius: $SUB_GRID;
  box-shadow: $DEPTH_LV_1;
  color: $BG_COLOR_BOX;
  display: block;
  font-size: $FONT_SIZE_SS;
  height: $btnHeight;
  position: absolute;
  right: -$GRID * 5;
  bottom: calc(50% - #{$btnHeight * 0.5});
  width: $GRID * 4;
  transition: 0.5s;

  &:hover {
    opacity: 0.8;
  }

  &::before {
    border-bottom: $arrowSideLength * 0.5 solid transparent;
    border-right: $arrowHeight solid $COLOR_KEY;
    border-top: $arrowSideLength * 0.5 solid transparent;
    content: "";
    display: block;
    position: absolute;
    left: -$arrowHeight;
    top: ($btnHeight - $arrowSideLength) * 0.5;
  }

  &_inner {
    text-align: center;
    i,
    span {
      display: block;
    }
    span {
      line-height: 1.2;
      margin-top: $SUB_GRID;
    }
  }

  &[aria-pressed="true"] {
    background-color: $BG_COLOR_BOX;
    border: 1px solid $COLOR_KEY;
    bottom: 0;
    color: $COLOR_KEY;
    transition: 0.3s;
    &::before {
      opacity: 0;
    }
  }
}
</style>
