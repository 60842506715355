export const SET_LOADING_FINISH = "SET_LOADING_FINISH";

export const SET_FAIR_DATA = "SET_FAIR_DATA";
export const SET_MASTER_DATA = "SET_MASTER_DATA";
export const SET_TAB_KEY = "SET_TAB_KEY";
export const SET_SORT_KEY = "SET_SORT_KEY";
export const SET_FILTER = "SET_FILTER";
export const SET_FILTER_CHECK = "SET_FILTER_CHECK";
export const SET_FILTER_MOVIE = "SET_FILTER_MOVIE";
export const SET_CHECKED_ID = "SET_CHECKED_ID";
export const SET_IS_COMPLETED = "SET_IS_COMPLETED";
export const SET_DRAFT_INFO = "SET_DRAFT_INFO";
export const SET_PRIVATE_ID = "SET_PRIVATE_ID";
export const SET_PUBLISH_ID = "SET_PUBLISH_ID";
export const SET_GROUP_ID = "SET_GROUP_ID";
export const SET_TARGET_ID = "SET_TARGET_ID";
export const SET_IS_SHOW_TITLE_BY_MEDIA = "SET_IS_SHOW_TITLE_BY_MEDIA";

export const RESET_CHECKED_ID = "RESET_CHECKED_ID";

export const UPDATE_FAIR = "UPDATE_FAIR";
export const DELETE_FAIR = "DELETE_FAIR";

export const SET_COMPLETED_ID = "SET_COMPLETED_ID";
export const CLEAR_COMPLETED_ID = "CLEAR_COMPLETED_ID";

export const UPDATE_FAIR_ORDER = "UPDATE_FAIR_ORDER";
