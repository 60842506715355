<template>
  <div class="circle">
    <div class="circleSafe">
      <span class="circleSafe_cover1"></span>
      <span class="circleSafe_cover2"></span>
      <span class="circleSafe_check material-icons">check</span>
      <span class="circleSafe_finish" :class="{ 'type-1': type == 1 }">お疲れ様でした</span>
      <div v-if="type == 1">
        <img class="circleSafe_wing left" src="/img/modal/complete-left.png" />
        <img class="circleSafe_wing right" src="/img/modal/complete-right.png" />
        <img class="circleSafe_eye" src="/img/modal/complete-eye.png" />
        <img class="circleSafe_hair" src="/img/modal/complete-hair.png" />
      </div>
    </div>
    <div v-if="type == 2" class="circleSafe_bird circleSafe_boy">
      <img class="circleSafe_boyWing" src="/img/modal/complete-boy-wing.png" />
      <img class="circleSafe_birdBody" src="/img/modal/complete-boy.png" />
    </div>
    <div v-else-if="type == 3" class="circleSafe_bird circleSafe_boy">
      <img class="circleSafe_boyWingLeft" src="/img/modal/complete-boy-wing-left.png" />
      <img class="circleSafe_boyWingRight" src="/img/modal/complete-boy-wing-right.png" />
      <img class="circleSafe_birdBody" src="/img/modal/complete-boy.png" />
    </div>
    <div v-else-if="type == 4" class="circleSafe_bird circleSafe_girl">
      <img class="circleSafe_girlWing" src="/img/modal/complete-girl-wing.png" />
      <img class="circleSafe_birdBody" src="/img/modal/complete-girl.png" />
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      type: null,
    };
  },
  created: function() {
    const min = 1;
    const max = 4;
    const type = Math.floor(Math.random() * (max + 1 - min)) + min;
    this.type = type;
  },
};
</script>

<style scoped lang="scss">
.circle {
  height: 64px;
  margin: $GRID * 3 auto $GRID;
  position: relative;
  width: 64px;
}
.circleSafe {
  animation: filling-s 0.2s ease-in 0.75s forwards,
    expand 0.3s ease-in 0.8s forwards;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px $COLOR_SUBMIT;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 3;
}
.circleSafe {
  &_cover1,
  &_cover2 {
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 50%;
    &::before {
      background: #eafaf9;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
  &_cover1 {
    left: 50%;
    &::before {
      animation: draw 0.2s linear 0.3s forwards;
      height: 200%;
      transform-origin: 0 25%;
    }
  }
  &_cover2 {
    left: 0;
    &::before {
      animation: draw 0.15s ease-out 0.5s forwards;
      height: 120%;
      transform-origin: 100% 40%;
    }
  }
  &_check {
    animation: show-parts 0.2s ease-out 1s forwards;
    color: #fff;
    font-size: 40px;
    left: 0;
    line-height: 64px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
  }
  &_finish {
    animation: show-finish 0.2s ease-out 1.15s forwards;
    bottom: 100%;
    color: $COLOR_SUB_TEXT;
    font-size: 10px;
    left: -2px;
    margin-bottom: $GRID * 0.6;
    opacity: 0;
    position: absolute;
    width: 7em;
    &.type-1 {
      margin-bottom: $GRID * 0.8;
    }
    &::before,
    &::after {
      background: $COLOR_SUB_TEXT;
      content: "";
      display: block;
      height: 1.5em;
      position: absolute;
      top: 2px;
      width: 1px;
    }
    &::before {
      left: -1em;
      transform: rotate(-30deg);
    }
    &::after {
      right: -0.8em;
      transform: rotate(30deg);
    }
  }
  // チェックごと鳥 Ver
  &_wing,
  &_eye,
  &_hair {
    opacity: 0;
    position: absolute;
    z-index: 4;
  }
  &_wing {
    animation: show-wing 0.15s ease-out 1.25s forwards;
    top: 20%;
    &.left {
      right: 100%;
      margin-right: -5px;
      transform-origin: right bottom;
      transform: rotate(-50deg);
    }
    &.right {
      left: 100%;
      margin-left: -5px;
      transform-origin: left bottom;
      transform: rotate(50deg);
    }
  }
  &_eye {
    animation: show-parts 0.2s linear 1.25s forwards;
    left: 50%;
    right: 0;
    top: 5px;
    transform: translateX(-50%);
  }
  &_hair {
    animation: show-parts 0.2s linear 1.25s forwards;
    bottom: 100%;
    left: 50%;
    margin-bottom: -2px;
    transform: translateX(-50%);
  }
  // 後ろから出てくる Ver
  &_bird {
    position: absolute;
    & {
      bottom: 0;
      opacity: 0;
      z-index: 2;
    }
    &Body {
      position: relative;
    }
  }
  &_boy {
    &Wing,
    &WingLeft,
    &WingRight {
      position: absolute;
    }
    & {
      animation: show-boy 0.4s 1.15s forwards cubic-bezier(0.34, 1.56, 0.64, 1);
      right: 0;
    }
    &Wing {
      animation: wave-hand 0.25s ease 1.7s 2 forwards;
      left: 100%;
      margin-left: -6px;
      top: 18px;
      transform-origin: left bottom;
    }
    &WingLeft {
      animation: maru-left 0.25s ease 1.7s 2 forwards;
      left: 0;
      margin-left: 2px;
      top: -11px;
      transform-origin: left bottom;
    }
    &WingRight {
      animation: maru-right 0.25s ease 1.7s 2 forwards;
      left: 100%;
      margin-left: -17px;
      top: -7px;
      transform-origin: right bottom;
    }
  }
  &_girl {
    & {
      animation: show-girl 0.4s 1.15s forwards cubic-bezier(0.34, 1.56, 0.64, 1);
      left: 0;
    }
    &Wing {
      animation: wave-hand 0.25s ease 1.7s 2 forwards;
      margin-right: -5px;
      position: absolute;
      right: 100%;
      top: 18px;
      transform-origin: right bottom;
    }
  }
}

@keyframes draw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes filling-s {
  0% {
    background: rgba($COLOR_SUBMIT, 0);
  }
  100% {
    background: rgba($COLOR_SUBMIT, 1);
  }
}

@keyframes show-parts {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes show-finish {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes show-wing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
}

@keyframes show-boy {
  0% {
    opacity: 0;
    transform: rotate(-8deg) translateX(0px);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: rotate(0deg) translateX(28px);
  }
}
@keyframes show-girl {
  0% {
    opacity: 0;
    transform: rotate(8deg) translateX(0px);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: rotate(0deg) translateX(-28px);
  }
}

@keyframes wave-hand {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes maru-left {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes maru-right {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
