import * as types from "@/store/benefit/mutationTypes";

const mutations = {
  [types.IS_LOAD_EDIT](state, isLoadEdit) {
    state.isLoadEdit = isLoadEdit;
  },
  [types.IS_USED](state, isUsed) {
    state.isUsed = isUsed;
  },
  [types.ANCHOR](state, anchor) {
    state.anchor = anchor;
  },
  [types.ANCHOR_HASH](state, hash) {
    const anchor = {
      hash,
      id: state.anchor.id,
    };
    state.anchor = anchor;
  },
  [types.ANCHOR_ID](state, id) {
    const anchor = {
      id,
      hash: state.anchor.id,
    };
    state.anchor = anchor;
  },
  [types.BENEFITS](state, benefits) {
    state.benefits = benefits;
  },
  [types.BENEFIT](state, benefit) {
    const index = state.benefits.findIndex((item) => item.id === benefit.id);
    if (index > -1) {
      state.benefits.splice(index, 1, benefit);
    }
    state.benefits.unshift(benefit);
  },
  [types.MASTER](state, master) {
    state.master = master;
  },
  [types.SET_BENEFIT_DATA](state, { benefit }) {
    state.benefit = benefit;
  },
  [types.SET_MASTER_DATA](state, { master }) {
    state.master = master;
  },
  [types.SET_USAGE](state, isUsed) {
    state.isUsed = isUsed;
  },
  // ハッシュタグ
  [types.SET_HASH](state, anchor) {
    state.anchor = anchor;
  },
  // 編集モードを切り替え
  [types.UPDATE_LOAD_EDIT](state, isEdit) {
    state.isLoadEdit = isEdit;
  },
  // 更新内容を反映
  [types.UPDATE_BENEFIT](state, { data, id }) {
    // 配列のkey取得
    const index = state.benefit[data.group_type].findIndex(
      (benefit) => benefit.id === id
    );

    // 元データを更新内容で結合&上書き（元データの used_count, used_fairs を残して上書き）
    const result_assign = Object.assign(
      state.benefit[data.group_type][index],
      data
    );
    // データを最上部に移動
    state.benefit[data.group_type] = state.benefit[data.group_type].filter(
      (b) => b.id != id
    );
    state.benefit[data.group_type].unshift(result_assign);
  },
  // 削除を反映
  [types.DELETE_BENEFIT](state, { id, group_type }) {
    state.benefit[group_type] = state.benefit[group_type].filter(
      (b) => b.id != id
    );
  },
  // 使用中フェアを更新
  [types.UPDATE_USED_FAIRS](state, { benefit, fairs }) {
    const index = state.benefit[benefit.group_type].findIndex(
      (item) => item.id === benefit.id
    );
    state.benefit[benefit.group_type][index].used_fairs = fairs;
    state.benefit[benefit.group_type][index].used_count = fairs.length;
  },
  // 特典の公開ステータスを反映
  [types.UPDATE_STATUS](state, { benefitId, group_type, publishStatus }) {
    // 配列のkey取得
    const index = state.benefit[group_type].findIndex(
      ({ id }) => id === benefitId
    );

    state.benefit[group_type][index].status = publishStatus;
  },
  // 公開中の特典の並び順を反映
  [types.UPDATE_SORT](state, { benefitId, mediaId, group_type, sort }) {
    // 配列のkey取得
    const index = state.benefit[group_type].findIndex(
      ({ id }) => id === benefitId
    );

    state.benefit[group_type][index].sort[mediaId] = sort;
  },
  [types.SHOW_PUBLISH_MODAL](state, showModal) {
    state.showPublishModal = showModal;
  },
  [types.UPDATE_SETTING](state, setting) {
    state.setting = setting;
  },
};

export default mutations;
