/**
 *benefit/mutations.js
 */
export const IS_LOAD_EDIT = "isLoadEdit";
export const IS_USED = "isUsed";
export const ANCHOR = "anchor";
export const ANCHOR_HASH = "anchorHash";
export const ANCHOR_ID = "anchorId";
export const BENEFITS = "benefits";
export const BENEFIT = "benefit";
export const MASTER = "master";
export const SET_LOADING_FINISH = "SET_LOADING_FINISH";
export const UPDATE_LOAD_EDIT = "UPDATE_LOAD_EDIT";
export const SET_HASH = "SET_HASH";
export const SET_USAGE = "SET_USAGE";
export const SET_BENEFIT_DATA = "SET_BENEFIT_DATA";
export const SET_MASTER_DATA = "SET_MASTER_DATA";
export const SET_FILTER = "SET_FILTER";
export const SET_FILTER_CHECK = "SET_FILTER_CHECK";
export const SET_SORT_KEY = "SET_SORT_KEY";
export const UPDATE_BENEFIT = "UPDATE_BENEFIT";
export const DELETE_BENEFIT = "DELETE_BENEFIT";
export const SET_TAB_ACTIVE = "SET_TAB_ACTIVE";
export const UPDATE_USED_FAIRS = "UPDATE_USED_FAIRS";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_SORT = "UPDATE_SORT";
export const SHOW_PUBLISH_MODAL = "SHOW_PUBLISH_MODAL";
export const UPDATE_SETTING = "UPDATE_SETTING";

/**
 * benefit/edit/mutations.js
 */
export const SELECTED_DATA = "selectedData";
export const MEDIA_IDS = "mediaIds";
export const GROUP_TYPE = "groupType";
export const BENEFIT_TYPES = "benefitTypes";
export const BENEFIT_TYPE = "benefitType";
export const TITLES = "titles";
export const TITLE = "title";
export const DESCRIPTIONS = "descriptions";
export const DESCRIPTION = "description";
export const LIMITED_TO_FAIR = "limitedToFair";
export const LIMITED_TO_WEBTSUKU = "limitedToWebtsuku";
export const PERIOD_FROMS = "periodFroms";
export const PERIOD_FROM = "periodFrom";
export const PERIOD_UNTILS = "periodUntils";
export const PERIOD_UNTIL = "periodUntil";
export const PERIOD_TEXTS = "periodTexts";
export const PERIOD_TEXT = "periodText";
export const IMAGE_IDS = "imageIds";
export const IMAGE_ID = "imageId";
export const MEDIA_ONLY = "mediaOnly";
export const RECOMMENDED = "recommended";
export const USED_COUNT = "usedCount";
export const USED_FAIRS = "usedFairs";
export const LICENSE_STATUS = "licenseStatus";
export const LINK_STATUSES = "linkStatuses";
export const LINK_STATUS = "linkStatus";
export const UPDATED_AT = "updatedAt";
export const TARGET_ID = "targetId";
export const PERIOD_TYPES = "periodTypes";
export const PERIOD_TYPE = "periodType";
export const IS_BY_MEDIA = "isByMedia";
export const IS_BY_MEDIUM = "isByMedium";
export const APPLICATION_FROMS = "applicationFroms";
export const APPLICATION_FROM = "applicationFrom";
export const APPLICATION_UNTILS = "applicationUntils";
export const APPLICATION_UNTIL = "applicationUntil";
export const PUBLICATION_FROMS = "publicationFroms";
export const PUBLICATION_FROM = "publicationFrom";
export const PUBLICATION_UNTILS = "publicationUntils";
export const PUBLICATION_UNTIL = "publicationUntil";
export const IS_HIGHLIGHTEDS = "isHighlighteds";
export const IS_HIGHLIGHTED = "isHighlighted";
export const DETAIL_TYPES = "detailTypes";
export const DETAIL_TYPE = "detailType";
export const DISCOUNT_TYPES = "discountTypes";
export const DISCOUNT_TYPE = "discountType";
export const NOTES_LIST = "notesList";
export const NOTES = "notes";
export const PRICE_NOTES_LIST = "priceNotesList";
export const PRICE_NOTES = "priceNotes";
export const LINK_TITLES = "linkTitles";
export const LINK_TITLE = "linkTitle";
export const LINK_URLS = "linkUrls";
export const LINK_URL = "linkUrl";

export const ADD_BENEFIT = "ADD_BENEFIT";
export const RESET_OTHER_RECOMMENDED_WP = "RESET_OTHER_RECOMMENDED_WP";
