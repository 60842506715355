<template>
  <benefit-form :title="title" :option-level="optionLevel">
    <input-item-media
      v-for="medium in media"
      v-model="value"
      :media-id="medium.id"
      :media-name="medium.short_name"
      :enabled-media="enabledMedia"
      :is-warning="isWepaUnpublished(medium.id)"
      :is-linked="isLinkedHallBenefit(medium.id)"
      :key="medium.id"
    >
      <template v-if="isWepaUnpublished(medium.id)" #option>
        <p class="media-status">
          非公開
          <help-popup>
            <template v-slot:text
              >公開する場合は「ウエパに公開にする」を行ってください。</template
            >
          </help-popup>
        </p>
      </template>
    </input-item-media>
  </benefit-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";
import OPTION_LEVEL from "@/assets/common/option-level";

import * as types from "@/store/benefit/mutationTypes";

import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputItemMedia from "@/components/organisms/common/PublicationMediaForm.vue";
import HelpPopup from "@/components/atoms/common/HelpPopup.vue";

export default {
  components: {
    BenefitForm,
    InputItemMedia,
    HelpPopup,
  },
  data() {
    return {
      optionLevel: OPTION_LEVEL.required,
      title: "掲載媒体",
    };
  },
  computed: {
    ...mapGetters({
      master: "master",
      groupType: "groupType",
      targetId: "targetId",
      mediaIds: "mediaIds",
      mediaIdsByGroupType: "mediaIdsByGroupType",
      publishStatus: "publishStatus",
      isLinked: "isLinked",
    }),
    value: {
      get() {
        return this.mediaIds;
      },
      set(value) {
        this.updateMediaIds(value);
      },
    },
    enabledMedia() {
      return this.mediaIdsByGroupType(this.groupType);
    },
    media() {
      return Object.values(this.master.media).filter((media) =>
        this.enabledMedia.includes(media.id)
      );
    },
  },
  methods: {
    ...mapMutations({ updateMediaIds: `${types.MEDIA_IDS}` }),
    isWepaUnpublished(mediaId) {
      if (mediaId !== API_CODE.media.wp) return false;
      return this.publishStatus !== API_CODE.status.public;
    },
    isLinkedHallBenefit(mediaId) {
      if (this.groupType !== BENEFIT_GROUP_TYPE.hall) return false;
      return this.targetId && this.isLinked(mediaId);
    },
  },
};
</script>

<style scoped lang="scss">
.media-status {
  display: flex;
  align-items: center;
  gap: $SUB_GRID;
}
</style>
