<template>
  <h2 class="chapterTitle">
    <span class="chapterTitle_number fontNum">{{ number }}</span>
    <span class="chapterTitle_title">{{ title }}</span>
  </h2>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped lang="scss">
.chapterTitle {
  align-items: center;
  color: $COLOR_KEY;
  display: flex;
  &_number {
    @include insertArrowicon(#b4b4b5);
    align-items: center;
    display: flex;
    font-size: 24px;
    &::after {
      margin: 0 $SUB_GRID + 4px 0 $SUB_GRID;
    }
  }
  &_title {
    font-size: $FONT_SIZE_M;
    font-weight: bold;
  }
}
</style>
