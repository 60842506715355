<template>
  <benefit-form
    :title="title"
    :note="note"
    :need-by-media="true"
    :option-level="optionLevel"
  >
    <by-media-input
      :key-name="keyName"
      :is-by-media="isByMedia"
      :title="title"
      @toggleByMedia="changeIsByMedia({ [keyName]: $event })"
    >
      <input-item-description
        v-for="mediaId in targetMedia"
        :key="mediaId"
        :media-id="mediaId"
        :option-level="inputOptionLevel(mediaId)"
      />
    </by-media-input>
  </benefit-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

import InputItemDescription from "@/components/benefit/inputs/InputItemDescription.vue";
import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import ByMediaInput from "@/components/benefit/presentation/ByMediaInput.vue";

export default {
  components: {
    InputItemDescription,
    BenefitForm,
    ByMediaInput,
  },
  props: {
    title: String,
  },
  data() {
    return {
      keyName: "description",
    };
  },
  computed: {
    ...mapGetters({
      isByMedium: "isByMedium",
      groupType: "groupType",
      $optionLevel: "optionLevel",
      onlyMediaName: "onlyMediaName",
      needNote: "needNote",
      selectedOnlyMedia: "selectedOnlyMedia",
    }),
    isByMedia() {
      return this.isByMedium(this.keyName);
    },
    isHallBenefit() {
      return this.groupType === BENEFIT_GROUP_TYPE.hall;
    },
    targetMedia() {
      return this.isByMedia
        ? this.selectedOnlyMedia(this.keyName)
        : [API_CODE.mediaCommon];
    },
    note() {
      // TODO: 要調査・改めて正しい注釈の表示条件を検討する
      // if (this.needNote(this.keyName)) {
      //   return this.onlyMediaName(this.keyName);
      // }
      return null;
    },
    optionLevel() {
      return this.$optionLevel([this.keyName]);
    },
  },
  methods: {
    ...mapActions({ changeIsByMedia: "changeIsByMedia" }),
    inputOptionLevel(mediaId) {
      if (this.isHallBenefit) {
        return this.$optionLevel([this.keyName], mediaId);
      }
      return null;
    },
  },
};
</script>
