import contactModule from "@/store/contact";
import JobsControl from "@/store/modules/JobsControl.js";
import ActiveHallUsers from "@/store/modules/ActiveHallUsers";
import MaintenanceMessage from "@/store/modules/MaintenanceMessage";
import manualModule from "@/store/manual";

const utilityModule = {
  namespaced: true,
  modules: {
    contact: contactModule,
    jobs: JobsControl,
    activeUsers: ActiveHallUsers,
    maintenanceMessage: MaintenanceMessage,
    manual: manualModule,
  },
};

export default utilityModule;
