/**
 * 文字列がURLかどうかを判別して返す
 * @param {String} url URL文字列
 * @return {Boolean} URLならtrue
 */

import typeToString from "../typeToString";
import isValidUrl from "../isValidUrl";

const isValidCompleteUrl = (url) => {
  const type = typeToString(url);
  if (type !== "String") return false;
  if (!isValidUrl(url)) return false;

  try {
    return !!new URL(url);
  } catch {
    return false;
  }
};

export default isValidCompleteUrl;
