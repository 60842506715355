<template>
  <div class="job">
    <page-title icon="history" title="更新履歴" />
    <job-tab-unit v-if="showJobTab" />
    <component :is="currentComponent" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { getCookieAccount } from "@/lib/cookie";

import PageTitle from "@/components/atoms/common/PageTitle.vue";
import JobListUnit from "@/components/organisms/job/JobListUnit.vue";
import HistoryListUnit from "@/components/organisms/job/HistoryListUnit.vue";
import JobTabUnit from "@/components/organisms/job/JobTabUnit.vue";

export default {
  components: {
    PageTitle,
    JobListUnit,
    HistoryListUnit,
    JobTabUnit,
  },
  data() {
    return {
      tabComponents: {
        waiting: "job-list-unit",
        executed: "history-list-unit",
        operationLogs: "history-list-unit",
      },
    };
  },
  computed: {
    ...mapGetters({ tab: "job/tab" }),
    showJobTab() {
      const { userable_type } = getCookieAccount();
      return userable_type !== "Company";
    },
    currentComponent() {
      return this.tabComponents[this.tab.active] || Object.values(this.tabComponents)[0];
    },
  },
};
</script>

<style scoped lang="scss"></style>
