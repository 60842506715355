<template>
  <div class="helpPopup" :class="addClass">
    <button class="helpPopup_btn" :title="title" @click.stop="onClick()">
      <i class="material-icons">{{ icon }}</i>
    </button>
    <transition name="popup">
      <div class="helpPopup_body" v-if="isShow" :class="{ typeOver }">
        <slot name="text" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    about: {
      type: String,
      default: "",
    },
    isAlert: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isShow: false,
      aboutTitle: {
        close: this.about ? this.about + "についてを閉じる" : "ヘルプを閉じる",
        show: this.about ? this.about + "とは?" : "ヘルプを見る",
      },
      typeOver: false,
    };
  },
  computed: {
    addClass: function () {
      return {
        isAlert: this.isAlert,
      };
    },
    icon: function () {
      return this.isAlert ? "error" : "help";
    },
    title: function () {
      return this.isShow ? this.aboutTitle.close : this.aboutTitle.show;
    },
  },
  methods: {
    onClick: function () {
      this.isShow = !this.isShow;
      if (this.isShow) {
        // ポップアップが見切れる場合はクラスをふる
        this.typeOver =
          window.innerWidth - 240 < this.$el.getBoundingClientRect().left;
      }
    },
    closePopup: function () {
      this.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
@include HelpPopup();

@keyframes jump {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(3px);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(1px);
  }
  80% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
