// divisionごとに並び順を結合しなおす
const joinOrderByDivision = (order, divisionIds) => {
  if (divisionIds == null || divisionIds.length < 1) return order;
  let joinedOrder = [];
  divisionIds.forEach((divisionId) => {
    const orderByDivision = order.filter(
      (item) => item.plan_division === divisionId
    );
    joinedOrder = joinedOrder.concat(orderByDivision);
  });
  return joinedOrder;
};

export default joinOrderByDivision;
