<template>
  <div class="entries" :style="{ height }">
    <div v-for="(item, index) in entries" class="entry" :class="entryClass(index)" :key="index">
      <p class="entry__short-media-name">
        {{ item[labelKey] }}
      </p>
      <div class="entry__content" :class="{ 'entry__content--disabled': disabled(index) }">
        <span v-if="disabled(index)">-</span>
        <slot v-else :name="index"></slot>
      </div>
      <div v-if="needButton(index)" class="entry__button">
        <action-button text="変更する" :is-small="true" @clickHandler="$emit('click', item.id)" />
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton.vue";

export default {
  emits: ["click"],
  components: {
    ActionButton,
  },
  props: {
    entries: {
      type: [Array, Object],
      default: () => [],
    },
    labelKey: {
      type: String,
      default: "short_name",
    },
    height: {
      type: String,
      default: "100%",
    },
    needActionButton: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: Function,
      default: () => {},
    },
    disabler: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    disabled: function (mediaId) {
      return this.disabler(parseInt(mediaId));
    },
    needButton: function (mediaId) {
      return !this.disabled(mediaId) && this.needActionButton;
    },
    entryClass: function (mediaId) {
      if (this.disabled(mediaId)) {
        return "entry--disabled";
      }
      return this.customClass(mediaId);
    },
  },
};
</script>

<style scoped lang="scss">
.entries {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.entry {
  width: 100%;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  grid-row-gap: $SUB_GRID;
  background-color: $BG_COLOR_BOX;

  &:nth-child(odd) {
    background-color: $BG_COLOR_FLOOR;
  }

  &:nth-child(odd) &__short-media-name {
    background-color: #9497ab;
  }

  &--disabled {
    background-color: $COLOR_DISABLE_TEXT;

    &:nth-child(odd) {
      background-color: darken($COLOR_DISABLE_TEXT, 5%);
    }
  }

  &__short-media-name {
    grid-row: 1;
    text-align: center;
    background-color: #999bb0;
    font-size: $FONT_SIZE_S;
    line-height: $FONT_SIZE_S * 1.5;
    color: #fff;
    padding: $SUB_GRID;
  }

  &__content {
    grid-row: 2;
    font-size: $FONT_SIZE_S;
    padding: 0 $SUB_GRID;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;

    &--disabled {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__button {
    grid-row: 3;
    display: flex;
    justify-content: center;
    padding: 0 $SUB_GRID $SUB_GRID;
  }
}
</style>
