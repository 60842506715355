import { addDays, addYears, parseISO, startOfToday } from "date-fns";

/**
 * 開始日を設定するDatepickerに渡すdisabledDateを取得する
 * 終了日が入力されている場合は現在日から終了日まで選択できる
 * 引数として年数を持たせた場合は指定の年数まで選択できる
 * 終了日がまだ入力されていない場合は現在日以降を選択できる
 * @param {*} validUntil - 終了日
 * @param {Number} years - 選択できる年数
 */
const disabledDateFrom = (validUntil, years = null) => {
  const to = startOfToday();

  if (years) {
    const from = addYears(to, years);
    return { to, from };
  }

  return { to };
};

export default disabledDateFrom;
