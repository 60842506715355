/**
 * 受け取った期間を現在の日付から以下の4つに分類
 * - before: 期間前
 * - within: 期間内
 * - soon: 期限切れ間近
 * - over: 期限切れ
 */

import withinDateLimit from "@/lib/withinDateLimit";

const classifyPeriodState = (start, end, monthDiff = 0) => {
  if (!start && !end) {
    return null;
  }

  if (end) {
    // 終了日よりも後の場合はover
    if (!withinDateLimit(end)) {
      return "over";
    }
    // 間近判定日（終了日 - 月差分）よりも後の場合はsoon
    if (!withinDateLimit(end, monthDiff)) {
      return "soon";
    }
    if (!start) {
      return "within";
    }
  }

  // 開始日よりも後の場合はwithin
  if (!withinDateLimit(start)) {
    return "within";
  }
  // 上記以外の場合はbefore
  return "before";
};

export default classifyPeriodState;
