<template>
  <div class="tags-input">
    <vue-tags-input
      v-model="text"
      :tags="selectedTags"
      :placeholder="placeholder"
      @tags-changed="tagsChanged"
    />
    <div>
      <filter-checkbox
        v-for="(tagName, index) in autocompleteTagNames"
        :key="index"
        :label="String(index)"
        :text="tagName"
        :value="isSelected(tagName)"
        :is-btn-type="true"
        @update="clickTagBox(tagName)"
      />
    </div>
  </div>
</template>

<script>
import { VueTagsInput, createTags } from "@sipec/vue3-tags-input";

import FilterCheckbox from "@/components/atoms/common/FilterCheckbox.vue";

export default {
  components: {
    VueTagsInput,
    FilterCheckbox,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    autocompleteTagNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      text: "",
    };
  },
  computed: {
    selectedTags() {
      return createTags(this.modelValue);
    },
    placeholder() {
      return this.selectedTags.length > 0 ? "" : "選択、または入力してください";
    },
  },
  methods: {
    tagsChanged(tags) {
      const newTags = tags.map((tag) => tag.text.trim());
      this.$emit("update:modelValue", [...new Set(newTags)]);
    },
    isSelected: function (name) {
      return this.modelValue.includes(name);
    },
    clickTagBox: function (name) {
      const newTags = this.isSelected(name)
        ? this.modelValue.filter((tagName) => tagName !== name)
        : [...this.modelValue, name];
      this.$emit("update:modelValue", newTags);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/inputTag.scss";

.tags-input {
  width: 100%;

  ::v-deep .vue-tags-input {
    background-color: inherit;
    max-width: inherit;
    &.isDisabledInput {
      .ti-new-tag-input {
        cursor: none;
        pointer-events: none;
      }
    }
    // 自動補完欄
    .ti-autocomplete {
      max-height: 27 * 5px;
      overflow: auto;
      .ti-item {
        height: 27px;
      }
    }
    // 入力欄
    .ti-input {
      background-color: #fff;
      border-radius: 8px;
    }
    // 自動補完の選択時
    .ti-selected-item {
      background-color: #1ea7fd;
    }
    // タグ
    .ti-tag {
      font-size: $FONT_SIZE_S;
      background-color: $COLOR_KEY;
      .ti-tag-center {
        > span {
          word-break: break-all;
        }
      }
      // 編集不可
      &.fixed {
        opacity: 0.5;
        .ti-actions {
          display: none;
        }
      }
      // 削除直前
      &.ti-deletion-mark {
        background-color: $COLOR_SUB_LIGHT_TEXT !important;
      }
    }
  }
}
</style>
