import { NgWordType } from "@/assets/common/NgWordType";
import commonModule from "@/store/common";
import modalModule from "@/store/modal";

const NgCheck = {
  namespaced: true,
  modules: {
    common: commonModule,
    modal: modalModule,
  },
  state: {
    loading: true,
    data: {},
  },
  getters: {
    // 媒体IDで連結したNGワードの配列生成
    targetWord: ({ data }) => (mediaIds, types = [NgWordType.COMMON]) => {
      const notExistsData = Object.keys(data).length < 1;
      if (notExistsData) {
        return [];
      }
      const commonWord = data.common.filter((word) => types.includes(word.type));
      const words = mediaIds
        .reduce((accumulator, id) => {
          const wordsByMedia = data[id].filter((word) => types.includes(word.type));
          return [...accumulator, ...wordsByMedia];
        }, commonWord)
        .map((word) => word.ng_word);
      // 重複削除
      return Array.from(new Set(words));
    },
  },
  mutations: {
    // 初期データの反映
    setWord(state, { data, media }) {
      // 媒体ごとに初期化
      let word_by_media = {};
      for (let m in media) {
        let key = media[m].id || "common";
        word_by_media[key] = [];
      }
      // NGワードのみを抽出
      for (let d of data) {
        let key = d.media_id || "common";
        word_by_media[key].push(d);
      }
      state.data = word_by_media;
    },
    // 初期データの反映 完了
    setLoadingWord(state) {
      state.loading = false;
    },
  },
  actions: {
    // NGワード・媒体情報を取得
    async getInitialData({ commit, dispatch, state }) {
      const success = await dispatch(
        "setInitialData",
        await dispatch("common/getInitialData", { apiUrl: "/ngWords" })
      );
      if (success) {
        commit("setLoadingWord");
      } else {
        const e = {
          isShow: true,
          text: "NGワードの取得に失敗しました　ページを再読み込みしてください",
        };
        commit("modal/setErrorModal", e);
      }
    },
    // 初期データを state に反映
    async setInitialData({ commit, dispatch, state }, { data }) {
      if (!data || !data.data) return false;
      commit("setWord", { data: data.data, media: data.master.media });
      return true;
    },
  },
};

export default NgCheck;
