/**
 * 配列内に要素があれば削除し、なければ追加する
 * @param array [Array]
 * @param item [Any]
 */
const arrayToggle = (array, item) => {
  if (!Array.isArray(array)) return false;
  const i = array.indexOf(item);
  if (i >= 0) {
    array.splice(i, 1);
  } else {
    array.push(item);
  }
  return array;
};
export default arrayToggle;