import countTextLengthZexy from "@/lib/countTextLengthZexy";
import countTextLengthMynavi from "@/lib/countTextLengthMynavi";

/**
 * 文字数をカウント
 * @param {String} text 対象の文字列（注意：NGワードを除外する事）
 * @param {Array} mediaIds 対象の媒体IDリスト
 * @param {Boolean} countLfc 改行コードをカウントするかどうか(lfc = line feed code)
 */
export const countTextLength = (
  text,
  mediaIds = [],
  countLfc = true,
  fullWidth = false
) => {
  if (!text || text.length === 0) return 0;
  const methods = getMethods(mediaIds);
  if (methods.length >= 2) return countTextLengthMix(text, methods);
  if (methods.includes("zexy")) return countTextLengthZexy(text, fullWidth);
  if (methods.includes("mynavi")) return countTextLengthMynavi(text);

  const replaceLfcText = text.replace(/\r\n|\r/g, "\n");
  const textToCount = countLfc
    ? replaceLfcText
    : replaceLfcText.replace(/\n/g, "");
  return textToCount.length;
};

/**
 * 文字数カウントの注釈を取得
 * @param {Array} mediaIds 対象の媒体IDリスト
 */
export const getTextLengthNote = (mediaIds = [], fullWidth = false) => {
  const methods = getMethods(mediaIds);
  return methods.length === 1 && methods.includes("zexy") && !fullWidth
    ? "（半角は0.5文字）"
    : "";
};

// カウント方式リスト
// 優先順
// code: 方式のコード / mediaIds: 対象媒体 / isMix: 複数方式の時に使用する方式かどうか / note: 注釈
const methodInfos = [
  {
    code: "mynavi",
    mediaIds: [5, 6],
    isMix: true,
  },
  {
    code: "general",
    mediaIds: [1, 4],
    isMix: false,
  },
  {
    code: "zexy",
    mediaIds: [2, 3],
    isMix: false,
  },
];

/**
 * 媒体IDに応じた文字数カウント方式を取得
 * @param {Array} mediaIds
 */
const getMethods = (mediaIds) => {
  if (mediaIds == null) return [];
  const ids = Array.isArray(mediaIds)
    ? mediaIds.map(Number)
    : [Number(mediaIds)];
  return methodInfos
    .filter((value) => ids.some((id) => value.mediaIds.includes(id)))
    .map((value) => value.code);
};

/**
 * 複数方式で文字数をカウント
 *
 * 文字数が増加するカウント方式を使用
 * @param {String} text 対象の文字列
 * @param {Array} methods カウント方式
 */
const countTextLengthMix = (text, methods = []) => {
  const mixMethods = methodInfos
    .filter((value) => value.isMix && methods.includes(value.code))
    .map((value) => value.code);
  if (mixMethods.includes("mynavi")) return countTextLengthMynavi(text);
  return text.length;
};
