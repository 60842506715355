<template>
  <div class="period-info" :class="periodClass">
    <div class="period-info__header">
      <p class="period-info__label">
        <i class="material-icons">schedule</i>{{ title }}
      </p>
      <p v-if="!isByMedia" class="period-info__common">
        {{ commonText }}
      </p>
    </div>
    <ul v-if="isByMedia" class="period-info__by-media">
      <li
        v-for="(item, index) in periodList"
        class="period-info__media-item"
        :key="index"
      >
        <span class="period-info__media-name">{{ item.mediaName }}</span>
        <span class="period-info__media-value" :class="itemClass(item.mediaId)">
          {{ item.value || "―" }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    isByMedia: {
      type: Boolean,
      default: false,
    },
    periodList: {
      type: Array,
      default: () => ({}),
    },
    commonPeriod: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    periodClass() {
      // 媒体個別になっている場合は追加のクラス名を付与しない
      if (this.isByMedia) {
        return null;
      }
      return this.itemClass();
    },
    commonText() {
      return this.commonPeriod.value || "―";
    },
  },
  methods: {
    itemClass(mediaId = API_CODE.mediaCommon) {
      const isCommon = mediaId === API_CODE.mediaCommon;
      const { value, status } = isCommon
        ? this.commonPeriod
        : this.periodList.find((periodText) => periodText.mediaId === mediaId);

      if (value === null) {
        return "empty";
      }
      return { soon: status === 1, expired: status === 2 };
    },
  },
};
</script>

<style scoped lang="scss">
.period-info {
  width: 100%;
  background: $BG_COLOR_SUB;
  border-radius: $BOX_RADIUS;
  color: $COLOR_SUB_TEXT;
  font-size: 11px;
  padding: ($GRID * 0.25) ($GRID * 0.5);

  &.empty {
    background: none;
  }

  &__header {
    display: flex;
    align-items: center;
    font-size: $FONT_SIZE_S;
    line-height: 14px;
  }

  &__label {
    width: $GRID * 5;

    .material-icons {
      font-size: 14px;
      margin-right: $SUB_GRID;
    }
  }

  &__common {
    flex: 1;
    padding: 2px $SUB_GRID;
  }

  // 媒体個別
  &__by-media {
    margin-top: $SUB_GRID;
    margin-left: $GRID * 0.5;
    list-style: none;
  }

  &__media-item {
    display: flex;
    align-items: center;
    margin-bottom: $SUB_GRID;
    font-size: 11px;
    color: $COLOR_SUB_LIGHT_TEXT;

    &:last-child {
      margin: 0;
    }
  }

  &__media-name {
    width: $GRID * 5;
    font-weight: bold;
  }

  &__media-value {
    flex: 1;
    padding: 2px $GRID * 0.5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.empty {
  color: $COLOR_DISABLE_TEXT;
}
.soon,
.expired {
  color: #fff;
  border-radius: $BOX_RADIUS;
}
.soon {
  background: $BG_PERIOD_WARNING;
}
.expired {
  background: $BG_PERIOD_ALERT;
}
</style>
