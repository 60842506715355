/**
 * 特典
 * 共通テキスト
 */

// タイトル
export const GROUP_TITLE = {
  1: "ゼクシィ・みんな・マイナビ用",
  2: "婚スタ・ウエパ・ハナユメ用",
};
