import deepCopy from "@/lib/deepCopy";

/**
 * 並び替え - １つ上または１つ下の項目と入れ替える
 * @param {array} order 並び替え対象の配列
 * @param {number} id 対象のID
 * @param {boolean} isToUp 上に移動、false の場合は下に移動
 * @param {string} keyName IDの要素名
 */
const swap = (order, id, isToUp, keyName = null) => {
  const key = keyName || "id";
  let index = order.findIndex((item) => item[key] === id);
  index = isToUp ? index - 1 : index;
  const dist = order[index],
    next = order[index + 1];
  if (dist == null || next == null) return;
  const swapOrder = deepCopy(order);
  swapOrder.splice(index, 2, next, dist);
  return swapOrder;
};

export default swap;
