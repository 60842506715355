<template>
  <div class="filterSelectWrap">
    <select class="filterSelect" v-model="selfValue">
      <option value v-if="defaultText" :disabled="isRequired">
        {{ defaultText }}
      </option>
      <option v-for="(option, val) in options" :value="val" :selected="selfValue == val" :key="val">
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: [String, Number],
    defaultText: {
      type: String,
      default: "全て",
    },
    options: {
      type: [Array, Object],
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selfValue: {
      get: function () {
        const value = this.modelValue;
        return value !== null ? value : "";
      },
      set: function (value) {
        if (value !== this.selfValue) {
          this.$emit("update:modelValue", value);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.filterSelect {
  &Wrap {
    display: inline-block;
    position: relative;
    &:after {
      border-style: solid;
      border-color: #bc9e74 transparent transparent transparent;
      border-width: 6px 4px 0 4px;
      content: "";
      display: block;
      margin-top: -3px;
      position: absolute;
      right: 8px;
      top: 50%;
    }
  }
  & {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    padding: 3px 20px 3px 10px;
    width: 100%;
  }
}
</style>
