<template>
  <div class="jobTabUnit">
    <p class="supplement">※ 媒体の都合により、サイトへの反映に時間がかかることがあります</p>
    <tab-btn
      v-for="(v, i) in tab.key"
      :key="i"
      :text="v"
      :is-active="i == tab.active"
      :num="i == 'draft' ? draftCount : null"
      @tabChange="tabChange(i)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import TabBtn from "@/components/atoms/common/TabButton.vue";

export default {
  components: {
    TabBtn,
  },
  computed: {
    ...mapGetters({ tab: "job/tab" }),
  },
  methods: {
    ...mapActions({
      updateTabKey: "job/updateTabKey",
      getExecutedJobsApi: "job/getExecutedJobsApi",
      updateFilterType: "job/filter/updateFilterType",
      initialize: "job/filter/initialize",
    }),
    async tabChange(key) {
      if (key === this.tab.active) {
        // 現在開いているタブをクリックした際は、処理をスキップ
        return;
      }
      this.updateTabKey(key);
      this.updateFilterType(key);
      this.initialize();
      if (key != "executed") {
        // 連携履歴タブから移動した時にデータを初期化
        await this.getExecutedJobsApi();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.jobTabUnit {
  margin-bottom: $GRID;
}

.supplement {
  margin-bottom: $SUB_GRID;
  font-size: $FONT_SIZE_S;
  color: $COLOR_KEY;
}
</style>
