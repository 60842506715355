import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "benefits";
  const { instance, index, store, show, update, destroy } = Repository(
    `${hallId}/${resource}`,
    token
  );
  return {
    index,
    store,
    show,
    update,
    destroy,
    patch: (benefitId, payload) => instance.patch(`${benefitId}`, payload),
    linkFairs: (benefitId, payload) => instance.put(`${benefitId}/link-fairs`, payload),
    publish: (benefitId) => instance.put(`${benefitId}/publish`),
    unpublish: (benefitId) => instance.put(`${benefitId}/unpublish`),
    bulkPublish: (payload) => instance.put("bulk-publish", payload),
    bulkUnpublish: (payload) => instance.put("bulk-unpublish", payload),
    updateOrder: (payload) => instance.put("order", payload),
    replaceBenefit: (benefitId, payload) => instance.put(`${benefitId}/replace`, payload),
  };
};
