/**
 * 現在の日付が期限日よりも前ならtrueを返す
 *
 * @param {String|Date} date 期限日、文字列（yyyy-mm-dd）かDateオブジェクトのみ対応
 * @param {Number} diff 現在の日付からずらす日数/月数、月数の場合のみ小数点以下は0.5のみ14日換算で対応
 * @param {Boolean} useMonth diffを月数としては使用するか
 * @return {Boolean}
 */

import startOfToday from "date-fns/startOfToday";
import startOfDay from "date-fns/startOfDay";
import isBefore from "date-fns/isBefore";
import isEqual from "date-fns/isEqual";
import addMonths from "date-fns/addMonths";
import addDays from "date-fns/addDays";
import toDate from "../toDate";

const withinDateLimit = (date, diff = 0, useMonth = false) => {
  let today = startOfToday();
  const compDate = startOfDay(toDate(date));
  let days = diff;
  if (useMonth) {
    const months = Math.floor(diff),
      remainder = diff - months;
    if (months > 0) {
      today = addMonths(today, months);
    }
    // 月数の小数部分が0.5の場合のみ、14日換算で調整
    days = remainder === 0.5 ? 14 : 0;
  }

  if (days > 0) {
    today = addDays(today, days);
  }
  return isEqual(today, compDate) || isBefore(today, compDate);
};

export default withinDateLimit;
