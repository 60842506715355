/**
 * 入力項目一覧
 * - ここに追加した項目が、stateとmutationsに自動で追加される
 */

// 媒体側フェアID
export const MEDIA_FAIR_IDS = "media_fair_ids";

// 基本設定
export const MEDIA_IDS = "media_ids";
export const IS_SET_BY_MEDIA = "is_set_by_media";
export const TITLE = "title";
export const TITLE_BY_MEDIA = "title_by_media";
export const SHORT_TITLE = "short_title";
export const DESCRIPTION = "description";
export const DESCRIPTION_BY_MEDIA = "description_by_media";

// フェア構成
export const HELD_TYPE = "held_type";
export const PARTS = "parts";
export const TOTAL_START_TIME = "total_start_time";
export const TOTAL_END_TIME = "total_end_time";
export const IS_RESERVABLE_ANYTIME = "is_reservable_anytime";
export const DURATION = "duration";
export const PARTS_TIMETABLES = "parts_timetables";
export const PARTS_TO_MEDIA = "parts_to_media";
export const ACCEPT_COMBINED_BOOKING = "accept_combined_booking";
export const ACCEPT_REAL_TIME_BOOKING = "accept_real_time_booking";
export const RESERVATION_TYPE = "reservation_type";
export const MYNW_FULL_STOCK = "mynw_full_stock";
export const MYNW_UN_AUTO_DECREMENT = "mynw_un_auto_decrement";
export const UNIT_TYPE = "unit_type";
export const ZEBRA_RESERVATION_STOCK = "zebra_reservation_stock";
export const IS_INCLUDED_IN_SEARCH = "is_included_in_search";
export const SWITCH_TO_REQUEST_BOOKING = "switch_to_request_booking";
export const REMAINING_TO_SWITCH_TO_REQUEST = "remaining_to_switch_to_request";
export const CONTENTS = "contents";
export const CONTENTS_TO_MEDIA = "contents_to_media";
export const CONTENTS_TIMETABLES = "contents_timetables";
export const RESERVABLE_BY_CONTENTS = "reservable_by_contents";

// 画像設定
export const IMAGE = "image";
export const COMMON_IMAGE = "common_image";
export const IS_SET_IMAGES_BY_MEDIA = "is_set_images_by_media";
export const OPTIONAL_IMAGE = "optional_images";
export const MOVIE_SECTION = "movie_section";

// 特典設定
export const BENEFITS = "benefits";

/* 受付・掲載期間設定 */
export const SECTION_BOOKING = "section_booking";

/* ネット受付 */
// ネット受付設定するか
export const BOOKABLE_ONLINE = "bookable_online";
// 共通・媒体個別設定
export const BOOK_ONLINE_AVAILABLE = "book_online_available";
// ネット受付の掲載期間、受付時間設定
export const BOOK_ONLINE_AVAILABLE_DAYS = "book_online_available_until_days";
export const BOOK_ONLINE_AVAILABLE_DAYS_BY_MEDIA =
  "book_online_available_until_days_by_media";
export const BOOK_ONLINE_AVAILABLE_TIME = "book_online_available_until_time";
export const BOOK_ONLINE_AVAILABLE_TIME_BY_MEDIA =
  "book_online_available_until_time_by_media";

/* 電話受付 */
// 電話受付設定するか
export const BOOKABLE_BY_PHONE = "bookable_by_phone";
// 共通・媒体個別設定
export const BOOK_BY_PHONE_AVAILABLE = "book_by_phone_available";
// 電話受付の掲載期間、受付時間設定
export const BOOK_BY_PHONE_AVAILABLE_DAYS =
  "book_by_phone_available_until_days";
export const BOOK_BY_PHONE_AVAILABLE_DAYS_BY_MEDIA =
  "book_by_phone_available_until_days_by_media";
export const BOOK_BY_PHONE_AVAILABLE_TIME =
  "book_by_phone_available_until_time";
export const BOOK_BY_PHONE_AVAILABLE_TIME_BY_MEDIA =
  "book_by_phone_available_until_time_by_media";

// アクセス
export const VENUE_ID = "venue_id";

// 日毎設定用
export const MASTER_FAIR_ID = "master_fair_id";
export const MASTER_FAIR_TITLE = "master_fair_title";
export const MASTER_FAIR_MEDIA_IDS = "master_fair_media_ids";
export const DAILY_FAIR = "daily_fair";

// その他
export const CLUSTER = "cluster";
export const IS_SMALL_WEDDING = "is_small_wedding";
export const RECOMMENDED_CATCHPHRASE = "recommended_catchphrase";
export const RECOMMENDED_DESCRIPTION = "recommended_description";
export const USE_RECOMMENDED = "use_recommended";
export const REFERRER_NAME = "referrer_name";
export const REFERRER_DUTY = "referrer_duty";
export const REFERRER_IMAGE_ID = "referrer_image_id";
export const QUESTION = "question";
export const IS_ANSWER_REQUIRED = "is_answer_required";
export const PRICE_NOTES = "price_notes";
export const PRICE_NOTES_BY_MEDIA = "price_notes_by_media";
export const IS_SET_PRICE_NOTES_BY_MEDIA = "is_set_price_notes_by_media";
export const RESERVATION_NOTES = "reservation_notes";
export const RESERVATION_NOTES_BY_MEDIA = "reservation_notes_by_media";
export const URL_PC = "url_pc";
export const URL_SP = "url_sp";
export const USE_WEBTSUKU_PC = "use_webtsuku_pc";
export const USE_WEBTSUKU_SP = "use_webtsuku_sp";
export const RESERVE_TARGET = "target";
export const RESERVE_TARGET_BY_MEDIA = "target_by_media";
export const OTHERS = "others";
export const OTHERS_BY_MEDIA = "others_by_media";
export const BY_MEDIA = "by_media";
export const IS_DRAFT = "is_draft";
export const IS_PARTIAL_UPDATE = "is_partial_update";
export const LINK_STATUS = "link_status";
export const CATCHPHRASE = "catchphrase";
export const HP_RECOMMENDS = "recommended_points";
export const HAS_RECOMMENDATION = "has_recommendation";
export const HP_CUSTOMS = "custom_fields";
