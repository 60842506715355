<template>
  <section class="benefit-detail">
    <benefit-unit-kind
      :group-type="benefit.group_type"
      :media-ids="benefit.media_ids"
      class="benefit-detail__item"
    />
    <p class="benefit-detail__type benefit-detail__item">{{ benefitType }}</p>
    <benefit-unit-image
      :group-type="benefit.group_type"
      :image-ids="benefit.image_id"
      class="benefit-detail__item"
    />
    <benefit-unit-title
      :title="benefit.title"
      :media-ids="benefit.media_ids"
      :media="master.media"
      class="benefit-detail__item"
    />
    <benefit-unit-text-info
      v-if="hasValue(benefit.description)"
      :text-info="benefit.description"
      :media-ids="benefit.media_ids"
      :media="master.media"
      label="備考"
      class="benefit-detail__item"
    />
    <benefit-unit-valid-period-fair
      :media-ids="mediaIdsPeriod"
      :status="benefit.license_status"
      :period-from="benefit.valid_from"
      :period-until="benefit.valid_until"
      :valid-text="benefit.valid_period_text"
      :status-master="master.license_status"
      :media="master.media"
      title="適用期間"
      class="benefit-detail__item"
    />
    <div class="benefit-detail__item benefit-detail__footer">
      <slot name="benefitFooter" />
    </div>
    <slot name="benefitMenu" />
    <slot name="benefitStatus" />
  </section>
</template>

<script>
import BenefitUnitKind from "@/components/benefit/presentation/BenefitUnitKind.vue";
import BenefitUnitImage from "@/components/benefit/presentation/BenefitUnitImage.vue";
import BenefitUnitTitle from "@/components/benefit/presentation/BenefitUnitTitle.vue";
import BenefitUnitTextInfo from "@/components/benefit/presentation/BenefitUnitTextInfo.vue";
import BenefitUnitValidPeriodFair from "@/components/benefit/presentation/BenefitUnitValidPeriodFair.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    BenefitUnitKind,
    BenefitUnitImage,
    BenefitUnitTitle,
    BenefitUnitTextInfo,
    BenefitUnitValidPeriodFair,
  },
  props: {
    benefit: Object,
  },
  computed: {
    ...mapGetters({
      master: "master",
      mediaShortName: "mediaShortName",
      $benefitTypes: "benefitTypeOptions",
      onlyMedia: "rules/onlyMedia",
    }),
    benefitType() {
      const benefitTypes = this.$benefitTypes(this.benefit.group_type);
      const targetType = Object.values(this.benefit.type).find((type) => type);
      return (benefitTypes[targetType] || {}).name;
    },
    mediaIdsPeriod() {
      const onlyMedia = this.onlyMedia("valid_from", this.benefit.group_type);
      return this.benefit.media_ids
        .filter((mediaId) => onlyMedia.includes(mediaId))
        .sort((a, b) => a - b);
    },
  },
  methods: {
    hasValue(targetObj) {
      if (!targetObj) return false;
      return Object.values(targetObj).some((value) => value !== null);
    },
  },
};
</script>

<style scoped lang="scss">
.benefit-detail {
  @include Box();
  min-width: $GRID * 22;
  padding: $GRID;
  position: relative;

  &__type {
    color: $COLOR_KEY;
    font-size: 11px;
    text-decoration: underline;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    gap: $GRID $SUB_GRID;
  }

  &__item + &__item {
    margin-top: $GRID;
  }
}
</style>
