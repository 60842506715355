<template>
  <div class="media-form" :class="modifierClass" @click="onClickSelf">
    <p class="media-form__media-name" :class="announce">{{ mediaName }}</p>
    <coremonet-announce v-if="needAnnounce" class="media-form__announce" />
    <span v-else-if="isLocked || isLinked" class="material-icons"> lock </span>
    <div v-else class="media-form__input">
      <checkbox
        :model-value="modelValue"
        :checked-value="mediaId"
        name="publication-media"
        @update:modelValue="updateValue"
        @click.stop
      />
      <slot name="option" />
    </div>
    <p v-if="isLinked" class="media-form__information">連携中</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CoremonetAnnounce from "@/components/molecules/common/CoremonetAnnounce.vue";
import Checkbox from "@/components/plan-detail/inputs/Checkbox.vue";

import API_CODE from "@/assets/common/ApiCode";

/**
 * ブロックをクリックした際にエミットする値を生成する
 * @param {number[]} mediaIds - 選択されている媒体IDの配列
 * @param {number} mediaId - 媒体ID
 * @return - 新しい値
 */
const valueForCustomUpdate = (mediaIds, mediaId) => {
  const index = mediaIds.findIndex((item) => item === mediaId);
  if (index >= 0) {
    return [...mediaIds].filter((item) => item !== mediaId);
  }

  return [...mediaIds, mediaId];
};

export default {
  components: {
    CoremonetAnnounce,
    Checkbox,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    mediaId: {
      type: Number,
      default: 0,
    },
    mediaName: {
      type: String,
      default: "",
    },
    enabledMedia: {
      type: Array,
      default: () => [],
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    isLinked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      enabledCoremonet: "common/enabledCoremonet",
      isVendor: "common/isVendor",
    }),
    isHp() {
      return this.mediaId === API_CODE.media.homepage;
    },
    isLocked() {
      return this.isVendor && !this.isHp;
    },
    modifierClass() {
      return {
        "media-form--disabled": this.needAnnounce || this.isLocked || this.isLinked,
        "media-form--even": this.enabledMedia.indexOf(this.mediaId) % 2 === 0,
        "media-form--checked": this.isChecked,
        "media-form--warning": this.isWarning,
      };
    },
    isChecked() {
      return this.modelValue.some((mediaId) => mediaId === this.mediaId);
    },
    needAnnounce() {
      return this.isHp && !this.enabledCoremonet;
    },
    announce() {
      return this.needAnnounce ? "announce" : null;
    },
  },
  methods: {
    updateValue(value) {
      if (!this.needAnnounce) {
        this.$emit("update:modelValue", value);
      }
    },
    onClickSelf() {
      if (this.isLocked || this.isLinked) return;
      const value = valueForCustomUpdate(this.modelValue, this.mediaId);
      this.updateValue(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.media-form {
  width: 100%;
  min-height: 95px;
  background-color: #eee;
  border-left: 1px solid transparent;
  padding: $GRID $SUB_GRID ($SUB_GRID * 2);
  position: relative;
  text-align: center;
  transition: 0.3s;
  font-size: $FONT_SIZE_S;

  &:not(.media-form--disabled) {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  // Modifier
  &--even {
    background-color: #dcdcdc;
  }

  &--checked {
    background-color: $COLOR_KEY;
    border-left-color: rgba(255, 255, 255, 0.2);
    color: $BG_COLOR_BOX;
  }

  &--warning {
    background-color: $COLOR_WARNING;
  }

  // Children
  &__media-name {
    margin-bottom: $GRID;

    &.announce {
      margin-bottom: $GRID * 0.5;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $GRID;
  }

  &__announce {
    margin: 0 auto;
  }

  &__information {
    margin-top: 10px;
  }
}
</style>
