import { httpClient } from "@/lib/httpClient";

import commonModule from "@/store/common";

const MaintenanceMessage = {
  namespaced: true,
  modules: {
    common: commonModule,
  },
  state: {
    message: null,
  },
  getters: {
    message: (state) => {
      return state.message;
    },
  },
  mutations: {
    message(state, message) {
      state.message = message;
    },
  },
  actions: {
    /**
     * 有効期限切れの特典の有無を取得
     */
    async fetchExpiredBenefitMessage({ getters, commit }) {
      const isBenefitsViewable = getters["common/isAuthorized"]({ name: "benefits", type: "view" });
      if (!isBenefitsViewable) {
        return;
      }
      const url = "/benefits/expires-soon";
      const { data } = await httpClient.get(url);
      const benefits = data.data || [];
      const existsExpiredBenefit = benefits.some(({ license_status }) => license_status == 2);
      if (existsExpiredBenefit) {
        commit("message", "使用中で有効期限切れのフェア特典があります。ご対応下さい。");
      }
    },
  },
};

export default MaintenanceMessage;
