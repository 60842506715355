import API_CODE from "@/assets/common/ApiCode";

const isByMedia = (period) => {
  const commonIsNull = period[API_CODE.mediaCommon] === null;
  const mediaHasValue = Object.values(period).some((value) => value !== null);

  return commonIsNull && mediaHasValue;
};

export default isByMedia;
