import { getCookieAccount } from "@/lib/cookie";

import { createAxiosInstance } from "./repository";

const storeContact = async (payload) => {
  const { token } = getCookieAccount();
  const axios = createAxiosInstance(token);
  const response = await axios.post("contact", payload);
  return response;
};

export { storeContact };
