<template>
  <div class="errorView">
    <div class="errorBox">
      <p class="errorBox_title">ご指定のページが見つかりませんでした</p>
      <img class="errorBox_img" src="/img/404_bird.png" />
      <p class="errorBox_text">
        ページが削除されたか、URLが間違っている可能性があります。
        <br />ご指定のURLをご確認ください。
      </p>
      <div v-if="isLoginPage" class="errorBox_link">
        <link-button class="errorBox_link_item" href="/login/" text="ログインページ" />
      </div>
    </div>
  </div>
</template>

<script>
// component
import LinkButton from "@/components/atoms/common/LinkButton.vue";
// store
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    LinkButton,
  },
  computed: {
    isLoginPage: function() {
      return (
        location.pathname == "/login" ||
        location.pathname.indexOf("/login/") === 0
      );
    },
  },
  mounted: function() {
    this.getLoginAccount();
  },
  methods: {
    ...mapActions("common", ["getLoginAccount"]),
  },
};
</script>

<style scoped lang="scss">
.errorView {
  box-sizing: border-box;
  .errorBox {
    @include Box();
    color: $COLOR_KEY;
    margin: 0 auto;
    max-width: $MAX_WIDTH;
    padding: $GRID * 2;
    text-align: center;
    &_title {
      font-size: 24px;
    }
    &_img {
      height: $GRID * 10;
      padding: $GRID 0;
    }
    &_link {
      align-items: center;
      display: flex;
      justify-content: center;
      &_item {
        margin-top: $GRID;
        & + & {
          margin-left: $GRID;
        }
      }
    }
    :not(.mainBlock) & {
      margin-top: $GRID * 2;
    }
  }
}
</style>
