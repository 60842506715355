/**
 * 引数をDateオブジェクトに変換して返す
 * - Dateオブジェクトはそのまま返す
 * - 文字列はハイフン区切りのみ対応
 * - 数値（UNIX timestamp）には未対応
 *
 * @param {*}} target 変換したいデータ
 * @return {Date|null}
 */

import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";
import typeToString from "../typeToString";

const toDate = (target) => {
  // 文字列の場合はハイフン区切りの場合のみ変換
  if (typeToString(target) === "String") {
    return target.indexOf("-") >= 0 ? parseISO(target) : null;
  }

  // 既にDateオブジェクトだった場合はそのまま返す
  if (isValid(target)) {
    return target;
  }
  return null;
};

export default toDate;
