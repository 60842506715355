// Store Module
import commonModule from "@/store/common";
import utilityModule from "@/store/utility";
import modalModule from "@/store/modal";

import filterModule from "@/store/job/FilterModule";
import setupModule from "@/store/job/SetupModule";
import operationLogs from "@/store/job/operationLogs";

import actions from "@/store/job/main/actions";
import getters from "@/store/job/main/getters";
import mutations from "@/store/job/main/mutations";
import API_CODE from "../../../assets/common/ApiCode";

const jobModule = {
  namespaced: true,
  modules: {
    common: commonModule,
    filter: filterModule,
    utility: utilityModule,
    modal: modalModule,
    setup: setupModule,
    operationLogs,
  },
  state: {
    executedJobs: {},
    errorText: "",
    master: {},
    waitingJobs: {},
    tab: {
      active: "waiting",
      key: {
        waiting: "待機中",
        executed: "連携履歴",
        operationLogs: "操作履歴",
      },
    },
    isShowParent: false,
    queryName: {
      keyword: "title",
      period: "requested_date",
      status: "status",
      type: "data_type",
      media_id: "media_id",
      hall_id: "hall_id",
    }, // 実行ログ取得時のクエリパラメータ名
    isAuthorizedLink: {
      media: {
        permissionName: "mediaAccounts",
        view: false,
        edit: false,
      },
      fair: {
        permissionName: "fairs",
        view: false,
        edit: false,
      },
      image: {
        permissionName: "images",
        view: false,
        edit: false,
      },
      benefit: {
        permissionName: "benefits",
        view: false,
        edit: false,
      },
      hallBenefit: {
        permissionName: "hallBenefits",
        view: false,
        edit: false,
      },
      setting: {
        permissionName: "settings",
        view: false,
        edit: false,
      },
    },
    linkStatusCode: API_CODE.linkStatus,
  },
  actions,
  getters,
  mutations,
};

export default jobModule;
