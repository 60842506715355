<template>
  <section class="benefit-form">
    <div class="benefit-form__header">
      <input-option-icon
        class="benefit-form__icon"
        size="s"
        :option-level="optionLevel"
      />
      <div>
        <p class="benefit-form__title" v-html="title"></p>
        <p v-if="note" class="benefit-form__notes">{{ note }}</p>
      </div>
      <help-popup
        class="benefit-form__help"
        v-if="about || text"
        :about="about"
      >
        <template v-slot:text>{{ text }}</template>
      </help-popup>
    </div>
    <div class="benefit-form__body">
      <p v-if="needByMedia && !hasMedia" class="alert">
        連携する媒体を選択してください
      </p>
      <template v-else>
        <slot />
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import InputOptionIcon from "@/components/atoms/common/InputOptionIcon.vue";
import HelpPopup from "@/components/atoms/common/HelpPopup.vue";

export default {
  components: {
    InputOptionIcon,
    HelpPopup,
  },
  props: {
    needByMedia: {
      type: Boolean,
      default: false,
    },
    optionLevel: {
      type: Number,
      default: 0, // 0:アイコン無し 1:任意 2:必須
    },
    title: {
      type: String,
      default: "",
    },
    about: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    note: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["hasMedia"]),
  },
};
</script>

<style scoped lang="scss">
$TITLE_WIDTH: 25%;
.benefit-form {
  display: flex;
  font-size: 14px;

  &__header {
    display: flex;
    align-items: center;
    width: $TITLE_WIDTH;
    margin: $GRID $GRID * 0.5;
    color: $COLOR_KEY;
    font-size: 11px;
  }

  &__icon {
    height: 30px;
    margin-right: 10px;
    width: 30px;
    flex-shrink: 0;
  }

  &__title {
    font-weight: bold;
  }

  &__notes {
    margin-top: -2px;
    font-size: 10px;
    font-weight: normal;
  }

  &__help {
    margin: 0 0 0 auto;
  }

  &__body {
    display: flex;
    width: calc(100% - #{$TITLE_WIDTH});
    min-height: 85px;
  }

  .alert {
    display: flex;
    align-items: center;
    color: $COLOR_ALERT;
  }

  &:nth-child(odd) {
    background: $DETAIL_ROW_EVEN_COLOR;
  }
}
</style>
