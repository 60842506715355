<template>
  <section class="input-item" :class="{ 'by-media': isByMedia }">
    <div v-if="isByMedia" class="input-item__media-name">{{ mediaName }}</div>
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    mediaName: {
      type: String,
      default: null,
    },
    isByMedia: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.input-item {
  width: 100%;
  padding: $SUB_GRID * 2;
  padding-left: 0;

  &.by-media {
    @include gradientMediaSet();
    padding-left: $SUB_GRID * 2;
  }

  &__media-name {
    margin-bottom: $SUB_GRID;
    color: $COLOR_KEY;
    font-size: 11px;
  }
}
</style>
