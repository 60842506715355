/**
 * 年-月-日 を 0埋めの年.月.日に変換する
 * @param {String} date "2019-01-01" or "2019-01-01 12:12:12"
 * @param {Boolean} isSetTime 時間を表示するかどうか
 */
const formatDateText = (date, isSetTime = false) => {
  // 2019-01-01 -> 2019.1.1 に変換
  const isDateString = date && date.match(/\d{4}-\d{2}-\d{2}/);
  if (!isDateString) return;

  let dt = [];
  dt = date.split(" ");

  let d = [];
  d = dt[0].split("-");
  d = d.map((str) => parseInt(str, 10));
  d = d.join(".");
  if (isSetTime) {
    return d + " " + dt[1];
  } else {
    return d;
  }
};
export default formatDateText;
