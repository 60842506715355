<template>
  <modal-header-window
    class="modalManual"
    icon="school"
    title="マニュアル"
    :is-large-icon="true"
    :is-small-title="true"
    :is-set-footer="false"
    @close="close"
  >
    <template #content>
      <download-list
        :title="data.title"
        :date="data.date"
        :batch-path="data.path"
        :files="data.files"
        @select="download"
      />
      <contact-detail :is-horizontal="true" :is-show-button="true" />
    </template>
  </modal-header-window>
</template>

<script>
// component
import ContactDetail from "@/components/molecules/common/ContactDetail";
import DownloadList from "@/components/molecules/common/DownloadList";
import ModalHeaderWindow from "@/components/molecules/common/ModalHeaderWindow";
// store
import { mapActions, mapState } from "vuex";

export default {
  components: {
    ContactDetail,
    DownloadList,
    ModalHeaderWindow,
  },
  computed: {
    ...mapState("utility/manual", ["data"]),
  },
  methods: {
    ...mapActions("utility/manual", ["closeModalManual", "download"]),
    close() {
      this.closeModalManual();
    },
  },
};
</script>

<style scoped lang="scss">
.modalManual {
  ::v-deep .modalHeaderWindow {
    .modalContentInr {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
  .contactDetail {
    margin-bottom: $GRID;
  }
  .downloadList {
    margin-bottom: $GRID * 2;
  }
}
</style>
