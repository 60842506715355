<template>
  <p :class="{ 'with-tag': !needBenefitGroupLabel }" class="benefit-kind">
    <span v-if="needBenefitGroupLabel" class="kind__benefit-type">{{
      kindLabel
    }}</span>
    <span v-for="mediaId in mediaIds" :key="mediaId" class="kind__media">
      {{ mediaShortName(mediaId) }}
    </span>
  </p>
</template>

<script>
import { mapGetters } from "vuex";

import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

export default {
  props: {
    groupType: {
      type: Number,
      default: 1,
    },
    mediaIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      enabledHallBenefits: "enabledHallBenefits",
      mediaShortName: "mediaShortName",
    }),
    needBenefitGroupLabel() {
      return this.enabledHallBenefits ? this.groupType !== 2 : false;
    },
    kindLabel() {
      const isFair = this.groupType === BENEFIT_GROUP_TYPE.fair;
      const kind = isFair ? "フェア" : "式場";
      return `${kind}特典`;
    },
  },
};
</script>

<style scoped lang="scss">
.benefit-kind {
  color: $COLOR_KEY;
  margin-left: -$GRID;

  &.with-tag {
    position: relative;
    margin-left: 0px;
    &::before {
      background: $COLOR_KEY;
      content: "";
      left: -$GRID;
      height: 100%;
      position: absolute;
      top: 0;
      width: 3px;
    }
  }

  .kind__benefit-type {
    background-color: $COLOR_KEY;
    color: #fff;
    margin-right: 6px;
    padding: 6px;
    font-weight: bold;
    font-size: 14px;
  }

  .kind__media {
    color: $COLOR_SUBMIT;
    border: solid 1px $COLOR_SUBMIT;
    margin: 0px 2px;
    padding: 2px;
    font-size: 11px;
  }
}
</style>
