import API_CODE from "@/assets/common/ApiCode";

const requiredItem = (required) => ({
  [API_CODE.media.ksm]: required,
  [API_CODE.media.wp]: required,
  [API_CODE.media.hanayume]: required,
  [API_CODE.media.homepage]: required,
});

const allMedia = [
  API_CODE.media.ksm,
  API_CODE.media.wp,
  API_CODE.media.hanayume,
  API_CODE.media.homepage,
];

const wpState = {
  characters: {
    description: {
      [API_CODE.media.ksm]: 120,
      [API_CODE.media.wp]: 100,
      [API_CODE.media.hanayume]: 100,
      [API_CODE.media.homepage]: 120,
    },
    title: {
      [API_CODE.media.ksm]: 60,
      [API_CODE.media.wp]: 50,
      [API_CODE.media.hanayume]: 20,
      [API_CODE.media.homepage]: 500,
    },
    valid_period_text: {
      [API_CODE.media.homepage]: 100,
    },
  },
  isRequired: {
    media_ids: requiredItem(true),
    description: {
      [API_CODE.media.ksm]: false,
      [API_CODE.media.wp]: false,
      [API_CODE.media.hanayume]: true,
      [API_CODE.media.homepage]: false,
    },
    image_id: requiredItem(false),
    is_limited_to_fair: requiredItem(false),
    is_limited_to_webtsuku: requiredItem(false),
    is_media_only: requiredItem(false),
    recommended: requiredItem(false),
    title: requiredItem(true),
    type: requiredItem(true),
    valid_from: requiredItem(true),
    valid_period_text: requiredItem(false),
    valid_until: requiredItem(false),
  },
  onlyMedia: {
    media_ids: [Object.values(API_CODE.media)],
    description: allMedia,
    image_id: [API_CODE.media.wp, API_CODE.media.homepage],
    is_limited_to_fair: [API_CODE.media.wp],
    is_limited_to_webtsuku: [API_CODE.media.wp],
    is_media_only: [],
    recommended: [API_CODE.media.wp],
    title: allMedia,
    type: allMedia,
    valid_from: [API_CODE.media.ksm, API_CODE.media.wp, API_CODE.media.homepage],
    valid_period_text: [],
    valid_until: [API_CODE.media.ksm, API_CODE.media.wp, API_CODE.media.homepage],
  },
  enabledByMedia: {
    media_ids: false,
    description: true,
    image_id: true,
    is_limited_to_fair: false,
    is_limited_to_webtsuku: false,
    is_media_only: false,
    recommended: false,
    title: true,
    type: false,
    valid_from: true,
    valid_until: true,
  },
};

export default wpState;
